import * as React from 'react';
import {
    Badge, Box, Divider, Drawer, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import LoginIcon from '@mui/icons-material/Login';
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import {AuthContext} from "../contexts/AuthProvider";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BusinessIcon from '@mui/icons-material/Business';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import EuroIcon from '@mui/icons-material/Euro';
import GridOnIcon from '@mui/icons-material/GridOn';
import theme from "../theme";
import config from "../config.js";
import ContainerIcon from "../icons/ContainerIcon";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {DexieContext} from "../contexts/DexieProvider";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../common/db";
import TerminalIcon from "../icons/TerminalIcon";
import LaneIcon from "../icons/LaneIcon";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {default as AutoActionIcon} from '@mui/icons-material/AutoFixHigh';
import PowerOffIcon from '@mui/icons-material/PowerOff';


const menu = [
    {
        id: 'Engineering',
        roles: ['ROLE_USER'],
        subText: 'Engineer Access',
        defaultOpen: true,
        offline: true,  // show this menu when offline.
        children: [
            // { id: 'Profile', icon: <FaceIcon />, path: '/user/profile' },
            { id: 'Containers', icon: <ContainerIcon />, path: '/user/containers', offline: true },
            { id: 'Unplugs', icon: <PowerOffIcon />, path: '/user/unplugs', offline: true },
            { id: 'Action Detail Report', icon: <ListAltIcon />, path: '/user/report/action', offline: false },
            { id: 'Worksheets', icon: <GridOnIcon />, path: '/user/worksheets', offline: false },
        ],
    },
    {
        id: 'Administration',
        roles: ['ROLE_ADMIN'],
        subText: 'Backoffice tools',
        defaultOpen: true,
        children: [
            { id: 'Shipping Lists', icon: <FormatListNumberedIcon />, path: '/adm/order' },
            { id: 'Container Admin', icon: <ContainerIcon />, path: '/adm/containers', badge: 'numUnlinked' },
            { id: 'Invoiceable Containers',  icon: <ReceiptIcon />, path: '/adm/invoiceable' },
            { id: 'Invoices',  icon: <ReceiptIcon />, path: '/adm/invoice' },
            { id: 'Users', icon: <PeopleIcon />, path: '/adm/user' },
            { id: 'Customers', icon: <BusinessIcon />, path: '/adm/customer' },
        ],
    },
    {
        id: 'Settings',
        roles: ['ROLE_ADMIN'],
        subText: 'System Configuration and Settings',
        defaultOpen: false,
        children: [
            { id: 'Terminals', icon: <TerminalIcon />, path: '/adm/terminal' },
            { id: 'Lanes', icon: <LaneIcon />, path: '/adm/lane' },
            { id: 'Customer Types', icon: <CorporateFareIcon />, path: '/adm/customer-type' },
            { id: 'Billing Frequencies', icon: <ScheduleIcon />, path: '/adm/billing-freq' },
            { id: 'Prices', icon: <EuroIcon />, path: '/adm/price' },
            { id: 'Action Types', icon: <TaskAltIcon />, path: '/adm/action-type' },
            { id: 'Auto Actions', icon: <AutoActionIcon />, path: '/adm/auto-action' },
            // { id: 'Test Datagrid Generator', icon: <EuroIcon />, path: '/adm/test-datagrid-generator' },
        ],
    },
];

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};

export default function Navigator(props) {
    // console.log(props);
    const { variant, onLinkClick, ...other } = props;
    const navigate = useNavigate();
    const [menuStates, setMenuStates] = React.useState(
        //  This creates an object where keys are menu ids, and values are true/false (i.e. open/closed)
        Object.assign({}, ...menu.map((m) => ({[m.id]: m.defaultOpen})))
    );
    // console.log(menuStates);

    // we need to know when the url changes (and what it changes to) so we can highlight the appropriate entry
    // in the menu
    const locn = useLocation();
    // console.log(locn);
    const auth = React.useContext(AuthContext);
    const dexie = React.useContext(DexieContext);

    const numUnlinked = useLiveQuery(() =>
        db.OrderContainer.where('status').anyOf(['PRESENT']).filter(oc => !oc.order_id).count(),
        [], 0
    );

    React.useEffect(() => {
        //  When the location changes, we need to expand the menu that has that path.
        menu.forEach((m) => m.children.forEach((c) => {
            if (c.path === locn.pathname) setMenuStates((prev) => ({
                ...prev,
                [m.id]: true
            }));
        }))
    }, [locn]);

    const toggleMenu = (id) => {

        setMenuStates((prev) => ({
            ...prev,
            [id]: !prev[id]
        }))
    };

    const doLogout = () => {
        // If they have unsynced changes, warn them that they won't be synced if they log out.
        if (dexie.unsyncedChangeCount) {
            if (window.confirm('You have unsaved changes. If you log out now, they will not save until you log in again.\n\nAre you sure you want to log out?')) {
                navigate('/');
                auth.logout()
            }
        }
        else {
            if (window.confirm("Logout?")) {
                navigate('/');
                auth.logout();
            }
        }
    };

    return (
        <Drawer variant={variant || 'permanent'} {...other}>
            <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 20, color: '#fff', py: 0.5 }} key="title">
                    {`${config.APP_NAME}`}
                </ListItem>
                <Link component={RouterLink} to="/" sx={{textDecoration: 'none'}} key="homeLink" onClick={onLinkClick}>
                    <ListItem sx={{...itemCategory, py: 0, px: 0 }} >
                        <ListItemButton selected={locn.pathname === '/'} sx={{
                            '&.Mui-selected': { color: '#4fc3f7', },
                            ...item}}>
                            <ListItemIcon sx={{
                                color: 'inherit', minWidth: 'auto',
                                marginRight: theme.spacing(2),
                                '& svg': { fontSize: 20 }
                            }}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText sx={{
                                primary: {
                                    fontSize: 14,
                                    fontWeight: theme.typography.fontWeightMedium,
                                },
                            }}>Home</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                {
                    menu.filter(c => auth.hasRole(c.roles) && (dexie.netOnline || c.offline))
                        .map(({ id, roles, subText, children }) => (
                        <Box key={id} sx={{ bgcolor: '#101F33' }}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    sx={{ pt: 1, px: 3, pb: 1 }}
                                    onClick={() => {toggleMenu(id)}}
                                >
                                    <ListItemText
                                        primary={id}
                                        secondary={!menuStates[id] ? subText : null}
                                        secondaryTypographyProps={{
                                            noWrap: true,
                                            fontSize: 12,
                                            lineHeight: '16px',
                                            color: 'rgba(255,255,255,0.5)',
                                        }}
                                        sx={{ color: '#fff' }}
                                    />
                                    <KeyboardArrowDownIcon
                                        sx={{
                                            mr: -1,
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            opacity: 1,
                                            transform: menuStates[id] ? 'rotate(-180deg)' : 'rotate(0)',
                                            transition: '0.2s',
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                            {menuStates[id] && children.filter(c1 => (dexie.netOnline || c1.offline)).map(({ id: childId, icon, path, badge }) => {
                                return (
                                    <Link component={RouterLink} to={path} sx={{ textDecoration: 'none' }} key={childId} onClick={onLinkClick}>
                                        <ListItem
                                            disablePadding
                                            secondaryAction={badge ?
                                                // For now we only have one type of badge - if others are added, this will need
                                                // to be a bit smarter about which number it shows.
                                                <Badge badgeContent={numUnlinked} color="warning"
                                                    sx={{mr: 1}}
                                                    title="You have containers In-Port, without an associated order" /> :
                                                undefined
                                            }
                                        >
                                            <ListItemButton selected={locn.pathname === path}
                                                            sx={{
                                                                '&.Mui-selected': { color: '#4fc3f7', },
                                                                ...item}}>
                                                <ListItemIcon sx={{
                                                    color: 'inherit', minWidth: 'auto',
                                                    marginRight: theme.spacing(2),
                                                    '& svg': { fontSize: 20 }
                                                }}>{icon}</ListItemIcon>
                                                <ListItemText sx={{
                                                    primary: {
                                                        fontSize: 14,
                                                        fontWeight: theme.typography.fontWeightMedium,
                                                    },
                                                }}>{childId}</ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                );
                            })}
                            <Divider sx={{ mt: 1 }} />
                        </Box>
                    ))
                }
                {
                    (auth.user) ?
                    <ListItem sx={item} key="logoutStatic"
                              onClick={() => {
                                  onLinkClick?.();
                                  doLogout();
                              }}>
                        <ListItemIcon sx={{
                            color: 'inherit', minWidth: 'auto',
                            marginRight: theme.spacing(2),
                            '& svg': { fontSize: 20 }
                        }}><LogoutIcon/></ListItemIcon>
                        <ListItemText sx={{
                            primary: {
                                fontSize: 14,
                                fontWeight: theme.typography.fontWeightMedium,
                            },
                        }}>Logout</ListItemText>
                    </ListItem> :
                    <Link component={RouterLink} to="/login" sx={{textDecoration: 'none'}} key="loginStatic" onClick={onLinkClick}>
                        <ListItem sx={{...item, py: 0, px:1, '&.Mui-selected': { color: '#4fc3f7', }, }}>
                            <ListItemButton selected={locn.pathname === '/login'}>
                                <ListItemIcon sx={{
                                    color: 'inherit', minWidth: 'auto',
                                    marginRight: theme.spacing(2),
                                    '& svg': { fontSize: 20 }
                                }}><LoginIcon/></ListItemIcon>
                                <ListItemText sx={{
                                    primary: {
                                        fontSize: 14,
                                        fontWeight: theme.typography.fontWeightMedium,
                                    },
                                }}>Login</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                }
            </List>
        </Drawer>
    );
}

import {default as pkg} from '../package.json';
import preval from 'preval.macro';

// This is a `let` (as opposed to a `const`) as the incoming serviceWorker modifies it on the fly.
let config = {
  // The name of the application.
  // Note: this differs from the `name` in package.json (which can't contain capital letters).
  APP_NAME: "Containers",

  // This is the same as the `version` in package.json.
  APP_VERSION: pkg.version,

  // 'development', 'production' or (if we ever use it) 'test'.
  BUILD_TYPE: process.env.NODE_ENV,

  // Build date (per https://github.com/facebook/create-react-app/issues/4960)
  BUILD_TIMESTAMP: preval`module.exports = new Date().getTime()`,

  // How often does dexie poll the server for changes (in ms).
  DEXIE_POLL_INTERVAL: 30000,

  // How often does a running serviceWorker check for updates (in ms).
  // Note: this happens at reload automatically, and the browser checks this once every 24hrs anyway, so a large
  // value here (e.g. an hour or more) isn't unreasonable.
  // 1800000 = 30 mins
  SW_UPDATE_FREQ: 1800000,

  // If set, we automatically skip-waiting on a new service worker. So a new serviceWorker is automatically installed
  // as soon as it is detected. This is generally safe to do, unless the serviceWorker changes the caching or routing
  // mechanisms in a way that would break the previous version of the app.
  // Note: this setting affects the update of the serviceWorker only (not the app in general). Update of the app is
  // controlled by `SW_RELOAD_HANDLING` below.
  SW_AUTO_SKIP_WAITING: true,

  // After applying the `SW_AUTO_SKIP_WAITING` above, what does the app do to trigger an actual reload (and
  // hence receive the new app).
  // Possible values are:
  //  'none'   - Do nothing, and just wait for the user to reload or close & re-open the app themselves.
  //             Note: the upgrade will effectively be silent in this case, but may take a long time if the user
  //             never closes the browser or reloads the page).
  //  'prompt' - Shows a prompt (which can't be dismissed) prompting the user to reload and hence get the new changes.
  //             The prompt includes a reload button.
  //  'force'  - Automatically reload the app as soon as the new serviceWorker (and hence new version of the app) is
  //             detected. Can lead to data loss if the user had unsaved changes.
  //             Note: in this case the value of `SW_AUTO_SKIP_WAITING` above is ignored (automatically set to true).
  SW_RELOAD_HANDLING: "force"
}

export default config;
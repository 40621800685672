import * as React from 'react';
import PropTypes from 'prop-types';
import {
    AppBar, Avatar, Badge, Box, Button, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, MenuList, Toolbar,
    Tooltip, Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/AuthProvider";
import LogoutIcon from '@mui/icons-material/Logout';
import FaceIcon from '@mui/icons-material/Face';
import LoginIcon from '@mui/icons-material/Login';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import InfoIcon from '@mui/icons-material/Info';
import config from "../config.js";
import {DexieContext} from "../contexts/DexieProvider";
import Dexie from "dexie";
// import {reconnectIfNeeded} from "../common/db";
import {useServiceWorker} from "../services/useServiceWorker";
import {useSnackbar} from "notistack";
import eventBus from "../common/EventBus";
import DevicesIcon from '@mui/icons-material/Devices';
// import {recordUserAct} from "../common/shared";
import CircularProgress from '@mui/material/CircularProgress';

//const lightColor = 'rgba(255, 255, 255, 0.7)';

export default function Header(props) {
    const auth = React.useContext(AuthContext);
    const dexie = React.useContext(DexieContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { onDrawerToggle } = props;
    const navigate = useNavigate();
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
    const profileMenuOpen = Boolean(profileMenuAnchorEl);
    const [pageTitle, setPageTitle] = React.useState(config.APP_NAME);
    const [lastSyncProgress, setLastSyncProgress] = React.useState(null);
    const [warnResyncNeeded, setWarnResyncNeeded] = React.useState(!dexie.netOnline && dexie.unsyncedChangeCount > 0);

    React.useEffect(() => {
        setWarnResyncNeeded(!dexie.netOnline && dexie.unsyncedChangeCount > 0);
    }, [dexie.netOnline, dexie.unsyncedChangeCount]);

    const openProfileMenu = (event) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };
    const closeProfileMenu = () => {
        setProfileMenuAnchorEl(null);
    };

    // Listen for a page title change as we'll need to re-render for it.
    React.useEffect(() => {
        const cb = (data) => {
            setPageTitle(data.title ?? config.APP_NAME);
        }
        eventBus.on('pageTitleChange', cb);
        return () => {
            eventBus.remove('pageTitleChange', cb);
        }
    }, []);

    React.useEffect(() => {
        // Listen for appNavigate messages, and navigate to the given path when we get one
        const cb = (data) => {
            // console.log('appNavigate', data);
            if (data.path) {
                navigate(data.path);
            }
        }
        eventBus.on('appNavigate', cb);
        return () => {
            eventBus.remove('appNavigate', cb);
        }
    }, [navigate]);

    // Listen for appPopup messages, and pop-up a toast when we get one
    React.useEffect(() => {
        const cb = (data) => {
            // console.log('appPopup', data);
            if (data.message) {
                enqueueSnackbar(data.message,
                    {
                        variant: data.variant ?? 'info',
                        autoHideDuration: data.autoHideDuration ?? 15000,
                        preventDuplicate: true,
                        key: data.key,
                    }
                );
            }
        }
        eventBus.on('appPopup', cb);
        return () => {
            eventBus.remove('appPopup', cb);
        }
    }, [enqueueSnackbar]);

    // Listen for appPopupClose messages, and close the toast if we have one open with the same key
    React.useEffect(() => {
        const cb = (data) => {
            // console.log('appPopupClose', data);
            closeSnackbar(data.key);
        }
        eventBus.on('appPopupClose', cb);
        return () => {
            eventBus.remove('appPopupClose', cb);
        }
    }, [closeSnackbar]);

    // Listen of appinstalled event, as we need to do some special handling for that
    React.useEffect(() => {
        const cb = (event) => {
            console.log('appinstalled event received');

            // Delete the PHPSESSID cookie, as it is from the browser that we came from, not us
            document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            // We also need to remove the 'clientIdentity' from dexie (it'll give us a new one the next time we sync)
            // so that we're not sharing it with the browser.
            // This is messy, as it's stored in dexie itself, and there doesn't seem to be any easy way to get it.
            // db._syncNodes.where('url').equalsIgnoreCase(SYNC_URL).first((node) => {
            //     console.log(node);
            //     if (node) {
            //         if (node.syncContext?.clientIdentity) {
            //             console.log('Deleting clientIdentity from dexie');
            //             delete node.syncContext.clientIdentity;
            //             console.log(node);
            //             db._syncNodes.put(node);
            //         }
            //     }
            // });
            // The above works, but it looks like the next sync just writes back in the clientIdentity anyway (I guess
            // it's stored somewhere in dexie), so we need to do it another way.
            // Instead we set a flag on the sync protocol, and the next time it syncs it'll see the flag and send an
            // empty clientIdentity to the server, which will then generate a new one for us.
            // (Note: this only works b/c we've deleted the session cookie above, so our session has no clientIdentity)
            const syncProt = Dexie.Syncable.registeredProtocols['rest_protocol'];
            console.log(syncProt);
            if (syncProt) {
                syncProt.resetClientIdentity = true;
            }
        }
        window.addEventListener('appinstalled', cb);
        return () => {
            window.removeEventListener('appinstalled', cb);
        }
    }, []);

    // Listen for syncProgress messages
    React.useEffect(() => {
        const cb = (data) => {
            // console.log('syncProgress', data);
            setLastSyncProgress({
                ...data,
                timestamp: Date.now(),
            });

            if (data.error) {
                enqueueSnackbar(
                    'Sync failure: ' + (data.message || 'Unknown error'),
                    {
                        variant: 'error',
                        preventDuplicate: true,
                        autoHideDuration: 15000,
                    })
            }
        }
        eventBus.on('syncProgress', cb);
        return () => {
            eventBus.remove('syncProgress', cb);
        }
    }, [enqueueSnackbar, setLastSyncProgress]);


    // We currently handle service worker updates in here because we need to popup a toast, and we can't do that
    // in App.js (the toast needs to be under `SnackbarProvider`). Works fine here though, as this component
    // is always rendered too.
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();

    React.useEffect(() => {
        if (showReload && waitingWorker) {
            enqueueSnackbar(
                <div>
                    A new version of this application is available.
                    <Button onClick={reloadPage}>RELOAD NOW</Button>
                </div>,
                {
                    persist: true,
                    variant: 'warning',
                    preventDuplicate: true,
                })
        }
    }, [waitingWorker, showReload, reloadPage, enqueueSnackbar]);

    let initials = auth.getInitials();
    let fullName = auth.getFullName();


    const doLogout = () => {
        // If they have unsynced changes, warn them that they won't be synced if they log out.
        if (dexie.unsyncedChangeCount) {
            if (window.confirm('You have unsaved changes. If you log out now, they will not save until you log in again.\n\nAre you sure you want to log out?')) {
                navigate('/');
                auth.logout()
            }
        }
        else {
            navigate('/');
            auth.logout()
        }
    };

    // console.log('lsp', lastSyncProgress);

    return (
        <React.Fragment>
            <AppBar color={warnResyncNeeded ? 'warning' : 'primary'} position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid sx={{ display: { xs: 'block' } }} item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs sx={{fontSize: 20}}>
                            {pageTitle}
                        </Grid>
                        <Grid item>
                            <DexieStatusIcon lastSyncProgress={lastSyncProgress} />
                        </Grid>
                        <Grid item>
                            <Tooltip title={fullName}>
                                <IconButton
                                    color="inherit" sx={{ p: 0.5 }}
                                    id="profile-menu-button"
                                    aria-controls={profileMenuOpen ? 'profile-menu': undefined}
                                    aria-haspopup="true"
                                    aria-expanded={profileMenuOpen ? 'true': undefined}
                                    onClick={openProfileMenu}>
                                    <Avatar>{
                                        initials
                                    }</Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="profile-menu"
                                open={profileMenuOpen}
                                anchorEl={profileMenuAnchorEl}
                                onClose={closeProfileMenu}
                                onClick={closeProfileMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'profile-menu-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                <MenuList dense>
                                    {
                                        (() => {
                                            if (!auth.isLoggedIn()) {
                                                return (<MenuItem onClick={() => { navigate('/login')}}>
                                                    <ListItemIcon><LoginIcon fontSize="small" /></ListItemIcon>
                                                    Login
                                                </MenuItem>);
                                            }
                                            else {
                                                const items = [
                                                    <MenuItem key="profMenu" onClick={() => { navigate('/user/profile') }}>
                                                        <ListItemIcon><FaceIcon fontSize="small" /></ListItemIcon>
                                                        About Me
                                                    </MenuItem>,
                                                    <Divider key="divProf" />,
                                                ];

                                                if (auth.hasRole('ROLE_SUPER')) {
                                                    items.push(
                                                        <MenuItem key="devicesMenu" onClick={() => { navigate('/adm/device') }}>
                                                            <ListItemIcon><DevicesIcon fontSize="small" /></ListItemIcon>
                                                            Devices
                                                        </MenuItem>
                                                    );
                                                    items.push(<Divider key="divAdmin" />);
                                                }

                                                items.push(
                                                    <MenuItem key="aboutMenu" onClick={() => { navigate('/about') }}>
                                                        <ListItemIcon><InfoIcon fontSize="small" /></ListItemIcon>
                                                        About {config.APP_NAME}
                                                    </MenuItem>,
                                                    <MenuItem key="profLogout" onClick={doLogout}>
                                                        <ListItemIcon><LogoutIcon fontSize="small" /></ListItemIcon>
                                                        Logout
                                                    </MenuItem>,
                                                );


                                                return items;
                                            }
                                        })()
                                    }

                                </MenuList>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

Header.propTypes = {
    onDrawerToggle: PropTypes.func.isRequired,
};

function DexieStatusIcon(props) {
    const {lastSyncProgress} = props;
    const dexie = React.useContext(DexieContext);
    const auth = React.useContext(AuthContext);
    const [message, setMessage] = React.useState('');
    const showSyncProgress = lastSyncProgress && ((Date.now() - lastSyncProgress.timestamp) < 60000) &&
        !lastSyncProgress.complete && !lastSyncProgress.error && lastSyncProgress.totalBlocks > 1;

    // console.log(lastSyncProgress);

    // An irrepairable error occurred and the sync provider is dead.
    // ERROR = -1,
    //
    //     /** The sync provider hasnt yet become online, or it has been disconnected. */
    //     OFFLINE = 0,
    //
    //     /** Trying to connect to server */
    //     CONNECTING = 1,
    //
    //     /** Connected to server and currently in sync with server */
    //     ONLINE = 2,
    //
    //     /** Syncing with server. For poll pattern, this is every poll call.
    //      * For react pattern, this is when local changes are being sent to server. */
    //     SYNCING = 3,
    //
    //     /** An error occured such as net down but the sync provider will retry to connect. */
    //     ERROR_WILL_RETRY = 4

    React.useEffect(() => {
        let msg = dexie.statusName;

        if (showSyncProgress) {
            msg = `Synced ${lastSyncProgress?.currentBlock} of ${lastSyncProgress?.totalBlocks} blocks`;
        }
        else {
            if (!dexie.netOnline) {
                // If their device is offline, we don't care about status really.
                msg = 'Your device is currently offline.';
            } else if (dexie.status === 4) {
                // Special case, 'ERROR_WILL_RETRY' is likely to cause undue worry, make it more friendly
                msg = 'Temporarily offline.';
            }

            if (dexie.unsyncedChangeCount) {
                if (dexie.unsyncedChangeCount === 1) {
                    msg += '\nOne unsaved change.';
                } else {
                    msg += `\n${dexie.unsyncedChangeCount} unsaved changes.`;
                }
            }

            if (dexie.netOnline) {
                if (!auth.isLoggedIn()) {
                    msg += '\nPlease login to sync changes.';
                } else {
                    switch (dexie.status) {
                        default:
                        case Dexie.Syncable.Statuses.OFFLINE:
                            msg += '\nSynchronisation has been halted, click here to connect again.';
                            break;
                        case Dexie.Syncable.Statuses.CONNECTING:
                            msg += ' \nConnecting, please wait a moment...';
                            break;
                        case Dexie.Syncable.Statuses.ONLINE:
                            if (dexie.unsyncedChangeCount) {
                                msg += "\nYour changes will be saved in a moment...";
                            }
                            break;
                        case Dexie.Syncable.Statuses.SYNCING:
                            msg += '\nChanges are being saved, please wait a moment...';
                            break;
                        case Dexie.Syncable.Statuses.ERROR_WILL_RETRY:
                            msg += '\nTemporary error during sync, will retry again in a moment...';
                            break;
                        case Dexie.Syncable.Statuses.ERROR:
                            msg += '\nSync has been halted due to a permanent error, click here to retry.';
                            break;
                    }
                }
            }
        }

        setMessage(msg);
    }, [dexie.statusName, dexie.status, dexie.netOnline, dexie.unsyncedChangeCount, auth,
        showSyncProgress, lastSyncProgress]);

    const handleSyncButtonClick = () => {

        // this button does nothing now
        // cf: https://tickety.pentagoncomputers.com/ticket/217
        // const lastSyncSecsAgo = (lastSyncProgress && lastSyncProgress.timestamp) ? (Date.now() - lastSyncProgress.timestamp) / 1000 : 999999;
        // const lastSyncStartTime = Number(localStorage.getItem('lastSyncStartTime') ?? 0);
        // const lastSyncStartSecsAgo = (lastSyncStartTime) ? (Date.now() - lastSyncStartTime) / 1000 : 999999;
        // const willHonour = auth.isLoggedIn() && dexie.netOnline && lastSyncSecsAgo > 60 && lastSyncStartSecsAgo > 10;
        //
        // recordUserAct( {
        //     action: 'syncButton',
        //     user: auth.user,
        //     willHonour: willHonour,
        // });
        //
        // if (willHonour) {
        //     reconnectIfNeeded(true);
        // }
    }

    const getSyncProgress = () => {
        try {
            if (lastSyncProgress && lastSyncProgress.totalBlocks && lastSyncProgress.currentBlock) {
                return 100 * lastSyncProgress.currentBlock / lastSyncProgress.totalBlocks;
            }
            return 0;
        }
        catch (e) {
            // Ensure this never fails - can't risk losing the header over it.
            return 0;
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{message}</span>} arrow>
                <IconButton color="inherit" onClick={handleSyncButtonClick}>
                    {
                        showSyncProgress ? (
                            <CircularProgressWithLabel value={getSyncProgress()} color={'warning'} size={32} />
                        ): (
                            <Badge color={'secondary'} badgeContent={dexie.unsyncedChangeCount} overlap="circular">
                                {(() => {
                                    if (dexie.netOnline) {
                                        switch (dexie.status) {
                                            default:
                                            case Dexie.Syncable.Statuses.OFFLINE:
                                                return <CloudOffIcon color="warning"/>;
                                            case Dexie.Syncable.Statuses.CONNECTING:
                                                return <CloudQueueIcon/>;
                                            case Dexie.Syncable.Statuses.ONLINE:
                                                return <CloudDoneIcon/>
                                            case Dexie.Syncable.Statuses.SYNCING:
                                                return <CloudSyncIcon/>
                                            case Dexie.Syncable.Statuses.ERROR_WILL_RETRY:
                                                return <CloudOffIcon color="warning"/>;
                                            case Dexie.Syncable.Statuses.ERROR:
                                                return <CloudOffIcon color="error"/>;
                                        }
                                    }
                                    else {
                                        return <CloudOffIcon color="disabled"/>;
                                    }
                                })()}
                            </Badge>
                        )
                    }

                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}

DexieStatusIcon.propTypes = {
    lastSyncProgress: PropTypes.object,
};


function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="inherit">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};
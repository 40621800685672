import React, {useCallback} from "react";
import {Badge, Box, LinearProgress, SpeedDial, SpeedDialAction, useTheme} from "@mui/material";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import PropTypes from "prop-types";
import {ACT_TYPE, formatReefer, prettyDateTime, VENT_POSNS} from "../../../common/shared";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {
    CHECK_COL_KEY, COLS_KEY, DFLT_COLUMNS, IMPORT_EXPORT_ICONS, IMPORT_EXPORT_LABELS, NUM_ROWS_KEY, REQD_COLUMNS,
    SORT_MODEL_KEY
} from "./defaults";
import useMediaQuery from "@mui/material/useMediaQuery";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import ActionIcon from "../../../components/ActionIcon";
import {alpha} from "@mui/material/styles";
import ActionDialog from "./ActionDialog";
import BulkActionDialog from "./BulkActionDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SelectActionDialog from "./SelectActionDialog";
import ThermIcon from "../../../icons/ThermIcon";
import {useSnackbar} from "notistack";
import {DateTime} from "luxon";

// What's the maximum number of actions we can have in the bulk action menu?
const MAX_BULK_ACTIONS = 6;


const ContainersTable = React.forwardRef(function ContainersTable(props, ref) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState(() => {
        // Calculate the initial filter (on the off-chance that this is first generated with a filter)
        let filtStr = (props.filterUnit ?? '').replace(' ', '').toUpperCase();
        if (filtStr) return {
            items: [{columnField: 'reefer_num', operatorValue: 'contains', value: filtStr}]
        };
        return {items: []};  //  no filter
    });
    const [pageSize, setPageSize] = React.useState(() => {
        const sz = parseInt(localStorage.getItem(NUM_ROWS_KEY));
        if ([25, 50, 100].includes(sz)) return sz;
        return 100;
    });
    const [sortModel, setSortModel] = React.useState(() => {
        const sm = JSON.parse(localStorage.getItem(SORT_MODEL_KEY));
        if (sm) return sm;
        return [{field: 'reefer_num', sort: 'asc'}];
    });
    const users = useLiveQuery(() => db.User.toArray());
    const allStatuses = useLiveQuery(() => db.ContainerStatus.toArray(), [], []);
    const notShippedStatuses = useLiveQuery(() => db.ContainerStatus.where('has_shipped').equals(0)
        .toArray(arr => arr.map(a => a.status)), [], undefined);
    const presentStatuses = useLiveQuery(() => db.ContainerStatus.where({'has_arrived': 1, 'has_shipped': 0})
        .toArray(arr => arr.map(a => a.status)), [], []);
    const dailyMonitorAction = useLiveQuery(() => db.ActionType.where('data_type')
        .equals(ACT_TYPE.MONITOR)
        .filter(a => a.system && !a.deleted).first(), [], null);
    const recordTempActionIds = useLiveQuery(() => db.ActionType.where('data_type')
        .equals(ACT_TYPE.RECTEMP)
        .filter(a => !a.deleted).toArray(arr => arr.map(a => a.id)), [], undefined);
    const allDepotsById = useLiveQuery(() => db.Depot.toArray(arr => arr.reduce(
        (obj, cur) => ({...obj, [cur.id]: cur}), {})), [], {});


    //const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), [], []);
    const depotRow = useLiveQuery(() => props.depot ? db.Depot.get(props.depot) : null, [props.depot]);
    const lanesById = useLiveQuery(() => db.Lane.toArray(arr => arr.reduce(
        (obj, cur) => ({...obj, [cur.id]: cur}), {})), [], undefined);
    const customersById = useLiveQuery(() => db.Customer.toArray(arr => arr.reduce(
        (obj, cur) => ({...obj, [cur.id]: cur}), {})), [], undefined);

    // Get the list of actionTypeIds available for each status, so we don't have to re-query it each time the
    // selection changes.
    const actionTypeIdsByContainerStatus = useLiveQuery(() => db.ContainerStatusActionType.toArray(arr => {
        const ret = {};
        arr.forEach(a => {
            if (!ret[a.status]) ret[a.status] = [];
            ret[a.status].push(a.action_type_id);
        });
        return ret;
    }), [], []);

    const actionTypeById = useLiveQuery(
        () => db.ActionType.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {}))
        , [], {});

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    // This updates the filter every time props.filterUnit changes
    React.useEffect(() => {
        let filtStr = (props.filterUnit ?? '').replaceAll(' ', '').toUpperCase();
        if (filtStr) {
            setFilterModel({
                items: [{columnField: 'reefer_num', operatorValue: 'contains', value: filtStr}]
            });
        }
        else {
            setFilterModel({items: []});
        }
    }, [props.filterUnit]);

    const [showCheckCol, setShowCheckCol] = React.useState(() => {
        const c = localStorage.getItem(CHECK_COL_KEY);
        if (c === 'true') return true;
        if (c === 'false') return false;
        // By default, we show the column on large screens.
        return isLgUp;
    });

    // We need today's date (in Irish time as an iso string) to filter out containers that aren't due yet.
    const [todayDateStr, setTodayDateStr] = React.useState(DateTime.local({ zone: "Europe/Dublin" }).toSQLDate());
    // Also handy to have a luxon object which is the start of today
    const [startOfToday, setStartOfToday] = React.useState(DateTime.local({ zone: "Europe/Dublin" }).startOf('day'));

    // And we also need this date to be updated when the irish date changes
    React.useEffect(() => {
        const checkDate = () => {
            const newDate = DateTime.local({ zone: "Europe/Dublin" }).toSQLDate();
            if (newDate !== todayDateStr) setTodayDateStr(newDate);

            const newStart = DateTime.local({ zone: "Europe/Dublin" }).startOf('day');
            if (!newStart.equals(startOfToday)) setStartOfToday(newStart);
        }
        const interval = setInterval(checkDate, 60*1000);

        return () => clearInterval(interval);
    }, [todayDateStr, startOfToday]);

    React.useImperativeHandle(ref, () => ({
        selectContainer: (uuid) => {
            setSelected([uuid]);
        },
        getColumnVisibility: () => {
            return columns.map(c => ({
                field: c.field,
                headerName: c.headerName,
                visible: REQD_COLUMNS.includes(c.field) || (colVis?.[c.field] ?? true),
                forced: REQD_COLUMNS.includes(c.field),
            }))
        },
        setColumnVisibility: (cols) => {
            setColVis((oldVis) => {
                const newCols = {
                    ...oldVis,
                    ...cols
                };
                // Ensure REQD_COLUMNS are always true
                REQD_COLUMNS.forEach(k => newCols[k] = true);
                localStorage.setItem(COLS_KEY, JSON.stringify(newCols));
                return newCols;
            });
        },
        getCheckColumnVisibility: () => {
            return showCheckCol;
        },
        setCheckColumnVisibility: (show) => {
            setShowCheckCol(!!show);
            localStorage.setItem(CHECK_COL_KEY, show ? 'true' : 'false');
        }
    }));

    const [actionToAdd, setActionToAdd] = React.useState();
    const [allActsDlgVisible, setAllActsDlgVisible] = React.useState(false);

    const handleRecordTemp = React.useCallback(async (oc) => {
        // console.log(oc);
        // Select the row first
        onSelectedRowsChange([oc.uuid]);
        // For this we need to identify the action type that has the "record temp" action, and is available for this container.
        const recTempActType = await db.ActionType.where('data_type').equals(ACT_TYPE.RECTEMP)
            .filter(at => (!at.deleted && actionTypeIdsByContainerStatus?.[oc.status]?.includes(at.id))).first();
        if (!recTempActType) {
            enqueueSnackbar('No action available to record temperature for this container', {variant: 'error'});
        }
        else {
            handleAddAction(recTempActType);
        }
    }, [enqueueSnackbar, actionTypeIdsByContainerStatus]);

    const columns = React.useMemo(() => [
        {
            field: 'lane_name',
            headerName: 'Lane',
            minWidth: 80,
            maxWidth: 100,
            flex: 0.5,
            valueGetter: ({row}) => row.lane?.name
        },
        {
            field: 'reefer_num',
            headerName: 'Unit Number',
            hideable: false,
            width: 130,
            valueFormatter: ({value}) => formatReefer(value),
            cellClassName: 'reefer-num'
        },
        {
            field: 'set_temp',
            headerName: 'Set Point',
            type: 'number',
            width: 80,
            valueFormatter: ({value}) => value === null ? '-' : Number(value).toFixed(1) + '°C',
        },
        {
            field: 'customer.name',
            headerName: 'Customer',
            minWidth: 80,
            flex: 1.5,
            valueGetter: ({row}) => row.customer?.name
        },
        {
            field: 'actions',
            headerName: 'Action',
            type: 'actions',
            width: 70,
            align: 'left',
            getActions: (params) => {
                const acts = [];

                // For speed here, we assume that there's a record temp action available for all `presentStatuses`.
                if (presentStatuses.includes(params.row.status)) {
                    acts.push(<GridActionsCellItem icon={<ThermIcon />}
                                                   label="Record Temperature"
                                                   title="Record Temperature"
                                                   onClick={() => handleRecordTemp(params.row)}
                    />);
                }

                return acts;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 80,
            flex: 1,
            maxWidth: 120,
            valueGetter: ({value}) => allStatuses.find(s => s.status === value)?.status_name ?? '- Not Set -',
            cellClassName: ({row}) => `status-${row.status}`,
        },
        {
            field: 'ship',
            headerName: 'Ship',
            description: 'Name of Vessel',
            flex: 1,
            minWidth: 80,
            type: 'string',
        },
        {
            field: 'set_humidity',
            headerName: 'Set Humidity',
            type: 'number',
            width: 80,
            valueFormatter: ({value}) => value === null ? '-' : Number(value).toFixed(0) + '%',
        },
        {
            field: 'sail_date',
            headerName: 'Vessel Date',
            description: 'Scheduled sail date.',
            flex: 1,
            minWidth: 100,
            // valueGetter: (params) => prettyDate(params.value)
            type: 'date',
            valueGetter: ({ value }) => {
                if (!value) return null;
                try {
                    return DateTime.fromSQL(value).toJSDate();
                }
                catch (e) {
                    console.error(e);
                    return null;
                }
            },
            valueFormatter: ({ value }) => {
                if (!value) return null;
                try {
                    return DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT);
                }
                catch (e) {
                    console.error(e);
                    return null;
                }
            }
        },
        {
            field: 'lastAction',
            headerName: 'Last Action Today',
            minWidth: 50,
            maxWidth: 230,
            flex: 1,
            // valueGetter only needed so we can sort by action label.
            valueGetter: ({row}) => {
                if (row.lastAction) {
                    // Since this only shows actions from today now, we return null when the action_date isn't today.
                    const dtStr = `${row.lastAction.action_date}${row.lastAction.action_time ? (' ' + row.lastAction.action_time) : ''}`;
                    const luxDt = DateTime.fromSQL(dtStr, { zone: 'Europe/Dublin' });

                    if (luxDt >= startOfToday) {
                        return `${actionTypeById[row.lastAction.action_type_id]?.label}`;
                    }
                }
                return null;
            },
            renderCell: ({row, value}) => {
                if (!value) return null;    //  no value -> don't render.  Note: value is just the label here, from the valueGetter above.
                const actionType = actionTypeById[row.lastAction?.action_type_id];
                if (!actionType) return null;
                const useColour = {
                    'default': 'primary',
                    'AM': 'success',
                    'MD': 'warning',
                    'PM': 'error',
                }[row.lastAction?.time_slot ?? 'default'];
                return (
                    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                        {(() => {
                            if (row.lastAction?.time_slot) {
                                return (<Badge
                                    badgeContent={row.lastAction?.time_slot?.[0] ?? ''}
                                    color={useColour}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    overlap="circular"
                                    sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 12, minWidth: 12 } }}
                                >
                                    <ActionIcon iconData={actionType?.icon_data}
                                                sx={{color: actionType?.icon_color, alignSelf: 'center',mr: '2px'}}/>
                                </Badge>);
                            }
                            else {
                                return (<ActionIcon iconData={actionType?.icon_data}
                                                    sx={{color: actionType?.icon_color, alignSelf: 'center',mr: '2px'}}/>);
                            }
                        })()}
                        <span>
                                {actionType?.label}
                        </span>
                    </Box>
                )
            }
        },
        {
            field: 'lastTemp',
            headerName: 'Last Temp',
            minWidth: 50,
            maxWidth: 100,
            flex: 1,
            valueGetter: ({row}) => {
                // console.log(row);
                if (!row.lastRecTempAction) return null;

                // Convert data_value to a float (if it is one) and round to 1 decimal place.
                let temp = parseFloat(row.lastRecTempAction.data_value);
                temp = isNaN(temp) ? ' - ' : temp.toFixed(1) + '°C';

                // lankel requested specifically just the timeslot here and not the date (even if it's weeks ago).
                return `${temp} ${row.lastRecTempAction.time_slot ?? ''}`.trim();
            },
        },
        {
            field: 'order.import_export',
            headerName: 'Import/Export',
            minWidth: 50,
            maxWidth: 120,
            flex: 1,
            valueGetter: ({row}) => IMPORT_EXPORT_LABELS?.[row.order?.import_export],
            renderCell: ({row, value}) => (
                <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                    {IMPORT_EXPORT_ICONS[row.order?.import_export]} {value}
                </Box>
            )
        },
        // {
        //     field: 'lane_name',
        //     headerName: 'Lane',
        //     minWidth: 50,
        //     maxWidth: 100,
        //     flex: 0.5,
        //     valueGetter: ({row}) => row.lane?.name
        // },
        {
            field: 'cargo_description',
            headerName: 'Cargo',
            minWidth: 50,
            flex: 1.5,
        },
        {
            field: 'booking_ref',
            headerName: 'Booking Ref',  // DB 2023-01-16: The new booking ref
            minWidth: 50,
            maxWidth: 100,
            valueGetter: ({row}) => row.booking_ref ?? '',
        },
        {
            field: 'vent',
            headerName: 'Vent',
            width: 80,
            valueFormatter: ({value}) => VENT_POSNS[value] ?? '-'
        },
        {
            field: 'in_at',
            headerName: 'Arrived On',
            description: 'When the container arrived.',
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => prettyDateTime(params.value, {isMagic: true})
        },
        {
            field: 'out_at',
            headerName: 'Dispatched On',
            description: 'When the container was dispatched.',
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => prettyDateTime(params.value, {isMagic: true})
        },
        {
            field: 'ie',
            headerName: 'I/E Cycle',
            description: 'Import/Export',
            align: 'center',
            width: 70,
            valueGetter: ({row}) => row.ie || '-',
        },
        {
            field: 'lastActionBy',
            headerName: 'Last Action By',
            minWidth: 50,
            flex: 1,
            valueGetter: ({row}) => {
                if (row.lastAction && users) {
                    const u = users.find(u => u.id === row.lastAction.recorded_by);
                    return (u ? `${u.firstname} ${u.surname}` : '');
                }
                return '';
            }
        },
        {
            field: 'customer_note',
            headerName: 'Notes',
            description: 'Customer Notes',
            flex: 1,
            minWidth: 80,
            type: 'string',
        },
        {
            field: 'order.booking_ref',
            headerName: 'Ship Ref',  // DB 2023-01-16: Renamed to avoid confusion with new booking_ref field in oc.
            minWidth: 50,
            maxWidth: 100,
            valueGetter: ({row}) => row.order?.booking_ref ?? '',
        },
        {
            field: 'lane_id',
            headerName: 'Lane ID',
            minWidth: 50,
            maxWidth: 100,
            flex: 0.5,
        },
        {
            field: 'order_id',
            headerName: 'Shipping List ID',
            minWidth: 50,
            maxWidth: 100,
            type: 'number',
        },
        {
            field: 'lastActionAt',
            headerName: 'Last Action At',
            minWidth: 50,
            maxWidth: 130,
            flex: 1,
            valueGetter: ({row}) => row.lastAction ? `${row.lastAction.action_date}${row.lastAction.action_time ? (' ' + row.lastAction.action_time) : ''}` : null,
            valueFormatter: (params) => {
                // console.log(params);
                return params.value ? prettyDateTime(params.value, {isMagic: true, useTomorrow: true, useYesterday: true, inTz: 'Europe/Dublin'}) : 'None'
            }
        },
        {
            field: 'lastActionByInitials',
            headerName: 'Last Action By (Initials)',
            width: 50,
            valueGetter: ({row}) => {
                if (row.lastAction && users) {
                    const u = users.find(u => u.id === row.lastAction.recorded_by);
                    return (u ? u.initials : '');
                }
                return '';
            }
        }
        // Removed per lankel/lankel#34
        // {
        //     field: 'voyage_number',
        //     headerName: 'Voyage Number',
        //     description: 'Customer Voyage Number',
        //     flex: 1,
        //     minWidth: 50,
        //     type: 'string',
        // },
    ], [users, allStatuses, actionTypeById, presentStatuses, handleRecordTemp, startOfToday]);

    const [colVis, setColVis] = React.useState(() => {
        try {
            const c = localStorage.getItem(COLS_KEY);
            let cols = null;
            if (c) cols = JSON.parse(c); //  note: `c` can be the string 'null', so `cols` might end up being null here.

            if (!cols) {
                // cols = {
                //     cargo_description: false,
                //     order_id: false,
                //     'order.booking_ref': false, // now labelled 'Ship Ref'
                //     booking_ref: false,
                //     in_at: false,
                //     out_at: false,
                //     ship: false,
                //     sail_date: false,
                //     lane_id: false,
                //     // voyage_number: false,  removed per lankel/lankel#34
                //     customer_note: false,
                //     lastTemp: false,
                //     lastActionAt: false,
                //     lastActionBy: false,
                //     set_humidity: false,
                //     vent: false,
                // };
                // By default, just give them all DFLT_COLUMNS.
                // To do this, we need to set anything that isn't in DFLT_COLUMNS to false.
                cols = {}
                columns.forEach(col => {
                    if (!DFLT_COLUMNS.includes(col.field)) {
                        cols[col.field] = false;
                    }
                })
            }

            // Make sure all the keys in REQD_COLUMNS are true
            Object.keys(cols).forEach(k => {
                if (REQD_COLUMNS.includes(k)) {
                    cols[k] = true;
                }
            })

            return cols;

        }
        catch (e) {
            console.error(e);
            // Safe fallback if the above screws up again.
            return {
                "vent":false,
                "in_at":false,
                "out_at":false,
                "ie":false,
                "lastActionBy":false,
                "customer_note":false,
                "ship":false,
                // "order.booking_ref":false,
                "lane_id":false,
                "order_id":false,
                "lastActionAt":false,
                "lastActionByInitials":false,
            };
        }
    });

    const showDepotIds = useLiveQuery(() => {
        if (props.depot) return [props.depot];
        if (!props.portCode) return []; //  sanity
        return db.Depot.where('port_code').equals(props.portCode).toArray(arr => arr.map(d => d.id));
    }, [props.depot, props.portCode], []);

    const ocs = useLiveQuery(
        () => {

            const start = Date.now();
            setLoading(true);

            // if the dependencies are still loading, no point in running this query
            if (!lanesById) return [];
            if (!customersById) return [];
            if (!recordTempActionIds) return [];
            if (!notShippedStatuses) return [];

            // console.log(showDepotIds, props.laneGroupId, props.ship, props.status, lanesById, customersById,
            //     notShippedStatuses, dailyMonitorAction, allDepotsById, todayDateStr, recordTempActionIds, colVis);

            return db.OrderContainer.where('status').anyOf((props.status && props.status.length > 0) ? props.status : notShippedStatuses)
                .and(oc => showDepotIds.includes(oc.depot_id))
                .toArray(async (arr) => {
                    // console.log(arr);
                    // console.log('OrderContainer query returned', arr.length, 'rows');

                    // We can save a lot of effort here by only doing the lookups that we'll actually need to display.
                    const needCustomers = colVis['customer.name'] !== false;
                    const needLastActions = colVis['lastTemp'] !== false || colVis['lastAction'] !== false ||
                        colVis['lastActionAt'] !== false || colVis['lastActionBy'] !== false ||
                        colVis['lastActionByInitials'] !== false;
                    // Note: Since lankel/lankel#28, we always need orders, as we have to filter out containers not due yet.

                    console.log('needsCustomers', needCustomers, 'needLastActions', needLastActions);

                    // if there's a laneGroup filter, need to apply it here
                    if (props.laneGroupId) {
                        arr = arr.filter(oc => lanesById?.[oc.lane_id]?.lane_group_id === props.laneGroupId);
                    }

                    // if there's a ship filter, also apply it here
                    // if (props.ship) {
                    //     arr = arr.filter(oc => oc.ship === props.ship);
                    // }

                    // if there's an order filter, also apply it here
                    if (props.orderIds && props.orderIds.length > 0) {
                        arr = arr.filter(oc => oc.order_id && props.orderIds.includes(oc.order_id));
                    }

                    const orders = await Promise.all(arr
                            .filter(a => a.order_id)    //  ignore entries with no order_id
                            .map(a => db.Order.get(a.order_id))
                        )
                            // just in case, we filter out any orders we don't know about (which would be undefined in this array).
                            // these should never happen normally, but it could be that dexie simply hasn't synced them yet.
                            .then(orders => orders.filter(o => o));

                    //  Now put each order and lane into the ocs array
                    arr = arr.map(oc => ({
                        ...oc,
                        order: oc.order_id ? orders.find(o => o?.id === oc.order_id) ?? null: null,
                        lane: oc.lane_id ? lanesById?.[oc.lane_id] ?? null : null,
                    }));

                    // let custProms = [];
                    // if (needCustomers) {
                    //     //  Get customers for these orders
                    //     custProms = orders.map(o => db.Customer.get(o.customer_id));
                    // }

                    let lastActsProms = [];
                    if (needLastActions) {
                        //  Without waiting for the above, lookup the last action and the last RECTEMP action on each container too
                        lastActsProms = arr.map(oc =>
                            db.Action.where('order_container_uuid')
                                .equals(oc.uuid)
                                .filter(a => !a.deleted)
                                .filter(a => a.action_type_id !== dailyMonitorAction?.id)
                                .sortBy('recorded_at')
                                .then((acts) => {
                                    // We return 2 things here - the last action, and the last rectemp action
                                    const lastAct = acts.length > 0 ? acts[acts.length - 1] : null;
                                    // Note: `findLast` is ECMAScript 2022. I'm pretty sure that babel will 'fix' this for us.
                                    // Note2: it didn't (or at least CRAs cut-down version didn't).  We need to check if manually.
                                    let lastRecTemp;
                                    if (Array.prototype.findLast) {
                                        lastRecTemp = acts.findLast(a => recordTempActionIds.includes(a.action_type_id));
                                    } else {
                                        for (let i = acts.length - 1; i >= 0; i--) {
                                            if (recordTempActionIds.includes(acts[i].action_type_id)) {
                                                lastRecTemp = acts[i];
                                                break;
                                            }
                                        }
                                    }
                                    return [lastAct, lastRecTemp];
                                })
                        );
                    }

                    //  Wait on the promises and process when we have them.
                    // let custs = [];
                    // if (needCustomers) {
                    //     custs = await Promise.all(custProms);
                    // }

                    let lastActions = [];
                    let lastRecTemps = [];
                    if (needLastActions) {
                        const lastBothActions = await Promise.all(lastActsProms);

                        // lastBothActions is an array of arrays, the first entry of each being the last action, and the
                        // second entry being the last 'RECTEMP' action.  We need to split it into 2 arrays (without any
                        // nulls).
                        lastActions = lastBothActions.map(lba => lba[0]).filter(la => la);
                        lastRecTemps = lastBothActions.map(lba => lba[1]).filter(la => la);
                    }

                    // console.log(custs);
                    // console.log(lastActions);
                    //  And put them into the ocs array
                    arr = arr.map(oc => ({
                        ...oc,
                        customer: needCustomers && oc.order ? customersById?.[oc.order?.customer_id] : null,
                        lastAction: needLastActions ? lastActions.find(la => la?.order_container_uuid === oc.uuid) ?? null : null,
                        lastRecTempAction: needLastActions ? lastRecTemps.find(la => la?.order_container_uuid === oc.uuid) ?? null : null,
                        depot: oc.depot_id ? allDepotsById?.[oc.depot_id] : null,
                    }));

                    // lankel/lankel#28 says we should filter out containers in an order where the due date is in the future.
                    arr = arr.filter(oc => {
                        if (!oc.order) return true;
                        if (!oc.order.due_date) return true;
                        if (oc.order.import_export !== 'IMP') return true;  // lankel/lankel#48: now only applies to imports
                        return oc.order.due_date <= todayDateStr;
                    });

                    props.callbackNumContainers?.(arr.length)

                    if (needCustomers) {
                        // Lastly, if we have any rows without a customer (because they don't have an order), we need to
                        // look up the customer from the Container table.
                        const noCust = arr.filter(a => !a.customer);
                        // console.log(noCust);
                        if (noCust.length) {
                            const conts = (await Promise.all(noCust.map(oc => db.Container.get(oc.reefer_num)))).filter(c => c);
                            // Note: .filter(c => c) is to remove undefined values.  These should never happen, but I'm seeing
                            // them in production, so it's safer to deal with them.
                            // console.log(conts, customers, noCust);
                            const contCusts = await Promise.all(conts.filter(a => a.customer_id).map(c => ({
                                ...c,
                                customer: customersById?.[c.customer_id]
                            })));
                            //  And put them into the ocs array
                            arr = arr.map(oc => ({
                                ...oc,
                                customer: oc.customer ?? contCusts.find(c => c.reefer_num === oc.reefer_num)?.customer ?? null,
                            }));
                        }
                    }

                    // console.log(arr)

                    return arr;
                })
                .finally(() => {
                    setLoading(false);
                    console.log(`ocs query took ${Date.now() - start}ms`);
                });
        },
        [showDepotIds, props.laneGroupId, props.ship, props.status, lanesById, customersById,
            notShippedStatuses, dailyMonitorAction, allDepotsById, todayDateStr, recordTempActionIds, colVis]
    );

    const availBulkActions = useLiveQuery(async () => {
        // If the check column isn't visible, there are no bulk actions
        if (!showCheckCol) return [];
        if (!selected?.length) return [];
        if (!ocs?.length) return [];

        const bulkOnly = selected.length > 1;

        // For bulk actions, all the ocs must have the same depot_id (or be all null)
        if (bulkOnly) {
            const allDepotIds = ocs.filter(oc => selected.includes(oc.uuid)).map(oc => oc.depot_id);
            const uniqDepotIds = [...new Set(allDepotIds)];
            if (uniqDepotIds.length > 1) return [];
        }

        // Get the statuses for the selected containers
        const selStatuses = ocs.filter(c => selected.includes(c.uuid)).map(c => c.status);
        // Remove duplicates
        const uniqStatuses = [...new Set(selStatuses)];
        // console.log(uniqStatuses);

        // This can happen when the user filters and removes all the selected containers from the list:
        // `selected` won't have updated yet, and includes containers that are no longer in `ocs`.
        if (uniqStatuses.length === 0) return [];

        // We only want actionTypeIds that appear in ALL of these statuses.
        const actTypeIds = uniqStatuses.map(a => actionTypeIdsByContainerStatus?.[a] ?? []).reduce((a, b) => a.filter(c => b.includes(c)));
        // console.log(actTypeIds);

        // Need to go through the ocs again, as the available actions depend on location type too.
        const actIds = (await Promise.all(ocs.filter(c => selected.includes(c.uuid)).map(oc => {
            return db.ActionType.where('id').anyOf(actTypeIds)
                .filter(at => (!at.deleted) && (!bulkOnly || at.allow_bulk))
                .toArray(arr => {
                    // Need to filter arr for actionTypes that apply to this container.
                    return arr.filter(at => (
                        // oc doesn't have a location type, or
                        !oc.depot || oc.depot.entry_type === 'BLANK' ||
                        // the action type doesn't care what location type is, or
                        (at.available_in === 'BOTH') ||
                        // the action type location is for a depot, and the oc is in one, or
                        (at.available_in === 'DEPOT' && oc.depot.entry_type === 'DEPOT') ||
                        // the action type location is for a terminal, and the oc is in one.
                        (at.available_in === 'TERMINAL' && oc.depot.entry_type === 'TERMINAL'))
                    ).map(at => at.id); // we just need the ids of the ActionTypes here
                });
            }))).reduce((a, b) => a.filter(c => b.includes(c)));
            // the reduce/filter here is to get the intersection of all the arrays.
        // console.log(actIds);
        return db.ActionType.where('id').anyOf(actIds)
            .sortBy('sort_order');
    }, [selected, ocs, actionTypeIdsByContainerStatus, showCheckCol], []);

    const [speedDialExpanded, setSpeedDialExpanded] = React.useState(false);


    // console.log(ocs, props.depot, props.laneGroupId);



    const handleClick = (uuid) => {
        onSelectedRowsChange([uuid]);
        props.onContainerSelected(ocs.find((oc) => oc.uuid === uuid));
    }

    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const onPageSizeChange = (newSize) => {
        localStorage.setItem(NUM_ROWS_KEY, newSize)
        setPageSize(newSize);
    }

    const onSortModelChange = (newModel) => {
        localStorage.setItem(SORT_MODEL_KEY, JSON.stringify(newModel));
        setSortModel(newModel);
    }

    const onSelectedRowsChange = (newRows) => {
        setSelected(newRows);
        // console.log(newRows);
    }

    const statusColCssClasses = React.useMemo(() => {
        const classes = {};
        allStatuses.forEach(s => classes[`& .status-${s.status}`] = {
            borderLeftWidth: '4px',
            borderLeftStyle: 'solid',
            borderLeftColor: s.color,
        });
        return classes;
    }, [allStatuses]);

    const getRowClassName = useCallback((params) => {
        if (params.row.unplug_only && params.row.unplug_at?.substring(0, 10) === todayDateStr && !params.row.is_unplugged) {
            return 'unplug-today';
        }
        return params.row.is_flagged ? 'flagged' : '';
    }, [todayDateStr]);

    const handleAddAction = (actionType) => {
        // console.log('handleAddAction', actionType);
        setActionToAdd(actionType);
    }

    const onActionAdded = (/*acts*/) => {
        setActionToAdd(undefined);  //  this will close the dialog
    };

    // console.log('filterModel = ', filterModel, props.filterUnit);
    // console.log(props.depot);
    //console.log('colVis = ', colVis);

    return (
        <Box sx={{ display: 'flex', visibility: props.show ? 'visible' : 'hidden', height: '100%', ...props.sx }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={ocs ?? []}
                          loading={ocs == null || loading}
                          sx={{
                              borderRadius: 0,
                              bgcolor: '#ffffff',
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              "& .MuiDataGrid-cell:focus": {
                                  outline: "none",
                              },
                              '& .reefer-num > div': {
                                  textAlignLast: 'justify',
                                  width: '100%'
                              },
                              // Fix for the issue with header sort icons ignoring parent visibility.
                              '& .MuiDataGrid-iconButtonContainer': {
                                  visibility: 'inherit !important',
                              },
                              // 'flagged' rows get a special background color
                              '& .flagged': {
                                  backgroundColor: '#fcf983 !important',
                              },
                              // as does 'unplug-today'
                              '& .unplug-today': {
                                  backgroundColor: '#43d0ff !important',
                              },
                              ...statusColCssClasses
                          }}
                          getRowClassName={getRowClassName}
                          hideFooterSelectedRowCount={true}
                          disableColumnMenu={true}
                          disableColumnFilter={true}
                          onRowClick={(params) => handleClick(params.row.uuid)}
                          getRowId={(r) => r.uuid}
                          columnVisibilityModel={colVis}
                          onColumnVisibilityModelChange={onColVisChange}
                          components={{
                              NoRowsOverlay: () => {
                                  // console.log(depotRow);
                                  let msg = 'No Containers Found';
                                  let filterMsg = filterModel.items?.length > 0 ? ' matching your current filter' : '';
                                  // if (props.ship) {
                                  //     filterMsg += ` for ${props.ship}`;
                                  // }
                                  if (props.orderIds) {
                                      filterMsg += ` for Shipping Lists ${props.orderIds.join(', ')}`;
                                  }
                                  if (props.status && props.status.length > 0) {
                                      // console.log(props.status);
                                      // filterMsg += ` with status ${allStatuses.find(s => s.status === props.status)?.status_name || props.status}`;
                                      const statusName = allStatuses.filter(s => props.status.includes(s.status)).map(s => s.status_name).join(', ');
                                      filterMsg += ` with status ${statusName}`;
                                  }
                                  if (depotRow) {
                                      if (props.laneGroupId) {
                                          msg = `No Containers Found in this lane group${filterMsg}.`;
                                      }
                                      else {
                                          switch (depotRow.entry_type) {
                                              case 'DEPOT':
                                                  msg = `No Containers Found in this Depot${filterMsg}.`;
                                                  break;
                                              default:
                                              case 'TERMINAL':
                                                  msg = `No Containers Found in this Terminal${filterMsg}.`;
                                                  break;
                                              case 'BLANK':
                                                  msg = `There are no Containers${filterMsg} in this port without a Terminal or Depot.`
                                                  break;
                                          }
                                      }
                                  }
                                  else if (props.portCode) {
                                      msg = `No Containers Found in this Port${filterMsg}.`;
                                  }
                                  else {
                                      msg = 'Please select a port and terminal above.'
                                  }
                                  return (<Box sx={{textAlign: 'center', p: 3}}
                                               color="text.disabled">{msg}</Box>);
                              },
                              LoadingOverlay: LinearProgress,
                          }}
                          componentsProps={{
                          }}
                          onSelectionModelChange={onSelectedRowsChange}
                          selectionModel={selected}
                          filterModel={filterModel}
                          // This is the number of off-screen rows that get rendered. Defaults to 3, but we use 15
                          // here as data-grid also considers rows to be off-screen when the data-grid itself is
                          // hidden (which we do when they open the container view), and hence doesn't have any
                          // rows rendered when its shown again.
                          rowBuffer={15}
                          pageSize={pageSize}
                          onPageSizeChange={onPageSizeChange}
                          sortModel={sortModel}
                          onSortModelChange={onSortModelChange}
                          checkboxSelection={showCheckCol}
                />
            </Box>
            {props.show && showCheckCol && availBulkActions.length > 0 &&
                <SpeedDial
                    open={speedDialExpanded}
                    ariaLabel={"Container Action"}
                    sx={{position: 'fixed', bottom: 16 + 48, right: 16}}
                    icon={<SpeedDialIcon/>}
                    // Note: transitionDuration here _should_ control the speed at which the menu shows/hides.
                    // It's broken in mui at the moment, but if they ever fix it...
                    transitionDuration={{
                        enter: 50,
                        exit: theme.transitions.duration.leavingScreen
                    }}
                    FabProps={{
                        sx: {
                            bgcolor: alpha(selected.length > 1 ? theme.palette.secondary.main: theme.palette.primary.main, 0.5),
                            '&:hover': {
                                bgcolor: selected.length > 1 ? theme.palette.secondary.main: theme.palette.primary.main,
                            }
                        }
                    }}
                    onClick={(/*event*/) => {
                        // console.log('click', e);
                        setSpeedDialExpanded(oldVis => !oldVis);
                    }}
                    onClose={(e, reason) => {
                        // console.log('close', reason, e);
                        // We ignore 'mouseLeave' as a close reason (it's just silly on a desktop, and never fires
                        // on touch devices anyway).
                        // We also ignore 'toggle' as a reason, as we will get an onClick event for that anyway,
                        // which already toggles.
                        if (['blur', 'escapeKeyDown'].includes(reason)) setSpeedDialExpanded(false);

                    }}
                >
                    {
                        availBulkActions.length > MAX_BULK_ACTIONS ? [
                                availBulkActions.slice(0, MAX_BULK_ACTIONS - 1).map((at) => {
                                            return (
                                                <SpeedDialAction
                                                    key={at.id}
                                                    icon={<ActionIcon iconData={at.icon_data} sx={{color: `${at.icon_color ?? '#000000'} !important`}} />}
                                                    tooltipTitle={at.label}
                                                    tooltipOpen
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "none",
                                                    }}
                                                    onClick={() => handleAddAction(at)}
                                                />
                                            );
                                        }
                                    ),
                                    <SpeedDialAction
                                        key='other'
                                        icon={<MoreVertIcon/>}
                                        tooltipTitle="More..."
                                        tooltipOpen
                                        sx={{
                                            whiteSpace: "nowrap",
                                            maxWidth: "none",
                                        }}
                                        onClick={() => {
                                            // Too many bulk actions, prompt them to open the container (just the first one).
                                            //handleClick(selected?.[0])
                                            setAllActsDlgVisible(true);
                                        }}
                                    />
                                ] :
                                availBulkActions.map((at) => {
                                    return (
                                        <SpeedDialAction
                                            key={at.id}
                                            icon={<ActionIcon iconData={at.icon_data} sx={{color: `${at.icon_color ?? '#000000'} !important`}} />}
                                            tooltipTitle={at.label}
                                            tooltipOpen
                                            sx={{
                                                whiteSpace: "nowrap",
                                                maxWidth: "none",
                                            }}
                                            onClick={() => handleAddAction(at)}
                                        />
                                    );
                                }
                        )
                    }
                </SpeedDial>
            }
            {
                props.show && allActsDlgVisible && availBulkActions.length && selected?.length > 0 &&
                <SelectActionDialog
                    actions={availBulkActions}
                    onAction={(act) => {
                        // Ensure that the SelectActionDialog gets hidden
                        setTimeout(() => setAllActsDlgVisible(false), 300);
                        handleAddAction(act);
                    }}
                    onCancel={() => setAllActsDlgVisible(false)} />
            }
            {
                props.show && actionToAdd && selected?.length === 1 &&
                <ActionDialog
                    oc={ocs.find(oc => oc.uuid === selected[0])} actionType={actionToAdd}
                    onSave={onActionAdded}
                    onCancel={() => setActionToAdd(undefined)}
                />
            }
            {
                props.show && actionToAdd && selected?.length > 1 &&
                <BulkActionDialog
                    ocs={ocs.filter(oc => selected.includes(oc.uuid))} actionType={actionToAdd}
                    onSave={onActionAdded}
                    onCancel={() => setActionToAdd(undefined)}
                />
            }
        </Box>
    )
});
ContainersTable.propTypes = {
    show: PropTypes.bool.isRequired,
    portCode: PropTypes.string,  // only used if depot is null
    depot: PropTypes.number,
    laneGroupId: PropTypes.number,
    onContainerSelected: PropTypes.func.isRequired,
    callbackNumContainers: PropTypes.func,
    filterUnit: PropTypes.string,
    // ship: PropTypes.string,
    orderIds: PropTypes.array,  // array of order ids
    status: PropTypes.array, // this is an array of statuses now
}

export default ContainersTable;

import React from "react";
import {db, getUnsyncedChangeCount, reconnectIfNeeded} from "../common/db";
import Dexie from "dexie";
import {AuthContext} from "./AuthProvider";


export const DexieContext = React.createContext(null);

export default function DexieProvider({ children }) {
    const auth = React.useContext(AuthContext);
    const [dexieStatus, setDexieStatus] = React.useState(0);
    const [changeCount, setChangeCount] = React.useState(0);
    const [dexieOnline, setDexieOnline] = React.useState(false);    // simplified version of dexieStatus
    const [netOnline, setNetOnline] = React.useState(navigator.onLine);
    const [offlineSince, setOfflineSince] = React.useState(null);

    const updateStatus = (newStatus) => {
        switch (newStatus) {
            case Dexie.Syncable.Statuses.OFFLINE:
            case Dexie.Syncable.Statuses.ERROR_WILL_RETRY:
            case Dexie.Syncable.Statuses.ERROR:
                setDexieOnline(false);
                break;
            default:
            case Dexie.Syncable.Statuses.CONNECTING:
            case Dexie.Syncable.Statuses.SYNCING:
                // no change
                break;
            case Dexie.Syncable.Statuses.ONLINE:
                setDexieOnline(true);
                break;
        }
        setDexieStatus(newStatus);
    }

    const checkChangeCount = React.useCallback(() => {
        getUnsyncedChangeCount().then((n) => {
            setChangeCount(n);
            if ('setAppBadge' in navigator) {
                navigator.setAppBadge(n).catch(err => {
                    console.error(err);
                });
            }
        });
    }, []);

    React.useEffect(() => {
        db.syncable.on('statusChanged', function (newStatus, url) {
            updateStatus(newStatus);
            checkChangeCount();
        });

        db.on('changes', (changes) => {
            //console.log('changes', changes);
            checkChangeCount();
        });

        // IMPORTANT: There doesn't seem to be any way to unsubscribe from the two events above, so be very careful
        // about what goes in the dependency array here, it can only be something that NEVER changes.
    }, [checkChangeCount]);

    React.useEffect(() => {
        const onOffline = () => {
            setNetOnline(false);
            setDexieOnline(false);
            setOfflineSince(Date.now());
        }
        const onOnline = () => {
            setNetOnline(true);
            // if we were offline more than 60 secs, clear the `lastSyncStartTime` in local storage, so that
            // dexie will try to sync again immediately
            if (offlineSince && Date.now() - offlineSince > 60000) {
                localStorage.removeItem('lastSyncStartTime');
            }
            reconnectIfNeeded();
            setOfflineSince(null);
        }

        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);

        return () => {
            window.removeEventListener('offline', onOffline);
            window.removeEventListener('online', onOnline);
        }
    }, [offlineSince, setDexieOnline, setNetOnline]);

    React.useEffect(() => {
        // 40 secs after startup, login, or coming online, if we're logged-in and online, but dexie is offline,
        // tell it to reconnect (handles odd cases where it gave up trying to reconnect).
        const tid = setTimeout(() => {
            // console.log(auth.user, netOnline, dexieOnline);
            if (auth?.isLoggedIn() && netOnline && !dexieOnline) {
                reconnectIfNeeded(true);
            }
        }, 40000);
        return () => clearTimeout(tid);
    }, [auth, netOnline, dexieOnline])

    const value = {
        status: dexieStatus,    //  raw dexie status -1..4.
        statusName: Dexie.Syncable.StatusTexts[dexieStatus],  // name for `status`
        online: dexieOnline,    // boolean - is dexie online & synced
        netOnline: netOnline,   // boolean - is the internet online
        unsyncedChangeCount: changeCount, // number of unsynced changes we have.
    };

    // console.log('changeCount', changeCount);

    return (
        <DexieContext.Provider value={value}>
            {children}
        </DexieContext.Provider>
    )
}
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {db} from "../../../common/db";
import {useLiveQuery} from "dexie-react-hooks";
import {
    AppBar, Box, CircularProgress, Grid, InputAdornment, SpeedDial, SpeedDialAction, Tab, Tabs, TextField, Toolbar,
    Typography, useTheme
} from "@mui/material";
import {ACT_TYPE, formatReefer, prettyDate, prettyDateTime, VENT_POSNS} from "../../../common/shared";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {default as PortIcon} from '@mui/icons-material/AlignVerticalTop';
import {default as CustomerIcon} from '@mui/icons-material/Business';
import {default as TempIcon} from '@mui/icons-material/Thermostat';
import {default as HumidityIcon} from '@mui/icons-material/InvertColors';
import {default as VentIcon} from '@mui/icons-material/HeatPump';
import {default as TempImpExpIcon} from '@mui/icons-material/NoteAlt';
import LaneIcon from "../../../icons/LaneIcon";
import {useNavigate} from "react-router-dom";
import ActionIcon from "../../../components/ActionIcon";
import ActionDialog from "./ActionDialog";
import SelectActionDialog from "./SelectActionDialog";
import HistoryTable from "./HistoryTable";
import ExportIcon from "../../../icons/ExportIcon";
import ImportIcon from "../../../icons/ImportIcon";
import EditIcon from "@mui/icons-material/Edit";
import SelectDepotDialog from "./SelectDepotDialog";
import {IMPORT_EXPORT_LABELS} from "./defaults";
import SelectCustomerDialog from "./SelectCustomerDialog";
import EditShipDialog from "./EditShipDialog";
import {DexieContext} from "../../../contexts/DexieProvider";
import axios from "axios";

export default function Container(props) {
    const theme = useTheme();
    const { uuid } = props;
    const dexie = React.useContext(DexieContext);
    const allStatuses = useLiveQuery(() => db.ContainerStatus.toArray(), [], []);
    const [actionToAdd, setActionToAdd] = React.useState();
    const [actionToEdit, setActionToEdit] = React.useState();
    const [allActsDlgVisible, setAllActsDlgVisible] = React.useState(false);
    const [speeddialVisible, setSpeeddialVisible] = React.useState(false);
    const [selectDepotDialogVisible, setSelectDepotDialogVisible] = React.useState(false);
    const [visibleTab, setVisibleTab] = React.useState('history');
    const [canChangeCustomer, setCanChangeCustomer] = React.useState(false);
    const [selectCustomerDialogVisible, setSelectCustomerDialogVisible] = React.useState(false);
    const [editShipDialogVisible, setEditShipDialogVisible] = React.useState(false);
    // This is just here so we can force a re-query of oc when we need to.
    const [ocQuery, setOcQuery] = React.useState(0);

    useEffect(() => {
        if (dexie.netOnline && uuid) {
            // let the server know we're viewing this container
            // (this is to check for missed deletions ultimately, but for now it does nothing here)
            axios.get('/home/orderContainer/checkOc', {params: {uuid: uuid}})
                .then((response) => {
                    if (response.data?.error) console.log(response.data);
                });
        }
    }, [dexie.netOnline, uuid]);

    const oc = useLiveQuery(() => db.OrderContainer.get(uuid).then(async (oc) => {
        //console.log(oc);
        //  If there's an associated order, we need to look it up
        oc.order = await (oc.order_id ? db.Order.get(oc.order_id) : undefined);
        //  Ditto customer
        if (oc.order?.customer_id) {
            oc.customer = await db.Customer.get(oc.order.customer_id);
        }
        else {
            // If we have no order_id in the oc, we need to look up the customer_id in the container
            const cont = await db.Container.get(oc.reefer_num);
            if (cont?.customer_id) {
                oc.customer = await db.Customer.get(cont.customer_id);
            }
        }
        //  And the depot too
        oc.depot = await (oc.depot_id ? db.Depot.get(oc.depot_id) : undefined);
        //  And the port
        oc.port = await (oc.depot?.port_code ? db.Port.get(oc.depot.port_code) : undefined);
        // If we don't have a depot_id (and hence no port_code from it), we might be able to get it from the order.
        if (!oc.port && oc.order && oc.order.port_code) {
            oc.port = await db.Port.get(oc.order.port_code);
        }
        // And the lane now too
        oc.lane = await (oc.lane_id ? db.Lane.get(oc.lane_id) : undefined);

        // They can change the customer if the oc doesn't have an order.
        setCanChangeCustomer(!oc.order_id);

        //  That's about it I think.
        return oc;
    }), [uuid, ocQuery]);

    const actionTypes = useLiveQuery(
        () => {
            // 'oc' not loaded yet - no actions available.
            if (oc?.status === undefined) return [];
            return db.ContainerStatusActionType.where('status').equals(oc.status).toArray(csats => {
                const actionTypeIds = csats.map(csat => csat.action_type_id);

                return db.ActionType.where('id').anyOf(actionTypeIds)
                    .filter(at => !at.deleted)
                    .sortBy('sort_order')
                    .then(arr => {
                        // Need to filter arr for actionTypes that apply to this container.
                        return arr.filter(at => (
                                // oc doesn't have a location type, or
                                !oc.depot || oc.depot.entry_type === 'BLANK' ||
                                // the action type doesn't care what location type is, or
                                (at.available_in === 'BOTH') ||
                                // the action type location is for a depot, and the oc is in one, or
                                (at.available_in === 'DEPOT' && oc.depot.entry_type === 'DEPOT') ||
                                // the action type location is for a terminal, and the oc is in one.
                                (at.available_in === 'TERMINAL' && oc.depot.entry_type === 'TERMINAL'))
                            );
                    })
                    .then(arr => {
                        // Any UNFLAG action is only available on a flagged container.
                        if (!oc.is_flagged) return arr.filter(at => at.data_type !== ACT_TYPE.UNFLAG);
                        return arr;
                    });
            });

        },
        [oc?.status, oc?.depot_id]
    );

    //const lanes = useLiveQuery(() => db.Lane.where('depot_id').equals(oc?.depot_id).and(l => !l.deleted), [oc?.depot_id]);

    const handleAddAction = (act) => {
        setActionToAdd(act);
    };

    const onActionAdded = (/*act*/) => {
        setActionToAdd(undefined);  //  this will close the dialog
    };

    const handleEditAction = (act) => {
        console.log('handleEditAction', act)
        setActionToEdit(act);
    }

    const onActionEdited = (/*act*/) => {
        setActionToEdit(undefined); //  just close the dialog
    }

    const onActionDeleted = (/*act*/) => {
        setActionToEdit(undefined); //  just close the dialog
    }

    const onSelectNewDepot = async (depotId, laneId) => {
        console.log('onSelectNewDepot', depotId, laneId);
        setSelectDepotDialogVisible(false);
        oc.depot = depotId ? await db.Depot.get(depotId) : undefined;
        oc.lane = laneId ? await db.Lane.get(laneId) : undefined;
        db.OrderContainer.update(oc.uuid, {depot_id: depotId, lane_id: laneId});
    }

    const onSelectNewCustomer = async (customerId) => {
        console.log('onSelectNewCustomer', customerId);
        setSelectCustomerDialogVisible(false);
        if (canChangeCustomer) {
            if (customerId) {
                oc.customer = await db.Customer.get(customerId);
                db.Container.update(oc.reefer_num, {customer_id: customerId});
            }
            else {
                oc.customer = undefined;
                db.Container.update(oc.reefer_num, {customer_id: null});
            }
            setOcQuery(ocQuery + 1);    //  force a re-query of oc (and hence a re-render)
        }
    }

    const onSaveNewShip = async (ship) => {
        console.log('onSaveNewShip', ship);
        setEditShipDialogVisible(false);
        if (ship) {
            ship = ship.trim().toUpperCase();
            oc.ship = ship;
            db.OrderContainer.update(oc.uuid, {ship: ship});
        }
    }

    if (!oc) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    // console.log(oc);


    return (
        <Box sx={{minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box sx={{p:1, bgcolor: 'white'}}>
                <Typography variant="h4" component="div">{formatReefer(oc.reefer_num)}</Typography>
                <Box>
                    {oc.order && <InfoBox>{(() => {
                        switch (oc.order?.import_export) {
                            case 'IMP': return [<ImportIcon fontSize="small" key="icn" />, <strong key="lbl">Import</strong>];
                            case 'EXP': return [<ExportIcon fontSize="small" key="icn" />, <strong key="lbl">Export</strong>];
                            default: return '';
                        }
                    })()}</InfoBox>}
                    <InfoBox sx={{
                        paddingLeft: '2px',
                        borderLeftWidth: '4px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: allStatuses.find(s => s.status === oc.status)?.color
                    }}>{(() => {
                        let msg = [];
                        const statusRow = allStatuses.find(s => s.status === oc.status);
                        if (statusRow) {
                            // Hasn't arrived yet.
                            if (!(statusRow.has_arrived)) {
                                msg.push(<strong key="due">{statusRow.status_name}</strong>);
                                if (oc.order?.due_date) {
                                    msg.push(<span key="dueOn"> - ({prettyDate(oc.order?.due_date)})</span>);
                                }
                            }
                            else if (statusRow.has_arrived && !statusRow.has_shipped) {
                                msg.push(<strong key="inPort">{statusRow.status_name}</strong>);
                                if (oc.in_at) {
                                    msg.push(<span key="portSince"> (since {prettyDateTime(oc.in_at, {isMagic: true})})</span>);
                                }
                                if (oc.order?.import_export === 'EXP' && oc.sail_date) {
                                    msg.push(<span key="sailsOn"> - (Sails {prettyDate(oc.sail_date)})</span>);
                                }
                            }
                            else {
                                msg.push(<strong key="shipped">{statusRow.status_name}</strong>);
                                if (oc.out_at) {
                                    msg.push(<span key="shippedOn"> (since {prettyDateTime(oc.out_at, {isMagic: true})})</span>);
                                }
                            }
                        }
                        return msg;
                    })()}</InfoBox>
                </Box>
                <Box>
                    <InfoBox title='Set Temp'><TempIcon fontSize="small" />{oc.set_temp == null ? '' : oc.set_temp + '°C'}</InfoBox>
                    {oc.set_humidity == null ? '' : <InfoBox title='Humidity'><HumidityIcon fontSize="small" />{oc.set_humidity}%</InfoBox>}
                    {oc.ie == null ? '' : <InfoBox title='I/E Cycle'><TempImpExpIcon fontSize="small" />{oc.ie}</InfoBox>}
                    <InfoBox title='Vent'><VentIcon fontSize="small" />{VENT_POSNS[oc.vent] ?? '- Unknown -'}</InfoBox>
                    <InfoBox title={`Port (${oc.depot?.entry_type === 'DEPOT' ? 'Depot' : 'Terminal'})`}><PortIcon fontSize="small" />{`${oc.port?.name ?? '- Not Set -'} (${oc.depot?.name ?? '- Not Set -'})`}</InfoBox>
                    {oc.lane && <InfoBox title='Lane'><LaneIcon fontSize="small" />{oc.lane.name}</InfoBox>}
                    <InfoBox title='Customer'><CustomerIcon fontSize="small" />{oc.customer?.name ?? '- Not Set -'}</InfoBox>
                    <InfoBox title='Cargo'>{oc.cargo_description ?? '- Not Set -'}</InfoBox>
                </Box>
            </Box>
            <Box sx={{width: '100%'}}>
                <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider' }}>
                    <Tabs value={visibleTab} onChange={(e, newVal) => setVisibleTab(newVal)} aria-label="Container Tabs">
                        <Tab label="HISTORY" icon={<HistoryIcon />} iconPosition='start'
                             sx={{minHeight: 48}} // default minHeight for tabs with icon & label is 72, which makes no sense when the icon is on the left
                             value='history' id="tab-button-history" aria-controls='tabpanel-history' />
                        <Tab label="DETAILS" icon={<InfoOutlinedIcon />} iconPosition='start'
                             sx={{minHeight: 48}}
                             value='details' id="tab-button-details" aria-controls='tabpanel-details' />
                    </Tabs>
                </Box>
                <div id='tabpanel-history' role="tabpanel" aria-labelledby='tab-button-history' hidden={visibleTab !== 'history'}>
                    <HistoryTable oc={oc} onEditAction={handleEditAction} />
                </div>
                <div id='tabpanel-details' role="tabpanel" aria-labelledby='tab-button-details' hidden={visibleTab !== 'details'}>
                    <Box sx={{ p: 1, bgcolor: 'white' }}>
                        <Grid container spacing={1} >
                            <GridEntry xs={4} lg={3} label="Container Number" defaultValue={formatReefer(oc.reefer_num)} inputProps={{size: 13}} />
                            <GridEntry xs={2} lg={1} label="Set Temp" value={oc.set_temp == null ? '' : oc.set_temp + '°C'} inputProps={{size: 7}} />
                            <GridEntry xs={2} lg={1} label="Set Humidity" value={oc.set_humidity == null ? '' : oc.set_humidity + '%'} inputProps={{size: 7}} />
                            <GridEntry xs={4} lg={1} label="I/E Cycle" value={oc.ie == null ? '' : oc.ie} inputProps={{size: 7, style: {textAlign: 'center'}}} />
                            <GridEntry xs={4} lg={1} label="Vent" value={VENT_POSNS[oc.vent] ?? '- Unknown -'} inputProps={{size: 7}} />
                            <GridEntry
                                xs={6} label={`Location`}
                                title="Port/Terminal - Lane"
                                value={`${oc.port?.name || '- Not Set -'}/${oc.depot?.name || '- Not Set -'} ${oc.lane ? `- ${oc.lane.name}` : ''}`}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Change Terminal"
                                                edge="end"
                                                sx={{mr: 0}}
                                                onClick={() => setSelectDepotDialogVisible(true)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <GridEntry
                                xs={6} label="Customer"
                                value={oc.customer?.name || ''}
                                placeholder="Not set"
                                fullWidth
                                InputProps={{
                                    endAdornment: canChangeCustomer ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Change Customer"
                                                title="Change Customer"
                                                edge="end"
                                                sx={{mr: 0}}
                                                onClick={() => setSelectCustomerDialogVisible(true)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined
                                }}
                            />
                            <GridEntry
                                xs={6} label="Ship"
                                value={oc.ship || ''}
                                placeholder="Not set"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Change Ship"
                                                edge="end"
                                                sx={{mr: 0}}
                                                onClick={() => setEditShipDialogVisible(true)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <GridEntry xs={2} label="Status" value={allStatuses.find(s => s.status === oc.status)?.status_name} placeholder="Not set" inputProps={{size: 7}} />
                            <GridEntry xs={4} label="Vessel Date" value={prettyDate(oc.sail_date) || ''} placeholder="Not set" />
                            {oc.order?.import_export && <GridEntry xs={4} label="Import/Export" defaultValue={IMPORT_EXPORT_LABELS?.[oc.order?.import_export]} placeholder="Not set" />}
                            {oc.in_at && <GridEntry xs={4} label="Arrived On" defaultValue={prettyDateTime(oc.in_at, {isMagic: true})} placeholder="Not set" />}
                            {oc.out_at && <GridEntry xs={4} label="Dispatched On" defaultValue={prettyDateTime(oc.out_at, {isMagic: true})} placeholder="Not set" />}
                            {/*{oc.release_no && <GridEntry xs={6} label="Release No." defaultValue={oc.release_no} placeholder="Not set" />}*/}
                            {/*{oc.voyage_number && <GridEntry xs={6} label="Voyage Number" defaultValue={oc.voyage_number} placeholder="Not set" />}*/}
                            {oc.booking_ref && <GridEntry xs={6} label="Booking ref" defaultValue={oc.booking_ref} placeholder="Not set" />}
                            {oc.cargo_description && <GridEntry xs={12} label="Cargo Description" defaultValue={oc.cargo_description} placeholder="Unknown" fullWidth />}
                            {oc.customer_note && <GridEntry xs={12} label="Customer Note" defaultValue={oc.customer_note} placeholder="None" fullWidth />}
                        </Grid>
                    </Box>
                </div>
            </Box>
            {actionTypes &&
                <SpeedDial
                    open={speeddialVisible}
                    ariaLabel={"Container Action"}
                    sx={{position: 'fixed', bottom: 16 + 48, right: 16}}
                    icon={<SpeedDialIcon/>}
                    // Note: transitionDuration here _should_ control the speed at which the menu shows/hides.
                    // It's broken in mui at the moment, but if they ever fix it...
                    transitionDuration={{
                        enter: 50,
                        exit: theme.transitions.duration.leavingScreen
                    }}
                    onClick={(/*event*/) => {
                        // console.log('click', e);
                        setSpeeddialVisible(oldVis => !oldVis);
                    }}
                    onClose={(e, reason) => {
                        // console.log('close', reason, e);
                        // We ignore 'mouseLeave' as a close reason (it's just silly on a desktop, and never fires
                        // on touch devices anyway).
                        // We also ignore 'toggle' as a reason, as we will get an onClick event for that anyway,
                        // which already toggles.
                        if (['blur', 'escapeKeyDown'].includes(reason)) setSpeeddialVisible(false);

                    }}
                >
                    {actionTypes.length > 6 ? [
                            actionTypes.slice(0, 5).map((at) => {
                                    return (
                                        <SpeedDialAction
                                            key={at.id}
                                            icon={<ActionIcon iconData={at.icon_data} sx={{color: `${at.icon_color ?? '#000000'} !important`}} />}
                                            tooltipTitle={at.label}
                                            tooltipOpen
                                            sx={{
                                                whiteSpace: "nowrap",
                                                maxWidth: "none",
                                            }}
                                            onClick={() => handleAddAction(at)}
                                        />
                                    );
                                }
                            ),
                            <SpeedDialAction
                                key='other'
                                icon={<MoreVertIcon/>}
                                tooltipTitle="More..."
                                tooltipOpen
                                sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "none",
                                }}
                                onClick={() => setAllActsDlgVisible(true)}
                            />
                        ] :
                        actionTypes.map((at) => {
                                return (
                                    <SpeedDialAction
                                        key={at.id}
                                        icon={<ActionIcon iconData={at.icon_data} sx={{color: `${at.icon_color ?? '#000000'} !important`}} />}
                                        tooltipTitle={at.label}
                                        tooltipOpen
                                        sx={{
                                            whiteSpace: "nowrap",
                                            maxWidth: "none",
                                        }}
                                        onClick={() => handleAddAction(at)}
                                    />
                                );
                            }
                        )
                    }
                </SpeedDial>
            }
            {
                actionToAdd &&
                <ActionDialog
                    oc={oc} actionType={actionToAdd}
                    onSave={onActionAdded}
                    onCancel={() => setActionToAdd(undefined)}
                />
            }
            {
                actionToEdit &&
                <ActionDialog actionType={actionToEdit.actionType} oc={oc} onSave={onActionEdited}
                              onCancel={() => setActionToEdit(undefined)}
                              onDelete={onActionDeleted}
                              record={actionToEdit}
                />
            }
            {
                allActsDlgVisible &&
                <SelectActionDialog
                    actions={actionTypes}
                    onAction={(act) => {
                        // Ensure that the SelectActionDialog gets hidden
                        setTimeout(() => setAllActsDlgVisible(false), 300);
                        handleAddAction(act);
                    }}
                    onCancel={() => setAllActsDlgVisible(false)} />
            }
            {
                selectDepotDialogVisible &&
                <SelectDepotDialog
                    portCode={oc.depot?.port_code}
                    depotId={oc.depot_id}
                    laneId={oc.lane_id}
                    onSelectDepot={(depotId, laneId) => onSelectNewDepot(depotId, laneId)}
                    onCancel={() => setSelectDepotDialogVisible(false)} />
            }
            {
                selectCustomerDialogVisible &&
                <SelectCustomerDialog
                    customerId={oc.customer?.id}
                    onSelectCustomer={(customerId) => onSelectNewCustomer(customerId)}
                    onCancel={() => setSelectCustomerDialogVisible(false)} />
            }
            {
                editShipDialogVisible &&
                <EditShipDialog
                    ship={oc.ship}
                    onSaveShip={(newShip) => onSaveNewShip(newShip)}
                    onCancel={() => setEditShipDialogVisible(false)} />
            }
            <BottomBar />
        </Box>
    )
}
Container.propTypes = {
    uuid: PropTypes.string.isRequired
}

function InfoBox(props) {
    const {title, color, fontSize, children, sx=[]} = props;

    return (
        <Box
            display="inline-block"
            // Cannot 'spread' sx, see: https://mui.com/system/the-sx-prop/#passing-sx-prop
            sx={[{my: 0.5, ml: 0, mr: 1.5}, ...(Array.isArray(sx) ? sx : [sx]),]}
        >
            {title && <Typography variant="caption" fontSize="10px" display="block" color="text.disabled">{title}</Typography>}
            <Typography variant="body2" component="div" display="inline-flex" color={color} fontSize={fontSize}
                sx={{verticalAlign: 'middle', gap: '0.5ch'}}
            >{children}</Typography>
        </Box>
    )
}
InfoBox.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    children: PropTypes.any,
    sx: PropTypes.object
}

function BottomBar() {
    const navigate = useNavigate();

    return (
        <AppBar position="sticky" component="nav" color="primary" sx={{ bottom: 0, top: 'auto', mt: 'auto'}}>
            <Toolbar>
                <IconButton color="inherit" onClick={() => navigate(-1)} title="Go Back">
                    <ArrowBackIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}


function GridEntry(props) {

    const { xs, sm, md, lg, xl, ...others } = props;
    const gridProps = {
        xs, sm, md, lg, xl,
        item: true,
    }
    const inputProps = others.InputProps ?
        {
            ...others.InputProps,
            readOnly: true
        } :
        { readOnly: true };
    const fieldProps = {
        ...others,
        InputProps: inputProps,
        InputLabelProps: {
            shrink: true,
        },
        variant: 'standard',
    }

    return (
        <Grid { ...gridProps }><TextField { ...fieldProps }  /></Grid>
    )
}
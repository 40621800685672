import Dexie from "dexie";
import axios from "axios";
import config from "../config.js";
import {db, recreateDb} from "./db";
import eventBus from "./EventBus";


// const inSW = () => (typeof window === 'undefined');

// Store the time we did a full resync, so we can avoid doing it too often.
let lastFullResyncTime = 0;


function logClientSyncError (error, request) {
    console.error("Client sync error", error);

    // Send everything to /home/sync/clientSyncFailure
    const allInfo = {
        // message: error?.message,
        // stack: error?.stack,
        // name: error?.name,
        error: {
            name: error.name,
            message: error.message,
            stack: error.stack,
            inner: error.inner,
        },
        request: request
    }
    axios.post('/home/sync/clientSyncFailure', allInfo)
        .then((response) => {
            console.log("clientSyncFailure sent");
        }, (error) => {
            console.error("clientSyncFailure error", error);
        });
}


Dexie.Syncable.registerSyncProtocol("rest_protocol", {

    sync: function (context, url, options, baseRevision, syncedRevision, changes, partial, applyRemoteChanges, onChangesAccepted, onSuccess, onError) {
        /// <param name="context" type="IPersistedContext"></param>
        /// <param name="url" type="String"></param>
        /// <param name="changes" type="Array" elementType="IDatabaseChange"></param>
        /// <param name="applyRemoteChanges" value="function (changes, lastRevision, partial, clear) {}"></param>
        /// <param name="onSuccess" value="function (continuation) {}"></param>
        /// <param name="onError" value="function (error, again) {}"></param>

        // console.log(this);

        // Get the last sync start time from localStorage
        let lastSyncStartTime = localStorage.getItem('lastSyncStartTime');
        // Force it to be a number
        lastSyncStartTime = Number(lastSyncStartTime);


        const CHUNK_BLOCK_SIZE = 4000;
        const POLL_INTERVAL = config?.DEXIE_POLL_INTERVAL || 60000; // in milliseconds
        const POLL_RANDOMIZE_BY = 0.1;    //  a proportion of POLL_INTERVAL (e.g. 0.1 = ±10%)
        const nextPoll = POLL_INTERVAL * (1.0 + (POLL_RANDOMIZE_BY * 2.0 * (Math.random() - 0.5)));

        // If the lastSyncStartTime is less than 0.9 * POLL_INTERVAL ago, then we're probably already in a sync,
        // so wait.
        if (lastSyncStartTime > 0 && (Date.now() - lastSyncStartTime) < (0.9 * POLL_INTERVAL)) {
            const msg = `Already syncing, started ${(Date.now() - lastSyncStartTime)/1000}s ago. Wait...`;
            console.warn(msg);
            onError(msg, nextPoll);
            return;
        }

        const forceNewClientIdentity = this.resetClientIdentity || false;
        if (forceNewClientIdentity) {
            this.resetClientIdentity = false;   //   remove the flag, only need to do this once.
        }

        // In this example, the server expects the following JSON format of the request:
        //  {
        //      [clientIdentity: unique value representing the client identity. If omitted, server will return a new client identity in its response that we should apply in next sync call.]
        //      baseRevision: baseRevision,
        //      partial: partial,
        //      changes: changes,
        //      syncedRevision: syncedRevision
        //  }
        //  To keep the sample simple, we assume the server has the exact same specification of how changes are structured.
        //  In real world, you would have to pre-process the changes array to fit the server specification.
        //  However, this example shows how to deal with ajax to fulfil the API.

        // Also try to identify if they've installed us as an app, to help with debugging issues
        let isApp = null;
        try {
            isApp = window.matchMedia('(display-mode: standalone)').matches;
        }
        catch (e) {
            // ignore
        }

        // Also send the db version number, and the app version number.
        const dbVersion = db.verno;
        const appVersion = config?.APP_VERSION || "unknown";

        const request = {
            clientIdentity: context.clientIdentity || null,
            baseRevision: baseRevision,
            partial: partial,
            changes: changes,
            syncedRevision: syncedRevision,
            isApp: isApp,
            appVersion,
            dbVersion,
            prevRequestId: localStorage.getItem('lastSyncRequestId'),
        };

        if (forceNewClientIdentity) {
            console.log("Forcing new client identity");
            request.clientIdentity = null;
        }

        // Write the last sync start time to localStorage
        localStorage.setItem('lastSyncStartTime', `${Date.now()}`);

        axios.post(url, request, {timeout: 14*60*1000})
            .then((response) => {
                let data = response.data;

                // Write the last sync request id to localStorage
                localStorage.setItem('lastSyncRequestId', `${data.nextRequestId}`);

                // In this example, the server response has the following format:
                //{
                //    success: true / false,
                //    errorMessage: "",
                //    changes: changes,
                //    currentRevision: revisionOfLastChange,
                //    needsResync: false, // Flag telling that server doesn't have given syncedRevision or of other reason wants client to resync. ATTENTION: this flag is currently ignored by Dexie.Syncable
                //    partial: true / false, // The server sent only a part of the changes it has for us. On next resync it will send more based on the clientIdentity
                //    [clientIdentity: unique value representing the client identity. Only provided if we did not supply a valid clientIdentity in the request.]
                //}
                if (!data.success) {
                    onError (data.errorMessage, Infinity); // Infinity = Don't try again. We would continue getting this error.
                } else {

                    const _applyChangesLocal = async () => {
                        // Since we got success, we also know that server accepted our changes:
                        onChangesAccepted();

                        if (data.needsResync) {
                            // Server is telling us we need to resync.  No point in applying changes.
                            // onSuccess({again: nextPoll});
                            onError("needsResync", nextPoll);
                        }
                        else {

                            const changesBlocks = [];

                            // Split changes into blocks of CHUNK_BLOCK_SIZE
                            for (let i = 0; i < data.changes.length; i += CHUNK_BLOCK_SIZE) {
                                const isLastSlice = i + CHUNK_BLOCK_SIZE >= data.changes.length;

                                changesBlocks.push({
                                    'changes': data.changes.slice(i, i + CHUNK_BLOCK_SIZE),
                                    // We use our own `syncedRevision` for everything except the last block.
                                    // We do this so that if we get killed here, we want it to start again next time,
                                    // not assume that it's finished.
                                    'revision': isLastSlice ? data.currentRevision : syncedRevision,
                                    // Even though all these blocks are partials, except the last one, we lie about that
                                    // and say they're not.
                                    // This is because dexie will queue them if they're partials, and ultimately apply
                                    // them together at the end - which is fine normally, but routinely fails when
                                    // there are 40k+ changes.
                                    // Telling dexie that they're not partials means it will apply them each immediately.
                                    'partial': data.partial,
                                    // (note: data.partial is always false in the current server implementation)
                                });
                            }

                            // It looks like dexie expects applyRemoteChanges to be called, even if there are no changes
                            // to apply.  So give it an empty block when this happens.
                            if (changesBlocks.length === 0) {
                                changesBlocks.push({
                                    'changes': [],
                                    'revision': data.currentRevision,
                                    'partial': data.partial,
                                });
                            }

                            try {

                                // Send a progress message (first one, i.e. 0%)
                                eventBus.dispatch('syncProgress', {
                                    numChanges: data?.numChanges,
                                    complete: data?.numChanges === 0,
                                    currentBlock: 0,
                                    totalBlocks: changesBlocks.length,
                                });

                                // Apply the changes in blocks, so we can update the user on progress.
                                for (let i = 0; i < changesBlocks.length; i++) {
                                    const block = changesBlocks[i];
                                    if (block.changes.length > 1) {
                                        console.log(`Applying changes block ${i + 1} of ${changesBlocks.length}: partial = ${block.partial}`);
                                    }
                                    await applyRemoteChanges(block.changes, block.revision, block.partial, data.needsResync);
                                    // Send a progress message
                                    eventBus.dispatch('syncProgress', {
                                        numChanges: data?.numChanges,
                                        complete: i >= changesBlocks.length - 1,
                                        currentBlock: i + 1,
                                        totalBlocks: changesBlocks.length,
                                    });
                                }

                                // console.log("Calling onSuccess");
                                onSuccess({again: nextPoll});
                            }
                            catch (e) {
                                // Send a sync progress failure
                                eventBus.dispatch('syncProgress', {
                                    numChanges: data?.numChanges,
                                    error: true,
                                    message: e.message || e.toString(),
                                });

                                logClientSyncError(e, request);
                                onError(e, nextPoll);
                            }
                            finally {
                                // console.log("Closing popups");
                                eventBus.dispatch('appPopupClose', {key: 'resync'});
                            }



                            // Convert the response format to the Dexie.Syncable.Remote.SyncProtocolAPI specification:
                            // console.log("Applying changes");
                            // applyRemoteChanges(data.changes, data.currentRevision, data.partial, data.needsResync)
                            //     .then(() => {
                            //         // If we got here, then the changes were applied successfully.
                            //         // We can now call onSuccess to signal to Dexie.Syncable that we're done.
                            //         console.log("Calling onSuccess");
                            //         onSuccess({again: nextPoll});
                            //     })
                            //     .catch(error => {
                            //         logClientSyncError(error, request);
                            //         console.log("Calling onError");
                            //         onError(error, nextPoll);
                            //     })
                            //     .finally(() => {
                            //         // Close the resync popup if there is one
                            //         console.log("Closing popups");
                            //         eventBus.dispatch('appPopupClose', {key: 'resync'});
                            //         eventBus.dispatch('appPopupClose', {key: 'resync2'});
                            //     });

                        }
                    };

                    if ('clientIdentity' in data) {
                        context.clientIdentity = data.clientIdentity;
                        // Make sure we save the clientIdentity sent by the server before we try to resync.
                        // If saving fails we wouldn't be able to do a partial synchronization
                        context.save()
                            .then(() => {
                                _applyChangesLocal();
                            })
                            .catch((e) => {
                                // We didn't manage to save the clientIdentity stop synchronization
                                onError(e, Infinity);
                            });
                    } else {
                        _applyChangesLocal();
                    }


                    // Dexie doesn't handle the data.needsResync flag yet, so we'll do it manually
                    if (data.needsResync) {
                        if (Date.now() - lastFullResyncTime > 1000 * 60 * 15) {
                            console.error("Server wants us to resync. Doing that now.");
                            lastFullResyncTime = Date.now();    // Remember when we did a full resync
                            // The server wants us to resync. We'll do that by calling sync() again with baseRevision = null
                            // and syncedRevision = null. This will make the server send all changes it has for us.
                            // this.sync(context, url, options, null, null, [], false,
                            //     applyRemoteChanges, onChangesAccepted, onSuccess, onError);
                            // The above won't work, as it won't have the deletions that we've missed.  We need to
                            // empty the db first.
                            // console.log(this);
                            // console.log(context);
                            setTimeout(() => {
                                eventBus.dispatch('appPopup', {
                                    message: 'Your copy of system data needs to be refreshed.  Please wait a ' +
                                        'moment while we do that.  This may take a while if your connection is slow. ' +
                                        '\nDO NOT CLOSE THE APP OR REFRESH THE PAGE.'
                                    ,
                                    variant: 'warning',
                                    autoHideDuration: 120000,
                                    key: 'resync',
                                });
                                // Clear the lastSyncStartTime, so we don't delay the next sync call unnecessarily.
                                localStorage.setItem('lastSyncStartTime', '0');
                                // If the current page is /user/containers, navigate to / instead, so dexie doesn't
                                // try to liveQuery all the changes
                                if (window.location.pathname === '/user/containers') {
                                    eventBus.dispatch('appNavigate', { path: '/' });
                                }
                                recreateDb();
                            }, 5000);
                        }
                        else {
                            console.error("Server wants us to resync, but we did that less than 15 minutes ago. Skipping.");
                        }
                    }
                }
            }, (error) => {
                // Network down, server unreachable or other failure. Try again in POLL_INTERVAL milliseconds.
                onError(error.message || error.toString(), nextPoll);
            });
    }
});

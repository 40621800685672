import React from 'react';
import {alpha, styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from "prop-types";


const SearchWrap = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    //width: '100%',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        //width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 0.5),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 1),
    },
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        // width: '100%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '8ch',
        //     '&:focus, &:not([value=""])': {
        //         width: '13ch',
        //     },
        // },
        // Instead go with:
        width: '8ch',
        '&:focus, &:not([value=""])': {
            width: '13ch',
        },
    },
}));

export default function Search(props) {

    const inputProps = {
        placeholder: 'Search',
        type: 'search',
        inputProps: { 'aria-label': 'search' },
        value: props.value ?? '',
        onChange: props.onChange,
        ...props.inputProps
    }
    return (
        <SearchWrap>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                {...inputProps}
            />
        </SearchWrap>
    )
}
Search.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    inputProps: PropTypes.object
}
import React from "react";
import {AuthContext} from "../../contexts/AuthProvider";
import {
    Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    FormControl, FormHelperText, FormLabel, Grid, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography,
    useTheme
} from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {useSnackbar} from "notistack";
import axios from "axios";
import PropTypes from "prop-types";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {setTitle} from "../../common/shared";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../common/db";
import useMediaQuery from "@mui/material/useMediaQuery";
import {DexieContext} from "../../contexts/DexieProvider";

const ROLE_NAMES = {
    ROLE_USER: 'User',
    ROLE_ADMIN: 'Administrator',
    ROLE_SUPER: 'Superuser',
};

const ROLE_ICONS = {
    ROLE_USER: <FaceIcon />,
    ROLE_ADMIN: <SupervisorAccountIcon />,
    ROLE_SUPER: <ManageAccountsIcon />,
}

function EditProfileDialog(props) {
    const auth = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [record, setRecord] = React.useState(props.record || {
        firstname: auth.user.firstname,
        surname: auth.user.surname,
        initials: auth.user.initials,
        port_code: null,
    });
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    // const [ports, setPorts] = React.useState([]);
    const ports = useLiveQuery(
        () => db.Port.toArray()
    );

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {
            setSaving(true);
            axios.post('/home/user/update', record)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'User Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.user);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    }

    const onPortChange = (event, newPortCode) => {
        setRecord((prev) => ({
            ...prev,
            port_code: newPortCode
        }));
    }

    const isValid = (record.firstname && record.surname && record.initials);

    return (
        <form id="frmEditProfile" onSubmit={(event) => {
            event.preventDefault();
        }}>
            <Dialog open={true} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle>Edit Details</DialogTitle>
                <DialogContent dividers>
                    {   error && (
                        <Alert severity="error">{error.message || error}</Alert> )
                    }
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        helperText="Given Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={record.firstname}
                        onChange={onFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="surname"
                        name="surname"
                        label="Surname"
                        helperText="Last Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={record.surname}
                        onChange={onFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="initials"
                        name="initials"
                        label="Initials"
                        helperText="Your Initials"
                        type="text"
                        variant="standard"
                        required={true}
                        value={record.initials ?? ''}
                        onChange={onFormChange}
                    />
                    <FormControl fullWidth>
                        <FormLabel id="lblPortGroup">Default Port</FormLabel>
                        <ToggleButtonGroup
                            color="primary"
                            value={record.port_code}
                            exclusive
                            onChange={onPortChange}
                            >{
                                ports?.map((p) => {
                                    return (
                                        <ToggleButton value={p.code} key={p.code}>{p.name}</ToggleButton>
                                    )
                            })
                        }</ToggleButtonGroup>
                        <FormHelperText>The Port where you work (primarily).</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleSave} disabled={!isValid || saving}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}

EditProfileDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}


function ChangeEmailDialog(props) {
    const auth = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [newEmail, setNewEmail] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (newEmail) {
            setSaving(true);
            axios.post('/home/user/changeEmail', {newEmail})
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Email change has been submitted', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave();
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const onEmailChange = (event) => {
        setNewEmail(event.target.value);
    }

    const isValid = !!newEmail && (newEmail !== auth.user.email);

    return (
        <form id="frmChangeEmail" onSubmit={(event) => {
            event.preventDefault();
        }}>
            <Dialog open={true} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle>Change Email</DialogTitle>
                <DialogContent dividers>
                    {   error && (
                        <Alert severity="error">{error.message || error}</Alert> )
                    }
                    <DialogContentText>
                        To change your email address, we will send an email to your new address. Follow the link in
                        the email to complete the change.
                    </DialogContentText>
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id="email"
                        name="email"
                        label="New Email"
                        helperText="Please enter your new email address"
                        type="email"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={newEmail}
                        onChange={onEmailChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleSave} disabled={!isValid || saving}>Send Verification Email</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}

ChangeEmailDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}


export default function Profile(props) {
    const auth = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dexie = React.useContext(DexieContext);
    const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
    const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
    const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);
    const [port, setPort] = React.useState({
        id: null,
        name: 'Loading...'
    });

    React.useEffect(() => {
        setTitle('Profile');
    }, []);

    React.useEffect(() => {
        axios.get('/home/user/me')
            .then(
                (result) => {
                    console.log(result);
                    if (result.data.error) {
                        enqueueSnackbar(result.data.message || 'Failed to refresh your user data.', {variant: 'warning'});
                        setPort((prev) =>({
                            ...prev,
                            id: null,
                            name: ' - Failed - '
                        }));
                    } else {
                        let u = result.data.user;
                        setPort((prev) => ({
                            ...prev,
                            code: u.port_code,
                            name: u.port_name
                        }));
                    }
                },
                (error) => {
                    console.log(error);
                    enqueueSnackbar(error.message || error.toString(), {
                        variant: 'warning'
                    });
                    setPort((prev) => ({
                        ...prev,
                        id: null,
                        name: ' - Failed -'
                    }));
                }
            )
    }, [enqueueSnackbar]);

    if (!auth.user) {
        return <div className="container">
            <p>You are not logged-in.</p>
        </div>
    }

    const onEditProfileCancel = () => {
        setProfileDialogOpen(false);
    }

    const onEditProfileSave = (record) => {
        setProfileDialogOpen(false);
        setPort((prev) => {
            let newPort = { ...prev };
            if (record.port_code !== undefined) newPort.code = record.port_code;
            if (record.port_name !== undefined) newPort.name = record.port_name;
            return newPort;
        });
        //  Tell auth that it needs to pull in new info
        auth.checkLogin();
    }

    const onEditPasswordCancel = () => {
        setPasswordDialogOpen(false);
    }

    const onEditPasswordSave = () => {
        setPasswordDialogOpen(false);
    }

    const onEditEmailCancel = () => {
        setEmailDialogOpen(false);
    }

    const onEditEmailSave = () => {
        setEmailDialogOpen(false);
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Paper variant="outlined" sx={{ minHeight: '100%', p: 3 }}>
                    <Typography variant="h6" gutterBottom component="div">
                        About You
                    </Typography>
                    <Grid container spacing={2} sx={{pt:2}}>
                        <Grid item xs={2} sx={{textAlign: "right"}}>Name:</Grid>
                        <Grid item xs={10}>{auth.getFullName()} ({auth.getInitials()})</Grid>
                        <Grid item xs={2} sx={{textAlign: "right"}}>Email:</Grid>
                        <Grid item xs={10}>{auth.user?.email}</Grid>
                        <Grid item xs={2} sx={{textAlign: "right"}}>Default Port:</Grid>
                        <Grid item xs={10}>{port.name}</Grid>
                        <Grid item xs={2} sx={{textAlign: "right"}}>Roles:</Grid>
                        <Grid item xs={10}>
                            <Stack direction={{ xs: 'column', md: 'row'}} spacing={1}>
                            {
                                auth.user.roles &&
                                auth.user.roles.map((role, idx) =>
                                    <Chip
                                        key={idx}
                                        label={ROLE_NAMES[role] || role}
                                        icon={ROLE_ICONS[role] || <QuestionMarkIcon />}
                                        variant="outlined" />
                                )
                            }
                            </Stack>
                        </Grid>
                    </Grid>
                    {dexie.netOnline &&
                        <React.Fragment>
                            <Divider sx={{pt: 2}}>OPTIONS</Divider>
                            <Box py={1} sx={{display: 'flex', justifyContent: 'space-around'}}>
                                <Button onClick={() => setProfileDialogOpen(true)}>Edit Details</Button>
                                <Button onClick={() => setEmailDialogOpen(true)}>Change Email</Button>
                                <Button onClick={() => setPasswordDialogOpen(true)}>Change Password</Button>
                            </Box>
                        </React.Fragment>
                    }
                </Paper>
            </Box>
            {
                profileDialogOpen &&
                <EditProfileDialog onCancel={onEditProfileCancel}
                            onSave={onEditProfileSave}
                                   record={{
                                       firstname: auth.user.firstname,
                                       surname: auth.user.surname,
                                       initials: auth.user.initials,
                                       port_code: port.code,
                                   }}
                />
            }
            {
                passwordDialogOpen &&
                <ChangePasswordDialog onCancel={onEditPasswordCancel}
                                   onSave={onEditPasswordSave}
                />
            }
            {
                emailDialogOpen &&
                <ChangeEmailDialog onCancel={onEditEmailCancel}
                                      onSave={onEditEmailSave}
                />
            }
        </Box>
    )
}

import {LUXON_MYSQL_DATETIME_FMT, MAGIC_TIME} from "../../../common/shared";
import {DateTime} from "luxon";
import ImportIcon from "../../../icons/ImportIcon";
import ExportIcon from "../../../icons/ExportIcon";
import React from "react";

// This is the key in localstorage where we save the column settings
const COLS_KEY = 'user/Unplugs/ContainersTable/cols2';
const NUM_ROWS_KEY = 'user/Unplugs/ContainersTable/numRows';
const SORT_MODEL_KEY = 'user/Unplugs/ContainersTable/sortModel';
const CHECK_COL_KEY = 'user/Unplugs/ContainersTable/checkCol';


// The key in localStorage for the last depot used (for each port).
const LAST_DEPOT_BY_PORT_KEY = 'user/Unplugs/lastViewForPort';
const LAST_LANEGROUP_BY_DEPOT_KEY = 'user/Unplugs/lastViewForDepot';


const _sanitizeNum = (val) => {
    if (val === null || val === undefined) return null;
    const v = parseInt(val);
    return isNaN(v) ? null : v;
}

const getDefaultDepotForPort = (portCode) => {
    if (!portCode || portCode === '') return undefined;   //  sanity
    const lastDepotByPort = localStorage.getItem(LAST_DEPOT_BY_PORT_KEY);
    if (lastDepotByPort) {
        const depotByPort = JSON.parse(lastDepotByPort);
        return _sanitizeNum(depotByPort[portCode]);
    }
    return undefined;
}

const getDefaultLaneGroupForDepot = (depotId) => {
    if (!depotId || depotId === '') return undefined;   //  sanity
    const lastLaneGroupByDepot = localStorage.getItem(LAST_LANEGROUP_BY_DEPOT_KEY);
    if (lastLaneGroupByDepot) {
        const laneGroupByDepot = JSON.parse(lastLaneGroupByDepot);
        return _sanitizeNum(laneGroupByDepot[depotId]);
    }
    return undefined;
}

/**
 * Sets the last viewed depotId and laneGroupId for the given portCode.
 *
 * @param portCode
 * @param depotId
 * @param laneGroupId
 */
const setDefaultViewForPort = (portCode, depotId, laneGroupId = null) => {
    const depotByPort = JSON.parse(localStorage.getItem(LAST_DEPOT_BY_PORT_KEY)) || {};
    depotByPort[portCode] = depotId;
    localStorage.setItem(LAST_DEPOT_BY_PORT_KEY, JSON.stringify(depotByPort));

    if (depotId) {
        const laneGroupByDepot = JSON.parse(localStorage.getItem(LAST_LANEGROUP_BY_DEPOT_KEY)) || {};
        laneGroupByDepot[depotId] = laneGroupId;
        localStorage.setItem(LAST_LANEGROUP_BY_DEPOT_KEY, JSON.stringify(laneGroupByDepot));
    }
};

const IMPORT_EXPORT_LABELS = {
    'IMP': 'Import',
    'EXP': 'Export',
};

const IMPORT_EXPORT_ICONS = {
    IMP: <ImportIcon fontSize="small" key="impIcn" />,
    EXP: <ExportIcon fontSize="small" key="expIcn" />,
};


/**
 *
 * @param time_str Time string in the format HH:MM (without seconds)
 * @returns {string}
 */
const timeToSlot = (time_str) => {
    if (time_str < '11:45') return 'AM';
    if (time_str < '15:00') return 'MD';
    return 'PM'
}

const swapKeysAndVals = (obj) => Object.fromEntries(Object.entries(obj).map(a => a.reverse()));

// MAGIC_TIME flipped so that its keys are 'AM', 'MD' and 'PM'.
const MAGIC_TIME_INV = swapKeysAndVals(MAGIC_TIME);

/**
 * Take an iso date (YYYY-MM-DD) and a slot ('AM', 'MD', or 'PM') and return a utc datetime in sql format.
 * @param dt_str
 * @param slot
 * @returns {string|null}
 */
const slotToDateTime = (dt_str, slot) => {
    if (!slot || !dt_str) return null;
    const dt_str_irish = dt_str + ' ' + MAGIC_TIME_INV[slot];
    return DateTime.fromSQL(dt_str_irish, {zone: 'Europe/Dublin'}).toUTC()
        .toFormat(LUXON_MYSQL_DATETIME_FMT);
        //.toSQL({includeZone: false, includeOffset: false});
}

/**
 * Take an iso date (YYYY-MM-DD) and a slot ('AM', 'MD', or 'PM') and return a time string in UTC (either 'HH:MM' or
 * 'HH:MM:SS'), depending on the value of `withSecs`.
 *
 * @param dt_str {string} e.g. '2021-01-01'
 * @param slot 'AM', 'MD', or 'PM'
 * @param withSecs true to include seconds, false to omit
 * @returns {string|null} either 'HH:MM' or 'HH:MM:SS'
 */
const slotToTime = (dt_str, slot, withSecs=true) => {
    if (!slot || !dt_str) return null;
    const dt_str_irish = dt_str + ' ' + MAGIC_TIME_INV[slot];
    return DateTime.fromSQL(dt_str_irish, {zone: 'Europe/Dublin'}).toUTC().toFormat(withSecs ? 'HH:mm:ss' : 'HH:mm');
}

export {getDefaultDepotForPort, getDefaultLaneGroupForDepot, setDefaultViewForPort, IMPORT_EXPORT_LABELS,
    COLS_KEY, NUM_ROWS_KEY, SORT_MODEL_KEY, slotToDateTime, slotToTime, timeToSlot, IMPORT_EXPORT_ICONS,
    CHECK_COL_KEY};

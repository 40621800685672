import React from "react";
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {ACT_TYPE, PORTS, setTitle} from "../../common/shared";
import axios from "axios";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Paper, Select, Switch,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography, useTheme
} from "@mui/material";
import * as PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import ActionIcon from "../../components/ActionIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../common/db";
import {default as AutoActionIcon} from '@mui/icons-material/AutoFixHigh';
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from "@mui/icons-material/Delete";


const ENTRY_TYPE_LABELS = {
    '': '- Any Location -',
    // BLANK: '"Blank" entry',
    DEPOT: 'Depot',
    TERMINAL: 'Terminal',
};


export default function AutoAction() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [autoActions, setAutoActions] = React.useState([]);
    const ports = useLiveQuery(() => db.Port.toArray(), [], []);
    const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), [], []);

    React.useEffect(() => {
        setTitle('Auto Actions');
    }, []);

    const refreshAutoActions = () => {
        let params = null;
        axios.get('/admin/autoAction', { params: params })
            .then(
                (result) => {
                    console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else if (result.data.result) {
                        setAutoActions(result.data.result);
                    } else {
                        setError('Missing result is server response.');
                    }

                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }

    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            port_code: null,
            location_type: null,
            depot_id: null,
            autoActionTypes: [],
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshAutoActions(); //  easiest way to get the new data (but not really necessary, we could just modify rows too)
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setAutoActions((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params/*, event, details*/) => {
        // console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    React.useEffect(() => {
        refreshAutoActions();
    }, []);

    const makeLabel = React.useCallback((port_code, location_type, depot_id) => {
        // Depending on where we're using this, some of the params passed-in might not be of the correct type (yet).
        port_code = port_code ? port_code.toString() : null;
        location_type = location_type ? location_type.toString() : null;
        depot_id = depot_id ? parseInt(depot_id) : null;

        let lbl = 'Add Container ';
        if (location_type && depot_id) {
            lbl += 'to ';
            const depot = depots.find((d) => d.id === depot_id);
            const port = ports.find((p) => p.code === depot?.port_code);
            lbl += depot ? `${port?.name}/${depot.name}` : 'Unknown';
        }
        else if (location_type) {
            lbl += 'to any ';
            lbl += ENTRY_TYPE_LABELS[location_type] ?? 'Unknown';
            if (port_code) {
                const port = ports.find((p) => p.code === port_code);
                lbl += ` in ${port?.name}`;
            }
        }
        else if (port_code) {
            lbl += 'in ';
            const port = ports.find((p) => p.code === port_code);
            lbl += port ? port.name : 'Unknown';
        }
        else {
            lbl += 'anywhere';
        }

        return lbl;
    }, [ports, depots]);

    const columns = React.useMemo(() => [
            {
                field: 'id', headerName: 'ID', width: 50, type: 'number',
                valueGetter: ({ value }) => parseInt(value) },
            {
                field: 'label', headerName: 'Description', flex: 1,
                valueGetter: ({ row }) => makeLabel(row.port_code, row.location_type, row.depot_id),
            },
            {
                field: 'num_act_types', headerName: 'Num Actions', width: 100,
                valueGetter: ({ row }) => row.autoActionTypes?.length ?? 0,
            },
        ], [makeLabel],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={autoActions}
                          loading={!isLoaded}
                          sx={{
                              bgcolor: '#ffffff',
                              borderRadius: 0,
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                          }}
                          components={{
                              Toolbar: CustomToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  addAutoAction: addNewRow
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                          initialState={{
                              sorting: {
                                  sortModel: [{field: 'id', sort: 'asc'}]
                              },
                              columns: {
                                  columnVisibilityModel: { id: false }
                              }
                          }}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                            makeLabel={makeLabel}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {

    return (
        <GridToolbarContainer>
            {/*<GridToolbarColumnsButton />*/}
            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            <Button aria-label="Add AutoAction" size="small" startIcon={<AutoActionIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addAutoAction}> Add Auto Actions</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    addAutoAction: PropTypes.func.isRequired,
}

function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isNew = !record.id;
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {'': '- Any Port -'}))
        , [], {'': '- Any Port -', ...PORTS});
    const depotsInPort = useLiveQuery(
        () => {
            if (!record.port_code) {
                return {'': '- Any -'};
            }
            if (!record.location_type) {
                return {'': '- Any -'};
            }
            return db.Depot.where('port_code').equals(record.port_code)
                .and((d) => d.entry_type === record.location_type)
                .toArray((arr) => arr.reduce((obj, cur) => ({
                    ...obj,
                    [cur.id]: cur.name
                }), {'': '- Any -'}));
        }, [record.port_code, record.location_type], {'': '- Any -'});
    const [usedActionTypeIds, setUsedActionTypeIds] = React.useState(props.record.autoActionTypes?.map((aat) => aat.action_type_id) ?? []);
    React.useEffect(() => {
        setUsedActionTypeIds(record.autoActionTypes?.map((aat) => aat.action_type_id) ?? []);
    }, [record.autoActionTypes]);

    const availableActionTypes = useLiveQuery(
        () => db.ActionType.where('id').noneOf(usedActionTypeIds)
            .and(at => !at.deleted)
            .sortBy('sort_order')
            .then((arr) => {
                // SETTEMP, SETHUMID, SETVENT, UNFLAG don't make any sense here, so remove them
                const forbiddenActionTypes = [ACT_TYPE.SETTEMP, ACT_TYPE.SETHUMID, ACT_TYPE.SETVENT, ACT_TYPE.UNFLAG];
                arr = arr.filter((at) => !forbiddenActionTypes.includes(at.data_type));

                // if record.location_type === 'DEPOT', only show action types that have available_in = 'DEPOT' or 'BOTH'
                if (record.location_type === 'DEPOT') {
                    arr = arr.filter((at) => at.available_in === 'DEPOT' || at.available_in === 'BOTH');

                    // Also remove any that have require_location = 'TERMINAL' or 'TERMINAL_ALWAYS'
                    arr = arr.filter((at) => at.require_location !== 'TERMINAL' && at.require_location !== 'TERMINAL_ALWAYS');
                }
                // if record.location_type === 'TERMINAL', only show action types that have available_in = 'TERMINAL' or 'BOTH'
                else if (record.location_type === 'TERMINAL') {
                    arr = arr.filter((at) => at.available_in === 'TERMINAL' || at.available_in === 'BOTH');

                    // Also remove any that have require_location = 'DEPOT' or 'DEPOT_ALWAYS'
                    arr = arr.filter((at) => at.require_location !== 'DEPOT' && at.require_location !== 'DEPOT_ALWAYS');
                }
                return arr;
            }),
        [usedActionTypeIds, record.location_type], []
    );

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {

            // Make a copy of record so we can sanitize some data
            let rec = {
                ...record
            };
            // @todo: sanitize data here

            axios.post('/admin/autoAction/save', rec)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Auto Actions Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.autoAction);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/autoAction/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Auto Actions Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onLocationTypeChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            location_type: event.target.value,
            depot_id: null
        }))
    }

    const onPortChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            port_code: event.target.value,
            depot_id: null
        }))
    }

    const onDepotChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            depot_id: event.target.value
        }))
    }

    const handleAppendActionType = (event) => {
        const actionTypeId = event.target.value;
        if (usedActionTypeIds.includes(actionTypeId)) {
            return; // can't add it twice
        }

        setRecord((prev) => ({
            ...prev,
            autoActionTypes: [
                ...prev.autoActionTypes,
                {
                    action_type_id: actionTypeId,
                    actionType: availableActionTypes.find((at) => at.id === actionTypeId),
                    required: 0,
                    default_on: 0,
                }
            ]
        }));
    }

    const setActionTypeRequired = (atIndex, required) => {
        setRecord((prev) => {
            const autoActionTypes = [...prev.autoActionTypes];
            autoActionTypes[atIndex].required = required ? 1 : 0;
            if (required) {
                autoActionTypes[atIndex].default_on = 1;
            }
            return {
                ...prev,
                autoActionTypes
            }
        });
    }

    const setActionTypeDefaultOn = (atIndex, defaultOn) => {
        setRecord((prev) => {
            const autoActionTypes = [...prev.autoActionTypes];
            autoActionTypes[atIndex].default_on = defaultOn ? 1 : 0;
            return {
                ...prev,
                autoActionTypes
            }
        });
    }

    const swapActionType = (atIndex, withIndex) => {
        // Make sure the indexes are valid
        if (atIndex < 0 || atIndex >= record.autoActionTypes.length) {
            return;
        }
        if (withIndex < 0 || withIndex >= record.autoActionTypes.length) {
            return;
        }
        setRecord((prev) => {
            const autoActionTypes = [...prev.autoActionTypes];
            const tmp = autoActionTypes[atIndex];
            autoActionTypes[atIndex] = autoActionTypes[withIndex];
            autoActionTypes[withIndex] = tmp;
            return {
                ...prev,
                autoActionTypes
            }
        });
    }

    const removeActionTypeAtIndex = (atIndex) => {
        // Make sure index is valid
        if (atIndex < 0 || atIndex >= record.autoActionTypes.length) {
            return;
        }
        setRecord((prev) => {
            const autoActionTypes = [...prev.autoActionTypes];
            autoActionTypes.splice(atIndex, 1);
            return {
                ...prev,
                autoActionTypes
            }
        });
    }

    let isValid = true;

    return (
        <Dialog open={true} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="md">
            <DialogTitle>{props.makeLabel(record.port_code, record.location_type, record.depot_id)}</DialogTitle>
            <DialogContent dividers={true}>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    id="location_type_id"
                    name="location_type"
                    sx={{mr: 1}}
                    value={record.location_type || ''}
                    select
                    label="Location Type"
                    helperText='Type of location where the container is being added'
                    variant="standard"
                    margin="dense"
                    onChange={onLocationTypeChange}
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{ shrink: true }}
                >
                    {
                        Object.entries(ENTRY_TYPE_LABELS).map(([k,v],i) => <MenuItem key={i} value={k}>{v}</MenuItem>)
                    }
                </TextField>
                <TextField
                    margin="dense"
                    name="port_code"
                    sx={{mr: 1}}
                    label="Port"
                    helperText="Port to which it's being added"
                    select
                    variant="standard"
                    value={record.port_code || ''}
                    onChange={onPortChange}
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{ shrink: true }}
                >
                    {
                        Object.entries(ports).map(([k,v],i) => <MenuItem key={i} value={k}>{v}</MenuItem>)
                    }
                </TextField>
                {
                    (record.location_type && record.port_code) && <TextField
                        margin="dense"
                        name="depot_id"
                        label={record.location_type === 'TERMINAL' ? 'Terminal' : record.location_type === 'DEPOT' ? 'Depot' : 'Location'}
                        helperText={`Only for this ${record.location_type.toLowerCase()} in this port`}
                        select
                        variant="standard"
                        value={record.depot_id || ''}
                        onChange={onDepotChange}
                        SelectProps={{ displayEmpty: true }}
                        InputLabelProps={{ shrink: true }}
                    >
                        {
                            Object.entries(depotsInPort).map(([k,v],i) => <MenuItem key={i} value={k}>{v}</MenuItem>)
                        }
                    </TextField>
                }
                <br /><br />
                <Typography variant="subtitle2" gutterBottom>
                    Make the following actions available:
                </Typography>

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Action Type</TableCell>
                                <TableCell>Required</TableCell>
                                <TableCell>Turned On</TableCell>
                                <TableCell width={130}></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (record.autoActionTypes || []).map((aat  , i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box sx={{display: 'flex',alignItems: 'left',}}>
                                                <ActionIcon iconData={aat.actionType?.icon_data}
                                                            sx={{color: aat.actionType?.icon_color, alignSelf: 'center',mr: '8px'}}/>
                                                    <span>{aat.actionType?.label}</span>
                                            </Box>
                                        </TableCell>
                                        <TableCell><Switch
                                            size={'small'}
                                            checked={!!aat.required}
                                            onChange={(e) => {
                                                setActionTypeRequired(i, e.target.checked)
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        /></TableCell>
                                        <TableCell><Switch
                                            size={'small'}
                                            disabled={!!aat.required}
                                            checked={!!aat.default_on}
                                            onChange={(e) => {
                                                setActionTypeDefaultOn(i, e.target.checked)
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        /></TableCell>
                                        <TableCell>{
                                                i > 0 &&
                                                <IconButton size={'small'}
                                                            onClick={() => {swapActionType(i, i-1);}}
                                                            sx={{float: 'left'}}
                                                            title={'Move Up'}
                                                >
                                                    <ArrowUpwardIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            {
                                                i < record.autoActionTypes.length - 1 &&
                                                <IconButton size={'small'}
                                                            onClick={() => {swapActionType(i, i+1);}}
                                                            sx={{float: 'right'}}
                                                            title={'Move Down'}
                                                >
                                                    <ArrowDownwardIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <IconButton size={'small'}
                                                        onClick={() => {removeActionTypeAtIndex(i);}}
                                                        title={'Remove'}
                                                        color={'error'}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <FormControl fullWidth size={'small'} variant={'standard'}>
                                        <Select
                                            id="demo-simple-select"
                                            value={''}
                                            label="Add Action Type"
                                            onChange={handleAppendActionType}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            renderValue={() => <Typography variant="caption" sx={{ml: 1}}>Add Action Type...</Typography>}
                                        >
                                            {
                                                availableActionTypes.map((aat, i) => (
                                                    <MenuItem key={i} value={aat.id}><Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                                                        <ActionIcon iconData={aat.icon_data}
                                                                    sx={{color: aat.icon_color, alignSelf: 'center',mr: '8px'}}/>
                                                        <span>{aat.label}</span>
                                                    </Box></MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell colSpan={4}></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    makeLabel: PropTypes.func.isRequired,
};

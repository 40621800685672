/**
 * This is *just* for external links sent to the new email when they change their email address,
 * mid-way through the change email procedure.
 */

import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React from "react";
import {AuthContext} from "../../contexts/AuthProvider";
import axios from "axios";
import {Alert, Box, CircularProgress, Paper, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {setTitle} from "../../common/shared";


export default function EmailChange(props) {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setTitle('Confirming Email Change');
    }, []);

    React.useEffect(() => {
        if (code) {
            setLoading(true);
            // console.log('checking...', code, loading, error, auth.user);
            axios.post('/home/user/changeEmailConfirm', {code})
                .then(
                    (response) => {
                        console.log(response);
                        if (response.data?.error) {
                            setError(response.data.message || 'Unknown error while confirming change.');
                        }
                        else {
                            enqueueSnackbar(response.data.message || 'Email Changed.', {
                                variant: 'success'
                            });
                            auth.checkLogin().finally(() => {
                                navigate('/user/profile');
                            });
                        }
                    },
                    (error) => {
                        setError(error?.response?.data.message || error.message || error.toString());
                    }
                )
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [code, auth, enqueueSnackbar, navigate]);

    if (error) {
        return (
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Paper variant="outlined" sx={{ minHeight: '100%', p: 3}}>
                        <Typography variant="h6" gutterBottom component="div" align="center">
                            Email Change
                        </Typography>
                        <Alert severity="error">{error.message || error}</Alert>
                        <Box py={3} sx={{ textAlign: 'center' }}>
                            <Link to="/user/profile">Return to your profile.</Link>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        )
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Paper variant="outlined" sx={{ minHeight: '100%', p: 3}}>
                        <Typography variant="h6" gutterBottom component="div" align="center">
                            Email Change
                        </Typography>
                        <Box component="div" sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Box>
                        <Box sx={{ textAlign: 'center' }} color="text.disabled">
                            Confirming your email change, please wait a moment...
                        </Box>
                    </Paper>
                </Box>
            </Box>

        );
    }

    if (!code) {
        return (
            <div>Missing confirmation code.  Please open the link in the email again.</div>
        );
    }

    return (
        <div>Please wait a moment...</div>
    );
}
import React from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import {useSnackbar} from "notistack";
import {
    Alert, Badge, Box, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Toolbar,
    Tooltip, Typography
} from "@mui/material";
import theme from "../../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {formatReefer} from "../../../common/shared";
import {DateTime} from "luxon";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

export default function Upload(props) {
    const {fileUuid, onComplete, onCancel} = props;
    const { enqueueSnackbar } = useSnackbar();
    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(null);

    const [result, setResult] = React.useState(null);
    const [activeSheet, setActiveSheet] = React.useState(null);

    const runProcess = React.useCallback((finalize = false) => {
        setWorking(true);
        axios.post(`/home/sheet/process`, {finalize, uuid: fileUuid})
            .then(
                (result) => {
                    console.log(result);

                    if (!result.data) {
                        setError('Missing data in response');
                    }
                    else if (result.data.error) {
                        enqueueSnackbar(result.data.message || 'Processing Failed', {variant: 'error'});
                        setError(result.data.message);
                    }
                    else {
                        if (finalize) {
                            onComplete(result.data.result);
                            setResult(null);
                            setActiveSheet(null);
                        }
                        else {
                            setResult(result.data.result);
                            setActiveSheet(Object.keys(result.data.result.sheets)[0]);
                        }
                    }
                },
                (error) => {
                    enqueueSnackbar(error.response?.data?.message || error.toString(), {variant: 'error'});
                }
            )
            .catch(err => {
                enqueueSnackbar(err.message || err.toString(), {variant: 'warning'});
            })
            .finally(() => {
                setWorking(false);
            });
    }, [enqueueSnackbar, fileUuid, onComplete]);

    React.useEffect(() => {
        runProcess(false);
    }, [runProcess]);

    const toolbarColour = theme.palette.secondary.main;

    return (
        <Box sx={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', bgcolor: 'white'}}>
            <Toolbar
                sx={{ backgroundColor: toolbarColour, color: theme.palette.getContrastText(toolbarColour) }}
                variant={'dense'}
            >
                <IconButton
                    sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5, borderRadius: 0}}
                    aria-label="Back"
                    edge={'start'}
                    onClick={onCancel}
                >
                    <ArrowBackIcon fontSize="small" />
                    <Typography variant="caption" sx={{lineHeight: 1}}>Cancel</Typography>
                </IconButton>
                <IconButton
                    sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5, borderRadius: 0, ml: 'auto'}}
                    aria-label="Complete Upload"
                    edge={'start'}
                    onClick={() => runProcess(true)}
                >
                    <SaveAltIcon fontSize="small" />
                    <Typography variant="caption" sx={{lineHeight: 1}}>Save</Typography>
                </IconButton>
            </Toolbar>
            {
                working ? <Typography variant="caption" sx={{textAlign: 'center', py: 2}}>Processing...</Typography> :
                error ? <Alert severity={'error'} message={error} /> :
                <Box sx={{width: '100%'}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%'}}>
                        <Tabs
                            value={activeSheet}
                            onChange={(event, value) => setActiveSheet(value)}
                            variant={'scrollable'}
                            scrollButtons={'auto'}
                        >
                            {
                                Object.entries(result?.sheets || {}).map(([sheetName, sheet]) => {

                                    const laneGroupName = sheet.laneGroupName || sheetName;
                                    //  Decide if we should show a badge or not.
                                    let badgeCount = 0;
                                    let hasWarnings = false;
                                    let hasIgnored = false;
                                    Object.values(sheet.result || {}).forEach(row => {
                                        // console.log(row);
                                        if (row.ignored) {
                                            badgeCount++;
                                            hasIgnored = true;
                                        }
                                        // console.log(row.warnings, Object.keys(row.warnings || {}), Object.keys(row.warnings || {}).length);
                                        if (Object.keys(row.warnings || {}).length > 0) {
                                            // console.log(row.warnings.length);
                                            badgeCount += Object.keys(row.warnings || {}).length;
                                            hasWarnings = true;
                                        }
                                    });
                                    let tooltip = 'There are problems with this sheet';
                                    if (hasWarnings && hasIgnored) {
                                        tooltip = 'There are warnings and ignored rows in this sheet';
                                    }
                                    else if (hasWarnings) {
                                        tooltip = 'There are warnings in this sheet';
                                    }
                                    else if (hasIgnored) {
                                        tooltip = 'There are ignored rows in this sheet';
                                    }

                                    return (
                                        <Tab key={sheetName}
                                             label={ badgeCount >0 ? <Badge title={tooltip} badgeContent={badgeCount} color='warning'>{laneGroupName}</Badge> : laneGroupName}
                                             value={sheetName} />
                                    );
                                })
                            }
                        </Tabs>
                    </Box>
                    {
                        Object.entries(result?.sheets || {}).map(([sheet, data]) => (
                            <SheetTab value={activeSheet} key={sheet} index={sheet} data={data}>
                            </SheetTab>
                        ))
                    }
                </Box>
            }
        </Box>
    );
}

Upload.propTypes = {
    fileUuid: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

function specialFormatDate(str) {
    if (!str) {
        return '';
    }
    const dt = DateTime.fromSQL(str);
    return dt.isValid ? dt.toFormat('dd/LL') : str;
}

function formatTemp(temp) {
    if (!temp) {
        return '';
    }
    return `${temp}°C`;
}

function makeCell(col, row, warnings, changed, rowIgnored) {
    const field = col.key;
    let val = row[field];
    // eslint-disable-next-line default-case
    switch (field) {
        case 'reefer_num':
            val = formatReefer(val);
            break;
        case 'set_temp':
        case 'temp_am':
        case 'temp_md':
        case 'temp_pm':
            val = formatTemp(val);
            break;
        case 'set_humidity':
            val = val !== null ? `${val}%` : '';
            break;
        case 'in_at_date':
        case 'out_at_date':
            val = specialFormatDate(val);
            break;
    }

    const prps = {};
    if (col.align) {
        prps.align = col.align;
    }

    let showWarning = false;
    if (warnings[field]) {
        prps.bgcolor = '#f8f2dc';
        showWarning = true;
    }
    else if (changed[field] && !rowIgnored) {
        prps.bgcolor = '#d7f865';
    }

    return (
        <TableCell key={field} {...prps} sx={{color: 'inherit'}}>
            {showWarning &&
                <Tooltip title={warnings[field]} arrow><WarningAmberIcon sx={{color: 'black'}} /></Tooltip>}
            <span>{val}</span>
        </TableCell>
    );
}

function SheetTab(props) {
    const { value, index, data, ...other } = props;

    console.log(data);
    const cols = data.cols;
    const groups = [];
    const visibleCols = [];

    cols.forEach(col => {
        if (col.hidden) return;
        visibleCols.push(col);
    })

    visibleCols.forEach((col, idx) => {
        if (col.group) {
            const oldEntry = groups.findIndex(g => g.name === col.group);
            if (oldEntry === -1) {
                groups.push({name: col.group, start: idx, end: idx});
            }
            else {
                groups[oldEntry].end = idx;
            }
        }
    });
    //console.log(groups);
    //console.log(visibleCols);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`sheet-tabpanel-${index}`}
            aria-labelledby={`sheet-tab-${index}`}
            {...other}
        >
            {value === index && (
                <TableContainer>
                    <Table size={'small'}>
                        <TableHead>
                            {groups.length > 0 && (
                                <TableRow>
                                    {
                                        // start with the empty space before the first group
                                        groups[0].start > 0 && (
                                            <TableCell colSpan={groups[0].start}></TableCell>
                                        )
                                    }
                                    {(() => {
                                        const cells = [];
                                        for (let i = 0; i < groups.length; i++) {
                                            const group = groups[i];
                                            cells.push(<TableCell
                                                key={`group-${i}-start`}
                                                colSpan={group.end - group.start + 1}
                                                align={'center'}
                                            >{group.name}</TableCell>);
                                            const nextGrp = groups?.[i + 1];
                                            const spaceAfter = nextGrp ? nextGrp.start - group.end - 1 : visibleCols.length - group.end - 1;
                                            if (spaceAfter > 0) {
                                                cells.push(<TableCell key={`group-${i}-space`} colSpan={spaceAfter}></TableCell>);
                                            }
                                        }
                                        return cells;
                                    })()}
                                </TableRow>
                                )
                            }
                            <TableRow>
                                {visibleCols.map((col, idx) => {
                                    const prps = {};
                                    if (col.align) {
                                        prps.align = col.align;
                                    }
                                    return (
                                        <TableCell key={`col-${idx}`} {...prps}>{col.label}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.entries(data?.result || {}).map(([key, row]) => {
                                    const rowSx = {};
                                    const rowProps = {};
                                    if (row.ignored) {
                                        rowSx.textDecoration = 'line-through';
                                        rowSx.color = '#999';
                                        rowProps.title = 'Row ignored: ' + row.ignoredReason;
                                    }
                                    return (
                                        <TableRow key={key} sx={rowSx} {...rowProps}>
                                            {visibleCols.map((col, idx) => {
                                                return makeCell(col, row.data, row.warnings, row.changed, row.ignored);
                                            })}
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
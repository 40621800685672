import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import {getBlankDepot, PORTS} from "../../../common/shared";
import {
    AppBar, Box, Chip, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper,
    Popper, Toolbar, Typography
} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {default as PortIcon} from '@mui/icons-material/AlignVerticalTop';
import {default as StatusIcon} from '@mui/icons-material/FilterAlt';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {Check} from "@mui/icons-material";
import Search from "./Search";
import theme from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddContainerIcon from "../../../icons/AddContainerIcon";
import {getDefaultDepotForPort, getDefaultLaneGroupForDepot} from "./defaults";
import TerminalIcon from "../../../icons/TerminalIcon";
import LaneIcon from "../../../icons/LaneIcon";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {DateTime} from "luxon";


/**
 * Takes an array of objects with 'id' and 'label' properties, and returns an array of objects, each with a 'label' and
 * 'ids' property.  The 'ids' property is an array of the ids from the original array that had that label.
 *
 * @param arr Array of objects with 'id' and 'label' properties
 * @returns {[]} Array of object with 'label' and 'ids' properties (sorted alphabetically by label)
 */
const groupByLabel = (arr) => {
    const labels = arr.map(a => a.label);
    const uniqueLabels = [...new Set(labels)].sort();
    return uniqueLabels.map(label => {
        return {
            label: label,
            ids: arr.filter(a => a.label === label).map(a => a.id)
        }
    });
}

export default function TopBar(props) {
    const { portCode, depotId, laneGroupId, orderIds, selectedStatus, handleFilterChange, onAddContainerClick,
        showingContainer, tableRef, onSrchValueChange } = props;
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    // const [buttonSize, setButtonSize] = React.useState('medium');
    const [loading, setLoading] = React.useState(true);
    const notShippedStatuses = useLiveQuery(() => db.ContainerStatus.where('has_shipped').equals(0)
        .toArray(arr => arr.map(a => a.status)), [], []);
    const ports = useLiveQuery(() => db.Port.orderBy('name').toArray(), [], PORTS);
    const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), []);
    const depotIdsInThisPort = useLiveQuery(() => db.Depot.where('port_code').equals(portCode)
        .toArray(arr => arr.map(d => d.id)), [portCode]);
    const laneGroups = useLiveQuery(() => db.LaneGroup.orderBy('sort_order').toArray(), [], []);
    // notShippedStatusesFull is used to filter the search results, and contains the full ContainerStatus objects, not just their keys.
    const notShippedStatusesFull = useLiveQuery(
        () => db.ContainerStatus.where('has_shipped').equals(0).sortBy('sort_order'), [], []);
    // const [shipsInPort, setShipsInPort] = React.useState([]);
    // const [shipsInDepot, setShipsInDepot] = React.useState([]);
    // const [shipsInPortButNotInDepot, setShipsInPortButNotInDepot] = React.useState([]);
    // const [shipsByDepotInPort, setShipsByDepotInPort] = React.useState({});
    const [orderIdsByDepotInPort, setOrderIdsByDepotInPort] = React.useState({}); //  key is depot_id, value is array of order_ids (ints)
    const [orderIdLabels, setOrderIdLabels] = React.useState({}); //  key is order_id, value is string to display in menu
    const orderIdLabelsRef = React.useRef(orderIdLabels);
    orderIdLabelsRef.current = orderIdLabels;
    const [portHasOCsWithoutDepot, setPortHasOCsWithoutDepot] = React.useState(false);
    const [laneGroupsInThisDepot, setLaneGroupsInThisDepot] = React.useState([]);
    const [portAnchorEl, setPortAnchorEl] = React.useState(null);
    const [depotAnchorEl, setDepotAnchorEl] = React.useState(null);
    const [laneGroupAnchorEl, setlaneGroupAnchorEl] = React.useState(null);
    const [colsAnchorEl, setColsAnchorEl] = React.useState(null);
    const [srchAnchorEl, setSrchAnchorEl] = React.useState(null);
    // const [shipAnchorEl, setShipAnchorEl] = React.useState(null);
    const [orderAnchorEl, setOrderAnchorEl] = React.useState(null);
    const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState();
    const [isSrchMenuOpen, setSrchMenuOpen] = React.useState(false);
    const [blankDepot, setBlankDepot] = React.useState(undefined);

    const isPortMenuOpen = Boolean(portAnchorEl);
    const isDepotMenuOpen = Boolean(depotAnchorEl);
    const isLaneGroupMenuOpen = Boolean(laneGroupAnchorEl);
    const isColsMenuOpen = Boolean(colsAnchorEl);
    // const isShipMenuOpen = Boolean(shipAnchorEl);
    const isOrderMenuOpen = Boolean(orderAnchorEl);
    const isStatusMenuOpen = Boolean(statusAnchorEl);

    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    // We need today's date (in Irish time as an iso string) to filter out import orders that have passed their date.
    const [todayDateStr, setTodayDateStr] = React.useState(DateTime.local({ zone: "Europe/Dublin" }).toSQLDate());

    // And we also need this date to be updated when the irish date changes
    React.useEffect(() => {
        const checkDate = () => {
            const newDate = DateTime.local({ zone: "Europe/Dublin" }).toSQLDate();
            if (newDate !== todayDateStr) setTodayDateStr(newDate);
        }
        const interval = setInterval(checkDate, 60*1000);

        return () => clearInterval(interval);
    }, [todayDateStr]);

    React.useEffect(() => {
        if (portCode && depotIdsInThisPort && depotIdsInThisPort.length > 0) {
            db.OrderContainer.where('status')
                .anyOf(notShippedStatuses)
                .filter(c => depotIdsInThisPort.includes(c.depot_id))
                .filter(c => !!c.order_id) // only want OCs with an order_id
                .toArray(async (arr) => {

                    // We only care about rows that have an order_id here.
                    arr = arr.filter(a => a.order_id);

                    const checkOrderIds = arr.map(a => a.order_id);
                    const orders = await db.Order.where('id').anyOf(checkOrderIds).toArray();

                    // Update the labels, since we've done the lookup anyway
                    setOrderIdLabels((prev) => {
                        const newLabels = orders.reduce((acc, cur) => {
                            acc[cur.id] = cur.booking_ref || `${cur.id}`;
                            return acc;
                        }, {});
                        return {...prev, ...newLabels};
                    });

                    setOrderIdsByDepotInPort(arr.reduce((acc, cur) => {
                        if (cur.order_id) {
                            const o = orders.find(o => o.id === cur.order_id);
                            if (!o) return acc; //  shouldn't happen, but just in case
                            if (o.import_export === 'IMP' && o.due_date && o.due_date < todayDateStr) return acc; //  skip import orders that have passed their date

                            if (cur.depot_id in acc) {
                                if (!(acc[cur.depot_id].includes(cur.order_id))) acc[cur.depot_id].push(cur.order_id);
                            } else {
                                acc[cur.depot_id] = [cur.order_id];
                            }
                        }
                        return acc;
                    }, {}));
                });
        }
        else {
            // setShipsByDepotInPort({});
            setOrderIdsByDepotInPort({});
        }
    }, [depotIdsInThisPort, portCode, notShippedStatuses, todayDateStr]);


    // console.log('depots', depots);
    // console.log('depotsIdsInThisPort', depotIdsInThisPort);
    // console.log('shipsInPort: ', shipsByDepotInPort);

    // React.useEffect(() => {
    //     setButtonSize(isSm ? 'small' : 'medium');
    // }, [isSm]);

    // Each time the portCode changes, we need to check if there are any OCs without a depot
    React.useEffect(() => {
        if (portCode) {
            getBlankDepot(portCode).then(blankDepot => {
                setBlankDepot(blankDepot);
                // Search for OCs without a depot, but with an order_id that belongs to this port.
                db.OrderContainer.where('status').anyOf(notShippedStatuses).filter(oc => oc.depot_id === blankDepot?.id).toArray().then(async ocs => {
                    // iterate here instead of doing a forEach, as we can stop when we find the first one for this port
                    for (const oc of ocs) {
                        const order = await db.Order.get(oc.order_id);
                        if (order.port_code === portCode) {
                            setPortHasOCsWithoutDepot(true);
                            return;
                        }
                    }

                    // If we get here, we went through all the ocs, and none of them were for this port
                    setPortHasOCsWithoutDepot(false);
                });
            });

        }
        else {
            setPortHasOCsWithoutDepot(false);
        }}, [portCode, notShippedStatuses]);

    // Each time the depotId changes, we need to check if there are any lane groups in this depot
    React.useEffect(() => {
        setLaneGroupsInThisDepot(depotId ? laneGroups.filter(laneGroup => laneGroup.depot_id === depotId) : []);
    }, [depotId, laneGroups]);

    const onPortEntryChange = React.useCallback(async (newPortCode) => {
        setPortAnchorEl(null);  //  close the menu if it's open.
        const depId = getDefaultDepotForPort(newPortCode);
        const lgId = getDefaultLaneGroupForDepot(depId);
        handleFilterChange(newPortCode, depId, lgId, null, selectedStatus, 'port');
    }, [handleFilterChange, selectedStatus]);

    const onDepotEntryChange = React.useCallback(async (newDepotId) => {
        setDepotAnchorEl(null); //  close the menu (if it's actually open)
        const lgId = getDefaultLaneGroupForDepot(newDepotId);
        handleFilterChange(null, newDepotId, lgId, null, selectedStatus, 'depot');
    }, [handleFilterChange, selectedStatus]);

    const onLaneGroupEntryChange = React.useCallback(async (newLaneGroupId) => {
        setlaneGroupAnchorEl(null); //  close the menu (if it's actually open)
        handleFilterChange(null, null, newLaneGroupId, orderIds, selectedStatus, 'laneGroup');
    }, [handleFilterChange, orderIds, selectedStatus]);

    const onOrderEntryChange = React.useCallback(async (newOrderIds, inDepot) => {
        setOrderAnchorEl(null); //  close the menu (if it's actually open)
        handleFilterChange(portCode, inDepot, null, newOrderIds, selectedStatus, 'order');
    }, [handleFilterChange, portCode, selectedStatus]);

    const onStatusEntryToggle = React.useCallback(async (newStatus) => {
        setStatusAnchorEl(null); //  close the menu (if it's actually open)

        let newStatuses;
        // If we get a null for newStatus, this means they clicked the "All" button, so we need to clear the filter.
        if (newStatus === null) {
            newStatuses = [];
        }
        else {
            // if `newStatus` is in the list, remove it.  Otherwise, add it.
            newStatuses = selectedStatus.includes(newStatus) ? selectedStatus.filter(s => s !== newStatus) : [...selectedStatus, newStatus];
        }
        handleFilterChange(portCode, depotId, laneGroupId, orderIds, newStatuses, 'status');
    }, [handleFilterChange, portCode, depotId, laneGroupId, orderIds, selectedStatus]);

    /**
     * This is called when they select something from unit search dropdown menu.
     * It's only passed the depotId (the one that we'll change to), as that's all we need to know.
     * But it clears filters for lane group, ship, and status, as they could lead to nothing being actually shown.
     *
     * @type {(function(*): Promise<void>)|*}
     */
    const onUnitSearchResultSelected = React.useCallback(async (inDepotId) => {
        handleFilterChange(null, inDepotId, null, null, null, 'depot');
    }, [handleFilterChange]);

    const setColumnVisibility = (field, visible) => {
        tableRef.current.setColumnVisibility({[field]: visible});
        //  Messy way to force a re-render here (and hence have tableRef.current.getColumnVisibility() called again
        //  to get the new columns). Unfortunately we need to do it this way as there's no way (that I know of) for
        //  us to get access to the state from ContainersTable here.
        setTimeout(() => forceUpdate());
    }

    React.useEffect(() => {
        if (ports && depots && laneGroups && notShippedStatusesFull) {
            setLoading(false);
        }
    }, [ports, depots, laneGroups, notShippedStatusesFull]);

    const onSrchFieldChange = (event) => {
        const _srchTerm = event.target.value.replaceAll(' ', '').toUpperCase();
        // console.log('_srchTerm', _srchTerm);
        onSrchValueChange?.(event);
        if (_srchTerm !== '') {
            setSrchAnchorEl(event.currentTarget);
        }
        else {
            setSrchAnchorEl(null);
        }
        setSearchTerm(_srchTerm);
    };

    //  When the searchTerm changes, run the queries for it.
    React.useEffect(() => {
        // Don't query if there's no term
        if (searchTerm == null || searchTerm === '') {
            setSearchResults(null);
            return;
        }

        // IndexedDb has no 'LIKE' functionality, so we need to hack it a little.
        // First get all the depots in the current port
        const ourDepotIds = depots.filter(d => d.port_code === portCode).map(d => d.id);

        // Then get all the unshipped ocs in these depots that match the search term
        db.OrderContainer.where('status').anyOf(notShippedStatuses)
            .filter(oc => ourDepotIds.includes(oc.depot_id))
            .filter(oc => oc.reefer_num.includes(searchTerm))
            .toArray(ocs => {
                // console.log(ocs);

                // We actually only need to count the matching containers in each
                let depotCounts = depotId ? {[depotId]: 0} : {};   //  add the current depot initially to ensure it's listed
                ocs.forEach(oc => {
                    depotCounts[oc.depot_id] = (depotCounts[oc.depot_id] ?? 0) + 1;
                });

                const labelStr = (count, depotName/*, isCurrent*/) => {
                    if (count === 1) {
                        return `1 Container in ${depotName} matching '${searchTerm}'.`;
                    }
                    else if (count > 1) {
                        return `${count} Containers in ${depotName} matching '${searchTerm}'.`;
                    }
                    else {
                        return `No unshipped containers matching '${searchTerm}' in ${depotName}.`;
                    }
                }
                const getDepotName = (id) => depots.find(d => d.id === id)?.name;

                // console.log('depotCounts', depotCounts);
                let menuEntries = [];
                //  Put the current depot first
                if (depotId) {
                    menuEntries.push({
                        depotId: depotId,
                        count: depotCounts[depotId],
                        label: labelStr(depotCounts[depotId], getDepotName(depotId), true),
                        isCurrent: true,
                        isDisabled: true
                    });
                }
                //  Then put in the others, in order
                for (const did of ourDepotIds) {
                    if (!depotCounts[did] || did === depotId) continue;    //  skip current, and depots with no entry
                    menuEntries.push({
                        depotId: did,
                        count: depotCounts[did],
                        label: labelStr(depotCounts[did], getDepotName(did), false),
                        isCurrent: false,
                        isDisabled: depotCounts[did] === 0
                    });
                }

                setSearchResults(menuEntries);
            });
    }, [searchTerm, portCode, depotId, depots, notShippedStatuses]);

    //console.log('searchResults = ', searchResults, isSrchMenuOpen);

    // const dropdownButtonSx = {
    //     '& .MuiButton-startIcon': { marginRight: isSm ? 0 : '4px' },
    //     '& .MuiButton-endIcon': { marginLeft: 0 }
    // };
    //
    // if (isSm) {
    //     //dropdownButtonSx['px'] = 0;
    //     dropdownButtonSx['minWidth'] = '32px';
    // }
    // console.log(dropdownButtonSx);

    const iconButtonSx = {display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5,
        borderRadius: 0, position: 'relative', top: '5px'};
    const selectedOptionSx = {lineHeight: 1, textTransform: 'none', display: 'flex', alignItems: 'center'};

    if (isSm) {
        iconButtonSx['px'] = '4px';
    }

    return (
        <AppBar position="sticky" color="secondary">
            <Toolbar disableGutters>
                {
                    loading ? <Box sx={{ textAlign: 'center', }} color={"white"}>Loading...</Box> :
                        <>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton
                                    id="port-button"
                                    sx={{
                                        ...iconButtonSx,
                                        paddingLeft: '8px',
                                    }}
                                    aria-label="Select Port"
                                    title={"Select Port"}
                                    color="inherit"
                                    aria-controls={isPortMenuOpen ? 'port-menu': undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isPortMenuOpen ? 'true': undefined}
                                    disableRipple
                                    onClick={(event) => setPortAnchorEl(event.currentTarget)}
                                >
                                    <Typography variant="button" component='div'
                                                sx={selectedOptionSx}>
                                        {!isSm && <PortIcon fontSize='small' />}
                                        { ports.find(p => p.code === portCode)?.name || '- Port -' }
                                    </Typography>
                                    <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                </IconButton>
                                <Menu open={isPortMenuOpen}
                                      id="port-menu"
                                      MenuListProps={{ 'aria-labelledby': 'port-button' }}
                                      anchorEl={portAnchorEl}
                                      onClose={() => setPortAnchorEl(null)}
                                      transitionDuration={50}
                                      PaperProps={{
                                          square: true,
                                          sx: { borderRadius: 0 },
                                      }}
                                >
                                    {
                                        ports.map((p) =>
                                            <MenuItem key={p.code}
                                                      onClick={() => onPortEntryChange(p.code)}
                                                      selected={p.code === portCode}
                                            >{ p.name }</MenuItem> )
                                    }
                                </Menu>
                            </Box>
                            {portCode &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="depot-button"
                                        sx={iconButtonSx}
                                        aria-label="Select Terminal"
                                        title="Select Terminal/Depot"
                                        color="inherit"
                                        aria-controls={isDepotMenuOpen ? 'depot-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isDepotMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(event) => setDepotAnchorEl(event.currentTarget)}
                                    >
                                        <Typography variant="button" component='div'
                                                    sx={selectedOptionSx}>
                                            {!isSm && <TerminalIcon fontSize='small' sx={{mr: 0.5}} />}
                                            {depotId === null ? '- All - ' : depots.find(d => d.id === depotId)?.name || '- No Terminal -'}
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isDepotMenuOpen}
                                          id="depot-menu"
                                          MenuListProps={{'aria-labelledby': 'depot-button'}}
                                          anchorEl={depotAnchorEl}
                                          onClose={() => setDepotAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {
                                            // show terminals first
                                            depots
                                                .filter(d => d.port_code === portCode && d.entry_type === 'TERMINAL')
                                                .map((d, i, arr) =>
                                                    <MenuItem key={d.id}
                                                              onClick={() => onDepotEntryChange(d.id)}
                                                              divider={i === arr.length - 1}
                                                              selected={d.id === depotId}
                                                    >{d.name}</MenuItem>)
                                        }
                                        {(() => {
                                            // then show depots (if any)
                                            const justDepots = depots.filter(d => d.port_code === portCode && d.entry_type === 'DEPOT');
                                            if (justDepots.length === 0) return '';
                                            return (justDepots.map((d, i, arr) =>
                                                    <MenuItem key={d.id}
                                                              onClick={() => onDepotEntryChange(d.id)}
                                                              divider={i === arr.length - 1}
                                                              selected={d.id === depotId}
                                                    >{d.name}</MenuItem>)
                                            );
                                        })()}
                                        {
                                            // Then show the 'blank' entry, if it's needed.
                                            (portHasOCsWithoutDepot || (depotId === blankDepot?.id)) && (
                                                <MenuItem key="no-depot"
                                                          onClick={() => onDepotEntryChange(blankDepot?.id)}
                                                          selected={depotId === blankDepot?.id}
                                                >{blankDepot?.name ?? '- No Terminal -'}</MenuItem>
                                            )
                                        }
                                        {
                                            // Then show the 'all' entry
                                            <MenuItem key="all-depots"
                                                        onClick={() => onDepotEntryChange(null)}
                                                        selected={depotId === null}
                                            >- All -</MenuItem>
                                        }
                                    </Menu>
                                </Box>
                            }
                            {portCode && depotId && laneGroupsInThisDepot.length > 0 &&
                                !(isSm && orderIds && orderIds.length > 0) &&  // don't show lane group selector on mobile if an orderId is selected
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="lane-group-button"
                                        sx={iconButtonSx}
                                        aria-label="Select Lane Group"
                                        title="Select Lane Group"
                                        color="inherit"
                                        aria-controls={isLaneGroupMenuOpen ? 'lane-group-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isLaneGroupMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(event) => setlaneGroupAnchorEl(event.currentTarget)}
                                    >
                                        <Typography variant="button" component='div'
                                                    sx={selectedOptionSx}>
                                            {!isSm && <LaneIcon fontSize='small' />}
                                            {laneGroups.find(d => d.id === laneGroupId)?.name || (isSm ? <em>All</em> : '- All -')}
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isLaneGroupMenuOpen}
                                          id="lane-group-menu"
                                          MenuListProps={{'aria-labelledby': 'lane-group-button'}}
                                          anchorEl={laneGroupAnchorEl}
                                          onClose={() => setlaneGroupAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {

                                            laneGroupsInThisDepot
                                                .map((d, i, arr) =>
                                                    <MenuItem key={d.id}
                                                              onClick={() => onLaneGroupEntryChange(d.id)}
                                                              divider={i === arr.length - 1}
                                                              selected={d.id === laneGroupId}
                                                    >{d.name}</MenuItem>)
                                        }
                                        <MenuItem key="no-lane-group"
                                                  onClick={() => onLaneGroupEntryChange(null)}
                                                  selected={laneGroupId == null}
                                        >- All -</MenuItem>
                                    </Menu>
                                </Box>
                            }
                            {portCode && notShippedStatusesFull.length > 0 &&
                                !(isSm && orderIds && orderIds.length > 0) &&  // don't show status selector on mobile if an Order is selected
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="status-button"
                                        sx={iconButtonSx}
                                        aria-label="Select Status"
                                        title="Select Status"
                                        color="inherit"
                                        aria-controls={isStatusMenuOpen ? 'status-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isStatusMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(event) => setStatusAnchorEl(event.currentTarget)}
                                    >
                                        <Typography variant="button" component='div'
                                                    sx={selectedOptionSx}>
                                            {!isSm && <StatusIcon fontSize='small' />}
                                            {
                                                //notShippedStatusesFull.find(d => d.status === selectedStatus)?.status_name || (isSm ? <em>All</em> : '- All -')
                                                (() => {
                                                    const showNames = notShippedStatusesFull.filter(d => selectedStatus.includes(d.status)).map(d => d.status_name);
                                                    if (showNames.length === 0) return (isSm ? <em>All</em> : '- All -');
                                                    return showNames.join(', ');
                                                })()
                                            }
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isStatusMenuOpen}
                                          id="status-menu"
                                          MenuListProps={{'aria-labelledby': 'status-button'}}
                                          anchorEl={statusAnchorEl}
                                          onClose={() => setStatusAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {

                                            notShippedStatusesFull
                                                .map((d, i, arr) =>
                                                    <MenuItem key={d.status}
                                                              onClick={() => onStatusEntryToggle(d.status)}
                                                              divider={i === arr.length - 1}
                                                              selected={selectedStatus.includes(d.status)}
                                                    >{d.status_name}</MenuItem>)
                                        }
                                        <MenuItem key="no-status"
                                                  onClick={() => onStatusEntryToggle(null)}
                                                  selected={selectedStatus == null || selectedStatus.length === 0}
                                        >- All -</MenuItem>
                                    </Menu>
                                </Box>
                            }
                            <div style={{marginLeft: 'auto'}} />
                            {!showingContainer &&
                                <div style={{width: 'auto'}}>
                                    <Search id="unit-search"
                                        aria-controls={isSrchMenuOpen ? 'srch-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isSrchMenuOpen ? 'true' : undefined}
                                        aria-label="Search by Unit Number"
                                        value={props.srchValue} onChange={onSrchFieldChange}
                                        inputProps={{
                                            title: 'Search by Unit Number',
                                            placeholder: 'Reefer',
                                            onFocus: () => setSrchMenuOpen(true),
                                            onBlur: () => setTimeout(() => setSrchMenuOpen(false), 200)
                                        }}
                                    />
                                    <Popper open={!!(isSrchMenuOpen && srchAnchorEl)}
                                          id="srch-menu"
                                          aria-labelledby='unit-search'
                                          anchorEl={srchAnchorEl}
                                          placement='bottom-end'
                                          sx={{
                                              zIndex: theme.zIndex.modal
                                          }}
                                    ><Paper square elevation={8} sx={{borderRadius: 0, square: true}}>
                                        <List disablePadding dense>
                                            {(() => {
                                                if (searchResults == null) {
                                                    return (<ListItem><ListItemText
                                                        primary="Search for a container."
                                                    ></ListItemText></ListItem>)
                                                }
                                                else if (searchResults === []) {
                                                    return (<ListItem><ListItemText
                                                        primary="No matching containers found."
                                                    ></ListItemText></ListItem>)
                                                }
                                                else {
                                                    return searchResults.map(s => (
                                                        <ListItemButton
                                                            key={s.depotId}
                                                            // divider={s.isCurrent}
                                                            disabled={s.isDisabled}
                                                            selected={s.isCurrent}
                                                            onClick={() => onUnitSearchResultSelected(s.depotId)}
                                                        >
                                                            <ListItemText primary={s.label} />
                                                        </ListItemButton>
                                                    ))
                                                }
                                            })()}
                                        </List>
                                    </Paper></Popper>
                                </div>
                            }
                            {!showingContainer && (Object.keys(orderIdsByDepotInPort).length > 0) &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="order-button"
                                        sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5,
                                            borderRadius: 0, position: 'relative', top: '5px'}}
                                        aria-label="Select Order"
                                        title={(orderIds && orderIds.length > 0) ?
                                            `Orders: ${orderIds.join(', ')}` :
                                            "Select Order"
                                        }
                                        color="inherit"
                                        aria-controls={isOrderMenuOpen ? 'order-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isOrderMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(e) => isOrderMenuOpen ? setOrderAnchorEl(null) : setOrderAnchorEl(e.currentTarget)}
                                    >
                                        {(orderIds && orderIds.length > 0) ?
                                            <Chip label={orderIdLabels[orderIds[0]] ?? `IDs: ${orderIds.join(', ')}`}
                                                  size="small"
                                                  color="secondary"
                                                  onDelete={() => onOrderEntryChange(null, depotId)} /> :
                                            <DirectionsBoatIcon fontSize="small"/>
                                        }
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isOrderMenuOpen}
                                            id="order-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'order-button',
                                                dense: true,
                                            }}
                                            anchorEl={orderAnchorEl}
                                            onClose={() => setOrderAnchorEl(null)}
                                            transitionDuration={50}
                                            PaperProps={{
                                                square: true,
                                                sx: {borderRadius: 0},
                                            }}
                                    >
                                        {
                                            (() => {
                                                const menuItems = [];
                                                const numDepots = Object.keys(orderIdsByDepotInPort).length;

                                                // Start with this depot, if it's present
                                                if (depotId in orderIdsByDepotInPort) {
                                                    menuItems.push(
                                                        <MenuItem key="miDepot"
                                                                  sx={{fontSize: '0.75rem', fontWeight: 500, minHeight: 'auto', py: 0, mx: '0.75em'}}
                                                                  disabled
                                                                  dense
                                                                  disableGutters
                                                        >In {depots.find(d => d.id === depotId)?.name}</MenuItem>
                                                    );

                                                    // console.log(`orderIdLabels: `, orderIdLabels);

                                                    groupByLabel(orderIdsByDepotInPort[depotId].map(id => {
                                                        return { id: id, label: orderIdLabels[id] ?? `ID: ${id}` };
                                                    })).forEach((s, i, arr) => {
                                                        // console.log(s);
                                                        menuItems.push(
                                                            <MenuItem key={`${depotId}_${s.ids.join('_')}`}
                                                                      onClick={() => onOrderEntryChange(s.ids, depotId)}
                                                                      divider={i === (arr.length - 1) && (numDepots > 1)}
                                                                      title={`IDs: ${s.ids.join(', ')}`}
                                                                      // This equality only works if we never change the order of the ids.
                                                                      selected={orderIds && (orderIds.join('|') === s.ids.join('|'))}
                                                            >{s.label}</MenuItem>
                                                        );
                                                    });

                                                    // orderIdsByDepotInPort[depotId].map(id => {
                                                    //     return { id: id, label: orderIdLabels[id] ?? `ID: ${id}` };
                                                    // }).sort((a, b) => a.label.localeCompare(b.label))
                                                    //     .forEach((s, i, arr) => {
                                                    //         menuItems.push(
                                                    //             <MenuItem key={`${depotId}_${s.id}`}
                                                    //                       onClick={() => onOrderEntryChange(s.id, depotId)}
                                                    //                       divider={i === (arr.length - 1) && (numDepots > 1)}
                                                    //                       title={`Shipping List: ${s.id}`}
                                                    //                       selected={s.id === orderId}
                                                    //             >{s.label}</MenuItem>
                                                    //         )
                                                    //     });
                                                }

                                                // Then iterate through all the depots (but skip this one)
                                                Object.keys(orderIdsByDepotInPort).filter(d => parseInt(d) !== depotId).forEach((d, i, arr) => {
                                                    menuItems.push(
                                                        <MenuItem key={`mi${d}`}
                                                                  sx={{fontSize: '0.75rem', fontWeight: 500, minHeight: 'auto', py: 0, mx: '0.75em'}}
                                                                  disabled
                                                                  dense
                                                                  disableGutters
                                                        >In {depots.find(de => de.id === parseInt(d))?.name}</MenuItem>
                                                    );
                                                    groupByLabel(orderIdsByDepotInPort[d].map(id => {
                                                        return { id: id, label: orderIdLabels[id] ?? `ID: ${id}` };
                                                    })).forEach((s, j, arr2) => {
                                                        menuItems.push(
                                                            <MenuItem key={`${d}_${s.ids.join('_')}`}
                                                                        onClick={() => onOrderEntryChange(s.ids, parseInt(d))}
                                                                        divider={i !== (arr.length - 1) && j === (arr2.length - 1)}
                                                                        title={`IDs: ${s.ids.join(', ')}`}
                                                                        // This equality only works if we never change the order of the ids.
                                                                        selected={orderIds && (orderIds.join('|') === s.ids.join('|'))}
                                                            >{s.label}</MenuItem>
                                                        );
                                                    });
                                                    // orderIdsByDepotInPort[d].sort().forEach((s, j, arr2) => {
                                                    //     menuItems.push(
                                                    //         <MenuItem key={`${d}_${s}`}
                                                    //                   onClick={() => onOrderEntryChange(s, parseInt(d))}
                                                    //                   divider={i !== (arr.length - 1) && j === (arr2.length - 1)}
                                                    //                   title={`Shipping List: ${s}`}
                                                    //                   selected={s === orderIds}
                                                    //         >{orderIdLabels[s] ?? `ID: ${s}`}</MenuItem>
                                                    //     )
                                                    // });
                                                });

                                                return menuItems;
                                            })()
                                        }
                                    </Menu>
                                </Box>
                            }
                            {!showingContainer &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="cols-button"
                                        sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5,
                                            borderRadius: 0, position: 'relative', top: '5px'}}
                                        aria-label="Select Columns"
                                        title="Select Columns"
                                        color="inherit"
                                        aria-controls={isColsMenuOpen ? 'cols-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isColsMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(e) => isColsMenuOpen ? setColsAnchorEl(null) : setColsAnchorEl(e.currentTarget)}
                                    >
                                        <ViewColumnIcon fontSize="small" />
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isColsMenuOpen}
                                          id="cols-menu"
                                          MenuListProps={{
                                              'aria-labelledby': 'cols-button',
                                              dense: true,
                                          }}
                                          anchorEl={colsAnchorEl}
                                          onClose={() => setColsAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {isColsMenuOpen && (() => {
                                            const menuItems = [];
                                            const isCheckColVisible = tableRef.current.getCheckColumnVisibility();
                                            menuItems.push(<MenuItem
                                                    key="miCheck"
                                                    divider={true}
                                                    onClick={() => {
                                                        tableRef.current.setCheckColumnVisibility(!isCheckColVisible);
                                                        setTimeout(() => forceUpdate()); // causes a re-render
                                                    }}
                                                >{
                                                    isCheckColVisible ?
                                                        <>
                                                            <ListItemIcon>
                                                                <Check/>
                                                            </ListItemIcon>
                                                            Selection Column
                                                        </> :
                                                        <ListItemText inset>Selection Column</ListItemText>
                                                }</MenuItem>
                                                );
                                            return menuItems.concat(tableRef.current.getColumnVisibility().map(c =>
                                                <MenuItem
                                                    key={c.field}
                                                    onClick={() => setColumnVisibility(c.field, !c.visible)}
                                                    disabled={c.forced}
                                                >{
                                                    c.visible ?
                                                        <>
                                                            <ListItemIcon>
                                                                <Check/>
                                                            </ListItemIcon>
                                                            {c.headerName}
                                                        </> :
                                                        <ListItemText inset>{c.headerName}</ListItemText>
                                                }</MenuItem>));
                                            })()
                                        }
                                    </Menu>
                                </Box>
                            }
                            {depotId &&
                                <IconButton
                                    aria-label="Add Container"
                                    title="Add Container"
                                    color="inherit"
                                    onClick={onAddContainerClick}
                                    disabled={depotId === blankDepot?.id}
                                >
                                    <AddContainerIcon />
                                </IconButton>
                            }
                        </>
                }
            </Toolbar>
        </AppBar>
    )
}
TopBar.propTypes = {
    portCode: PropTypes.string,
    depotId: PropTypes.number,
    laneGroupId: PropTypes.number,
    orderIds: PropTypes.array,
    selectedStatus: PropTypes.array,
    handleFilterChange: PropTypes.func,
    onAddContainerClick: PropTypes.func,
    showingContainer: PropTypes.object,
    tableRef: PropTypes.object,
    srchValue: PropTypes.string,
    onSrchValueChange: PropTypes.func,
}
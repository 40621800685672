import React from "react";
import { AuthContext } from "../contexts/AuthProvider";
import { useLocation, Navigate, Outlet } from 'react-router-dom';


export default function ProtectedRoute({ role, children }) {
    const auth = React.useContext(AuthContext);
    const locn = useLocation();

    const isLoggedIn = auth.isLoggedIn();

    //  Immediately after a reload, we won't know the login state yet, in which case isLoggedIn will be null.
    //  Instead of assuming they're not logged-in, we just return nothing and wait.
    if (isLoggedIn === null) {
        return <></>;
    }

    if (isLoggedIn && auth.hasRole(role)) {
        // if we were given children, render them
        if (children) return children;
        // otherwise we're an outlet for any children of the Route parent we're in
        return <Outlet />;
    }

    //  Not logged-in, or doesn't have the correct role - send them to login
    return <Navigate to="/login" replace state={{ from: locn,
        loginMessage: isLoggedIn ? 'You do not have access to this page.': 'You must login to access this page.' }} />;
}

import React from "react";
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {setTitle} from "../../common/shared";
import axios from "axios";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import * as PropTypes from "prop-types";
import {useSnackbar} from "notistack";

export default function Customer() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [customers, setCustomers] = React.useState([]);
    const [customerTypes, setCustomerTypes] = React.useState([]);
    const [billingFreqs, setBillingFreqs] = React.useState([]);

    React.useEffect(() => {
        setTitle('Customers');
    }, []);

    const refreshCustomers = () => {
        let params = null;
        axios.get('/admin/customer', { params: params })
            .then(
                (result) => {
                    console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setCustomers(result.data.result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }

    React.useEffect(() => {
        axios.get('/admin/customerType')
            .then(
                (result) => {
                    // console.log(result);
                    // setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setCustomerTypes(result.data.result);
                    }
                },
                (error) => {
                    // setIsLoaded(true);
                    setError(error.response.data || error);
                }
            );
        axios.get('/admin/billingFreq')
            .then(
                (result) => {
                    // console.log(result);
                    // setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setBillingFreqs(result.data.result);
                    }
                },
                (error) => {
                    // setIsLoaded(true);
                    setError(error.response.data || error);
                }
            );
        refreshCustomers();
    }, []);

    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            name: '',
            type_id: '',
            billing_freq_id: '',
            is_inactive: 0,
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshCustomers(); //  easiest way to get the new data
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setCustomers((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params, event, details) => {
        // console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    const columns = React.useMemo(() => [
            {
                field: 'id', headerName: 'ID', width: 50, type: 'number',
                valueGetter: ({ value }) => parseInt(value),
            },
            {
                field: 'name', headerName: 'Customer Name', flex: 1,
                // For inactive rows, we want to show the row in a different color
                cellClassName: (params) => {
                    return params.row.is_inactive ? 'inactive-row' : '';
                }
            },
            { field: 'type_id', headerName: 'Type', flex: 1,
                valueGetter: ({ row }) => customerTypes.filter((ct) => ct.id === row.type_id)?.[0]?.type_name
            },
            { field: 'billing_freq_id', headerName: 'Billing Frequency', flex: 1,
                valueGetter: ({ row }) => billingFreqs.filter((ct) => ct.id === row.billing_freq_id)?.[0]?.freq_name
            },
            {
                field: 'is_inactive', headerName: 'Active', width: 100, type: 'boolean',
                valueGetter: ({value}) => !value,
            }
        ], [customerTypes, billingFreqs],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={customers}
                          loading={!isLoaded}
                          sx={{
                              bgcolor: '#ffffff',
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                                '& .inactive-row': {
                                    color: '#aaa',
                                },
                          }}
                          components={{
                              Toolbar: CustomToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  addCustomer: addNewRow
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                            customerTypes={customerTypes} billingFreqs={billingFreqs}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {

    return (
        <GridToolbarContainer>
            {/*<GridToolbarColumnsButton />*/}
            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            <Button aria-label="Add User" size="small" startIcon={<AddBusinessIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addCustomer}>New Customer</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    addCustomer: PropTypes.func.isRequired,
}

function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const isNew = !record.id;

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {
            axios.post('/admin/customer/save', record)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Customer Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.customer);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/customer/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Customer Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    let isValid = (!!record.name && !!record.type_id && !!record.billing_freq_id);

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>{isNew ? 'New': 'Edit'} Customer</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Customer Name"
                    helperText="Name of the Customer"
                    type="text"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={record.name}
                    onChange={onFormChange}
                />
                <FormControl size="small" sx={{minWidth: "150px", mt: 3, mr: 1}}>
                    <InputLabel id="customer-type-id-label">Type</InputLabel>
                    <Select
                        labelId="customer-type-id-label"
                        id="customer-type-id"
                        name="type_id"
                        value={record.type_id}
                        label="Customer Type"
                        onChange={onFormChange}
                    >
                        {
                            props.customerTypes.map((ct) => <MenuItem key={ct.id} value={ct.id}>{ct.type_name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{minWidth: "170px", mt: 3, mr: 1}}>
                    <InputLabel id="billing-freq-id-label">Billing Frequency</InputLabel>
                    <Select
                        labelId="billing-freq-id-label"
                        id="billing-freq-id"
                        name="billing_freq_id"
                        value={record.billing_freq_id}
                        label="Billing Frequency"
                        onChange={onFormChange}
                    >
                        {
                            props.billingFreqs.map((bf) => <MenuItem key={bf.id} value={bf.id}>{bf.freq_name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{mt: 3}}>
                    <InputLabel id="is-inactive-label">Active</InputLabel>
                    <Select
                        labelId="is-inactive-label"
                        id="is-inactive"
                        name="is_inactive"
                        value={record.is_inactive}
                        label="Active"
                        onChange={onFormChange}
                    >
                        <MenuItem value={0}>Active</MenuItem>
                        <MenuItem value={1}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New Customer' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    customerTypes: PropTypes.array.isRequired,
    billingFreqs: PropTypes.array.isRequired,
}
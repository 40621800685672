import * as React from 'react';
import {prettyDateTime, setTitle} from "../../../common/shared";
import axios from "axios";
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import * as PropTypes from "prop-types";
import {DataGrid, GridToolbarContainer} from "@mui/x-data-grid";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ProcessContainersDialog from "./ProcessContainersDialog";
import GenerateInvoicesDialog from "./GenerateInvoicesDialog";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

export default function Invoiceable() {
    const [error, setError] = React.useState(null);
    const [dialogProcessContainersVisible, setDialogProcessContainersVisible] = React.useState(false);
    const [dialogGenerateInvoicesVisible, setDialogGenerateInvoicesVisible] = React.useState(false);
    const [containers, setContainers] = React.useState([]);
    const [depotFilter, setDepotFilter] = React.useState(0);
    const [depotFilters, setDepotFilters] = React.useState([]) ;
    const [customerFilter, setCustomerFilter] = React.useState(0);
    const [customerFilters, setCustomerFilters] = React.useState([]) ;
    const [fromDateFilter, setFromDateFilter] = React.useState('');
    const [toDateFilter, setToDateFilter] = React.useState('');
    const [invoicedFilter, setInvoicedFilter] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState(0);
    const [shipFilter, setShipFilter] = React.useState('');
    const [containerSelection, setContainerSelection] = React.useState([]);

    const statusFilters = [
        {'status':0, 'label':'-Available-'},
        {'status':'NO','label':'NO'},
        {'status':'YES','label':'YES'},
        {'status':'INVOICED','label':'INVOICED'},
        {'status':'IGNORE','label':'IGNORE'}
    ]

    const isNonEmpty = (v) => (v !== '' && v !== null && v !== 0 && v !== '0' && v !== false);

    const hasFilterApplied = isNonEmpty(depotFilter) || isNonEmpty(customerFilter) || isNonEmpty(fromDateFilter) ||
        isNonEmpty(toDateFilter) || isNonEmpty(invoicedFilter) || isNonEmpty(statusFilter) || isNonEmpty(shipFilter);

    const clearFilters = () => {
        setDepotFilter(0);
        setCustomerFilter(0);
        setFromDateFilter('');
        setToDateFilter('');
        setInvoicedFilter(false);
        setShipFilter('');
        setStatusFilter(0);
    };


    React.useEffect(() => {
        setTitle('Invoice Manager - Invoiceable Containers');
        refreshDepots();
        refreshCustomers();
    }, []);

    const refreshDepots = () => {
        let params = null;
        axios.get('/admin/depot', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        let data = result.data.result;
                        data = [{'id':0, 'name':'-All-'}, ...data];
                        setDepotFilters(data);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }

    const refreshCustomers = () => {
        let params = null;
        axios.get('/admin/customer', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        //setCustomers(result.data.result);
                        let data = result.data.result;
                        data = [{'id':0, 'name':'-All-'}, ...data];
                        setCustomerFilters(data);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }

    const refreshOrderContainers = React.useCallback(() => {
        let params = null;

        if (depotFilter || customerFilter || fromDateFilter || toDateFilter || statusFilter || shipFilter || invoicedFilter) {
            params = {depot: depotFilter,customer: customerFilter, fromDate : fromDateFilter, toDate : toDateFilter, status : statusFilter, ship:shipFilter, invoiced:invoicedFilter};
        }

        axios.get('/admin/invoice/invoice-order-containers', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setContainers(result.data.result);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }, [depotFilter, customerFilter, fromDateFilter, toDateFilter, statusFilter,shipFilter, invoicedFilter]);

    const processContainers = () => {
        setDialogProcessContainersVisible(true);
    }

    const generateInvoices = () => {
        setDialogGenerateInvoicesVisible(true);
    }

    const onCancel = () => {
        setDialogProcessContainersVisible(false);
        setDialogGenerateInvoicesVisible(false);
    }

    const onUpdateContainers = () => {
        setDialogProcessContainersVisible(false);
        refreshOrderContainers();
    }

    const onGenerateInvoices = () => {
        setDialogGenerateInvoicesVisible(false);
        refreshOrderContainers();
    }

    const onDepotFilterChange = (event) => {
        setDepotFilter(event.target.value);
    }

    const onCustomerFilterChange = (event) => {
        setCustomerFilter(event.target.value);
    }

    const onFromDateFilterChange = (event) => {
        setFromDateFilter(event.target.value);
    }

    const onToDateFilterChange = (event) => {
        setToDateFilter(event.target.value);
    }

    const onInvoicedFilterChange = (event) => {
        setInvoicedFilter(event.target.checked);
    }

    const onStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    }

    const onShipFilterChange = (event) => {
        setShipFilter(event.target.value);
    }

    const onContainerSelectionModelChange = (selection) => {
        setContainerSelection(selection);
    }

    React.useEffect(() => {
        refreshOrderContainers();
    }, [ refreshOrderContainers]);

    const isLoaded = customerFilters && depotFilters;

    const containerColumns = React.useMemo(() => [
            {field: 'uuid', headerName: 'ID', width: 50, type: 'number', valueGetter: ({value}) =>value, hide:true},
            {field: 'depot_name', headerName: 'Depot',flex:1},
            {field: 'customer_name', headerName: 'Customer',flex: 1,},
            {field: 'order_id', headerName: 'Shipping List ID',flex: 1,},
            {field: 'reefer_num', headerName: 'Container',flex: 1,},
            {field: 'booking_ref', headerName: 'Booking Ref',flex: 1,},
            {field: 'ship', headerName: 'Ship',flex: 1,},
            {field: 'out_at', headerName: 'Out Date', flex: 1,
                valueFormatter: ({value}) => value ? prettyDateTime(value, {isMagic: true}) : '',
            },
            {field: 'invoice_status', headerName: 'Invoice Status', flex: 1},
            {field: 'invoice_id', headerName: 'Invoice #', flex: 1, hide:!invoicedFilter},
        ], [invoicedFilter]);

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <div id='panel-containers' role="tabpanel">
                    <Box sx={{ flexGrow: 1 }}>
                        <DataGrid id='containersDataGrid'
                                  columns={containerColumns}
                                  rows={containers}
                                  density="compact"
                                  getRowId={(r) => r.uuid}
                                  autoHeight
                                  loading={!isLoaded}
                                  sx={{bgcolor: '#ffffff'}}
                                  components={{
                                      Toolbar: CustomToolbar
                                  }}
                                  componentsProps={{
                                      toolbar: {
                                          containerSelection:containerSelection,
                                          processContainers: processContainers,
                                          generateInvoices: generateInvoices,
                                          depotFilter:depotFilter,
                                          depotFilters:depotFilters,
                                          onDepotFilterChange:onDepotFilterChange,
                                          customerFilter:customerFilter,
                                          customerFilters:customerFilters,
                                          onCustomerFilterChange:onCustomerFilterChange,
                                          fromDateFilter:fromDateFilter,
                                          onFromDateFilterChange:onFromDateFilterChange,
                                          toDateFilter:toDateFilter,
                                          onToDateFilterChange:onToDateFilterChange,
                                          invoicedFilter:invoicedFilter,
                                          onInvoicedFilterChange:onInvoicedFilterChange,
                                          statusFilter:statusFilter,
                                          statusFilters:statusFilters,
                                          onStatusFilterChange:onStatusFilterChange,
                                          shipFilter:shipFilter,
                                          onShipFilterChange:onShipFilterChange,
                                          hasFilterApplied:hasFilterApplied,
                                          clearFilters:clearFilters,
                                      }
                                  }}
                                  checkboxSelection
                            //disableSelectionOnClick
                                  isRowSelectable={(params) => params.row.invoice_id <= 0}
                                  onSelectionModelChange={onContainerSelectionModelChange}
                        />
                    </Box>
                </div>
            </Box>
            {
                dialogProcessContainersVisible &&
                <ProcessContainersDialog onCancel={onCancel}
                                         onSave={onUpdateContainers}
                                         containerSelection={containerSelection}
                />
            }
            {
                dialogGenerateInvoicesVisible &&
                <GenerateInvoicesDialog onCancel={onCancel}
                                        onSave={onGenerateInvoices}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {
    return (
        <GridToolbarContainer sx={{fontSize: '0.8125rem'}}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <InputLabel id="depot-filter-label">Port</InputLabel>
                <Select
                    labelId="depot-filter-label"
                    id="depot-filter"
                    value={props.depotFilter}
                    label="Port"
                    onChange={props.onDepotFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    {
                        props.depotFilters.map((dp) => <MenuItem key={dp.id} value={dp.id}>{dp.name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <InputLabel id="customer-filter-label">Customer</InputLabel>
                <Select
                    labelId="customer-filter-label"
                    id="customer-filter"
                    value={props.customerFilter}
                    label="Customer"
                    sx={{fontSize: 'inherit'}}
                    onChange={props.onCustomerFilterChange}
                >
                    {
                        props.customerFilters.map((vt) => <MenuItem key={vt.id} value={vt.id}>{vt.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Date From"
                    id="from-date-filter"
                    type="date"
                    //helperText="Filter from this date"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={
                        {sx:{fontSize: 'inherit'}}
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.fromDateFilter}
                    onChange={props.onFromDateFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Date To"
                    id="to-date-filter"
                    type="date"
                    //helperText="Filter to this date"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={
                        {sx:{fontSize: 'inherit'}}
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.toDateFilter}
                    onChange={props.onToDateFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <InputLabel id="status-selector-label" shrink={true} size="small" >Current Status</InputLabel>
                <Select
                    labelId="status-selector-label"
                    id="status-selector"
                    size="small"
                    label="Current Status"
                    value={props.statusFilter}
                    sx={{fontSize: 'inherit'}}
                    onChange={props.onStatusFilterChange}
                >
                    {
                        props.statusFilters.map((sf) => <MenuItem key={sf.status} value={sf.status}>{sf.label}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Ship"
                    id="ship-filter"
                    //helperText="All or part of ship name"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 13
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.shipFilter}
                    onChange={props.onShipFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 180, ml: 1, mt:1 }}>
                <InputLabel id="invoiced-filter-label" shrink={true} size="small">Show Invoiced Containers</InputLabel>
                <Switch
                        size="small"
                        sx={{ml: 6, mt:1}}
                        id="invoiced-filter"
                        checked={props.invoicedFilter}
                        onChange={props.onInvoicedFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 120, ml: 1, mt:1 }}>
                {props.hasFilterApplied &&
                    <Button color="warning" size="small"
                            startIcon={<FilterAltOffIcon />}
                            title="Remove all filters/Show all records"
                            onClick={() => props.clearFilters()}
                    >Clear Filters</Button>
                }
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
            <Button aria-label="Update Status" size="small" startIcon={<DoneAllIcon />}
                    disabled={!props.containerSelection.length>0}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.processContainers}
            > Update Status</Button>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 180, ml: 1, mt:1 }}>
            <Button aria-label="Generate Invoices" size="small" startIcon={<ReceiptIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.generateInvoices}
            > Generate Invoices</Button>
            </FormControl>

        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    processContainers:PropTypes.func,
    generateInvoices:PropTypes.func,
    containerSelection:PropTypes.array,
    depotFilter: PropTypes.number,
    onDepotFilterChange: PropTypes.func.isRequired,
    customerFilter: PropTypes.number,
    customerFilters: PropTypes.array.isRequired,
    onCustomerFilterChange: PropTypes.func.isRequired,
    statusFilters: PropTypes.array,
    onStatusFilterChange: PropTypes.func,
    hasFilterApplied: PropTypes.bool,
    clearFilters: PropTypes.func,

}


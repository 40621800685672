import React from "react";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import {default as appConfig} from "../config.js";

/**
 * Serviceworker related things that need to be in a react component.
 * To work properly (i.e. at all), this needs to be `use`d _once_ in some component
 * of the app that's always present (e.g. App.js or Header.js).
 *
 * @returns {{waitingWorker: unknown, showReload: boolean, reloadPage: ((function(): void)|*)}}
 */
export const useServiceWorker = () => {
    const [waitingWorker, setWaitingWorker] = React.useState(null);
    const [showReload, setShowReload] = React.useState(false);

    const _onWaitingWorker = (waitingWorker, when) => {
        if (appConfig.SW_AUTO_SKIP_WAITING || appConfig.SW_RELOAD_HANDLING === 'force') {
            // If SW_AUTO_SKIP_WAITING is set, the new sw doesn't need to wait
            waitingWorker?.postMessage({ type: "SKIP_WAITING" });
        }

        if (appConfig.SW_RELOAD_HANDLING) {
            switch (appConfig.SW_RELOAD_HANDLING) {
                case 'none':
                    // do nothing
                    break;
                case 'prompt':
                    setShowReload(true);
                    break;
                case 'force':
                    setTimeout(() => window.location.reload());
                    break;
                default:
                    console.warn(`'Unrecognised value of SW_RELOAD_HANDLING ${appConfig.SW_RELOAD_HANDLING}, treating as 'none'`);
            }
        }
    }

    // Called when a service worker updates. this function is a callback
    // to the actual service worker registration onUpdate.
    const onSWUpdate = React.useCallback((registration) => {
        console.log('onSWUpdate', registration);
        setWaitingWorker(registration.waiting);
        _onWaitingWorker(registration.waiting, 'installed');

    }, []);

    const onSWRegister = React.useCallback((registration) => {
        console.log('onSWRegister', registration);

        // This is the initial callback from sw.register, first place we can catch some useful info.
        // In particular if we have 2 service workers, one active, and another waiting.
        if (registration.waiting && registration.active) {
            // This happens when they didn't click the install button on a previous notification, and simply
            // refreshed the page to make the message go away.
            // So we put the message back again (or install immediately, if that's what SW_AUTO_UPGRADE says)
            setWaitingWorker(registration.waiting);
            _onWaitingWorker(registration.waiting, 'loaded');
        }
    }, []);

    const onSWSuccess = React.useCallback((registration) => {
        console.log('onSWSuccess', registration);
    }, [])

    // simply put, this tells the service
    // worker to skip the waiting phase and then reloads the page
    const reloadPage = React.useCallback(() => {
        waitingWorker?.postMessage({ type: "SKIP_WAITING" });
        setShowReload(false);
        setTimeout(() => window.location.reload());
    }, [waitingWorker]);

    // register the service worker
    React.useEffect(() => {
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register({
            onRegister: onSWRegister,
            onUpdate: onSWUpdate,
            onSuccess: onSWSuccess,
        });
    }, [onSWUpdate, onSWRegister, onSWSuccess]);

    // Listen for messages from service workers
    React.useEffect(() => {
        if ('serviceWorker' in navigator) {
            const _handler = (event) => {
                console.log('from sw: ', event.data);
                // {
                //     "type": "SW_INSTALLING",
                //     "appConfig": {
                //          "APP_NAME": "Containers",
                //          "DEXIE_POLL_INTERVAL": 30000,
                //          "SW_UPDATE_FREQ": 1800000,
                //          "SW_AUTO_SKIP_WAITING": true,
                //          "SW_RELOAD_HANDLING": "force"
                //     }
                // }
                if (event.data) {
                    switch (event.data.type) {
                        case 'SW_INSTALLING':
                            // Overwrite the auto-upgrade flag with the incoming value, so it'll be as expected
                            // above when `onSWUpdate` gets called above.
                            appConfig.SW_AUTO_SKIP_WAITING = event.data.appConfig.SW_AUTO_SKIP_WAITING;
                            appConfig.SW_RELOAD_HANDLING = event.data.appConfig.SW_RELOAD_HANDLING;
                            break;
                        default:
                            console.log('unhandled event type', event.data.type);
                    }
                }
            }
            navigator.serviceWorker.addEventListener('message', _handler);
            return () => {
                navigator.serviceWorker.removeEventListener('message', _handler);
            }
        }
    }, []);

    return { showReload, waitingWorker, reloadPage };
};
import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export default function FileInput(props) {
    const { label, onChange, error, accept, buttonProps, ...boxParams } = props;
    const refFile = React.useRef();
    const refButton = React.useRef();
    // const [attachment, setAttachment] = React.useState(null);

    const handleChange = async (event) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            const [file] = files;
            // setAttachment(file);
            if (onChange) await onChange(file, {buttonRef: refButton, inputRef: refFile});
            event.target.value = '';    //  clear the file control
        }
    }

    const _buttonProps = Object.assign({
        variant: 'contained',
        color: 'primary'
    }, buttonProps)

    return (
        <Box { ...boxParams }>
            <LoadingButton { ..._buttonProps }
                ref={refButton}
                onClick={() => refFile.current.click()}
                >{label}</LoadingButton>
            <input
                ref={refFile}
                type="file"
                accept={accept}
                hidden
                onChange={handleChange}
                />
        </Box>
    )
}

FileInput.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    error: PropTypes.any,
    accept: PropTypes.string,
    buttonProps: PropTypes.object,
}

FileInput.defaultProps = {
    accept: '*/*'
}
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import useMediaQuery from "@mui/material/useMediaQuery";


export default function SelectCustomerDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { customerId, onSelectCustomer, onCancel } = props


    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(),
        [], []
    );
    const [selectedCustomerId, setSelectedCustomerId] = React.useState(customerId);

    // console.log(customers);

    return (
        <Dialog open={true} onClose={() => onCancel()} fullScreen={fullScreen} fullWidth maxWidth="sm">
            <DialogTitle>Select Customer</DialogTitle>
            <DialogContent dividers={true}>
                {customers.length &&
                    <TextField
                        fullWidth
                        id="customerId"
                        name="customerId"
                        value={selectedCustomerId || ''}
                        select
                        label="Customer"
                        helperText='Select the Customer.'
                        variant="standard"
                        margin="dense"
                        onChange={(e) => {
                            setSelectedCustomerId(e.target.value);
                        }}
                    >
                        {
                            customers.map((d) => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
                        }
                    </TextField>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}>Cancel</Button>
                <Button onClick={() => onSelectCustomer(selectedCustomerId)} disabled={!selectedCustomerId}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}

SelectCustomerDialog.propTypes = {
    customerId: PropTypes.number,
    onSelectCustomer: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
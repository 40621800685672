import React from 'react';
import {PORTS, prettyDate, prettyDateTime, setTitle} from "../../../common/shared";
import axios from "axios";
import {
    Alert, Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField
} from "@mui/material";
import PropTypes from "prop-types";
import {DataGrid, GridToolbarColumnsButton, GridToolbarContainer} from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';
import ActionIcon from "../../../components/ActionIcon";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {createBrowserHistory} from "history";
import {useSnackbar} from "notistack";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";


// This is the key in localstorage where we save the column settings

const COLS_KEY = 'user/ActionReport/cols';
const NUM_ROWS_KEY = 'user/ActionReport/numRows';


const statusFilters = [
    {'status':0, 'label':'-All-'},
    {'status':'NO','label':'NO'},
    {'status':'YES','label':'YES'},
    {'status':'INVOICED','label':'INVOICED'},
    {'status':'IGNORE','label':'IGNORE'}
]


export default function Report() {
    const history = createBrowserHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(null);
    const [pageSize, setPageSize] = React.useState(() => {
        const sz = parseInt(localStorage.getItem(NUM_ROWS_KEY));
        if ([10, 25, 50, 100].includes(sz)) return sz;
        return 25;
    });

    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [sortModel, setSortModel] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);



    const [actions, setActions] = React.useState([]);
    const [portFilter, setPortFilter] = React.useState(history.location.state?.portFilter || '0');
    const [depotFilter, setDepotFilter] = React.useState(history.location.state?.depotFilter || 0);
    const [customerFilter, setCustomerFilter] = React.useState(history.location.state?.customerFilter || 0);
    // const [customerFilters, setCustomerFilters] = React.useState() ;
    const [fromDateFilter, setFromDateFilter] = React.useState(history.location.state?.fromDateFilter || '');
    const [toDateFilter, setToDateFilter] = React.useState(history.location.state?.toDateFilter || '');
    const [statusFilter, setStatusFilter] = React.useState(history.location.state?.statusFilter || 0);
    const [shipFilter, setShipFilter] = React.useState(history.location.state?.shipFilter || '');
    const [orderFilter, setOrderFilter] = React.useState(history.location.state?.orderFilter || '');
    const [userFilter, setUserFilter] = React.useState(history.location.state?.userFilter || 0);
    // const [userFilters, setUserFilters] = React.useState() ;
    const [actionTypeFilter, setActionTypeFilter] = React.useState(history.location.state?.actionTypeFilter || [0]);
    // const [actionTypeFilters, setActionTypeFilters] = React.useState();
    // const [actionTypeById, setActionTypeById] = React.useState({});
    const [containerFilter, setContainerFilter] = React.useState(history.location.state?.containerFilter || '');
    const [currentStatusFilter, setCurrentStatusFilter] = React.useState(history.location.state?.currentStatusFilter || '0');

    React.useEffect(() => {
        try {
            history.replace(null, {
                ...history.location.state,
                portFilter,
                depotFilter,
                customerFilter,
                fromDateFilter,
                toDateFilter,
                statusFilter,
                shipFilter,
                orderFilter,
                userFilter,
                actionTypeFilter,
                containerFilter,
                currentStatusFilter
            });
        } catch (e) {
            console.log(e);
        }
    }, [history, portFilter, depotFilter, customerFilter, fromDateFilter, toDateFilter, statusFilter,
        shipFilter, orderFilter, userFilter, actionTypeFilter, containerFilter, currentStatusFilter]);

    // Handle to drop the columns menu from
    const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

    const isNonEmpty = (v) => (v !== '' && v !== null && v !== 0 && v !== '0' && v !== false);

    const hasFilterApplied = isNonEmpty(portFilter) || isNonEmpty(depotFilter) || isNonEmpty(customerFilter) || isNonEmpty(fromDateFilter) ||
        isNonEmpty(toDateFilter) || isNonEmpty(actionTypeFilter) || isNonEmpty(orderFilter) || isNonEmpty(shipFilter) ||
        isNonEmpty(containerFilter) || isNonEmpty(statusFilter)|| isNonEmpty(userFilter) || isNonEmpty(currentStatusFilter);

    const clearFilters = () => {
        setPortFilter('0');
        setDepotFilter(0);
        setCustomerFilter(0);
        setFromDateFilter('');
        setToDateFilter('');
        setActionTypeFilter([0]);
        setOrderFilter('');
        setUserFilter(0);
        setShipFilter('');
        setContainerFilter('');
        setStatusFilter(0);
        setCurrentStatusFilter('0');
    };

    React.useEffect(() => {
        setTitle('Action Detail Report');
    }, []);

    const actionTypeById = useLiveQuery(
        () => db.ActionType.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})),
        [], []);

    const refreshActions = React.useCallback(() => {
        const params = {
            pageSize:pageSize, page:page, sortModel: sortModel, port: portFilter, depot: depotFilter,
            customer: customerFilter, fromDate : fromDateFilter, toDate : toDateFilter, status : statusFilter,
            ship:shipFilter, order:orderFilter , actionType:actionTypeFilter, container:containerFilter, user:userFilter,
            currentStatus:currentStatusFilter
        };
        console.log(params)
        setLoading(true);

        axios.post('/home/report/action', params )
            .then(
                (response) => {
                    const data = response.data;
                    if (!data) {
                        setError('Missing data in response');
                    }else{
                        if (data.error) {
                            setError(data.message);
                        } else {
                            setActions(data.rows);
                            setRowCount(data.numRows);
                        }
                    }
                },
                (error) => {
                    setError(error.response?.data?.message || error.toString());
                }
            ).finally(() => {
                setLoading(false);
            });
    }, [pageSize, page, sortModel, portFilter, depotFilter, customerFilter, fromDateFilter, toDateFilter,
        statusFilter, shipFilter, orderFilter, actionTypeFilter, containerFilter, userFilter, currentStatusFilter]);

    const onPageSizeChange = (newSize) => {
        localStorage.setItem(NUM_ROWS_KEY, newSize)
        setPageSize(newSize);
    };

    const [colVis, setColVis] = React.useState(() => {
        const c = localStorage.getItem(COLS_KEY);
        if (c) return JSON.parse(c);
        return {
            uuid: false,
        }
    })
    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const onPortFilterChange = (event) => {
        setPortFilter(event.target.value);
    }

    const onDepotFilterChange = (event) => {
        setDepotFilter(event.target.value);
    }

    const onCustomerFilterChange = (event) => {
        setCustomerFilter(event.target.value);
    }

    const onFromDateFilterChange = (event) => {
        setFromDateFilter(event.target.value);
    }

    const onToDateFilterChange = (event) => {
        setToDateFilter(event.target.value);
    }

    const onStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    }

    const onShipFilterChange = (event) => {
        setShipFilter(event.target.value);
    }

    const onOrderFilterChange = (event) => {
        setOrderFilter(event.target.value);
    }

    const onUserFilterChange = (event) => {
        setUserFilter(event.target.value);
    }

    const onCurrentStatusFilterChange = (event) => {
        setCurrentStatusFilter(event.target.value);
    }

    const onActionTypeFilterChange = (event) => {
        // For a multi-select, this value must be an array.
        // Need to do a little changing here to make the 'All' (0) option work.
        const oldVal = actionTypeFilter;
        let newVal = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

        // If the newVal is empty, add the 'All' option
        if (newVal.length === 0) {
            newVal = [0];
        }
        else {
            // If they added the 'All' option, remove all the others
            if (newVal.includes(0) && !oldVal.includes(0)) {
                newVal = [0];
            }
            else {
                // Otherwise, if there's an option selected that isn't 'All', remove 'All'
                if (newVal.includes(0) && newVal.length > 1) {
                    newVal = newVal.filter((v) => v !== 0);
                }
            }
        }

        setActionTypeFilter(newVal);
    }

    const onContainerFilterChange = (event) => {
        setContainerFilter(event.target.value);
    }

    const handleDownload = () => {

        if (rowCount && rowCount > 10000) {
            if (!window.confirm(`You are about to download ${rowCount} rows.  This may take a while.  Are you sure?`)) {
                return;
            }
        }

        setError(null);
        const path = '/home/reportExport/action';
        const params = {
            pageSize:pageSize, page:page, sortModel: sortModel, port: portFilter, depot: depotFilter,
            customer: customerFilter, fromDate : fromDateFilter, toDate : toDateFilter, status : statusFilter,
            ship:shipFilter, order:orderFilter , actionType:actionTypeFilter, container:containerFilter,user:userFilter,
            currentStatus:currentStatusFilter, colVis:colVis
        };

        // Simplest way
        //window.open(`${path}?port=${port}&date=${selectedDate}&token=${auth.getToken()}`);

        // Or using axios
        //const params = {port, date: selectedDate};
        console.log(params);
        setDownloading(true);
        axios.post(path, params, {responseType: 'blob'})
            .then((response) => {
                // console.log(response, response.data);
                const blob = new Blob([response.data], {type: response.data.type});
                if (blob.type === 'application/json') {
                    // Errors come back as application/json, which get forced into a blob by our responseType
                    // requirement.  Need to convert them back to text and parse as json.
                    blob.text().then(text => {
                        const data = JSON.parse(text);
                        //setError(data.message);
                        enqueueSnackbar(data.message, {variant: 'error'});
                    });
                }
                else {
                    // Hacky way to force browser to save the file we just received.  Convert it to a data-url,
                    // put that in a link (with the download attribute set to the filename), and click the link.
                    const urlRef = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = urlRef;
                    if (response.headers['content-disposition']) {
                        const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
                        link.download = fileNameMatch ? fileNameMatch[1] : 'actions.xlsx';
                    }
                    else {
                        // No content-disposition header?  Go with a sensible default.
                        link.download = 'actions.xlsx';
                    }
                    document.body.appendChild(link);
                    link.click();
                    // then tidy up.
                    link.parentNode.removeChild(link);
                    window.URL.revokeObjectURL(urlRef);
                }

            })
            .catch((error) => {
                //setError(error.response?.data?.message || error.toString());
                enqueueSnackbar(error.response?.data?.message || error.toString(), {variant: 'error'});
            })
            .finally(() => {
                setDownloading(false);
            });
    }


    React.useEffect(() => {
        refreshActions();
    }, [ refreshActions ]);

    const actionColumns = React.useMemo(() => [
        {field: 'a.uuid', headerName: 'ID',flex:1, hide:true, valueGetter: ({row}) => row.a?.uuid,},
        {field: 'p.name', headerName: 'Port',flex:1, valueGetter: ({row}) => row.p?.name,},
        {field: 'd.name', headerName: 'Depot/Terminal',flex:1, valueGetter: ({row}) => row.d?.name,},
        {field: 'c.name', headerName: 'Customer',flex: 1, valueGetter: ({row}) => row.c?.name,},
        {field: 'oc.order_id', headerName: 'Shipping List ID',flex: 1, valueGetter: ({row}) => row.oc?.order_id,},
        {field: 'oc.booking_ref', headerName: 'Booking Ref',flex: 1, valueGetter: ({row}) => row.oc?.booking_ref,},
        {field: 'oc.ship', headerName: 'Ship',flex: 1, valueGetter: ({row}) => row.oc?.ship,},
        {field: 'oc.reefer_num', headerName: 'Container',flex: 1, valueGetter: ({row}) => row.oc?.reefer_num,},
        {field: 'oc.cargo_description', headerName: 'Cargo',flex: 1, valueGetter: ({row}) => row.oc?.cargo_description,},
        {field: 'oc.set_temp',headerName: 'Set Point',type: 'number',flex: 1,
            valueGetter: ({row}) => row.oc?.set_temp,
            valueFormatter: ({value}) => value == null ? '-' : Number(value).toFixed(1) + '°C'
        },
        {field: 'oc.ie', headerName: 'I/E Cycle',flex: 1, valueGetter: ({row}) => row.oc?.ie,},
        {field: 'a.action_type_id', headerName: 'Action', flex: 1,
            renderCell: ({row}) => {
                const actionType = actionTypeById?.[row.a.action_type_id];
                return (
                    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                        <ActionIcon iconData={actionType?.icon_data}
                                    sx={{color: actionType?.icon_color, alignSelf: 'center',mr: '8px'}}/>
                        <span>
                                {actionType?.label}
                        </span>
                    </Box>
                )
            }
        },
        {field: 'a.data_value', headerName: 'Action Data', flex: 1, valueGetter: ({row}) => row.a?.data_value,},
        {field: 'a.action_date', headerName: 'Action Date', flex: 1,
            valueGetter: ({row}) => row.a?.action_date,
            valueFormatter: ({value}) => value == null ? '-' : prettyDate(value),
        },
        {field: 'oc.in_at', headerName: 'In Date', flex: 1,
            valueGetter: ({row}) => row.oc?.in_at,
            valueFormatter: ({value}) => value == null ? '-' : prettyDateTime(value, {isMagic: true}),
        },
        {field: 'oc.out_at', headerName: 'Out Date', flex: 1,
            valueGetter: ({row}) => row.oc?.out_at,
            valueFormatter: ({value}) => value == null ? '-' : prettyDateTime(value, {isMagic: true}),
        },
        {field: 'u.initials', headerName: 'User',flex:1, valueGetter: ({row}) => row.u?.initials,},
        {field: 'oc.invoice_status', headerName: 'Invoice Status', flex: 1, valueGetter: ({row}) => row.oc?.invoice_status,},
        {field: 'oc.invoice_id', headerName: 'Invoice Id', flex: 1, valueGetter: ({row}) => row.oc?.invoice_id,},

    ], [actionTypeById]);

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ height: '100%', position: 'relative' }}>
            <DataGrid id='actionsDataGrid'
                      density="compact"
                      disableSelectionOnClick
                      columns={actionColumns}
                      rows={actions || []}
                      getRowId={(r) => r.a.uuid}
                      rowBuffer={10}
                      page={page}
                      onPageChange={(p) => setPage(p)}
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      rowCount={rowCount}
                      paginationMode="server"
                      sortingMode="server"
                      filterMode="server"
                      onSortModelChange={(sm) => setSortModel(sm)}
                      columnVisibilityModel={colVis}
                      onColumnVisibilityModelChange={onColVisChange}
                      // autoHeight <- this breaks scrolling
                      loading={loading}
                      sx={{
                          bgcolor: '#ffffff',
                          // position: 'absolute',
                          // top: 0, left: 0, right: 0, bottom: 0,
                          width: '100%',
                          height: '100%'
                      }}
                      components={{
                          Toolbar: CustomToolbar
                      }}
                      componentsProps={{
                          panel: {
                              anchorEl: columnsButtonEl
                          },
                          toolbar: {
                              portFilter:portFilter,
                              onPortFilterChange:onPortFilterChange,
                              depotFilter:depotFilter,
                              onDepotFilterChange:onDepotFilterChange,
                              customerFilter:customerFilter,
                              onCustomerFilterChange:onCustomerFilterChange,
                              fromDateFilter:fromDateFilter,
                              onFromDateFilterChange:onFromDateFilterChange,
                              toDateFilter:toDateFilter,
                              onToDateFilterChange:onToDateFilterChange,
                              statusFilter:statusFilter,
                              onStatusFilterChange:onStatusFilterChange,
                              shipFilter:shipFilter,
                              onShipFilterChange:onShipFilterChange,
                              orderFilter:orderFilter,
                              onOrderFilterChange:onOrderFilterChange,
                              userFilter:userFilter,
                              onUserFilterChange:onUserFilterChange,
                              actionTypeFilter:actionTypeFilter,
                              onActionTypeFilterChange:onActionTypeFilterChange,
                              containerFilter:containerFilter,
                              onContainerFilterChange:onContainerFilterChange,
                              handleDownload:handleDownload,
                              allowDownload: !downloading,
                              hasFilterApplied:hasFilterApplied,
                              clearFilters:clearFilters,
                              setColumnsButtonEl:setColumnsButtonEl,
                              currentStatusFilter:currentStatusFilter,
                              onCurrentStatusFilterChange:onCurrentStatusFilterChange,
                          }
                      }}
            />
        </Box>
    )
}

function CustomToolbar(props) {

    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {}))
        , [], PORTS);
    const depots = useLiveQuery(() => db.Depot.toArray(arr => {
        // Need to sort the depots, first by port_code, then by name
        return arr.sort((a, b) => {
            if (a.port_code === b.port_code) {
                return a.name.localeCompare(b.name);
            }
            return a.port_code.localeCompare(b.port_code);
        });
    }), [], []);
    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(), [], []);
    const actionTypeById = useLiveQuery(
        () => db.ActionType.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})),
        [], []);
    const actionTypes = useLiveQuery(() => db.ActionType.where('deleted').equals(0).sortBy('sort_order'), [], []);
    const users = useLiveQuery(() => db.User.toCollection().sortBy('initials'), [], []);
    const allStatuses = useLiveQuery(() => db.ContainerStatus.orderBy('sort_order').toArray(), [], []);

    const fcCommonSx = {mr: 1, mt: 1, fontSize: 'inherit'};

    return (
        <GridToolbarContainer sx={{fontSize: '0.8125rem'}}>

            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="port-filter-label">Port</InputLabel>
                <Select
                    labelId="port-filter-label"
                    id="port-filter"
                    value={props.portFilter}
                    label="Port"
                    onChange={props.onPortFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem key={0} value={'0'}>-All-</MenuItem>
                    {
                        Object.keys(ports).map((p) => <MenuItem key={p} value={p}>{ports[p]}</MenuItem>)
                    }
                </Select>
            </FormControl>
            {depots?.length ? <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="depot-filter-label">Terminal/Depot</InputLabel>
                <Select
                    labelId="depot-filter-label"
                    id="depot-filter"
                    value={props.depotFilter}
                    label="Terminal/Depot"
                    onChange={props.onDepotFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem key={0} value={0}>-All-</MenuItem>
                    <MenuItem key={-2} value={-2}>--Terminals--</MenuItem>
                    <MenuItem key={-1} value={-1}>--Depots--</MenuItem>
                    {
                        (props.portFilter && props.portFilter !== '0') ?
                            depots.filter((dp) => dp.port_code === props.portFilter).map((dp) => <MenuItem key={dp.id} value={dp.id}>{dp.name}</MenuItem>)
                            : depots.map((dp) => <MenuItem key={dp.id} value={dp.id}>{`${ports[dp.port_code]}: ${dp.name}`}</MenuItem>)
                    }
                </Select>
            </FormControl> : null}
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="customer-filter-label">Customer</InputLabel>
                <Select
                    labelId="customer-filter-label"
                    id="customer-filter"
                    value={props.customerFilter}
                    label="Customer"
                    onChange={props.onCustomerFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem key={0} value={0}>-All-</MenuItem>
                    {
                        customers.map((vt) => <MenuItem key={vt.id} value={vt.id}>{vt.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <TextField
                    label="Action Date From"
                    id="from-date-filter"
                    type="date"
                    size="small"
                    sx={{ fontSize: 'inherit' }}
                    InputProps={
                        {sx:{fontSize: 'inherit'}}
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.fromDateFilter}
                    onChange={props.onFromDateFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <TextField
                    label="Action Date To"
                    id="to-date-filter"
                    type="date"
                    size="small"
                    sx={{ fontSize: 'inherit' }}
                    InputProps={
                        {sx:{fontSize: 'inherit'}}
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.toDateFilter}
                    onChange={props.onToDateFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="action-type-filter-label" shrink={true}>Action Type</InputLabel>
                <Select
                    labelId="action-type-filter-label"
                    id="action-type-filter"
                    multiple
                    value={props.actionTypeFilter}
                    label="Action Type"
                    onChange={props.onActionTypeFilterChange}
                    input={<OutlinedInput label="Action Type" />}
                    notched={true}
                    renderValue={(selected) => {
                        // console.log(selected);
                        if (!selected || selected?.length === 0) return '-All-';
                        return selected.map(at => actionTypeById[at]?.label ?? '-All-').join(', ');
                    }}
                    displayEmpty={true}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem key={0} value={0}>
                        <Checkbox checked={(props.actionTypeFilter.indexOf(0) > -1) || (props.actionTypeFilter?.length === 0)} />
                        <ListItemText primary="-All-" />
                    </MenuItem>
                    {
                        actionTypes.map((vt) => (
                            <MenuItem key={vt.id} value={vt.id}>
                                <Checkbox checked={props.actionTypeFilter.indexOf(vt.id) > -1} />
                                <ListItemText primary={vt.label} />
                            </MenuItem>))
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <TextField
                    label="Shipping List #"
                    id="order-filter"
                    size="small"
                    sx={{ fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 6
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.orderFilter}
                    onChange={props.onOrderFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <TextField
                    label="Ship"
                    id="ship-filter"
                    size="small"
                    sx={{ fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 10
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.shipFilter}
                    onChange={props.onShipFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <TextField
                    label="Container"
                    id="container-filter"
                    size="small"
                    sx={{ fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 11
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.containerFilter}
                    onChange={props.onContainerFilterChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <InputLabel id="status-selector-label" shrink={true} size="small">Invoice Status</InputLabel>
                <Select
                    labelId="status-selector-label"
                    id="status-selector"
                    size="small"
                    label="Invoice Status"
                    value={props.statusFilter}
                    sx={{fontSize: 'inherit'}}
                    onChange={props.onStatusFilterChange}
                >
                    {
                        statusFilters.map((sf) => <MenuItem key={sf.status} value={sf.status}>{sf.label}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <InputLabel id="user-filter-label">User</InputLabel>
                <Select
                    labelId="user-filter-label"
                    id="user-filter"
                    value={props.userFilter}
                    label="User"
                    onChange={props.onUserFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem key={0} value={0}>-All-</MenuItem>
                    {
                        users.map((u) => <MenuItem key={u.id} value={u.id}>{u.initials}: {`${u.surname}, ${u.firstname}`}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                <InputLabel id="currentStatus-filter-label">Status</InputLabel>
                <Select
                    labelId="currentStatus-filter-label"
                    id="currentStatus-filter"
                    value={props.currentStatusFilter || '0'}
                    label="Status"
                    onChange={props.onCurrentStatusFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem key={'0'} value={'0'}>-Any-</MenuItem>
                    {
                        allStatuses.map((s) => <MenuItem key={s.status} value={s.status}>{s.status_name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ ...fcCommonSx }}>
                {props.hasFilterApplied &&
                    <Button color="warning" size="small"
                            startIcon={<FilterAltOffIcon fontSize={'inherit'} />}
                            title="Remove all filters/Show all records"
                            onClick={() => props.clearFilters()}
                    >Clear Filters</Button>
                }
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ ml: 'auto', mt: 1, fontSize: 'inherit' }}>
                <Button
                    startIcon={<DownloadIcon fontSize={'inherit'} />}
                    onClick={props.handleDownload}
                    disabled={!props.allowDownload}
                    sx={{marginLeft: 'auto', fontSize: 'inherit'}}
                >Download</Button>
            </FormControl>
            <GridToolbarColumnsButton sx={{ml: 1, mt: 1, fontSize: 'inherit'}} ref={props.setColumnsButtonEl} />
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    portFilter: PropTypes.string,
    onPortFilterChange: PropTypes.func.isRequired,
    depotFilter: PropTypes.number,
    onDepotFilterChange: PropTypes.func.isRequired,
    customerFilter: PropTypes.number,
    onCustomerFilterChange: PropTypes.func.isRequired,
    onStatusFilterChange: PropTypes.func,
    onActionTypeFilterChange: PropTypes.func.isRequired,
    handleDownload: PropTypes.func,
    allowDownload: PropTypes.bool,
    hasFilterApplied: PropTypes.bool,
    clearFilters: PropTypes.func,
    setColumnsButtonEl: PropTypes.func,
    userFilter: PropTypes.number,
    onUserFilterChange: PropTypes.func.isRequired,
    currentStatusFilter: PropTypes.string,
    onCurrentStatusFilterChange: PropTypes.func.isRequired,
}


import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import useMediaQuery from "@mui/material/useMediaQuery";


export default function SelectDepotDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { portCode, depotId , laneId, onSelectDepot, onCancel } = props
    // console.log(portCode);
    const depots = useLiveQuery(() => (portCode ? db.Depot.where('port_code').equals(portCode) : db.Depot.toCollection()).sortBy('name'),
        [portCode]);
    const lanes = useLiveQuery(() => db.Lane.where('deleted').equals(0).sortBy('name'),
    [], []);
    const [lanesInDepot, setLanesInDepot] = React.useState([]);
    const [selectedDepotId, setSelectedDepotId] = React.useState(depotId);
    const [selectedLaneId, setSelectedLaneId] = React.useState(laneId);

    React.useEffect(() => {
        setLanesInDepot(lanes.filter(l => l.depot_id === selectedDepotId));
    }, [lanes, selectedDepotId]);

    return (
        <Dialog open={true} onClose={() => onCancel()} fullScreen={fullScreen} fullWidth maxWidth="sm">
            <DialogTitle>Select Location</DialogTitle>
            <DialogContent dividers={true}>
                {depots &&
                    <TextField
                        id="depotId"
                        name="depotId"
                        sx={{mr: 1}}
                        value={selectedDepotId || ''}
                        select
                        label="Terminal/Depot"
                        helperText='Select the Terminal or Depot.'
                        variant="standard"
                        margin="dense"
                        onChange={(e) => {
                            setSelectedDepotId(e.target.value);
                            setSelectedLaneId(null);
                        }}
                    >
                        {
                            depots
                                .filter(d => d.entry_type !== 'BLANK')
                                .sort((a, b) => -1*(a.entry_type.localeCompare(b.entry_type)) || a.name.localeCompare(b.name))
                                .map((d, i, arr) => <MenuItem
                                    divider={i !== arr.length - 1 && arr[i+1].entry_type !== d.entry_type}
                                    key={d.id} value={d.id}>{d.name}</MenuItem>)
                        }
                    </TextField>
                }
                {lanesInDepot.length > 0 &&
                    <TextField
                        id="laneId"
                        name="laneId"
                        sx={{mr: 1}}
                        value={selectedLaneId || ''}
                        select
                        label="Lane"
                        helperText='Select the Lane.'
                        variant="standard"
                        margin="dense"
                        onChange={(e) => {
                            setSelectedLaneId(e.target.value);
                        }}
                    >
                        <MenuItem key={0} value={null}>- None -</MenuItem>
                        {
                            lanesInDepot
                                .sort((a, b) => a.name.localeCompare(b.name, 'en', {numeric: true}))
                                .map(l => <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>)
                        }
                    </TextField>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onCancel()}>Cancel</Button>
                <Button onClick={() => onSelectDepot(selectedDepotId, selectedLaneId)} disabled={!selectedDepotId}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}

SelectDepotDialog.propTypes = {
    portCode: PropTypes.string.isRequired,
    depotId: PropTypes.number,
    laneId: PropTypes.number,
    onSelectDepot: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
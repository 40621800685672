import React from "react";
import {PORTS, prettyDate, setTitle} from "../../../common/shared";
import {
    Alert, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper,
    Select, Stack, TextField, Typography
} from "@mui/material";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import {AuthContext} from "../../../contexts/AuthProvider";
import FileInput from "../../../components/FileInput";
import {DateTime} from "luxon";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DownloadIcon from '@mui/icons-material/Download';
import {useSnackbar} from "notistack";
import Upload from "./Upload";


export default function Worksheets() {
    React.useEffect(() => {
        setTitle('Worksheets');
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const auth = React.useContext(AuthContext);
    const [error, setError] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {})),
        [], PORTS);
    const [port, setPort] = React.useState(auth.getDefaultPort());
    // this is the depots in the selected port
    const depots = useLiveQuery(
        () => db.Depot.where({port_code: port, entry_type: 'TERMINAL'}).sortBy('name')
            .then(newDepots => {
                // console.log(newDepots);
                // Set our current depot to be the first one
                if (newDepots.length > 0) {
                    setDepot(newDepots[0].id);
                }
                return newDepots;
            }),
        [port], []);
    // this is all the depots in all the ports (just for looking up names)
    const depotsById = useLiveQuery(
        () => db.Depot.toArray(arr => arr.reduce((o, c) => ({...o, [c.id]: c}), {})),
        [], {});
    const [depot, setDepot] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState(DateTime.now().toISODate());
    const [sheetStatus, setSheetStatus] = React.useState();
    const [uploadRunning, setUploadRunning] = React.useState(false);

    // This is the uuid of the upload file (while we're processing one)
    const [uploadFileUuid, setUploadFileUuid] = React.useState();

    const refreshSheet = React.useCallback(() => {
        if (port && depot && selectedDate) {
            setLoading(true);
            axios.get('/home/sheet', {params: {port, depot_id: depot, date: selectedDate}})
                .then(
                    (response) => {
                        const data = response.data;
                        if (!data) setError('Missing data in response');
                        else if (data.error) setError(data.message);
                        else setSheetStatus(data);
                    },
                    (error) => {
                        // Network down, server unreachable or other failure.
                        setError(error.message || error.toString());
                    })
                .catch(err => {
                    setError(err.message || err.toString());
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else {
            setSheetStatus(null);
        }
    }, [port, depot, selectedDate]);


    React.useEffect(() => {
        refreshSheet();
    }, [refreshSheet]);

    const prevDate = () => {
        setError(null);
        let selDt = DateTime.fromISO(selectedDate);
        if (!selDt.isValid) {
            selDt = DateTime.now();
        }
        setSelectedDate(selDt.minus({ days: 1 }).toISODate());
    };

    const nextDate = () => {
        setError(null);
        let selDt = DateTime.fromISO(selectedDate);
        // If the date is invalid, or today, just return today.
        if (!selDt.isValid || selDt.toISODate() >= DateTime.now().toISODate()) {
            setSelectedDate(DateTime.now().toISODate());
        }
        else {
            setSelectedDate(selDt.plus({ days: 1 }).toISODate());
        }
    };

    const handleDownload = () => {
        console.log(sheetStatus);
        if (sheetStatus) {
            setError(null);
            const path = '/home/sheetGet';
            // Simplest way
            //window.open(`${path}?port=${port}&date=${selectedDate}&token=${auth.getToken()}`);

            // Or using axios
            const params = {date: selectedDate, depot_id: depot};
            axios.post(path, params, {responseType: 'blob'})
                .then((response) => {
                    console.log(response, response.data);
                    const blob = new Blob([response.data], {type: response.data.type});
                    if (blob.type === 'application/json') {
                        // Errors come back as application/json (which get forced into a blob by our responseType
                        // requirement.  Need to convert them back to text and parse as json.
                        blob.text().then(text => {
                            const data = JSON.parse(text);
                            setError(data.message);
                        });
                    }
                    else {
                        // Hacky way to force browser to save the file we just received.  Convert it to a data-url,
                        // put that in a link (with the download attribute set to the filename), and click the link.
                        const urlRef = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = urlRef;
                        if (response.headers['content-disposition']) {
                            const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
                            link.download = fileNameMatch ? fileNameMatch[1] : 'worksheet.xlsx';
                        }
                        else {
                            // No content-disposition header?  Go with a sensible default.
                            link.download = 'worksheet.xlsx';
                        }
                        document.body.appendChild(link);
                        link.click();
                        // then tidy up.
                        link.parentNode.removeChild(link);
                        window.URL.revokeObjectURL(urlRef);
                    }

                });
        }
    }

    const onFileUpload = async (file, params) => {
        console.log(file, params);
        setUploadRunning(true);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("depot_id", depot);
        formData.append("date", selectedDate);
        await axios.post('/home/sheet/upload', formData)
            .then(
                (result) => {
                    console.log(result);

                    if (!result.data) {
                        setError('Missing data in response');
                    }
                    else if (result.data.error) {
                        enqueueSnackbar(result.data.message || 'Upload Failed',
                            {variant: 'error', autoHideDuration: 30000});
                    }
                    else {
                        if (result.data.incorrectDepot || result.data.incorrectDate) {
                            const depotName = depotsById[result.data.depot_id] ? depotsById[result.data.depot_id].name : 'an Unknown Depot';
                            if (!window.confirm(`This is the file for ${depotName} in ` +
                                    `${ports[result.data.port] ?? result.data.port} on ` +
                                    `${prettyDate(result.data.date)}.  Would you like to upload that instead?`)) {
                                return;
                            }
                            setPort(result.data.port);
                            setDepot(result.data.depot_id);
                            setSelectedDate(result.data.date);
                        }
                        // show the upload handler screen
                        setUploadFileUuid(result.data.uuid);
                    }
                },
                (error) => {
                    enqueueSnackbar(error.response?.data?.message || error.toString(),
                        {variant: 'error', autoHideDuration: 30000});
                }
            )
            .catch(err => {
                enqueueSnackbar(err.message || err.toString(), {variant: 'warning'});
            })
            .finally(() => {
                setUploadRunning(false);
            });
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                { uploadFileUuid &&
                    <Upload fileUuid={uploadFileUuid}
                            onComplete={(result) => {
                                if (result.error) {
                                    enqueueSnackbar(result.message || 'Save Failed', {variant: 'error'});
                                }
                                else {
                                    setUploadFileUuid(null);
                                    const msg = `${result.numUpdated} rows updated, ${result.numAdded} rows added.`;
                                    enqueueSnackbar(msg, {variant: 'success'});
                                    refreshSheet();
                                }
                            }}
                            onCancel={() => setUploadFileUuid(null)}
                    /> }
                {!uploadFileUuid &&
                    <Paper square sx={{minHeight: '100%', p: 3}}>
                        <Stack direction="row"
                               spacing={2}
                               justifyContent="space-between"
                               alignItems="center">

                            <TextField
                                variant="outlined" size="small"
                                id="selected_date"
                                name="selected_date"
                                label="Date"
                                type="date"
                                value={selectedDate}
                                required={true}
                                sx={{minWidth: 135}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: DateTime.local().toSQLDate(), // can't be later than today
                                }}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton edge="start" onClick={prevDate}>
                                                <NavigateBeforeIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={nextDate}
                                                        disabled={selectedDate >= DateTime.now().toISODate()}>
                                                <NavigateNextIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <FormControl size="small" sx={{minWidth: "150px"}} required={true}>
                                <InputLabel id="port-id-label">Port</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="port-id-label"
                                    id="port-id"
                                    value={port}
                                    label="Port"

                                    onChange={(e) => setPort(e.target.value)}
                                >
                                    {
                                        Object.entries(ports).map(([k, v]) => <MenuItem key={k}
                                                                                        value={k}>{v}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            <FormControl size="small" sx={{minWidth: "150px"}} required={true}>
                                <InputLabel id="depot-id-label">Terminal</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="depot-id-label"
                                    id="depot-id"
                                    value={depot ?? ''}
                                    label="Terminal"
                                    onChange={(e) => setDepot(e.target.value)}
                                >
                                    {
                                        depots.map((d) => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Stack>

                        <Box>
                            {
                                error && <Alert severity="error">{error}</Alert>
                            }
                            {(() => {
                                if (loading) {
                                    return (<Box sx={{display: 'flex', justifyContent: 'center', pt: 3}}>
                                        <CircularProgress/>
                                    </Box>);
                                } else {
                                    //return JSON.stringify(sheetStatus);
                                    const amend = !!sheetStatus.numActions;
                                    const dateStr = prettyDate(selectedDate, {ucFirst: false});
                                    // const portName = ports[port] ?? port;
                                    const depotName = depotsById[depot] ? depotsById[depot].name : '???';

                                    return (
                                        <Grid container direction="row" spacing={2} alignItems="stretch" pt={3}>
                                            <Grid item xs={12} textAlign="center">
                                                {sheetStatus.numActions ?
                                                    `${sheetStatus.numActions} action${sheetStatus.numActions > 1 ? 's' : ''} recorded already for ${dateStr}` :
                                                    `No actions recorded yet for ${dateStr}`
                                                } in { depotName }.
                                            </Grid>
                                            <Grid item xs={12} md={6} textAlign="center"
                                                  sx={{display: 'flex', flexDirection: 'column'}}>
                                                <Typography component="div" variant="body2" color="text.disabled"
                                                            sx={{flexGrow: '1', pb: 2}}>
                                                    Download the{amend ? ' latest' : ' unfilled'} worksheet
                                                    for {dateStr}.
                                                </Typography>
                                                <div>
                                                    <Button variant="contained"
                                                            startIcon={<DownloadIcon/>}
                                                            onClick={handleDownload}
                                                    >Download Worksheet</Button>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} textAlign="center"
                                                  sx={{display: 'flex', flexDirection: 'column'}}>
                                                <Typography component="div" variant="body2" color="text.disabled"
                                                            sx={{flexGrow: '1', pb: 2}}>{
                                                    amend ?
                                                        `Upload a completed worksheet to amend the existing records for ${dateStr}.` :
                                                        `Upload a completed worksheet for ${dateStr}`
                                                }</Typography>
                                                <div>
                                                    <FileInput
                                                        buttonProps={{
                                                            title: amend ? 'Amend Worksheet' : 'Upload Worksheet',
                                                            color: amend ? 'warning' : "secondary",
                                                            startIcon: <UploadFileIcon/>,
                                                            disabled: uploadRunning,
                                                            loading: uploadRunning,
                                                        }}
                                                        label={amend ? 'Amend Worksheet' : 'Upload Worksheet'}
                                                        onChange={onFileUpload}
                                                        accept=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            })()}
                        </Box>
                    </Paper>
                }
            </Box>
        </Box>
    );
}
import {useSnackbar} from "notistack";
import React from "react";
import axios from "axios";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";


function ChangePasswordDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record || {
        oldPassword: '',
        newPassword: '',
        verifyPassword: '',
    });
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fieldRefs = {
        oldPassword: React.useRef(null),
        newPassword: React.useRef(null),
        verifyPassword: React.useRef(null),
    };

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {
            setSaving(true);
            axios.post('/home/user/setPassword', record)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                            if (result.data.field && fieldRefs[result.data.field]) {
                                fieldRefs[result.data.field].current.focus();
                            }
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Password Changed', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave();
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    }

    const isValid = (record.oldPassword && record.newPassword) &&
        (record.newPassword === record.verifyPassword);

    return (
        <form id="frmSetPass" onSubmit={(event) => {
            event.preventDefault();
        }}>
            <Dialog open={true} onClose={handleClose} fullScreen={fullScreen}>

                <DialogTitle>Change Password</DialogTitle>
                <DialogContent dividers>
                    {   error && (
                        <Alert severity="error">{error.message || error}</Alert> )
                    }
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id="oldPassword"
                        name="oldPassword"
                        label="Current Password"
                        helperText="Enter your current (old) password"
                        type="password"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={record.oldPassword}
                        onChange={onFormChange}
                        inputRef={fieldRefs.oldPassword}
                    />
                    <TextField
                        margin="dense"
                        id="newPassword"
                        name="newPassword"
                        label="New Password"
                        helperText="Enter your new password"
                        type="password"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={record.newPassword}
                        onChange={onFormChange}
                        inputRef={fieldRefs.newPassword}
                    />
                    <TextField
                        margin="dense"
                        id="verifyPassword"
                        name="verifyPassword"
                        label="Verify Password"
                        helperText="Re-enter your New Password (for verification)"
                        type="password"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={record.verifyPassword}
                        onChange={onFormChange}
                        inputRef={fieldRefs.verifyPassword}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleSave} disabled={!isValid || saving}>Update Password</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}

ChangePasswordDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}

export default ChangePasswordDialog;
import React from "react";
import {setTitle} from "../common/shared";
import {Box, Card, CardActionArea, CardContent, CardMedia, Paper, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DexieContext} from "../contexts/DexieProvider";


export default function Home() {

    const dexie = React.useContext(DexieContext);
    React.useEffect(() => {
        setTitle('Home');
    }, []);
    //console.log('Dexie status: ' + getStatusText());

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Paper variant="outlined" square sx={{ minHeight: '100%', p: 3}}>
                    <Stack direction="column" spacing={2}>
                        <Card sx={{position: 'relative', bgcolor: '#d5e0da'}} raised>
                            <CardActionArea component={RouterLink} to="/user/containers">
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image="/images/cards/containers960x328_2.jpg"
                                />
                                <CardContent sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    bgcolor: 'rgba(0, 0, 0, 0.54)',
                                    color: 'white',
                                    padding: '10px',
                                }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Containers
                                    </Typography>
                                    <Typography variant="body2">
                                        Realtime container records
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card sx={{position: 'relative', bgcolor: '#d5e0da'}} raised>
                            <CardActionArea component={RouterLink} to="/user/unplugs">
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image="/images/cards/unplugs.jpg"
                                />
                                <CardContent sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    bgcolor: 'rgba(0, 0, 0, 0.54)',
                                    color: 'white',
                                    padding: '10px',
                                }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Unplugs
                                    </Typography>
                                    <Typography variant="body2">
                                        Unplug-only containers
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        {dexie.netOnline &&
                            <Card sx={{position: 'relative', bgcolor: '#d5e0da'}} raised>
                                <CardActionArea component={RouterLink} to="/user/worksheets">
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="/images/cards/wks_dall-e_1000x450.jpg"
                                    />
                                    <CardContent sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '100%',
                                        bgcolor: 'rgba(0, 0, 0, 0.54)',
                                        color: 'white',
                                        padding: '10px',
                                    }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Worksheets
                                        </Typography>
                                        <Typography variant="body2">
                                            Worksheet download and upload
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        }
                    </Stack>
                </Paper>
            </Box>
        </Box>
    );
}
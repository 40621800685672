import React from "react";
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {setTitle} from "../../common/shared";
import axios from "axios";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import * as PropTypes from "prop-types";
import {useSnackbar} from "notistack";

export default function CustomerType() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [customerTypes, setCustomerTypes] = React.useState([]);

    React.useEffect(() => {
        setTitle('Customer Types');
    }, []);

    const refreshCustomerTypes = () => {
        let params = null;
        axios.get('/admin/customerType', { params: params })
            .then(
                (result) => {
                    console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setCustomerTypes(result.data.result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }

    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            type_name: ''
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshCustomerTypes(); //  easiest way to get the new data (but not really necessary, we could just modify users too)
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setCustomerTypes((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params, event, details) => {
        // console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    React.useEffect(() => {
        refreshCustomerTypes();
    }, []);

    const columns = React.useMemo(() => [
            { field: 'id', headerName: 'ID', width: 50, type: 'number',
                valueGetter: ({ value }) => parseInt(value) },
            { field: 'type_name', headerName: 'Customer Type', flex: 1 },
        ], [],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={customerTypes}
                          loading={!isLoaded}
                          sx={{bgcolor: '#ffffff'}}
                          components={{
                              Toolbar: CustomToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  addCustomerType: addNewRow
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {

    return (
        <GridToolbarContainer>
            {/*<GridToolbarColumnsButton />*/}
            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            <Button aria-label="Add User" size="small" startIcon={<AddBusinessIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addCustomerType}> New Customer Type</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    addCustomerType: PropTypes.func.isRequired,
}

function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const isNew = !record.id;

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {
            axios.post('/admin/customerType/save', record)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Customer Type Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.customerType);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/customerType/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Customer Type Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    let isValid = (!!record.type_name);

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>{isNew ? 'New': 'Edit'} Customer Type</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    autoFocus
                    margin="dense"
                    id="type_name"
                    name="type_name"
                    label="Customer Type Name"
                    helperText="Name of the Customer Type"
                    type="text"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={record.name}
                    onChange={onFormChange}
                />
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New Customer Type' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}
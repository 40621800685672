import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from '../logo.svg';
import './NoMatch.css';
import {setTitle} from "../common/shared";

export default function NoMatch() {

    React.useEffect(() => {
        setTitle('Page Not Found');
    }, []);

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography variant="h2" align="center" sx={{ mt: 5, mb: 3 }}>Page not Found</Typography>
            <Typography variant="p" component="div" align="center" color="text.disabled">You have followed a link to a
                page that doesn't exist (yet).</Typography>
            <br />
            <img src={logo} className="App-logo" alt="logo" />
        </Box>
    )
}
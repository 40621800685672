import * as React from 'react';
import {setTitle} from "../../../common/shared";
import axios from "axios";
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import {debounce} from '@mui/material/utils';
import * as PropTypes from "prop-types";
import {DataGrid, GridActionsCellItem, GridToolbarContainer} from "@mui/x-data-grid";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExportInvoicesDialog from "./ExportInvoicesDialog";
import InvoiceLinesDialog from "./InvoiceLinesDialog";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ListAltIcon from '@mui/icons-material/ListAlt';
import DoneIcon from '@mui/icons-material/Done';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useSnackbar} from "notistack";
import {useCallback} from "react";

export default function Invoice() {

    const [error, setError] = React.useState(null);
    const [dialogExportInvoicesVisible, setDialogExportInvoicesVisible] = React.useState(false);
    const [dialogInvoiceLinesVisible, setDialogInvoiceLinesVisible] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [invoices, setInvoices] = React.useState([]);
    const [customerFilter, setCustomerFilter] = React.useState(0);
    const [customerFilters, setCustomerFilters] = React.useState([]) ;
    const [fromDateFilter, setFromDateFilter] = React.useState('');
    const [toDateFilter, setToDateFilter] = React.useState('');
    const [exportedFilter, setExportedFilter] = React.useState(false);
    const [invoiceSelection, setInvoiceSelection] = React.useState([]);
    const [invoiceFromFilter, setInvoiceFromFilter] = React.useState('');
    const [invoiceToFilter, setInvoiceToFilter] = React.useState('');
    const [reeferFilter, setReeferFilter] = React.useState('');
    const [reeferFilterTemp, setReeferFilterTemp] = React.useState(''); // This is the non-debounce version of reeferFilter
    const { enqueueSnackbar } = useSnackbar();

    const isNonEmpty = (v) => (v !== '' && v !== null && v !== 0 && v !== '0' && v !== false);

    const hasFilterApplied =isNonEmpty(customerFilter) || isNonEmpty(fromDateFilter) ||
        isNonEmpty(toDateFilter) || isNonEmpty(exportedFilter) || isNonEmpty(invoiceFromFilter) || isNonEmpty(invoiceToFilter) ||
        isNonEmpty(reeferFilter);

    const clearFilters = () => {
        setCustomerFilter(0);
        setFromDateFilter('');
        setToDateFilter('');
        setExportedFilter(false);
        setInvoiceFromFilter('');
        setInvoiceToFilter('');
        setReeferFilter('');
        setReeferFilterTemp('');
    };



    React.useEffect(() => {
        setTitle('Invoice Manager - Invoices');
        refreshCustomers();
    }, []);

    const refreshCustomers = () => {
        let params = null;
        axios.get('/admin/customer', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        //setCustomers(result.data.result);
                        let data = result.data.result;
                        // sort customers by name
                        data.sort((a, b) => (a.name > b.name) ? 1 : -1);
                        data = [{'id':0, 'name':'-All-'}, ...data];
                        setCustomerFilters(data);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }

    const refreshInvoices = React.useCallback(() => {
        let params = null;

        if (customerFilter || fromDateFilter || toDateFilter || exportedFilter || invoiceFromFilter || invoiceToFilter || reeferFilter) {
            params = {customer: customerFilter, fromDate : fromDateFilter, toDate : toDateFilter,
                exported:exportedFilter, invoiceFrom:invoiceFromFilter , invoiceTo:invoiceToFilter,
                reefer:reeferFilter};
        }

        axios.get('/admin/invoice', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setInvoices(result.data.result);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }, [customerFilter, fromDateFilter, toDateFilter, exportedFilter, invoiceFromFilter, invoiceToFilter, reeferFilter]);

    const exportToCsv = () => {
        setDialogExportInvoicesVisible(true);
    }

    const onCancel = () => {
        setDialogExportInvoicesVisible(false);
        setDialogInvoiceLinesVisible(false);
    }

    const onExportInvoices = () => {
        setDialogExportInvoicesVisible(false);
        refreshInvoices();
    }

    const onCustomerFilterChange = (event) => {
        setCustomerFilter(event.target.value);
    }

    const onFromDateFilterChange = (event) => {
        setFromDateFilter(event.target.value);
    }

    const onToDateFilterChange = (event) => {
        setToDateFilter(event.target.value);
    }

    const onExportedFilterChange = (event) => {
        setExportedFilter(event.target.checked);
    }

    const onInvoiceSelectionModelChange = (selection) => {
        setInvoiceSelection(selection);
    }

    const onInvoiceFromFilterChange = (event) => {
        setInvoiceFromFilter(event.target.value);
    }

    const onInvoiceToFilterChange = (event) => {
        setInvoiceToFilter(event.target.value);
    }

    // Update reeferFilterTemp state in real-time as the user types in the Reefer field
    const onReeferFilterChange = (event) => {
        setReeferFilterTemp(event.target.value);
    }

    // Use the debounce function to delay the update of reeferFilter state
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateReeferFilter = useCallback(debounce(setReeferFilter, 500), []);

    // Call the debounced function whenever reeferFilterTemp state changes
    React.useEffect(() => {
        debouncedUpdateReeferFilter(reeferFilterTemp);
    }, [debouncedUpdateReeferFilter, reeferFilterTemp]);

    const viewInvoiceLines = (record) => {
        setDialogRecord(record);
        setDialogInvoiceLinesVisible(true);
    }

    const printInvoice = useCallback((record) => {
        console.log(record);
        if (record) {
            const path = '/admin/invoiceExport/invoiceDetail';
            let params = {
                invoiceId: record.id
            }
            axios.get(path, {responseType: 'blob', params})
                .then((response) => {
                    const blob = new Blob([response.data], {type: response.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    if (response.headers['content-disposition']) {
                        const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
                        link.download = fileNameMatch ? fileNameMatch[1] : 'filename.bin';
                    }
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    setError(false);
                    enqueueSnackbar(response.data.message || 'Invoice Exported', {
                        variant: 'success'
                    });

                    //props.onSave() ;  //result.data.price);

                }).catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            }).finally(() => {
                //setSaving(false);
            });
        }
    }, [enqueueSnackbar]);

    React.useEffect(() => {
        refreshInvoices();
    }, [ refreshInvoices ]);

    const isLoaded = customerFilters ;

    const invoiceColumns = React.useMemo(() => [
        {field: 'id', headerName: 'Invoice #', width: 100, type: 'number', valueGetter: ({value}) =>value},
        {field: 'customer_name', headerName: 'Customer',flex: 1,},
        {field: 'invoice_date', headerName: 'Invoice Date', flex: 1},
        {field: 'total_net', headerName: 'Total Net', flex: 1},
        {
            field: 'actions',
            headerName: 'Options',
            hideable: false,
            type: 'actions',
            width: 130,
            align: 'right',
            getActions: (params) => {
                const acts = [];
                acts.push(<GridActionsCellItem icon={<ListAltIcon />}
                                               title="View Invoice Details"
                                               label="View"
                                               onClick={() => viewInvoiceLines(params.row)}
                    />);
                acts.push(<GridActionsCellItem icon={<FileDownloadIcon />}
                                               title="Download Invoice"
                                               label="Print"
                    onClick={() => printInvoice(params.row)}
                />);
                return acts;
            }
        },

        {field: 'exported', headerName: 'Exported', flex: 1, hide:!exportedFilter,
            renderCell: ({row}) => {
            let icon = '';
                if(row.exported) {
                     icon = <DoneIcon sx={{color: '#009900', alignSelf: 'center',mr: '8px'}}/>
                }
                return (
                    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                        {icon}
                    </Box>
                )
            }
        },
        {field: 'export_filename', headerName: 'Export File', flex: 1, hide:!exportedFilter},
    ], [exportedFilter, printInvoice]);

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <div id='panel-invoices' role="tabpanel" >
                    <Box sx={{ flexGrow: 1 }}>
                        <DataGrid
                            id='invoicesDataGrid'
                            columns={invoiceColumns}
                            rows={invoices}
                            density="compact"
                            //getRowId={(r) => r.uuid}
                            autoHeight
                            loading={!isLoaded}
                            sx={{bgcolor: '#ffffff'}}
                            components={{
                                Toolbar: CustomToolbar
                            }}
                            componentsProps={{
                                toolbar: {
                                    exportToCsv: exportToCsv,
                                    invoiceSelection:invoiceSelection,
                                    customerFilter:customerFilter,
                                    customerFilters:customerFilters,
                                    onCustomerFilterChange:onCustomerFilterChange,
                                    fromDateFilter:fromDateFilter,
                                    onFromDateFilterChange:onFromDateFilterChange,
                                    toDateFilter:toDateFilter,
                                    onToDateFilterChange:onToDateFilterChange,
                                    exportedFilter:exportedFilter,
                                    onExportedFilterChange:onExportedFilterChange,
                                    invoiceFromFilter:invoiceFromFilter,
                                    onInvoiceFromFilterChange:onInvoiceFromFilterChange,
                                    invoiceToFilter:invoiceToFilter,
                                    onInvoiceToFilterChange:onInvoiceToFilterChange,
                                    reeferFilter:reeferFilterTemp,
                                    onReeferFilterChange:onReeferFilterChange,
                                    hasFilterApplied:hasFilterApplied,
                                    clearFilters:clearFilters,
                                }
                            }}
                            checkboxSelection
                            isRowSelectable={(params) => params.row.exported === 0}
                            onSelectionModelChange={onInvoiceSelectionModelChange}
                        />
                    </Box>
                </div>
            </Box>
            {
                dialogExportInvoicesVisible &&
                <ExportInvoicesDialog onCancel={onCancel}
                                      onSave={onExportInvoices}
                                      invoiceSelection={invoiceSelection}
                />
            }
            {
                dialogInvoiceLinesVisible &&
                <InvoiceLinesDialog record={dialogRecord} onCancel={onCancel}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {
    return (
        <GridToolbarContainer sx={{fontSize: '0.8125rem'}}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <InputLabel id="customer-filter-label">Customer</InputLabel>
                <Select
                    labelId="customer-filter-label"
                    id="customer-filter"
                    value={props.customerFilter}
                    label="Customer"
                    onChange={props.onCustomerFilterChange}
                    sx={{fontSize: 'inherit'}}
                >
                    {
                        props.customerFilters.map((vt) => <MenuItem key={vt.id} value={vt.id}>{vt.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Date From"
                    id="from-date-filter"
                    type="date"
                    //helperText="Filter from this date"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={
                        {sx:{fontSize: 'inherit'}}
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.fromDateFilter}
                    onChange={props.onFromDateFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Date To"
                    id="to-date-filter"
                    type="date"
                    //helperText="Filter to this date"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={
                        {sx:{fontSize: 'inherit'}}
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.toDateFilter}
                    onChange={props.onToDateFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Invoice# From"
                    id="invoice-from-filter"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 13
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.invoiceFromFilter}
                    onChange={props.onInvoiceFromFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Invoice# To"
                    id="invoice-to-filter"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 13
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.invoiceToFilter}
                    onChange={props.onInvoiceToFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
                <TextField
                    label="Reefer"
                    id="reefer-filter"
                    size="small"
                    sx={{ ml: 1, fontSize: 'inherit' }}
                    InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 13
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={props.reeferFilter}
                    onChange={props.onReeferFilterChange}
                />
            </FormControl>


            <FormControl variant="outlined" size="small" sx={{ minWidth: 180, ml: 1, mt:1 }}>
                <InputLabel id="exported-filter-label" shrink={true} size="small">Show Exported Invoices</InputLabel>
                <Switch
                    size="small"
                    sx={{ml: 6, mt:1}}
                    id="exported-filter"
                    checked={props.exportedFilter}
                    onChange={props.onExportedFilterChange}
                />
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 120, ml: 1, mt:1 }}>
                {props.hasFilterApplied &&
                    <Button color="warning" size="small"
                            startIcon={<FilterAltOffIcon />}
                            title="Remove all filters/Show all records"
                            onClick={() => props.clearFilters()}
                    >Clear Filters</Button>
                }
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80, ml: 1, mt:1 }}>
            <Button aria-label="Export & Close Selected Invoices" size="small" startIcon={<DoneAllIcon />}
                    disabled={!props.invoiceSelection}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.exportToCsv}
            > Export & Close Selected Invoices</Button>
            </FormControl>

        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    exportToCsv:PropTypes.func,
    invoiceSelection:PropTypes.array,
    customerFilter: PropTypes.number,
    customerFilters: PropTypes.array.isRequired,
    onCustomerFilterChange: PropTypes.func.isRequired,
    invoiceFromFilter: PropTypes.string,
    onInvoiceFromFilterChange: PropTypes.func.isRequired,
    invoiceToFilter: PropTypes.string,
    onInvoiceToFilterChange: PropTypes.func.isRequired,
    reeferFilter: PropTypes.string,
    onReeferFilterChange: PropTypes.func.isRequired,
    hasFilterApplied: PropTypes.bool,
    clearFilters: PropTypes.func,

}


import {useSnackbar} from "notistack";
import * as React from "react";
import axios from "axios";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as PropTypes from "prop-types";

export default function ExportInvoicesDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(null);
    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if(props.invoiceSelection)
        {
            const path = '/admin/invoiceExport';
            let params = {
                invoices: props.invoiceSelection
            }
            axios.get(path, {responseType: 'blob', params})
                .then((response) => {
                    const blob = new Blob([response.data], {type: response.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    if (response.headers['content-disposition']) {
                        const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
                        link.download = fileNameMatch ? fileNameMatch[1] : 'filename.bin';
                    }
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    setError(false);
                    enqueueSnackbar(response.data.message || 'Invoices Exported', {
                        variant: 'success'
                    });
                    props.onSave() ;  //result.data.price);

                }).catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            }).finally(() => {
                //setSaving(false);
            });
        } else {
            console.log('failed - nothing selected');
        }
    }

    let numberSelected = props.invoiceSelection.length;
    console.log(numberSelected);
    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle> Export Selected Invoices</DialogTitle>

            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }

                <p>You are about to export  {numberSelected} invoices records <br/>  This is not reversible - press <strong>Confirm</strong> to proceed</p>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} >Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

ExportInvoicesDialog.propTypes = {
    invoiceSelection:PropTypes.array,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
}

import Dexie from 'dexie';
import 'dexie-observable';
import 'dexie-syncable';
import './db_sync';

const inSW = () => (typeof window === 'undefined');

const _log = (...args) => {
    console.log(...[inSW() ? '(SW) db.js': 'db.js', ...args]);
}

Dexie.debug = process.env.NODE_ENV === 'development';

export const db = new Dexie('containers');
db.version(1).stores({
    //  Note: This is the indexes for each table, not the table definition. You only need to put the pk, and
    //  anything you need to filter on/order by in here, not the entire table defn.
    //  The pk is always the first entry.
    //  ++field - means a unique, autoincrement field. Tables with this should be treated as read-only on the client.
    //  [field1+field2] - means a compound index.
    //  $$field1 - means field1 is a uuid. If you're planning client-side changes, the pk MUST be a uuid.
    //  On the server side, any table here must be a DexieModel for things to work.
    Port: 'code, name',
    Depot: '++id, name, port_code',
    Container: 'reefer_num',
    CustomerType: '++id',
    Customer: '++id, name, type_id',
    Order: '++id, customer_id, port_code',
    ActionType: '++id, sort_order',
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, is_shipped',
    Action: '$$uuid, action_type_id, order_container_uuid, depot_id',
});

db.version(2).stores({
    //  Add our subset of the user table.
    User: 'id, email, [firstname+surname], port_code',
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, is_shipped, [depot_id+is_shipped]',
});

db.version(3).stores({
    // Add the is_deleted flag to actionTypes.
    ActionType: '++id, sort_order, is_deleted',
});

db.version(4).stores({
    // Renamed is_deleted to deleted
    ActionType: '++id, sort_order, deleted',
}).upgrade(tx => {
    return tx.table('ActionType').toCollection().modify(at => {
        at.deleted = at.is_deleted ?? 0;
        delete at.is_deleted;
    });
});

db.version(5).stores({
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, status, [depot_id+status]',
    Depot: '++id, name, port_code, is_depot',
}).upgrade(tx => {
    return tx.table('OrderContainer').toCollection().modify(oc => {
        oc.status = oc.is_shipped ? 'GONE' : oc.is_arrived ? 'PRESENT' : 'DUE';
        delete oc.is_shipped;
        delete oc.is_arrived;
    });
});

db.version(6).stores({
    // Need to add some indexes to ActionType (probably don't need all of these, but it's a tiny table)
    ActionType: '++id, data_type, has_slot, visible_before_dock, visible_in_dock, visible_after_dock, sort_order, ' +
        'deleted, available_in, slot_allow_dups, require_location',
});

db.version(7).stores({
    // Add the entry_type column
    Depot: '++id, name, port_code, entry_type',
});

db.version(8).stores({
    // Add Lane and LaneGroup.
    Lane: '++id, name, depot_id, lane_group_id, deleted',
    LaneGroup: '++id, name, depot_id, sort_order',
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, status, [depot_id+status], lane_id',
});

db.version(9).stores({
    // Add some indexes to keep Dexie happy.
    Depot: '++id, name, port_code, entry_type, [port_code+entry_type]',
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, status, [depot_id+status], lane_id, [reefer_num+depot_id]',
    Action: '$$uuid, action_type_id, order_container_uuid, depot_id, [order_container_uuid+action_type_id+action_date+time_slot]',
});

db.version(10).stores({
    // Add the customer_id to the container records.
    Container: 'reefer_num, customer_id',
    // Also need an index on 'ship' and 'in_at' here so we can use it un autosuggest
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, status, [depot_id+status], lane_id, [reefer_num+depot_id], ship, in_at',
});

db.version(11).stores({
    // Add the ContainerStatus table
    ContainerStatus: 'status, status_name, has_arrived, has_shipped, sort_order',
    ContainerStatusActionType: '++id, status, action_type_id',
    ActionType: '++id, data_type, has_slot, sort_order, ' +
        'deleted, available_in, slot_allow_dups, require_location, set_status_to',
});

db.version(12).stores({
    // Add 'booking_ref' to the Order table.
    Order: '++id, customer_id, port_code, booking_ref',
});

db.version(13).stores({
    // Add 'allow_bulk' to action_type
    ActionType: '++id, data_type, has_slot, sort_order, ' +
        'deleted, available_in, slot_allow_dups, require_location, set_status_to, allow_bulk',
});

db.version(14).stores({
    ContainerStatus: 'status, status_name, has_arrived, has_shipped, sort_order, [has_arrived+has_shipped]',
});

db.version(15).stores({
    AutoAction: '++id, port_code, location_type, depot_id',
    AutoActionActionType: '++id, auto_action_id, action_type_id, sort_order, required, default_on',
});

db.version(16).stores({
    // Add 'unplug_only', 'is_unplugged' and 'unplug_at' to the OrderContainer table.
    OrderContainer: '$$uuid, order_id, reefer_num, depot_id, status, [depot_id+status], lane_id, [reefer_num+depot_id], ship, in_at, unplug_only, unplug_at, is_unplugged, [unplug_only+is_unplugged]',
});


db.on('versionchange', function (ev) {
    // This is fired when another window upgrades the db.
    // When this happens, it means our code is outdated and needs to be reloaded.
    // (We can't give the option to wait, as it might not be possible to commit our changes or sync remote changes
    // any longer)
    console.log("Database version change: " + ev.oldVersion + " -> " + ev.newVersion);

    // Note: when the database is being deleted (e.g. during re-create db), this event is fired too,
    // but with newVersion === null.
    if (ev.newVersion) window.location.reload();
});

// db.on('blocked', function () {
//     // The "blocked" event occurs if database upgrade is blocked by another tab or browser window keeping a
//     // connection open towards the database.
//     _log("Database blocked.");
//
//     // For now, just send a note home about this, lets see if it happens.
//     const reportInfo = {
//         message: "Database blocked",
//     }
//     axios.post('/home/sync/clientSyncFailure', reportInfo);
// });
// Note: safer NOT to bind to the 'blocked' event - there's an implication in one of the github issues that doing
// so stops dexie's default handling of the event, which is to close the connection and try again.


let lastStatus = 0;
export const PROTOCOL_NAME = 'rest_protocol';
export const SYNC_URL = '/home/sync';

// Don't connect automatically - we won't be logged-in, and it will fail.
// Instead we call reconnectIfNeeded() just after login.
//db.syncable.connect(PROTOCOL_NAME, SYNC_URL);

db.syncable.on('statusChanged', function (newStatus, url) {
    if ((lastStatus === Dexie.Syncable.Statuses.ONLINE && newStatus === Dexie.Syncable.Statuses.SYNCING) ||
        (lastStatus === Dexie.Syncable.Statuses.SYNCING && newStatus === Dexie.Syncable.Statuses.ONLINE)) {
        //  Don't bother to log these two cases (very noisy)
        lastStatus = newStatus;
        return;
    }
    lastStatus = newStatus;
    _log("Sync Status changed: " + Dexie.Syncable.StatusTexts[newStatus]);
});

export function recreateDb(makeNewIdentity=false) {
    db.delete().then(() => db.open().then(
        async () => {
            if (makeNewIdentity) {
                // Don't care about the result from this, but we do need to tell the server to discard our old
                // identity (as it'll be in the session).
                //await axios.get('/home/sync/newClientIdentity');

                // Above way doesn't work any longer, as the clientIdentity is no longer stored in the session.
                // In fact, we'll ALWAYS get a new clientIdentity now (no need to do anything special), as we've
                // just deleted the place where it was stored.
            }
            db.syncable.connect(PROTOCOL_NAME, SYNC_URL)
        })
    );
}

export function getStatus() {
    return lastStatus;
}

export function getStatusText() {
    return Dexie.Syncable.StatusTexts[lastStatus];
}

export function needsReconnect() {
    // 4 here is 'ERROR_WILL_RETRY'. Doesn't strictly need a reconnect, but it does if you want immediate updates.
    return [-1, 0, 4].includes(lastStatus);
}

export function disconnect() {
    db.syncable.disconnect(SYNC_URL);
}

/**
 * Trigger a reconnect to dexie if one is needed.
 *
 * @param force Ignore current status and open/connect anyway.
 * @returns {Promise<void>}
 */
export async function reconnectIfNeeded(force=false) {

    _log('reconnectIfNeeded', force);
    _log('isOpen = ', db.isOpen());

    if (!db.isOpen()) {
        _log('opening db...');
        await db.open();
    }

    // An irrepairable error occurred and the sync provider is dead.
    // ERROR = -1,
    //
    // /** The sync provider hasnt yet become online, or it has been disconnected. */
    // OFFLINE = 0,
    //
    // /** Trying to connect to server */
    // CONNECTING = 1,
    //
    // /** Connected to server and currently in sync with server */
    // ONLINE = 2,
    //
    // /** Syncing with server. For poll pattern, this is every poll call.
    //  * For react pattern, this is when local changes are being sent to server. */
    // SYNCING = 3,
    //
    // /** An error occurred such as net down but the sync provider will retry to connect. */
    // ERROR_WILL_RETRY = 4

    // We check the status directly here (rather than relying on `lastStatus`) as `lastStatus` gets left at
    // CONNECTING when there's an initial connection error (which is incorrect).
    lastStatus = await db.syncable.getStatus(SYNC_URL);

    if (!force &&
        [Dexie.Syncable.Statuses.ONLINE,
         Dexie.Syncable.Statuses.SYNCING,
         Dexie.Syncable.Statuses.CONNECTING].includes(lastStatus)) return;    //  no reconnect needed.

    // This one is a kind of special case, as it will reconnect by itself, but we can force it to do so immediately
    // if we wish
    if (lastStatus === Dexie.Syncable.Statuses.ERROR_WILL_RETRY) {
        await db.syncable.disconnect(SYNC_URL);
    }

    // The others are OFFLINE and ERROR, both of which just need a connect.
    return db.syncable.connect(PROTOCOL_NAME, SYNC_URL);

    // if (true || needsReconnect()) {
    //     db.syncable.disconnect(SYNC_URL).then(() => {
    //         db.syncable.connect(PROTOCOL_NAME, SYNC_URL);
    //     }, (reason) => {
    //         console.log('disconnect rejected: ', reason);
    //     });
    // }
}

/**
 * Resolves with the number of unsynced changes we have in dexie.
 *
 * @returns {Promise<unknown>}
 */
export function getUnsyncedChangeCount() {
    return new Promise((resolve, reject) => {
        db.syncable.unsyncedChanges(SYNC_URL).then(c => {
            return resolve(c.length);
        }, err => {
            return reject(err);
        });
    });
}
import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import {getBlankDepot, PORTS} from "../../../common/shared";
import {AppBar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {default as PortIcon} from '@mui/icons-material/AlignVerticalTop';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {CalendarToday, Check} from "@mui/icons-material";
import theme from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getDefaultDepotForPort, getDefaultLaneGroupForDepot} from "./defaults";
import TerminalIcon from "../../../icons/TerminalIcon";
import LaneIcon from "../../../icons/LaneIcon";
import {DateTime} from "luxon";


const formatDtStr = (dtStr) => {
    if (!dtStr) return null;
    try {
        return DateTime.fromSQL(dtStr).toLocal().toLocaleString(DateTime.DATE_SHORT);
    }
    catch (e) {
        return dtStr;
    }
}

export default function UnplugsTopBar({ portCode, depotId, laneGroupId, unplugDate, handleFilterChange,
                                   showingContainer, tableRef }) {
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    // const [buttonSize, setButtonSize] = React.useState('medium');
    const [loading, setLoading] = React.useState(true);
    const ports = useLiveQuery(() => db.Port.orderBy('name').toArray(), [], PORTS);
    const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), []);
    const depotIdsInThisPort = useLiveQuery(() => db.Depot.where('port_code').equals(portCode || '')
        .toArray(arr => arr.map(d => d.id)), [portCode], []);
    const laneGroups = useLiveQuery(() => db.LaneGroup.orderBy('sort_order').toArray(), [], []);
    const unplugDatesInThisPort = useLiveQuery(() => db.OrderContainer
        .where({'unplug_only': 1, 'is_unplugged': 0})
        .filter(oc => depotIdsInThisPort.includes(oc.depot_id))
        .toArray(arr => {
            const s = new Set();
            arr.forEach(oc => oc.unplug_at && s.add(oc.unplug_at.substring(0, 10)));
            return [...s].sort();
        }), [depotIdsInThisPort], []);

    const [portHasOCsWithoutDepot, setPortHasOCsWithoutDepot] = React.useState(false);
    const [laneGroupsInThisDepot, setLaneGroupsInThisDepot] = React.useState([]);
    const [portAnchorEl, setPortAnchorEl] = React.useState(null);
    const [depotAnchorEl, setDepotAnchorEl] = React.useState(null);
    const [laneGroupAnchorEl, setlaneGroupAnchorEl] = React.useState(null);
    const [unplugDateAnchorEl, setUnplugDateAnchorEl] = React.useState(null);
    const [colsAnchorEl, setColsAnchorEl] = React.useState(null);
    const [blankDepot, setBlankDepot] = React.useState(undefined);

    const isPortMenuOpen = Boolean(portAnchorEl);
    const isDepotMenuOpen = Boolean(depotAnchorEl);
    const isLaneGroupMenuOpen = Boolean(laneGroupAnchorEl);
    const isColsMenuOpen = Boolean(colsAnchorEl);
    const isUnplugDateMenuOpen = Boolean(unplugDateAnchorEl);

    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    // We need today's date (in Irish time as an iso string) to filter out import orders that have passed their date.
    const [todayDateStr, setTodayDateStr] = React.useState(DateTime.local({ zone: "Europe/Dublin" }).toSQLDate());

    // And we also need this date to be updated when the irish date changes
    React.useEffect(() => {
        const checkDate = () => {
            const newDate = DateTime.local({ zone: "Europe/Dublin" }).toSQLDate();
            if (newDate !== todayDateStr) setTodayDateStr(newDate);
        }
        const interval = setInterval(checkDate, 60*1000);

        return () => clearInterval(interval);
    }, [todayDateStr]);

    // Each time the portCode changes, we need to check if there are any OCs without a depot
    React.useEffect(() => {
        if (portCode) {
            getBlankDepot(portCode).then(blankDepot => {
                setBlankDepot(blankDepot);
                // Search for OCs without a depot, which need to be unplugged
                db.OrderContainer.where({
                    'unplug_only': 1,
                    'is_unplugged': 0,
                }).filter(oc => oc.depot_id === blankDepot?.id).toArray().then(async ocs => {
                    // iterate here instead of doing a forEach, as we can stop when we find the first one for this port
                    for (const oc of ocs) {
                        const order = await db.Order.get(oc.order_id);
                        if (order.port_code === portCode) {
                            setPortHasOCsWithoutDepot(true);
                            return;
                        }
                    }

                    // If we get here, we went through all the ocs, and none of them were for this port
                    setPortHasOCsWithoutDepot(false);
                });
            });

        }
        else {
            setPortHasOCsWithoutDepot(false);
        }}, [portCode]);

    // Each time the depotId changes, we need to check if there are any lane groups in this depot
    React.useEffect(() => {
        setLaneGroupsInThisDepot(depotId ? laneGroups.filter(laneGroup => laneGroup.depot_id === depotId) : []);
    }, [depotId, laneGroups]);

    // const handleFilterChange = (_portCode, _depotId, _laneGroupId, _unplugDate, whichChanged = 'depot')

    const onPortEntryChange = React.useCallback(async (newPortCode) => {
        setPortAnchorEl(null);  //  close the menu if it's open.
        const depId = getDefaultDepotForPort(newPortCode);
        const lgId = getDefaultLaneGroupForDepot(depId);
        handleFilterChange(newPortCode, depId, lgId, null, 'port');
    }, [handleFilterChange]);

    const onDepotEntryChange = React.useCallback(async (newDepotId) => {
        setDepotAnchorEl(null); //  close the menu (if it's actually open)
        const lgId = getDefaultLaneGroupForDepot(newDepotId);
        handleFilterChange(null, newDepotId, lgId, null, 'depot');
    }, [handleFilterChange]);

    const onLaneGroupEntryChange = React.useCallback(async (newLaneGroupId) => {
        setlaneGroupAnchorEl(null); //  close the menu (if it's actually open)
        handleFilterChange(null, null, newLaneGroupId, null, 'laneGroup');
    }, [handleFilterChange]);

    const onUnplugDateChange = React.useCallback(async (newUnplugDate) => {
        setUnplugDateAnchorEl(null); //  close the menu (if it's actually open)
        handleFilterChange(portCode, depotId, laneGroupId, newUnplugDate, 'unplugDate');
    }, [depotId, handleFilterChange, laneGroupId, portCode]);

    const setColumnVisibility = (field, visible) => {
        tableRef.current.setColumnVisibility({[field]: visible});
        //  Messy way to force a re-render here (and hence have tableRef.current.getColumnVisibility() called again
        //  to get the new columns). Unfortunately we need to do it this way as there's no way (that I know of) for
        //  us to get access to the state from ContainersTable here.
        setTimeout(() => forceUpdate());
    }

    React.useEffect(() => {
        if (ports && depots && laneGroups) {
            setLoading(false);
        }
    }, [ports, depots, laneGroups]);

    const iconButtonSx = {display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5,
        borderRadius: 0, position: 'relative', top: '5px'};
    const selectedOptionSx = {lineHeight: 1, textTransform: 'none', display: 'flex', alignItems: 'center'};

    if (isSm) {
        iconButtonSx['px'] = '4px';
    }

    return (
        <AppBar position="sticky" color="secondary">
            <Toolbar disableGutters>
                {
                    loading ? <Box sx={{ textAlign: 'center', }} color={"white"}>Loading...</Box> :
                        <>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton
                                    id="port-button"
                                    sx={{
                                        ...iconButtonSx,
                                        paddingLeft: '8px',
                                    }}
                                    aria-label="Select Port"
                                    title={"Select Port"}
                                    color="inherit"
                                    aria-controls={isPortMenuOpen ? 'port-menu': undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isPortMenuOpen ? 'true': undefined}
                                    disableRipple
                                    onClick={(event) => setPortAnchorEl(event.currentTarget)}
                                >
                                    <Typography variant="button" component='div'
                                                sx={selectedOptionSx}>
                                        {!isSm && <PortIcon fontSize='small' />}
                                        { ports.find(p => p.code === portCode)?.name || '- Port -' }
                                    </Typography>
                                    <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                </IconButton>
                                <Menu open={isPortMenuOpen}
                                      id="port-menu"
                                      MenuListProps={{ 'aria-labelledby': 'port-button' }}
                                      anchorEl={portAnchorEl}
                                      onClose={() => setPortAnchorEl(null)}
                                      transitionDuration={50}
                                      PaperProps={{
                                          square: true,
                                          sx: { borderRadius: 0 },
                                      }}
                                >
                                    {
                                        ports.map((p) =>
                                            <MenuItem key={p.code}
                                                      onClick={() => onPortEntryChange(p.code)}
                                                      selected={p.code === portCode}
                                            >{ p.name }</MenuItem> )
                                    }
                                </Menu>
                            </Box>
                            {portCode &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="depot-button"
                                        sx={iconButtonSx}
                                        aria-label="Select Terminal"
                                        title="Select Terminal/Depot"
                                        color="inherit"
                                        aria-controls={isDepotMenuOpen ? 'depot-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isDepotMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(event) => setDepotAnchorEl(event.currentTarget)}
                                    >
                                        <Typography variant="button" component='div'
                                                    sx={selectedOptionSx}>
                                            {!isSm && <TerminalIcon fontSize='small' sx={{mr: 0.5}} />}
                                            {depotId === null ? '- All - ' : depots.find(d => d.id === depotId)?.name || '- No Terminal -'}
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isDepotMenuOpen}
                                          id="depot-menu"
                                          MenuListProps={{'aria-labelledby': 'depot-button'}}
                                          anchorEl={depotAnchorEl}
                                          onClose={() => setDepotAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {
                                            // show terminals first
                                            depots
                                                .filter(d => d.port_code === portCode && d.entry_type === 'TERMINAL')
                                                .map((d, i, arr) =>
                                                    <MenuItem key={d.id}
                                                              onClick={() => onDepotEntryChange(d.id)}
                                                              divider={i === arr.length - 1}
                                                              selected={d.id === depotId}
                                                    >{d.name}</MenuItem>)
                                        }
                                        {(() => {
                                            // then show depots (if any)
                                            const justDepots = depots.filter(d => d.port_code === portCode && d.entry_type === 'DEPOT');
                                            if (justDepots.length === 0) return '';
                                            return (justDepots.map((d, i, arr) =>
                                                    <MenuItem key={d.id}
                                                              onClick={() => onDepotEntryChange(d.id)}
                                                              divider={i === arr.length - 1}
                                                              selected={d.id === depotId}
                                                    >{d.name}</MenuItem>)
                                            );
                                        })()}
                                        {
                                            // Then show the 'blank' entry, if it's needed.
                                            (portHasOCsWithoutDepot || (depotId === blankDepot?.id)) && (
                                                <MenuItem key="no-depot"
                                                          onClick={() => onDepotEntryChange(blankDepot?.id)}
                                                          selected={depotId === blankDepot?.id}
                                                >{blankDepot?.name ?? '- No Terminal -'}</MenuItem>
                                            )
                                        }
                                        {
                                            // Then show the 'all' entry
                                            <MenuItem key="all-depots"
                                                        onClick={() => onDepotEntryChange(null)}
                                                        selected={depotId === null}
                                            >- All -</MenuItem>
                                        }
                                    </Menu>
                                </Box>
                            }
                            {(portCode && depotId && laneGroupsInThisDepot.length > 0) &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="lane-group-button"
                                        sx={iconButtonSx}
                                        aria-label="Select Lane Group"
                                        title="Select Lane Group"
                                        color="inherit"
                                        aria-controls={isLaneGroupMenuOpen ? 'lane-group-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isLaneGroupMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(event) => setlaneGroupAnchorEl(event.currentTarget)}
                                    >
                                        <Typography variant="button" component='div'
                                                    sx={selectedOptionSx}>
                                            {!isSm && <LaneIcon fontSize='small' />}
                                            {laneGroups.find(d => d.id === laneGroupId)?.name || (isSm ? <em>All</em> : '- All -')}
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isLaneGroupMenuOpen}
                                          id="lane-group-menu"
                                          MenuListProps={{'aria-labelledby': 'lane-group-button'}}
                                          anchorEl={laneGroupAnchorEl}
                                          onClose={() => setlaneGroupAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {

                                            laneGroupsInThisDepot
                                                .map((d, i, arr) =>
                                                    <MenuItem key={d.id}
                                                              onClick={() => onLaneGroupEntryChange(d.id)}
                                                              divider={i === arr.length - 1}
                                                              selected={d.id === laneGroupId}
                                                    >{d.name}</MenuItem>)
                                        }
                                        <MenuItem key="no-lane-group"
                                                  onClick={() => onLaneGroupEntryChange(null)}
                                                  selected={laneGroupId == null}
                                        >- All -</MenuItem>
                                    </Menu>
                                </Box>
                            }
                            {(portCode && unplugDatesInThisPort.length > 0) &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="unplug-date-button"
                                        sx={iconButtonSx}
                                        aria-label="Select Unplug Date"
                                        title="Select Unplug Date"
                                        color="inherit"
                                        aria-controls={isUnplugDateMenuOpen ? 'unplug-date-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isUnplugDateMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(event) => setUnplugDateAnchorEl(event.currentTarget)}
                                    >
                                        <Typography variant="button" component='div'
                                                    sx={selectedOptionSx}>
                                            {!isSm  && <CalendarToday fontSize='small' />}
                                            {unplugDate ? formatDtStr(unplugDate) : '- All -'}
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isUnplugDateMenuOpen}
                                            id="unplug-date-menu"
                                            MenuListProps={{'aria-labelledby': 'unplug-date-button'}}
                                            anchorEl={unplugDateAnchorEl}
                                            onClose={() => setUnplugDateAnchorEl(null)}
                                            transitionDuration={50}
                                            PaperProps={{
                                                square: true,
                                                sx: {borderRadius: 0},
                                            }}
                                    >
                                        {
                                            (unplugDate && !unplugDatesInThisPort.includes(unplugDate)) &&
                                            <MenuItem key={unplugDate}
                                                      onClick={() => onUnplugDateChange(unplugDate)}
                                                      selected={true}
                                            >{formatDtStr(unplugDate)}</MenuItem>
                                        }
                                        {
                                            unplugDatesInThisPort.map((d, i, arr) =>
                                                <MenuItem key={d}
                                                            onClick={() => onUnplugDateChange(d)}
                                                            divider={i === arr.length - 1}
                                                            selected={d === unplugDate}
                                                >{formatDtStr(d)}</MenuItem>)
                                        }
                                        <MenuItem key="no-unplug-date"
                                                    onClick={() => onUnplugDateChange(null)}
                                                    selected={unplugDate == null}
                                        >- All -</MenuItem>
                                    </Menu>
                                </Box>
                            }

                            <div style={{marginLeft: 'auto'}} />

                            {!showingContainer &&
                                <Box sx={{flexGrow: 0}}>
                                    <IconButton
                                        id="cols-button"
                                        sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5,
                                            borderRadius: 0, position: 'relative', top: '5px'}}
                                        aria-label="Select Columns"
                                        title="Select Columns"
                                        color="inherit"
                                        aria-controls={isColsMenuOpen ? 'cols-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={isColsMenuOpen ? 'true' : undefined}
                                        disableRipple
                                        onClick={(e) => isColsMenuOpen ? setColsAnchorEl(null) : setColsAnchorEl(e.currentTarget)}
                                    >
                                        <ViewColumnIcon fontSize="small" />
                                        <KeyboardArrowDownIcon sx={{width: '10px', height: '10px'}} fontSize="small" />
                                    </IconButton>
                                    <Menu open={isColsMenuOpen}
                                          id="cols-menu"
                                          MenuListProps={{
                                              'aria-labelledby': 'cols-button',
                                              dense: true,
                                          }}
                                          anchorEl={colsAnchorEl}
                                          onClose={() => setColsAnchorEl(null)}
                                          transitionDuration={50}
                                          PaperProps={{
                                              square: true,
                                              sx: {borderRadius: 0},
                                          }}
                                    >
                                        {isColsMenuOpen && (() => {
                                            return tableRef.current.getColumnVisibility().map(c =>
                                                <MenuItem
                                                    key={c.field}
                                                    onClick={() => setColumnVisibility(c.field, !c.visible)}
                                                    disabled={c.forced}
                                                >{
                                                    c.visible ?
                                                        <>
                                                            <ListItemIcon>
                                                                <Check/>
                                                            </ListItemIcon>
                                                            {c.headerName}
                                                        </> :
                                                        <ListItemText inset>{c.headerName}</ListItemText>
                                                }</MenuItem>);
                                            })()
                                        }
                                    </Menu>
                                </Box>
                            }
                        </>
                }
            </Toolbar>
        </AppBar>
    )
}
UnplugsTopBar.propTypes = {
    portCode: PropTypes.string,
    depotId: PropTypes.number,
    laneGroupId: PropTypes.number,
    unplugDate: PropTypes.string,
    handleFilterChange: PropTypes.func,
    showingContainer: PropTypes.object,
    tableRef: PropTypes.object,
}
import * as React from 'react';
import {useEffect} from 'react';
import axios from "axios";
import {DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField
} from "@mui/material";
import * as PropTypes from "prop-types";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {DateTime} from "luxon";
import {useSnackbar} from 'notistack';
import {setTitle} from "../../common/shared";

function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record || {
        id: null,
        email: '',
        firstname: '',
        surname: '',
        initials: '',
        is_admin: false,
    });
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const isNew = !record.id;
    const [notifyUser, setNotifyUser] = React.useState(isNew);
    const [password, setPassword] = React.useState(null);
    const isDisabled = React.useMemo(() => {
        if (record.password) {
            return record.password === 'DISABLED';
        }
        return record.is_disabled || false;
    }, [record]);

    const handleClose = () => {
        props.onEditCancel();
    }

    const handleSave = () => {
        if (record) {
            let data = {
                ...record,
                notify_user: notifyUser
            };
            //  Only send the password if it has changed.
            if (password !== null) data.password = password;

            axios.post('/admin/user/update', data)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'User Saved', {
                            variant: result.data.warning ? 'warning': 'success'
                        });
                        props.onSave(result.data.user);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/user/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'User Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const handleDisable = () => {
        setRecord((prev) => ({...prev, password: 'DISABLED'}));
    }

    const handleEnable = () => {
        setRecord((prev) => ({...prev, password: 'NOTSETYET'}));
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }))
    }
    const onNotifyChange = (event) => {
        setNotifyUser(event.target.checked);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    let isValid = (!!record.email);

    return (
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>{isNew ? 'New': 'Edit'} User</DialogTitle>
                <DialogContent>
                    {   error && (
                        <Alert severity="error">{error.message || error}</Alert> )
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email Address"
                        helperText="User Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={record.email || ''}
                        onChange={onFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        helperText="Given Name"
                        type="text"
                        variant="standard"
                        required={true}
                        value={record.firstname || ''}
                        onChange={onFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="surname"
                        name="surname"
                        label="Surname"
                        helperText="Last Name"
                        type="text"
                        variant="standard"
                        required={true}
                        value={record.surname || ''}
                        onChange={onFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="initials"
                        name="initials"
                        label="Initials"
                        helperText="User Initials"
                        type="text"
                        inputProps={{size: 4, maxLength: 4}}
                        variant="standard"
                        required={true}
                        value={record.initials || ''}
                        onChange={onFormChange}
                    />
                    {
                        isDisabled ?
                            <TextField
                                margin="dense"
                                id="password"
                                name="password"
                                label="Password"
                                helperText="Password"
                                variant="standard"
                                disabled={true}
                                value="DISABLED"
                                InputLabelProps={{ shrink: true }}
                            />
                            :
                            <TextField
                                margin="dense"
                                id="password"
                                name="password"
                                label="Password"
                                helperText="Password"
                                type="password"
                                variant="standard"
                                required={true}
                                value={password || "*************"}
                                onChange={onPasswordChange}
                                InputLabelProps={{ shrink: true }}
                            />
                    }
                    <br />
                    <FormControlLabel
                        control={<Switch name="is_admin" checked={record.is_admin} onChange={onFormChange} />}
                        label="Admin Account"
                    />
                    {
                        isNew && <FormControlLabel
                            control={<Switch name="notify_user" checked={notifyUser} onChange={onNotifyChange} />}
                            label="Send an Email to the user (to set their password)"
                        />
                    }
                </DialogContent>
                <DialogActions>
                    {!isNew && <Button onClick={handleDelete} color="error"
                                       disabled={saving}>Delete</Button>}
                    {(!isNew && !isDisabled) &&
                        <Button onClick={handleDisable} color="warning"
                                disabled={saving}>Disable</Button>
                    }
                    {(!isNew && isDisabled) &&
                        <Button onClick={handleEnable} color="success"
                                disabled={saving}>Enable</Button>
                    }
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New User' : 'Save'}</Button>
                </DialogActions>
            </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object,
    onEditCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

function CustomToolbar(props) {

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            <GridToolbarExport />
            <Button aria-label="Add User" size="small" startIcon={<PersonAddIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addUser}> New User</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    addUser: PropTypes.func.isRequired
}

export default function User() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    // const [selected, setSelected] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        setTitle('Users');
    }, []);

    const refreshUsers = () => {
        axios.get('/admin/user')
            .then(
                (result) => {
                    console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setUsers(result.data.result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }

    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            email: '',
            firstname: '',
            surname: '',
            initials: '',
            is_admin: false,
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshUsers(); //  easiest way to get the new data (but not really necessary, we could just modify users too)
            //setUsers((prevUsers) => prevUsers.map((user) => ))
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setUsers((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params, event, details) => {
        console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    useEffect(() => {
        refreshUsers();
    }, []);

    const columns = React.useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50, type: 'number',
            valueGetter: ({ value }) => parseInt(value) },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'fullName', headerName: 'Name', flex: 1,
            valueGetter: ({ row }) => `${row.firstname || ''} ${row.surname || ''}` },
        { field: 'initials', headerName: 'Initials', width: 50 },
        { field: 'is_admin', headerName: 'Admin', width: 60, type: 'boolean' },
        { field: 'is_disabled', headerName: 'Disabled', width: 60, type: 'boolean' },
        { field: 'last_login_at', headerName: 'Last Login', width: 150, type: 'dateTime',
            valueGetter: ({ value }) => value && DateTime.fromSQL(value, {zone: 'UTC'}).toJSDate(), },
        // { field: 'actions', type: 'actions', width: 100,
        //     getActions: (params) => [
        //         <GridActionsCellItem
        //             icon={<DeleteIcon />}
        //             label="Delete"
        //             onClick={onEditDelete(params.row)}
        //         />,
        //     ]
        // }
    ], [],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <DataGrid columns={columns} rows={users}
                              loading={!isLoaded}
                              sx={{
                                  bgcolor: '#ffffff',
                                  "& .MuiDataGrid-cell": {
                                      cursor: "pointer",
                                  },
                                  '& .MuiDataGrid-cell:focus': {
                                      outline: "none",
                                  },
                              }}
                              components={{
                                  Toolbar: CustomToolbar
                              }}
                              componentsProps={{
                                  toolbar: {addUser: addNewRow}
                              }}
                              hideFooterSelectedRowCount={true}
                              onRowClick={onRowClick}
                    />
                </Box>
                {
                    dialogOpen &&
                    <EditDialog record={dialogRecord} onEditCancel={onEditCancel}
                                onSave={onEditSave} onDelete={onEditDelete}
                    />
                }
            </Box>
    )
}

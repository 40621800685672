import React from "react";
import {Box} from "@mui/material";
import errSvg from './error.svg';
import "./ErrorFallback.css";
import Typography from "@mui/material/Typography";


export default function ErrorFallback({error, resetErrorBoundary}) {
    console.error(error);
    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={errSvg} className="ErrImage" alt="Error" />
            <br />
            <Typography variant="p" component="pre" align="center" color="error">{error.message}</Typography>
            <br />
            <button onClick={resetErrorBoundary}>Try again</button>
        </Box>
    )
}
import React from "react";
import {
    Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import {db} from "../../../common/db";
import {useLiveQuery} from "dexie-react-hooks";
import {DateTime} from "luxon";
import {LUXON_MYSQL_DATETIME_FMT} from "../../../common/shared";


export default function EditShipDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { ship, onSaveShip, onCancel } = props
    const [newShip, setNewShip] = React.useState(ship);

    // When suggesting ship names, only use ship names from the last 6 months.
    const dtCutoff = DateTime.now().minus({months: 6});
    const ships = useLiveQuery(() => db.OrderContainer
        .where('in_at').above(dtCutoff.toFormat(LUXON_MYSQL_DATETIME_FMT))
        .toArray(arr => {
            const s = new Set();
            // Just get the unique values
            arr.forEach(r => r.ship && s.add(r.ship.toUpperCase().trim()));
            // Sort them
            return [...s].sort();
        }), [], []);



    console.log(ships);
    // console.log(newShip);

    return (
        <Dialog open={true} onClose={() => onCancel()} fullScreen={fullScreen} fullWidth maxWidth="sm">
            <DialogTitle>Set Ship/Vessel Name</DialogTitle>
            <DialogContent dividers={true}>
                <Autocomplete
                    id="ship"
                    name="ship"
                    freeSolo
                    options={ships}
                    autoComplete={true}
                    autoSelect={true}
                    // autoComplete and autoSuggest are needed because otherwise, if the user tabs out of the field,
                    // the onChange will not be fired.  cf. https://github.com/mui/material-ui/issues/20904
                    // (this is a bug, but mui are being anal about it)
                    fullWidth
                    value={newShip || ''}
                    onChange={(e, value) => {
                        value = `${value || ''}`.trim(); // trim off any whitespace, just in case
                        // above doesn't work, do it in the call to onSaveShip instead.
                        console.log(value);
                        setNewShip(value);
                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        autoFocus
                        label="Vessel Name"
                        helperText='Enter the ship name.'
                        variant="standard"
                        margin="dense"
                    />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}>Cancel</Button>
                <Button onClick={() => onSaveShip(newShip)} disabled={!newShip}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}

EditShipDialog.propTypes = {
    ship: PropTypes.string,
    onSaveShip: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
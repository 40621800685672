import React from "react";
import {SvgIcon} from "@mui/material";


export default function AddContainerIcon(props) {
    return <SvgIcon {...props}>
        <path d="m19 5v14h-14v-9h-2v10s0.4 1 0.9 1h16l1-1v-16c0-0.5-1-0.9-1-0.9h-10v2h9m-4 2h2v2h-2zm-4 4h6v2h-6zm0 4h6v2h-6zm-4-8h6v2h-6zm0 4h2v2h-2zm0 4h2v2h-2z"/>
        <path d="m3 3v-3h2v3h3v2h-3v2.9h-2v-2.9h-3v-2z"/>
    </SvgIcon>
}
AddContainerIcon.propTypes = SvgIcon.propTypes;
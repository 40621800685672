import React, {useCallback} from "react";
import {Box, LinearProgress} from "@mui/material";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import PropTypes from "prop-types";
import {ACT_TYPE, formatReefer} from "../../../common/shared";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {COLS_KEY, NUM_ROWS_KEY, SORT_MODEL_KEY} from "./defaults";
import PowerOffIcon from '@mui/icons-material/PowerOff';
import {DateTime} from "luxon";
import {useSnackbar} from "notistack";
import ActionDialog from "../conts/ActionDialog";


const ContainersTable = React.forwardRef(function ContainersTable({
    show = true,
    onContainerSelected,
    portCode,
    depot,
    laneGroupId,
    unplugDate,
    sx
}, ref) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);
    const [selectedContainer, setSelectedContainer] = React.useState(null);
    // const [filterModel, setFilterModel] = React.useState(() => {
    //     return {items: []};  //  no filter
    // });
    const [pageSize, setPageSize] = React.useState(() => {
        const sz = parseInt(localStorage.getItem(NUM_ROWS_KEY));
        if ([25, 50, 100].includes(sz)) return sz;
        return 100;
    });
    const [sortModel, setSortModel] = React.useState(() => {
        const sm = JSON.parse(localStorage.getItem(SORT_MODEL_KEY));
        if (sm) return sm;
        return [{field: 'reefer_num', sort: 'asc'}];
    });
    // const users = useLiveQuery(() => db.User.toArray());
    const allStatuses = useLiveQuery(() => db.ContainerStatus.toArray(), [], []);
    const allDepotsById = useLiveQuery(() => db.Depot.toArray(arr => arr.reduce(
        (obj, cur) => ({...obj, [cur.id]: cur}), {})), [], {});
    const unplugAction = useLiveQuery(() => db.ActionType.where('data_type')
        .equals(ACT_TYPE.UNPLUG)
        .filter(a => !a.deleted).first(), [], null);


    //const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), [], []);
    const depotRow = useLiveQuery(() => depot ? db.Depot.get(depot) : null, [depot]);
    const lanesById = useLiveQuery(() => db.Lane.toArray(arr => arr.reduce(
        (obj, cur) => ({...obj, [cur.id]: cur}), {})), [], undefined);
    const customersById = useLiveQuery(() => db.Customer.toArray(arr => arr.reduce(
        (obj, cur) => ({...obj, [cur.id]: cur}), {})), [], undefined);

    const [actionToAdd, setActionToAdd] = React.useState();

    // We need today's date (in Irish time as an iso string) to highlight unplugs for today
    const [todayDateStr, setTodayDateStr] = React.useState(DateTime.local({ zone: "Europe/Dublin" }).toSQLDate());

    // And we also need this date to be updated when the irish date changes
    React.useEffect(() => {
        const checkDate = () => {
            const newDate = DateTime.local({ zone: "Europe/Dublin" }).toSQLDate();
            if (newDate !== todayDateStr) setTodayDateStr(newDate);
        }
        const interval = setInterval(checkDate, 60*1000);

        return () => clearInterval(interval);
    }, [todayDateStr]);


    // const theme = useTheme();
    // const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    React.useImperativeHandle(ref, () => ({
        getColumnVisibility: () => {
            return columns.map(c => ({
                field: c.field,
                headerName: c.headerName,
                visible: colVis?.[c.field] ?? true,
            }))
        },
        setColumnVisibility: (cols) => {
            setColVis((oldVis) => {
                const newCols = {
                    ...oldVis,
                    ...cols
                };
                localStorage.setItem(COLS_KEY, JSON.stringify(newCols));
                return newCols;
            });
        },
    }));

    const handleUnplugAction = React.useCallback((container) => {
        console.log('Unplugging container', container);
        setSelectedContainer(container);
        if (!unplugAction) {
            enqueueSnackbar('Unplug action not found.  Please contact support.', {variant: 'error'});
            return;
        }
        setActionToAdd(unplugAction);
    }, [enqueueSnackbar, unplugAction]);

    const columns = React.useMemo(() => [
        {
            field: 'reefer_num',
            headerName: 'Unit Number',
            hideable: false,
            width: 130,
            valueFormatter: ({value}) => formatReefer(value),
            cellClassName: 'reefer-num'
        },
        {
            field: 'actions',
            headerName: 'Action',
            type: 'actions',
            width: 70,
            align: 'left',
            getActions: (params) => {
                return [<GridActionsCellItem icon={<PowerOffIcon />}
                                             label="Unplug"
                                             title="Unplug"
                                             onClick={() => handleUnplugAction(params.row)}
                />];
            }
        },
        {
            field: 'depot_id',
            headerName: 'Terminal/Depot',
            minWidth: 80,
            maxWidth: 150,
            flex: 1,
            valueGetter: ({row}) => row.depot_id ? allDepotsById?.[row.depot_id]?.name : null,
        },
        {
            field: 'lane_name',
            headerName: 'Lane',
            minWidth: 80,
            maxWidth: 100,
            flex: 0.5,
            valueGetter: ({row}) => row.lane?.name
        },
        {
            field: 'customer.name',
            headerName: 'Customer',
            minWidth: 80,
            flex: 1.5,
            valueGetter: ({row}) => row.customer?.name
        },

        {
            field: 'status',
            headerName: 'Status',
            minWidth: 80,
            flex: 1,
            maxWidth: 120,
            valueGetter: ({value}) => allStatuses.find(s => s.status === value)?.status_name ?? '- Not Set -',
            cellClassName: ({row}) => `status-${row.status}`,
        },
        {
            field: 'cargo_description',
            headerName: 'Cargo',
            minWidth: 50,
            flex: 1.5,
        },
        {
            field: 'unplug_at',
            headerName: 'Unplug At',
            width: 150,
            type: 'dateTime',
            valueGetter: ({row}) => {
                if (!row.unplug_at) return null;
                // Value in the db is an iso string, in UTC.
                // We want to display it in the user's local timezone.
                return DateTime.fromSQL(row.unplug_at, {zone: 'utc'}).toLocal().toJSDate();
            },
            valueFormatter: ({ value }) => {
                if (!value) return null;
                try {
                    return DateTime.fromJSDate(value).toLocaleString(DateTime.DATETIME_SHORT);
                }
                catch (e) {
                    console.error(e);
                    return null;
                }
            }
        }
    ], [handleUnplugAction, allDepotsById, allStatuses]);

    const [colVis, setColVis] = React.useState(() => {
        try {
            const c = localStorage.getItem(COLS_KEY);
            let cols = null;
            if (c) cols = JSON.parse(c); //  note: `c` can be the string 'null', so `cols` might end up being null here.

            if (!cols) {
                // cols = {
                //     cargo_description: false,
                //     order_id: false,
                //     'order.booking_ref': false, // now labelled 'Ship Ref'
                //     booking_ref: false,
                //     in_at: false,
                //     out_at: false,
                //     ship: false,
                //     sail_date: false,
                //     lane_id: false,
                //     // voyage_number: false,  removed per lankel/lankel#34
                //     customer_note: false,
                //     lastTemp: false,
                //     lastActionAt: false,
                //     lastActionBy: false,
                //     set_humidity: false,
                //     vent: false,
                // };
                // By default, just give them all DFLT_COLUMNS.
                // To do this, we need to set anything that isn't in DFLT_COLUMNS to false.
                cols = {}
                // columns.forEach(col => {
                //     if (!DFLT_COLUMNS.includes(col.field)) {
                //         cols[col.field] = false;
                //     }
                // })
            }

            // Make sure all the keys in REQD_COLUMNS are true
            // Object.keys(cols).forEach(k => {
            //     if (REQD_COLUMNS.includes(k)) {
            //         cols[k] = true;
            //     }
            // })

            return cols;

        }
        catch (e) {
            console.error(e);
            // Safe fallback if the above screws up again.
            return {
            };
        }
    });

    const showDepotIds = useLiveQuery(() => {
        if (depot) return [depot];
        if (!portCode) return []; //  sanity
        return db.Depot.where('port_code').equals(portCode).toArray(arr => arr.map(d => d.id));
    }, [depot, portCode], []);

    const ocs = useLiveQuery(
        () => {

            const start = Date.now();
            setLoading(true);

            // if the dependencies are still loading, no point in running this query
            if (!lanesById) return [];
            if (!customersById) return [];

            return db.OrderContainer.where({
                'unplug_only': 1,
                'is_unplugged': 0,
            }).and(oc => showDepotIds.includes(oc.depot_id))
                .toArray(async (arr) => {
                    // console.log(arr);
                    // console.log('OrderContainer query returned', arr.length, 'rows');

                    // We can save a lot of effort here by only doing the lookups that we'll actually need to display.
                    const needCustomers = colVis['customer.name'] !== false;

                    // if there's an unplugDate filter, apply it here
                    if (unplugDate) {
                        arr = arr.filter(oc => oc.unplug_at && oc.unplug_at.substring(0, 10) === unplugDate);
                    }

                    // if there's a laneGroup filter, need to apply it here
                    if (laneGroupId) {
                        arr = arr.filter(oc => lanesById?.[oc.lane_id]?.lane_group_id === laneGroupId);
                    }

                    //  Now put each lane into the ocs array
                    arr = arr.map(oc => ({
                        ...oc,
                        lane: oc.lane_id ? lanesById?.[oc.lane_id] ?? null : null,
                    }));

                    //  And put them into the ocs array
                    arr = arr.map(oc => ({
                        ...oc,
                        customer: needCustomers && oc.order ? customersById?.[oc.order?.customer_id] : null,
                        depot: oc.depot_id ? allDepotsById?.[oc.depot_id] : null,
                    }));

                    // props.callbackNumContainers?.(arr.length)

                    if (needCustomers) {
                        // Lastly, if we have any rows without a customer (because they don't have an order), we need to
                        // look up the customer from the Container table.
                        const noCust = arr.filter(a => !a.customer);
                        // console.log(noCust);
                        if (noCust.length) {
                            const conts = (await Promise.all(noCust.map(oc => db.Container.get(oc.reefer_num)))).filter(c => c);
                            // Note: .filter(c => c) is to remove undefined values.  These should never happen, but I'm seeing
                            // them in production, so it's safer to deal with them.
                            // console.log(conts, customers, noCust);
                            const contCusts = await Promise.all(conts.filter(a => a.customer_id).map(c => ({
                                ...c,
                                customer: customersById?.[c.customer_id]
                            })));
                            //  And put them into the ocs array
                            arr = arr.map(oc => ({
                                ...oc,
                                customer: oc.customer ?? contCusts.find(c => c.reefer_num === oc.reefer_num)?.customer ?? null,
                            }));
                        }
                    }

                    // console.log(arr)

                    return arr;
                })
                .finally(() => {
                    setLoading(false);
                    console.log(`ocs query took ${Date.now() - start}ms`);
                });
        },
        [showDepotIds, laneGroupId, lanesById, customersById, unplugDate,
            allDepotsById, colVis]
    );

    const handleClick = (uuid) => {
        onContainerSelected(ocs.find((oc) => oc.uuid === uuid));
        setSelectedContainer(ocs.find((oc) => oc.uuid === uuid));
    }

    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const onPageSizeChange = (newSize) => {
        localStorage.setItem(NUM_ROWS_KEY, newSize)
        setPageSize(newSize);
    }

    const onSortModelChange = (newModel) => {
        localStorage.setItem(SORT_MODEL_KEY, JSON.stringify(newModel));
        setSortModel(newModel);
    }

    const statusColCssClasses = React.useMemo(() => {
        const classes = {};
        allStatuses.forEach(s => classes[`& .status-${s.status}`] = {
            borderLeftWidth: '4px',
            borderLeftStyle: 'solid',
            borderLeftColor: s.color,
        });
        return classes;
    }, [allStatuses]);

    const getRowClassName = useCallback((params) => {
        if (params.row.unplug_only && params.row.unplug_at?.substring(0, 10) === todayDateStr && !params.row.is_unplugged) {
            return 'unplug-today';
        }
        return params.row.is_flagged ? 'flagged' : '';
    }, [todayDateStr]);

    return (
        <Box sx={{ display: 'flex', visibility: show ? 'visible' : 'hidden', height: '100%', ...sx }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={ocs ?? []}
                          loading={ocs == null || loading}
                          sx={{
                              borderRadius: 0,
                              bgcolor: '#ffffff',
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              "& .MuiDataGrid-cell:focus": {
                                  outline: "none",
                              },
                              '& .reefer-num > div': {
                                  textAlignLast: 'justify',
                                  width: '100%'
                              },
                              // Fix for the issue with header sort icons ignoring parent visibility.
                              '& .MuiDataGrid-iconButtonContainer': {
                                  visibility: 'inherit !important',
                              },
                              // 'flagged' rows get a special background color
                              '& .flagged': {
                                  backgroundColor: '#fcf983 !important',
                              },
                              // as does 'unplug-today'
                              '& .unplug-today': {
                                  backgroundColor: '#43d0ff !important',
                              },
                              ...statusColCssClasses
                          }}
                          getRowClassName={getRowClassName}
                          hideFooterSelectedRowCount={true}
                          disableColumnMenu={true}
                          disableColumnFilter={true}
                          onRowClick={(params) => handleClick(params.row.uuid)}
                          selectionModel={selectedContainer ? [selectedContainer.uuid] : []}
                          getRowId={(r) => r.uuid}
                          columnVisibilityModel={colVis}
                          onColumnVisibilityModelChange={onColVisChange}
                          components={{
                              NoRowsOverlay: () => {
                                  // console.log(depotRow);
                                  let msg = 'No Unplug Containers Found';
                                  let filterMsg = '';
                                  if (unplugDate) {
                                      filterMsg += ` for Unplug Date ${unplugDate}`;
                                  }
                                  if (depotRow) {
                                      if (laneGroupId) {
                                          msg = `No Unplug Containers Found in this lane group${filterMsg}.`;
                                      }
                                      else {
                                          switch (depotRow.entry_type) {
                                              case 'DEPOT':
                                                  msg = `No Unplug Containers Found in this Depot${filterMsg}.`;
                                                  break;
                                              default:
                                              case 'TERMINAL':
                                                  msg = `No Unplug Containers Found in this Terminal${filterMsg}.`;
                                                  break;
                                              case 'BLANK':
                                                  msg = `There are no Unplug Containers${filterMsg} in this port without a Terminal or Depot.`
                                                  break;
                                          }
                                      }
                                  }
                                  else if (portCode) {
                                      msg = `No Unplug Containers Found in this Port${filterMsg}.`;
                                  }
                                  else {
                                      msg = 'Please select a port and terminal above.'
                                  }
                                  return (<Box sx={{textAlign: 'center', p: 3}}
                                               color="text.disabled">{msg}</Box>);
                              },
                              LoadingOverlay: LinearProgress,
                          }}
                          componentsProps={{
                          }}
                          // filterModel={filterModel}
                          // This is the number of off-screen rows that get rendered. Defaults to 3, but we use 15
                          // here as data-grid also considers rows to be off-screen when the data-grid itself is
                          // hidden (which we do when they open the container view), and hence doesn't have any
                          // rows rendered when its shown again.
                          rowBuffer={15}
                          pageSize={pageSize}
                          onPageSizeChange={onPageSizeChange}
                          sortModel={sortModel}
                          onSortModelChange={onSortModelChange}
                          checkboxSelection={false}
                />
            </Box>
            {
                show && actionToAdd && selectedContainer && (
                    <ActionDialog
                        oc={selectedContainer}
                        actionType={actionToAdd}
                        onSave={() => setActionToAdd(undefined)}
                        onCancel={() => setActionToAdd(undefined)}
                    />
                )
            }
        </Box>
    )
});
ContainersTable.propTypes = {
    show: PropTypes.bool.isRequired,
    portCode: PropTypes.string,  // only used if depot is null
    depot: PropTypes.number,
    laneGroupId: PropTypes.number,
    unplugDate: PropTypes.string,
    onContainerSelected: PropTypes.func.isRequired,
}

// show = true,
//     onContainerSelected,
//     portCode,
//     depot,
//     laneGroupId,
//     unplugDate,
//     sx

export default ContainersTable;

import React from "react";
import {AuthContext} from "../../../contexts/AuthProvider";
import {DateTime} from "luxon";
import {db} from "../../../common/db";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, TextField, ToggleButton,
    ToggleButtonGroup,
    useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    ACT_TYPE, ACT_TYPE_WITH_DATA, actTimeToSlot, LUXON_MYSQL_DATETIME_FMT, MAGIC_TIME, PORTS, VENT_POSNS
} from "../../../common/shared";
import {useLiveQuery} from "dexie-react-hooks";


// const irishTimeToLocal = (str) => DateTime.fromISO(str, {zone: 'Europe/Dublin'}).toLocal()
//     .toISOTime({suppressSeconds: true, suppressMilliseconds: true, includeOffset: false});
// const localTimeToUtc = (str) => DateTime.fromISO(str, {zone: 'local'}).toUTC()
//     .toISOTime({suppressSeconds: true, suppressMilliseconds: true, includeOffset: false});
// const utcTimeToLocal = (str) => DateTime.fromISO(str, {zone: 'utc'}).toLocal()
//     .toISOTime({suppressSeconds: true, suppressMilliseconds: true, includeOffset: false});
const irishTimeToUTC = (str) => {
    // If it's a valid iso string (with a 'T' in it), then we can just convert it to UTC
    let dtIrl = DateTime.fromISO(str, {zone: 'Europe/Dublin'});
    if (!dtIrl.isValid) {
        // try sql parsing instead
        dtIrl = DateTime.fromSQL(str, {zone: 'Europe/Dublin'});
    }

    if (!dtIrl.isValid) {
        // Still not valid?  Better to just give up here, log and error, and return what they gave us
        console.error(`Invalid date/time string: ${str}`);
        return str;
    }

    return dtIrl.toUTC().toFormat(LUXON_MYSQL_DATETIME_FMT);
}
const swapKeysAndVals = (obj) => Object.fromEntries(Object.entries(obj).map(a => a.reverse()));

// MAGIC_TIME flipped so that its keys are 'AM', 'MD' and 'PM'.
const MAGIC_TIME_INV = swapKeysAndVals(MAGIC_TIME);

// Lane names are strings, but we want them sorted as if they were numbers (if possible), hence this:
const sortLanes = (lanes) => lanes.sort((a, b) => a.name.localeCompare(b.name, 'en', {numeric: true}));

export default function BulkActionDialog(props) {
    const auth = React.useContext(AuthContext);
    const [record, setRecord] = React.useState(() => {
        //  Defaults for the action
        let rec = {
            action_type_id: props.actionType.id,
            ocs: props.ocs,
            depot_id: props.ocs?[0].depot_id: null,  // all the ocs will be the same depot, so just use the first one
            recorded_at: DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT), // DateTime.utc().toSQL({includeOffset: false, includeZone: false}),
            recorded_by: parseInt(auth.user.id),
            data_value: props.actionType.data_type === ACT_TYPE.SETTEMP ? props.oc.set_temp?.toString() : null,
            action_date: DateTime.now().setZone('Europe/Dublin').toSQLDate(),
            action_time: DateTime.now().setZone('Europe/Dublin').set({second: 0, millisecond: 0}).toISOTime(
                    {suppressSeconds: true, suppressMilliseconds: true, includeOffset: false}),
            time_slot: null
        };

        //  If the action has a slot, set a sensible default value for it
        if (props.actionType.has_slot) {
            rec.time_slot = actTimeToSlot(rec.action_time);
            // if (rec.action_time < '11:00') rec.time_slot = 'AM';
            // else if (rec.action_time <= '13:00') rec.time_slot = 'MD';
            // else rec.time_slot = 'PM';
        }

        return rec;
    });

    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), [], []);
    const portByCode = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {})),
        [], PORTS);
    const allLanes = useLiveQuery(() => db.Lane.where('deleted').equals(0).toArray(), [], []);

    // Full depot record for the first order container (undefined if it doesn't have one).
    // Bulk actions are only possible on containers in the same depot, so it'll be the same record for all of them.
    const [depot, setDepot] = React.useState(undefined);
    React.useEffect(() => {
        if (record.depot_id) {
            db.Depot.get(record.depot_id).then(dep => setDepot(dep));
        }
        else {
            setDepot(undefined);
        }
    }, [record.depot_id]);

    const handleClose = () => {
        props.onCancel();
    };

    const handleSave = (e) => {
        e.preventDefault(); //  prevent form submission
        if (isValid()) {
            setSaving(true);
            setError(false);
            const newActions = [];

            // We do all this in a transaction, so if one update fails, they all get rolled back.
            db.transaction('rw', db.Action, db.OrderContainer, async () => {

                for (let oc of record.ocs) {

                    //  Make a copy of the record, as we'll be modifying it before saving.
                    // Safer to do it this way (rather than spreading) so we don't send any details to
                    // dexie that can't really be saved in mysql (such as an 'actionType' object).
                    const rec = {
                        action_type_id: record.action_type_id,
                        order_container_uuid: oc.uuid,
                        depot_id: record.depot_id,
                        recorded_at: record.recorded_at,
                        recorded_by: record.recorded_by,
                        data_value: record.data_value,
                        action_date: record.action_date,
                        action_time: record.action_time,
                        time_slot: record.time_slot,
                        deleted: 0,
                    };
                    rec.recorded_at = DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT); //.toSQL({includeZone: false, includeOffset: false});
                    // Convert the action_time to UTC before saving it
                    // rec.action_time = localTimeToUtc(rec.action_time);

                    newActions.push(await db.Action.add(rec)
                        .then(async (uuid) => {
                            console.log(uuid);
                            rec.uuid = uuid;

                            // Important to return the added record here, as that's what the promise
                            // will resolve to.

                            // Also check if there's an old record we should delete (i.e. replace)
                            if (props.actionType.has_slot && !props.actionType.slot_allow_dups &&
                                record.action_date && record.time_slot) {

                                await db.Action.where({
                                    order_container_uuid: oc.uuid,
                                    action_type_id: props.actionType.id,
                                    action_date: rec.action_date,
                                    time_slot: rec.time_slot,
                                }).filter(act => !act.deleted && act.uuid !== uuid).toArray()
                                    .then(acts => {
                                        acts.forEach(act => {
                                            console.log(`Deleting old action ${act.uuid}`);
                                            db.Action.update(act.uuid, {deleted: 1});
                                        });
                                    });
                            }

                            // Try to batch any oc updates into one update
                            const ocUpdates = {};

                            // If the depot_id for this action is different from the depot_id for the order container,
                            // update the order container's depot_id to match the action's.
                            if (rec.depot_id) {
                                if (rec.depot_id !== oc.depot_id) {
                                    ocUpdates['depot_id'] = rec.depot_id;
                                }

                                // We _might_ also want to set a lane_id, if they have told us one.
                                // Note: checking `record` here, not `rec`, as action doesn't have a lane_id.
                                if (record.lane_id && allLanes.find(lane => lane.id === record.lane_id &&
                                    lane.depot_id === rec.depot_id)) {
                                    ocUpdates['lane_id'] = record.lane_id;
                                }
                            }

                            if (props.actionType.set_status_to) {
                                ocUpdates['status'] = props.actionType.set_status_to;
                            }

                            if (props.actionType.data_type === ACT_TYPE.ARRIVED) {
                                ocUpdates['in_at'] = irishTimeToUTC(rec.action_date + ' ' + rec.action_time);
                            }
                            else if (props.actionType.data_type === ACT_TYPE.SHIPPED) {
                                ocUpdates['out_at'] = irishTimeToUTC(rec.action_date + ' ' + rec.action_time);
                            }
                            else if (props.actionType.data_type === ACT_TYPE.SETTEMP) {
                                //  If the action is SETTEMP, we also need to update the 'set_temp' of the container
                                ocUpdates['set_temp'] = rec.data_value;
                            }
                            else if (props.actionType.data_type === ACT_TYPE.SETHUMID) {
                                ocUpdates['set_humidity'] = rec.data_value;
                            }
                            else if (props.actionType.data_type === ACT_TYPE.SETVENT) {
                                ocUpdates['vent'] = rec.data_value;
                            }
                            else if (props.actionType.data_type === ACT_TYPE.FLAG) {
                                ocUpdates['is_flagged'] = 1;
                            }
                            else if (props.actionType.data_type === ACT_TYPE.UNFLAG) {
                                ocUpdates['is_flagged'] = 0;
                            }

                            if (Object.keys(ocUpdates).length > 0) {
                                await db.OrderContainer.update(oc.uuid, ocUpdates)
                            }

                            return rec;
                        })
                    );
                }
            }).then(() => {
                props.onSave(newActions);
            }).catch((e) => {
                setError(e);
            }).finally(() => {
                setSaving(false);
            });
        }
    };

    const onDataValueChange = (e) => {
        let newVal = e.target.value;
        setRecord((prev) => ({
            ...prev,
            data_value: newVal
        }));
    }

    const handleFormValueChange = (e) => {
        setRecord((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSlotChange = (e, newSlot) => {
        if (newSlot !== null) {
            // Also a good idea to update the action_time, even though we don't have it directly.
            const action_time = MAGIC_TIME_INV[newSlot] ?? null
            setRecord((prev) => ({
                ...prev,
                time_slot: newSlot,

                // Note: we store it here as local time (same as we would if we were displaying the 'action_time'
                // field), and it gets convert to utc during save.
                //action_time: action_time ? irishTimeToLocal(action_time) : null
                // ^^ don't do this now, action times are always in irish time
                action_time: action_time
            }));
        }
    };

    const isValid = () => {
        if ([
            // These are not-null fields, value is always required (regardless of what the actionType says)
            ACT_TYPE.SETTEMP, ACT_TYPE.RECTEMP, ACT_TYPE.SETVENT].includes(props.actionType.data_type) ||
            // And these are everything else that can have data.
            (ACT_TYPE_WITH_DATA.includes(props.actionType.data_type) && props.actionType.data_required)) {
            if (record.data_value == null) return false;
            if (`${record.data_value}`.trim() === '') return false;
        }

        try {
            //  action_date is always required (and must be a valid date format)
            let dt = DateTime.fromISO(record.action_date);
            if (!dt || !dt.isValid) return false;

            if (props.actionType.has_slot) {
                // if it's supposed to have a slot, it must be set
                if (!record.time_slot) return false;
            }
            else {
                // otherwise it must have a time
                if (!record.action_time) return false;
            }
        }
        catch (e) {
            //  failure parsing the date -> not valid
            return false;
        }

        if (props.actionType.require_location !== 'NONE') {
            // If they haven't set a depot/terminal yet, it's not valid
            if (!record.depot_id) return false;

            // Need to know something about the depot they've selected
            const d = depots.find(d => d.id === record.depot_id);
            if (!d) return false;   // they haven't selected a valid depot

            // A 'blank' depot is considered unset (same as if depot_id was null in the past)
            if (d.entry_type === 'BLANK') return false;
            if (['DEPOT', 'DEPOT_ALWAYS'].includes(props.actionType.require_location) && (d.entry_type !== 'DEPOT')) return false;
            if (['TERMINAL', 'TERMINAL_ALWAYS'].includes(props.actionType.require_location) && (d.entry_type !== 'TERMINAL')) return false;
            // for 'ANY' and 'ANY_ALWAYS', we don't care, so long as it's set
        }

        return true;
    };

    // console.log(props.ocs);



    return (
        <form>
            <Dialog open={true} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle>{props.actionType.label}: {props.ocs.length} Containers</DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={1}>
                        {   error && (
                            <Alert severity="error">{error.message || error}</Alert> )
                        }
                        { props.actionType.action_message &&
                            <DialogContentText>{props.actionType.action_message}</DialogContentText>
                        }
                        {
                            props.actionType.has_slot ?
                                <Box display="flex">
                                    <TextField
                                        margin="dense"
                                        variant="standard" size="small"
                                        id="action_date"
                                        name="action_date"
                                        label="Date"
                                        type="date"
                                        value={record.action_date}
                                        required={true}
                                        sx={{ minWidth: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            max: DateTime.now().setZone('Europe/Dublin').toSQLDate(), // can't be later than today
                                            // Could also set `min` to props.oc.arrived_at, but better not in case
                                            // they're late marking it as arrived.
                                        }}
                                        onChange={handleFormValueChange}
                                    />
                                    <ToggleButtonGroup
                                        size="small"
                                        aria-label="Time Slot"
                                        sx={{ml: 1, alignSelf: 'flex-end'}}
                                        value={record.time_slot}
                                        exclusive
                                        onChange={handleSlotChange}
                                    >
                                        <ToggleButton value='AM' key='AM'>AM</ToggleButton>
                                        <ToggleButton value='MD' key='MD'>MD</ToggleButton>
                                        <ToggleButton value='PM' key='PM'>PM</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box> :
                                <Box display="flex">
                                    <TextField
                                        margin="dense"
                                        variant="standard" size="small"
                                        id="action_date"
                                        name="action_date"
                                        label="Date"
                                        type="date"
                                        value={record.action_date}
                                        required={true}
                                        sx={{ minWidth: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            max: DateTime.now().setZone('Europe/Dublin').toSQLDate(), // can't be later than today
                                            // Could also set `min` to props.oc.arrived_at, but better not in case
                                            // they're late marking it as arrived.
                                        }}
                                        onChange={handleFormValueChange}
                                    />
                                    <TextField
                                        margin="dense"
                                        variant="standard" size="small"
                                        id="action_time"
                                        name="action_time"
                                        label="Time"
                                        type="time"
                                        // Only use the first 5 chars to avoid problems with seconds
                                        value={record.action_time?.substring(0, 5) || ''}
                                        required={true}
                                        sx={{ ml:1, minWidth: 80 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 60, // 1 min
                                        }}
                                        onChange={handleFormValueChange}
                                    />
                                </Box>
                        }
                        {(() => {

                            let showDepots = false;
                            let label = 'Move To';
                            const locnIsBlank = (!depot || depot.entry_type === 'BLANK');

                            switch (props.actionType.require_location) {
                                default:
                                case 'NONE':
                                    // do nothing
                                    return '';
                                case 'DEPOT':
                                    if (locnIsBlank || depot.entry_type !== 'DEPOT') {
                                        showDepots = depots.filter(d => d.entry_type === 'DEPOT');
                                        label = locnIsBlank ? 'Select Depot' : 'Move To Depot';
                                    }
                                    break;
                                case 'DEPOT_ALWAYS':
                                    showDepots = depots.filter(d => d.entry_type === 'DEPOT');
                                    label = locnIsBlank ? 'Select Depot' : 'Move To Depot';
                                    break;
                                case 'TERMINAL':
                                    if (locnIsBlank || depot.entry_type !== 'TERMINAL') {
                                        showDepots = depots.filter(d => d.entry_type === 'TERMINAL');
                                        label = locnIsBlank ? 'Select Terminal' : 'Move To Terminal';
                                    }
                                    break;
                                case 'TERMINAL_ALWAYS':
                                    showDepots = depots.filter(d => d.entry_type === 'TERMINAL');
                                    label = locnIsBlank ? 'Select Terminal' : 'Move To Terminal';
                                    break;
                                case 'ANY':
                                    if (locnIsBlank) showDepots = depots;
                                    label = locnIsBlank ? 'Select Location' : 'Move To';
                                    break;
                                case 'ANY_ALWAYS':
                                    showDepots = depots;
                                    label = locnIsBlank ? 'Select Location' : 'Move To';
                                    break;
                            }

                            if (!showDepots) return '';

                            // If the container has a port (through its current depot), then we can restrict the
                            // list to just that.
                            const filterPort = depot?.port_code ?? null;
                            if (filterPort) {
                                showDepots = showDepots.filter(d => d.port_code === filterPort);
                            }
                            else {
                                // Otherwise we must show all of `showDepots`, and precede each name with the port name.
                                showDepots = showDepots.map(d => ({
                                    ...d,
                                    name: `${portByCode[d.port_code] || '???'}: ${d.name}`,
                                })).sort((a, b) => a.name.localeCompare(b.name));
                            }

                            return (
                                <>
                                    <TextField
                                        margin="dense"
                                        name="depot_id"
                                        label={label}
                                        helperText="Select Location"
                                        select
                                        variant="standard"
                                        value={showDepots.find(d => d.id === record.depot_id)?.id || ''}
                                        onChange={handleFormValueChange}
                                    >
                                        {showDepots
                                            .sort((a, b) => -1*a.entry_type.localeCompare(b.entry_type) || a.name.localeCompare(b.name))
                                            .map((d, i, arr) => <MenuItem
                                                divider={i !== arr.length - 1 && arr[i+1].entry_type !== d.entry_type}
                                                key={d.id} value={d.id}>{d.name}</MenuItem>)
                                        }
                                    </TextField>
                                    {(() => {
                                        if (record.depot_id) {
                                            const lanes = sortLanes(allLanes.filter(l => l.depot_id === record.depot_id));
                                            if (lanes.length) {
                                                return (
                                                    <TextField
                                                        name="lane_id"
                                                        label="Lane"
                                                        helperText="Select Lane"
                                                        select
                                                        variant="standard"
                                                        value={record.lane_id || ''}
                                                        onChange={handleFormValueChange}
                                                    >
                                                        <MenuItem value={null}>- None -</MenuItem>
                                                        {lanes.map(l => <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>)}
                                                    </TextField>
                                                );
                                            }
                                        }
                                        return '';
                                    })()}
                                </>
                            );
                        })()}
                        {(() => {
                            switch (props.actionType.data_type) {
                                default:
                                case ACT_TYPE.NONE:
                                    return <></>;
                                case ACT_TYPE.BOOL:
                                    return (
                                        <FormControl>
                                            <RadioGroup row name="data_value"
                                                        value={record.data_value} onChange={onDataValueChange}
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="0" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    );
                                case ACT_TYPE.SETVENT:
                                    return (
                                        <TextField
                                            margin="dense"
                                            name="data_value"
                                            label={props.actionType.label}
                                            helperText="Vent Setting"
                                            select
                                            variant="outlined" size="small"
                                            value={record.data_value || props.oc.vent || 'CLOSED'}
                                            onChange={onDataValueChange}
                                        >
                                            {
                                                Object.entries(VENT_POSNS).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                                            }
                                        </TextField>
                                    );
                                case ACT_TYPE.FLOAT:
                                case ACT_TYPE.INT:
                                case ACT_TYPE.SETTEMP:
                                case ACT_TYPE.RECTEMP:
                                case ACT_TYPE.SETHUMID:
                                    return (
                                        <TextField
                                            margin="dense"
                                            name="data_value"
                                            label={props.actionType.label}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <IconButton onClick={() => {
                                                        setRecord({...record, data_value: -1*record.data_value});
                                                    }}>±</IconButton>
                                                </InputAdornment>,
                                                endAdornment: props.actionType.data_unit ?
                                                    <InputAdornment position="end">{
                                                        props.actionType.data_unit
                                                    }</InputAdornment> :
                                                    undefined
                                            }}
                                            type="number"
                                            variant="outlined" size="small"
                                            inputProps={{ step: [ACT_TYPE.FLOAT, ACT_TYPE.SETTEMP, ACT_TYPE.RECTEMP].includes(props.actionType.data_type) ? 0.1: 1 }}
                                            required={!!props.actionType.data_required || [ACT_TYPE.SETTEMP, ACT_TYPE.RECTEMP].includes(props.actionType.data_type)}
                                            value={(record.data_value == null) ? '' : record.data_value}
                                            onChange={onDataValueChange}
                                        />
                                    );
                                case ACT_TYPE.TEXT:
                                case ACT_TYPE.FLAG:
                                case ACT_TYPE.UNFLAG:
                                    return (
                                        <TextField
                                            multiline
                                            minRows={3}
                                            margin="dense"
                                            name="data_value"
                                            label={props.actionType.label}
                                            placeholder="Enter some text..."
                                            maxRows={8}
                                            inputProps={{ maxLength: 1000 }}
                                            variant="outlined" size="small"
                                            required={!!props.actionType.data_required}
                                            value={(record.data_value === undefined || record.data_value === null) ? '' : record.data_value}
                                            onChange={onDataValueChange}
                                        />
                                    );
                            }
                        })()}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleSave} disabled={saving || !isValid()}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}

BulkActionDialog.propTypes = {
    actionType: PropTypes.object.isRequired, //  Full ActionType row
    ocs: PropTypes.arrayOf(PropTypes.object).isRequired, //  Full OrderContainer rows
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
import React from "react";
import {Box} from "@mui/material";
import {db} from "../../../common/db";
import {AuthContext} from "../../../contexts/AuthProvider";
import {setTitle} from "../../../common/shared";
import Container from "./Container";
import {useSearchParams} from "react-router-dom";
import AddContainerDialog from "./AddContainerDialog";
import TopBar from "./TopBar";
import ContainersTable from "./ContainersTable";
import {useLiveQuery} from "dexie-react-hooks";
import {getDefaultDepotForPort, getDefaultLaneGroupForDepot, setDefaultViewForPort} from "./defaults";


export default function Containers() {
    let [searchParams, setSearchParams] = useSearchParams();
    const auth = React.useContext(AuthContext);
    const depots = useLiveQuery(() => db.Depot.toArray(), [], []);
    const laneGroups = useLiveQuery(() => db.LaneGroup.orderBy('sort_order').toArray(), []);
    const [portCode, setPortCode] = React.useState(auth.getDefaultPort() || 'IEDUB');
    const [depotId, setDepotId] = React.useState(() => getDefaultDepotForPort(portCode));
    const [laneGroupId, setLaneGroupId] = React.useState(() => getDefaultLaneGroupForDepot(depotId));
    // const [ship, setShip] = React.useState(null);
    const [orderIds, setOrderIds] = React.useState([]);
    const [statusFilt, setStatusFilt] = React.useState([]);
    const [oc, setOc] = React.useState(null);
    const [dlgAddContVisible, setDlgAddContVisible] = React.useState(false);
    const tableRef = React.useRef();
    const [srchText, setSrchText] = React.useState('');

    React.useEffect(() => {
        setTitle('Active Containers');
    }, []);

    // Listen for changes to the 'oc' param in the url, and load the appropriate container.
    React.useEffect(() => {
        const ocUrl = searchParams.get('oc');
        if (ocUrl) {
            //  Only bother loading it if we actually need to.
            if (ocUrl !== oc?.uuid) {
                db.OrderContainer.get(ocUrl).then((oc) => setOc(oc));
            }
        }
        else {
            setOc(null);
        }
    }, [searchParams, oc]);

    // This is only for setting a reasonable default depotId when getDefaultDepotForPort() returns undefined (in
    // the useState for depotId above).  It runs when all depots have been loaded, and just picks the first one
    // if depotId is still undefined.
    React.useEffect(() => {
        if (depotId === undefined && portCode && depots.length > 0) {
            const firstDepot = depots.find((d) => d.port_code === portCode);
            if (firstDepot) {
                setDepotId(firstDepot.id);
                setLaneGroupId(null);  //  just in case one got set accidentally.
            }
        }
    }, [depots, portCode, depotId]);

    const openOC = React.useCallback(async (ocIn) => {
        //  We actually open an OC by putting its uuid in the url query.
        //  That, in turn, triggers a call to the useEffect above, which loads the container, and also
        //  nicely tidies up the history.
        const ocUrl = searchParams.get('oc');
        if (ocIn?.uuid === ocUrl) {
            //  it's already in the url, nothing to do.
        }
        else {
            if (ocIn?.uuid) {
                searchParams.set('oc', ocIn.uuid)
            }
            else {
                searchParams.delete('oc')
            }
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    const handleFilterChange = (_portCode, _depotId, _laneGroupId, _orderIds, _status=null, whichChanged = 'depot') => {
        // This is called the port, depot, laneGroup, or order is changed in the top bar (whichChanged tells you which one).
        // Any of the params may be null.

        switch (whichChanged) {
            case 'status':
                // Changing just the status doesn't modify any other filters
                break;
            // case 'ship':
            //     // Ship changes require a portCode, and may have a depotId.  They set the laneGroupId to null.
            //     _laneGroupId = null;
            //     // they also set the status to null.
            //     _status = null;
            //     break;
            case 'order':
                // Order changes require a portCode, and may have a depotId.  They set the laneGroupId to null.
                _laneGroupId = null;
                // they also set the status to null.
                _status = null;
                break;
            case 'laneGroup':
                if (_laneGroupId) {
                    const lg = laneGroups.find(lg => lg.id === _laneGroupId);
                    if (lg) {
                        // this forces the value of the depot, which in turn forces the value of the port.
                        _depotId = lg.depot_id;
                        _portCode = depots.find(d => d.id === _depotId)?.port_code;
                    }
                    else {
                        console.error(`Could not find lane group ${_laneGroupId}`);
                        _laneGroupId = null;
                    }
                }
                else {
                    // they set the lane group to null, everything above it is ok as long as it's sane
                    _depotId = _depotId || depotId;
                    _portCode = _portCode || portCode;
                }
                break;
            case 'depot':
                // when the depot changes, we may need to clear the lane group.
                if (_laneGroupId) {
                    // it also changed, check if the new value is ok.
                    const lg = laneGroups.find(lg => lg.id === _laneGroupId);
                    if (lg && lg.depot_id === _depotId) {
                        // it's ok, keep it.
                    }
                    else {
                        // it's not ok, clear it.
                        _laneGroupId = null;
                    }
                }
                else {
                    _laneGroupId = null;
                }

                if (_depotId) {
                    // this forces the value of the port.
                    _portCode = depots.find(d => d.id === _depotId)?.port_code;
                }
                else {
                    // Remove all the below for now - a null depot is now allowed (it means "all"):
                    // // they set the depot to null, we can't leave it like that.
                    // // First check that we have a port
                    // _portCode = _portCode || portCode || auth.getDefaultPort() || 'IEDUB';
                    // // If we have a default view for this port, use that.
                    // _depotId = getDefaultDepotForPort(_portCode);
                    // // Failing that, go with the 'blank' depot for this port
                    // if (!_depotId) {
                    //     _depotId = depots.find(d => d.port_code === _portCode && d.entry_type === 'BLANK')?.id;
                    //
                    //     // And failing that, just go with the first one
                    //     if (!_depotId) {
                    //         _depotId = depots.find(d => d.port_code === _portCode)?.id;
                    //     }
                    // }

                    // ... and replace it with this
                    _portCode = portCode || auth.getDefaultPort() || 'IEDUB';
                }
                break;
            default:
            case 'port':
                // Port changed. Ensure it has a value
                _portCode = _portCode || portCode || auth.getDefaultPort() || 'IEDUB';

                // If the depot changes too, check if it's in the correct port. If not, clear it.
                if (_depotId) {
                    const depot = depots.find(d => d.id === _depotId);
                    if (depot && depot.port_code === _portCode) {
                        // it's ok, keep it.
                    }
                    else {
                        // it's not ok, clear it.
                        _depotId = null;
                    }
                }

                // If we don't have a depot here, just go with the default for this port.
                if (!_depotId) {
                    _depotId = getDefaultDepotForPort(_portCode);
                    // Failing that, go with the 'blank' depot for this port
                    if (!_depotId) {
                        _depotId = depots.find(d => d.port_code === _portCode && d.entry_type === 'BLANK')?.id;

                        // And failing that, just go with the first one
                        if (!_depotId) {
                            _depotId = depots.find(d => d.port_code === _portCode)?.id;
                        }
                    }
                }

                // If they sent in a lane-group, make sure it's for the correct depot
                if (_laneGroupId) {
                    const lg = laneGroups.find(lg => lg.id === _laneGroupId);
                    if (lg && lg.depot_id === _depotId) {
                        // it's ok, keep it.
                    }
                    else {
                        // it's not ok, clear it.
                        _laneGroupId = null;
                    }
                }

                // Remembering that it's the port that just changed here, we can probably safely go with the
                // default lane-group (if it's still null at this point), assuming it matches what we currently
                // have for the depot.
                if (!_laneGroupId) {
                    _laneGroupId = getDefaultLaneGroupForDepot(_depotId);
                    const lg = laneGroups.find(lg => lg.id === _laneGroupId);
                    if (lg && lg.depot_id === _depotId) {
                        // it's ok, keep it.
                    }
                    else {
                        // it's not ok, clear it.
                        _laneGroupId = null;
                    }
                }


                break;
        }

        // Save the defaults for future reference (if they're sane)
        if (_portCode && _depotId) {
            setDefaultViewForPort(_portCode, _depotId, _laneGroupId);
        }

        // All done
        setPortCode(_portCode);
        setDepotId(_depotId);
        setLaneGroupId(_laneGroupId);
        // setShip(_ship);
        if (_orderIds) {
            if (!Array.isArray(_orderIds)) {
                _orderIds = [_orderIds];
            }
        }
        else {
            _orderIds = [];
        }
        setOrderIds(_orderIds)
        // Ensure _status is an array
        if (_status) {
            if (!Array.isArray(_status)) {
                _status = [_status];
            }
        }
        else {
            _status = [];
        }
        setStatusFilt(_status);
        openOC(null);   //  always hide an open OC when the port or depot changes.
    }

    const handleContainerSelected = (oc) => {
        openOC(oc);
    }

    const handleAddContainerClicked = () => {
        setDlgAddContVisible(true);
    }

    const afterAddContainer = (cont) => {
        // console.log(cont);
        setDlgAddContVisible(false);
        // This selects the row in the table, but doesn't trigger a click...
        tableRef.current.selectContainer(cont.uuid);
        // ... so we have to do this too.
        openOC(cont);
    }

    let showTable = true;
    return (
        <React.Fragment>
            <TopBar portCode={portCode}
                    depotId={depotId}
                    laneGroupId={laneGroupId}
                    orderIds={orderIds}
                    selectedStatus={statusFilt}
                    handleFilterChange={handleFilterChange}
                    onAddContainerClick={handleAddContainerClicked}
                    showingContainer={oc}
                    tableRef={tableRef}
                    srchValue={srchText} onSrchValueChange={(e) => setSrchText(e.target.value)}
            />
            <Box sx={{ height: "calc(100% - 48px)", position: "relative" }}>
                {(() => {
                    if (oc) {
                        showTable = false;
                        return (
                            <Container uuid={oc.uuid} />
                        )
                    }
                    else if (!portCode) {
                        showTable = false;
                        return (<div>Please select a Port from the dropdown above.</div>);
                    }
                    // depotId can be null now (means 'all depots')
                    // else if (!depotId) {
                    //     showTable = false;
                    //     return (<div>Please select a Terminal from the dropdown above.</div>);
                    // }
                })()}
                {
                    portCode && <ContainersTable show={showTable} ref={tableRef}
                                     onContainerSelected={handleContainerSelected}
                                     portCode={portCode}
                                     depot={depotId} laneGroupId={laneGroupId} filterUnit={srchText} orderIds={orderIds}
                                     status={statusFilt}
                                     sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
                    />
                }
                {
                    dlgAddContVisible &&
                    <AddContainerDialog
                        depotId={depotId}
                        laneGroupId={laneGroupId}
                        onCancel={() => setDlgAddContVisible(false)}
                        afterSave={afterAddContainer}
                        openOC={openOC}
                    />
                }
            </Box>
        </React.Fragment>
    )
}




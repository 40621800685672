import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import PropTypes from "prop-types";
import {
    Box, Chip, CircularProgress, Fade, Popper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead,
    TableRow
} from "@mui/material";
import {DateTime, Interval} from "luxon";
import {ACT_TYPE, prettyDate, VENT_POSNS} from "../../../common/shared";
import ActionIcon from "../../../components/ActionIcon";
import EditIcon from '@mui/icons-material/Edit';
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

export default function HistoryTable(props) {
    const {oc, onEditAction} = props;
    // We only actually show the 'MD' and 'Other' cols if there's something in them.
    const [hasMdCol, setHasMdCol] = React.useState(false);
    const [hasOtherCol, setHasOtherCol] = React.useState(false);

    const actionsByDate = useLiveQuery(() => oc && db.Action
            .where('order_container_uuid').equals(oc.uuid).filter(act => !act.deleted).toArray(async (actions) => {
                //  For each entry we need to set the actionType and the associated user.
                //  Rather than doing a query for each row, it's probably better to just load all actionTypes and users
                //  up front, and put them into the array as needed (there won't be large number of either type).
                const userById = (await db.User.toArray())
                    .reduce((o, c) => ({...o, [c.id]: c}), {}); //  keyed on user_id (as a string!)
                const actionTypeById = (await db.ActionType.toArray())
                    .reduce((o, c) => ({...o, [c.id]: c}), {}); //  keyed by action_id (again, as a string).
                const depotsById = (await db.Depot.toArray())
                    .reduce((o, c) => ({...o, [c.id]: c}), {}); //  keyed by depot_id (again, as a string).
                // Note: js object keys are always strings, even if you set them otherwise.

                //  Now we can update our actions array and set the user & actionType properties.
                actions = actions.map((a) => {
                    a.recordedBy = userById?.[a.recorded_by];
                    a.actionType = actionTypeById?.[a.action_type_id];
                    a.depot = depotsById?.[a.depot_id];
                    return a;
                });

                //  We want to divide these by day, and then further subdivide by 'AM', 'MD', 'PM', 'other'.
                let actionsByDate = {};
                // Also keep track of whether there's any entries in 'MD' and 'other'
                let hasMd = false, hasOther = false;
                let arrivedDate = null, shippedDate = null;
                actions.forEach((action) => {
                    // Every entry _should_ have an action_date, but just in case, we fall back to using the date
                    // from `recorded_at` if it's missing.
                    const act_date = action.action_date || DateTime.fromSQL(action.recorded_at, { zone: 'utc' }).setZone('Europe/Dublin').toSQLDate();
                    // The `slot` can be one of 'AM', 'MD', 'PM', or 'other' (for actions without a slot).
                    const hasSlot = action.actionType?.has_slot ?? false;
                    let useSlot = 'other';
                    if (hasSlot) {
                        // We identify which slot from the `time_slot` field
                        useSlot = action.time_slot ?? useSlot;
                    }
                    hasMd = hasMd || (useSlot === 'MD');
                    hasOther = hasOther || (useSlot === 'other');

                    // Need to keep track of arrivals and departures (so we know which dates we must show)
                    if (action.actionType?.data_type === ACT_TYPE.ARRIVED) {
                        if (!arrivedDate || act_date < arrivedDate) arrivedDate = act_date;
                    }
                    else if (action.actionType?.data_type === ACT_TYPE.SHIPPED) {
                        if (!shippedDate || act_date > shippedDate) shippedDate = act_date;
                    }

                    // If we don't already have an entry for this date, create one
                    if (!actionsByDate[act_date]) {
                        actionsByDate[act_date] = {
                            'AM': [],
                            'MD': [],
                            'PM': [],
                            'other': [],
                        };
                    }
                    actionsByDate[act_date][useSlot].push(action);
                });
                setHasMdCol(hasMd);
                setHasOtherCol(hasOther);

                // We must ensure that every date between the arrivedDate and shippedDate is shown, even if they
                // have no records.
                if (arrivedDate) {
                    const startDate = DateTime.fromSQL(arrivedDate, {zone: 'Europe/Dublin'});
                    const endDate = shippedDate ? DateTime.fromSQL(shippedDate, {zone: 'Europe/Dublin'}) : DateTime.now().setZone('Europe/Dublin');
                    const interval = Interval.fromDateTimes(startDate, endDate);
                    // Don't bother to do this if the interval is more than 60 days
                    if (interval.length('days') < 60) {
                        let dt = startDate;
                        while (dt <= endDate) {
                            if (!actionsByDate[dt.toSQLDate()]) {
                                actionsByDate[dt.toSQLDate()] = {
                                    'AM': [],
                                    'MD': [],
                                    'PM': [],
                                    'other': [],
                                };
                            }
                            dt = dt.plus({days: 1});
                        }
                    }
                }

                // console.log(oc.uuid, actionsByDate);

                return actionsByDate;
            }),
        [oc]
    );

    if (!actionsByDate) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (Object.keys(actionsByDate).length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }} color='text.disabled'>
                No history for this container.
            </Box>
        );
    }

    return (
        <TableContainer sx={{bgcolor: 'white'}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell align="center">AM</StyledTableCell>
                        {hasMdCol && <StyledTableCell align="center">MD</StyledTableCell>}
                        <StyledTableCell align="center">PM</StyledTableCell>
                        {hasOtherCol && <StyledTableCell align="center">Remarks</StyledTableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(actionsByDate).sort().reverse().map((dt_str) => {
                            const {AM, MD, PM, other} = actionsByDate[dt_str];
                            return (
                                <TableRow key={dt_str}>
                                    <StyledTableCell>{prettyDate(dt_str, {inTz: 'Europe/Dublin', outTz: 'Europe/Dublin'})}</StyledTableCell>
                                    <StyledTableCell align="center">{AM.sort(actionSort).map(act => <ActionChip key={act.uuid} action={act} editFunc={onEditAction} />)}</StyledTableCell>
                                    {hasMdCol && <StyledTableCell align="center">{MD.sort(actionSort).map(act => <ActionChip key={act.uuid} action={act} editFunc={onEditAction} />)}</StyledTableCell>}
                                    <StyledTableCell align="center">{PM.sort(actionSort).map(act => <ActionChip key={act.uuid} action={act} editFunc={onEditAction} />)}</StyledTableCell>
                                    {hasOtherCol && <StyledTableCell>{other.sort(actionSort).map(act => <ActionChip key={act.uuid} action={act} editFunc={onEditAction} />)}</StyledTableCell>}
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
HistoryTable.propTypes = {
    oc: PropTypes.object
}

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 13,
        padding: '4px 4px',
        borderRight: '1px solid #eee'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        paddingLeft: '4px',
        paddingRight: '4px',
        '.MuiChip-root': {
            margin: '1px'
        }
    },
}));

const chipProps = {
    variant: 'outlined',
    size: 'small',
    sx: {
        '& .MuiChip-label': {
            paddingLeft: '4px',
            paddingRight: '4px'
        }
    }
}

const ActionChip = (props) => {
    const {action, editFunc} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);


    // get the initials for the person who recorded the action
    const initials = action.recordedBy?.initials ?? '';
    let val = action.data_value;
    let label = val;

    switch (action.actionType?.data_type) {
        default:
            // Missing actionType (i.e. broken)
            label = action.data_value;
            break;
        case ACT_TYPE.INT:
            // For these we return a chip with the icon and value
            label = Number(val).toFixed(0);
            if (action.actionType.data_unit) label += action.actionType.data_unit;
            if (initials) label += ' (' + initials + ')';
            break;
        case ACT_TYPE.FLOAT:
        case ACT_TYPE.SETTEMP:
        case ACT_TYPE.RECTEMP:
            // For these we return a chip with the icon and value
            label = Number(val).toFixed(1);
            if (action.actionType.data_unit) label += action.actionType.data_unit;
            if (initials) label += ' (' + initials + ')';
            break;
        case ACT_TYPE.SETHUMID:
            // For these we return a chip with the icon and value
            label = Number(val).toFixed(0);
            if (action.actionType.data_unit) label += action.actionType.data_unit;
            if (initials) label += ' (' + initials + ')';
            break;
        case ACT_TYPE.SETVENT:
            label = VENT_POSNS[action.data_value] ?? 'Unknown';
            if (initials) label += ' (' + initials + ')';
            break;
        case ACT_TYPE.BOOL:
            label = val ? 'Y' : 'N';
            if (initials) label += ' (' + initials + ')';
            break;
        case ACT_TYPE.SHIPPED:
        case ACT_TYPE.ARRIVED:
        case ACT_TYPE.LEFT_DEPOT:
        case ACT_TYPE.NONE:
            label = `(${initials})`;
            break;
        case ACT_TYPE.TEXT:
            if (action.action_time) label = tidyTime(action.action_time);
            else label = '';
            label += ` ... (${initials})`;
            break;
        case ACT_TYPE.FLAG:
            label = 'FLAG'
            if (action.data_value) label += ': ' + action.data_value;
            if (initials) label += ' (' + initials + ')';
            break;
        case ACT_TYPE.UNFLAG:
            label = 'UNFLAG'
            if (action.data_value) label += ': ' + action.data_value;
            if (initials) label += ' (' + initials + ')';
            break;
    }

    return (
        <React.Fragment>
            <Chip
                {...chipProps}
                icon={<ActionIcon iconData={action.actionType?.icon_data} sx={{color: `${action.actionType?.icon_color ?? '#000000'} !important`}} />}
                label={label}
                title={action.actionType?.label}
                onClick={() => {}} // we show/hide using focus/blur, this is just here to make the chip clickable
                onFocus={(e) => setAnchorEl(e.currentTarget)}
                onBlur={() => setTimeout(() => setAnchorEl(null), 250)}
            />
            <Popper
                open={!!anchorEl}
                anchorEl={anchorEl}
                placement={'top'}
                onClose={() => setAnchorEl(null)}
                transition
            >{({ TransitionProps }) => (<Fade {...TransitionProps} timeout={350}>
                <Paper sx={{p:1, fontSize: 11}} >
                    <div style={{display: 'flex', alignItems: "center"}}>
                        <strong title="Type of Action" style={{fontSize: 'larger'}}>{action.actionType?.label}</strong>
                        { editFunc && <IconButton
                            aria-label="edit" title="Edit this action"
                            size="small" sx={{ml: 'auto'}}
                            onClick={() => editFunc(action)}
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton> }
                    </div>
                    <span title={"Location"}>{action.depot?.name || '- No Location -'}</span><br />
                    <em title={"Action Time"}>{prettyDate(action.action_date)} {action.time_slot ? action.time_slot : tidyTime(action.action_time)}</em><br />
                    {action.data_value ? <>{action.data_value ?? ''}{action.actionType?.data_unit ?? ''}<br /></> : ''}
                    <em title={"Recorded By"}>{action.recordedBy ? `${action.recordedBy.firstname} ${action.recordedBy.surname}` : ''}</em>
                </Paper></Fade>)}</Popper>
        </React.Fragment>
    );
};


// const utcToLocalTime = (str) => DateTime.fromISO(str, {zone: 'utc'}).toLocal()
//     .toISOTime({suppressSeconds: true, suppressMilliseconds: true, includeOffset: false});

/**
 * Just show the first 5 letters of the time (e.g. '14:30').
 *
 * @param str
 * @returns {string|*}
 */
const tidyTime = (str) => str ? str.substring(0, 5) : str;


/**
 * Sort fn for sorting actions that are in the same slot.
 * Sort first by `action_time`, and if those are equal, sort by `recorded_at`.
 *
 * @param a
 * @param b
 * @returns {number}
 */
const actionSort = (a, b) => {
    if (a.action_time < b.action_time) return -1;
    if (a.action_time > b.action_time) return 1;
    if (a.recorded_at < b.recorded_at) return -1;
    if (a.recorded_at > b.recorded_at) return 1;
    return 0;
};
import React from "react";
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {
    ACT_TYPE, ACT_TYPE_LABEL, ACT_TYPE_WITH_DATA, ACT_TYPE_WITH_SS_TERMS, ACT_TYPE_WITH_UNIT, setTitle
} from "../../common/shared";
import axios from "axios";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup,
    FormLabel, MenuItem, Radio, RadioGroup, Switch, TextField, useTheme
} from "@mui/material";
import * as PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import AddTaskIcon from '@mui/icons-material/AddTask';
import ActionIcon from "../../components/ActionIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../common/db";

const AVAILABLE_IN_LABELS = {
    DEPOT: 'Depots',
    TERMINAL: 'Terminals',
    BOTH: 'Anywhere',
};

const REQUIRE_LOCATION_LABELS = {
    DEPOT: 'Prompt for Depot (if unknown)',
    DEPOT_ALWAYS: 'Prompt for Depot (always)',
    TERMINAL: 'Prompt for Terminal (if unknown)',
    TERMINAL_ALWAYS: 'Prompt for Terminal (always)',
    ANY: 'Prompt for Terminal or Depot (if unknown)',
    ANY_ALWAYS: 'Prompt for Terminal or Depot (always)',
    NONE: "Don't Prompt (leave unchanged)",
};

export default function ActionType() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [actionTypes, setActionTypes] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const allStatuses = useLiveQuery(() => db.ContainerStatus.orderBy('sort_order').toArray(), [], []);

    React.useEffect(() => {
        setTitle('Action Types');
    }, []);

    const refreshActionTypes = () => {
        let params = null;
        axios.get('/admin/actionType', { params: params })
            .then(
                (result) => {
                    console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setActionTypes(result.data.result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }

    const editRow = (record) => {
        if(record.system !== 1){
            setDialogRecord(record);
            setDialogOpen(true);
        }else{
            enqueueSnackbar('System Owned Action ! - Cannot Edit.', {variant: 'error'});
        }
    }

    const addNewRow = () => {
        editRow({
            id: null,
            label: '',
            data_type: ACT_TYPE.NONE,
            data_required: 0,
            data_unit: '',
            sort_order: 100,
            icon_data: '',
            icon_color:'#000000',
            has_slot: 1,
            statuses: [],
            action_message: '',
            billable: 0,
            available_in: 'BOTH',
            slot_allow_dups: 0,
            require_location: 'NONE',
            ss_terms: '',
            allow_bulk: 0,
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshActionTypes(); //  easiest way to get the new data (but not really necessary, we could just modify rows too)
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setActionTypes((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params/*, event, details*/) => {
        // console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    React.useEffect(() => {
        refreshActionTypes();
    }, []);

    const columns = React.useMemo(() => [
            { field: 'id', headerName: 'ID', width: 50, type: 'number',
                valueGetter: ({ value }) => parseInt(value) },
            {field: 'label', headerName: 'Action Name', flex: 1,
                renderCell: ({ row }) => {
                    return (
                        <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                            <ActionIcon iconData={row.icon_data}
                                        sx={{color: row.icon_color,alignSelf: 'center',mr: '8px'}}/>
                            <span>
                             {row.label}
                            </span>
                        </Box>
                    )
                }
            },
            { field: 'data_type', headerName: 'Data recorded', flex: 1,
                valueGetter: ({ row }) => `${ACT_TYPE_LABEL[row.data_type] + (row.data_required ? ' (required)' : '')}`
            },
            {
                field: 'statuses',
                headerName: 'Statuses',
                flex: 1,
                valueGetter: ({ value }) => {
                    if (value && value.length) {
                        return value.map((stat) => {
                            const status = allStatuses.find((s) => s.status === stat);
                            return status ? status.status_name : stat;
                        }).join(', ');
                    }
                    return '';
                }
            },
            {
                field: 'available_in',
                headerName: 'Location',
                width: 100,
                valueGetter: ({value}) => AVAILABLE_IN_LABELS[value] ?? ' - ',
            },
            { field: 'billable', headerName: 'Billable', width: 90, type: 'boolean' },
            { field: 'system', headerName: 'System Owned', width: 120, type: 'boolean' },
            { field: 'sort_order', headerName: 'Sort', minWidth: 50, maxWidth: 80, flex: 0.5, type: 'number',
                valueGetter: ({ value }) => parseInt(value) },
        ], [allStatuses],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={actionTypes}
                          loading={!isLoaded}
                          sx={{
                              bgcolor: '#ffffff',
                              borderRadius: 0,
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                          }}
                          components={{
                              Toolbar: CustomToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  addActionType: addNewRow
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                          initialState={{
                              sorting: {
                                  sortModel: [{field: 'sort_order', sort: 'asc'}]
                              },
                              columns: {
                                  columnVisibilityModel: { id: false }
                              }
                          }}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {

    return (
        <GridToolbarContainer>
            {/*<GridToolbarColumnsButton />*/}
            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            <Button aria-label="Add User" size="small" startIcon={<AddTaskIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addActionType}> New Action Type</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    addActionType: PropTypes.func.isRequired,
}

function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const allStatuses = useLiveQuery(() => db.ContainerStatus.orderBy('sort_order').toArray(), [], []);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isNew = !record.id;

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {

            // Make a copy of record so we can sanitize some data
            let rec = {
                ...record
            };
            // Can't have required data on an action that doesn't support data
            if (!ACT_TYPE_WITH_DATA.includes(rec.data_type)) rec.data_required = 0;
            // Can't have a unit on an action that doesn't support it
            if (!ACT_TYPE_WITH_UNIT.includes(rec.data_type)) rec.data_unit = '';
            // Can't use a blank string or zero as set_status_to
            if (rec.set_status_to === '' || rec.set_status_to === '0') rec.set_status_to = null;

            axios.post('/admin/actionType/save', rec)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Action Type Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.actionType);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/actionType/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Action Type Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value
        }))
    }

    const onStatusCheckChange = (event) => {
        let statuses = record.statuses || [];
        if (event.target.checked) {
            statuses.push(event.target.value);
        }
        else {
            statuses = statuses.filter((s) => s !== event.target.value);
        }
        // Remove duplicates from statuses array
        statuses = [...new Set(statuses)];
        setRecord((prev) => ({
            ...prev,
            statuses: statuses
        }))
    }

    let isValid = (!!record.label && !!record.data_type);

    return (
        <Dialog open={true} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="md">
            <DialogTitle>{isNew ? 'New': 'Edit'} Action Type</DialogTitle>
            <DialogContent dividers={true}>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    autoFocus
                    // margin="dense"
                    id="label"
                    name="label"
                    label="Action Name"
                    helperText="What do we call the action (e.g. 'Record Temperature')"
                    type="text"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={record.label}
                    onChange={onFormChange}
                />
                <TextField
                    margin="dense"
                    id="action_message"
                    name="action_message"
                    label="Message"
                    helperText="Message shown to user before action"
                    type="text"
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={4}
                    inputProps={{ maxLength: 1000 }}
                    variant="standard"
                    value={record.action_message || ''}
                    onChange={onFormChange}
                />
                <TextField
                    id="data-type-id"
                    name="data_type"
                    sx={{mr: 1}}
                    value={record.data_type}
                    select
                    label="Data/Action Type"
                    helperText='What kind of data is entered with this action (if any)?'
                    variant="standard"
                    margin="dense"
                    onChange={onFormChange}
                >
                    {
                        Object.entries(ACT_TYPE_LABEL).map(([k,v],i) => <MenuItem key={i} value={k}>{v}</MenuItem>)
                    }
                </TextField>
                {
                    ACT_TYPE_WITH_UNIT.includes(record.data_type) && <TextField
                        autoFocus
                        margin="dense"
                        id="data_unit"
                        name="data_unit"
                        label="Unit"
                        helperText="Value Unit"
                        type="text"
                        variant="standard"
                        sx={{mr: 1}}
                        inputProps={{size: 5}}
                        required={false}
                        value={record.data_unit || ''}
                        onChange={onFormChange}
                    />
                }
                <TextField
                    margin="dense"
                    name="icon_data"
                    label="Icon"
                    helperText="Action Icon"
                    select
                    variant="standard"
                    value={record.icon_data || ICON_LIST[0]}
                    onChange={onFormChange}
                >
                    {ICON_LIST.map((d, idx) =>
                        <MenuItem value={d} key={idx}><ActionIcon iconData={d} fontSize="inherit" sx={{color: record.icon_color || '#000000'}} /></MenuItem>
                    )}
                </TextField>

                <TextField
                    margin="dense"
                    name="icon_color"
                    label="Colour"
                    helperText=" "
                    type="color"
                    variant="standard"
                    sx={{mr: 1, width: '40px'}}
                    value={record.icon_color || '#000000'}
                    onChange={onFormChange}
                />
                <TextField
                    margin="dense"
                    name="sort_order"
                    label="Sort Order"
                    helperText="Lower values listed first."
                    type="number"
                    variant="standard"
                    sx={{mr: 1}}
                    inputProps={{ step: 1, size: 5 }}
                    required={true}
                    value={record.sort_order || 0}
                    onChange={onFormChange}
                />
                {
                    (ACT_TYPE_WITH_DATA.includes(record.data_type)) && <FormControlLabel
                        control={<Switch name="data_required" checked={!!record.data_required} onChange={onFormChange} />}
                        label="Data is required (i.e. cannot be saved without some data)"
                    />
                }
                <br />
                <TextField
                    id="require_location"
                    name="require_location"
                    sx={{mr: 1}}
                    value={record.require_location}
                    select
                    label="Prompt for Location"
                    helperText="Prompt for new/changed location of type"
                    variant="standard"
                    margin="dense"
                    onChange={onFormChange}
                >
                    {
                        Object.entries(REQUIRE_LOCATION_LABELS).map(([k,v],i) => <MenuItem key={i} value={k}>{v}</MenuItem>)
                    }
                </TextField>
                <TextField
                    id="set_status_to"
                    name="set_status_to"
                    sx={{mr: 1}}
                    value={record.set_status_to ?? '0'}
                    select
                    label="Set Status To"
                    helperText="Set status to this value when action is performed"
                    variant="standard"
                    margin="dense"
                    onChange={onFormChange}
                >
                    <MenuItem value="0">Don't change status</MenuItem>
                    {
                        allStatuses.map((s, i) => <MenuItem key={i} value={s.status}>{s.status_name}</MenuItem>)
                    }
                </TextField>
                <br />
                <FormControlLabel title="Determines if this Action can be priced and billed"
                                  control={<Switch name="billable" checked={!!record.billable} onChange={onFormChange} />}
                                  label="Billable"
                />
                <br />
                <FormControl component="fieldset" variant="standard" sx={{border: 1, borderRadius: 1, borderColor: '#ddd', pl: 1, mr: 1}}>
                    <FormLabel component="legend">Availability</FormLabel>
                    <FormGroup>
                        {
                            allStatuses.map((s, idx) => <FormControlLabel
                                title={`Determines if this Action is available when the status is "${s.status_name}"`}
                                label={s.status_name}
                                key={idx}
                                control={<Switch name={`status_${s.status}_check`}
                                                 checked={record.statuses && record.statuses.includes(s.status)}
                                                 onChange={onStatusCheckChange} value={s.status} />}
                            />)
                        }
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" variant="standard" sx={{border: 1, borderRadius: 1, borderColor: '#ddd', pl: 1, mr: 1}}>
                    <FormLabel component="legend" id="lt-label" title="In what kind of locations is this action available (only affects containers with a location)">Location Type</FormLabel>
                    <RadioGroup
                        aria-labelledby="lt-label"
                        name="available_in"
                        value={record.available_in}
                        onChange={onFormChange}
                    >
                        <FormControlLabel value="DEPOT" control={<Radio size="small" />} label="Depots" title="This action is only available in Depots" />
                        <FormControlLabel value="TERMINAL" control={<Radio size="small" />} label="Terminals" title="This action is only available in Terminals" />
                        <FormControlLabel value="BOTH" control={<Radio size="small" />} label="Anywhere" title="This action is available in both Depots & Terminals" />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" variant="standard" sx={{border: 1, borderRadius: 1, borderColor: '#ddd', pl: 1, mr: 1}}>
                    <FormLabel component="legend">Time Slots</FormLabel>
                    <FormGroup>
                        <FormControlLabel title="Three 'slots' are available to record this action every day"
                                          control={<Switch name="has_slot" checked={!!record.has_slot} onChange={onFormChange} />}
                                          label="Uses time slots (AM/MD/PM)"
                        />
                        {record.has_slot ? <FormControlLabel title="Allow multiple entries of this type within a slot"
                                                             control={<Switch name="slot_allow_dups" checked={!!record.slot_allow_dups} onChange={onFormChange} />}
                                                             label="Allow multiple entries in slot"
                        /> : ''}
                    </FormGroup>
                </FormControl>
                <br />
                {
                    ACT_TYPE_WITH_SS_TERMS.includes(record.data_type) && <TextField
                        margin="dense"
                        id="ss_terms"
                        name="ss_terms"
                        label="Worksheet terms used (one per line)"
                        helperText="Terms used in the 'Other Actions' column of the worksheet to indicate this action"
                        type="text"
                        variant="standard"
                        sx={{mr: 1}}
                        multiline={true}
                        minRows={2}
                        required={false}
                        value={record.ss_terms || ''}
                        onChange={onFormChange}
                    />
                }
                <br />
                <FormControlLabel title="Allow this action to be performed on multiple records at once"
                                  control={<Switch name="allow_bulk" checked={!!record.allow_bulk} onChange={onFormChange} />}
                                  label="Bulk Action: Allow this action to be performed on multiple containers at once"
                />



            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New Action Type' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

const ICON_LIST = [
    // Default (clipboard with pencil)
    'M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 ' +
    '1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75' +
    '.34-.75.75-.75zM9.1 17H7v-2.14l5.96-5.96 2.12 2.12L9.1 17zm7.75-7.73-1.06 1.06-2.12-2.12 1.06-1.06c.2-.2' +
    '.51-.2.71 0l1.41 1.41c.2.2.2.51 0 .71z',
    // Power
    'M16.01 7 16 3h-2v4h-4V3H8v4h-.01C7 6.99 6 7.99 6 8.99v5.49L9.5 18v3h5v-3l3.5-3.51v-5.5c0-1-1-2-1.99-1.99z',
    // PowerOff
    'M18 14.49V9c0-1-1.01-2.01-2-2V3h-2v4h-4V3H8v2.48l9.51 9.5.49-.49zm-1.76 1.77L7.2 7.2l-.01.01L3.98 4 2.71 5.25l3.36 3.36C6.04 8.74 6 8.87 6 9v5.48L9.5 18v3h5v-3l.48-.48L19.45 22l1.26-1.28-4.47-4.46z',
    // PowerSettingsNewIcon
    'M13 3h-2v10h2V3zm4.83 2.17-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z',
    // SettingsPowerIcon
    'M7 24h2v-2H7v2zm4 0h2v-2h-2v2zm2-22h-2v10h2V2zm3.56 2.44-1.45 1.45C16.84 6.94 18 8.83 18 11c0 3.31-2.69 6-6 6s-6-2.69-6-6c0-2.17 1.16-4.06 2.88-5.12L7.44 4.44C5.36 5.88 4 8.28 4 11c0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.72-1.36-5.12-3.44-6.56zM15 24h2v-2h-2v2z',
    // Thermostat
    'M15 13V5c0-1.66-1.34-3-3-3S9 3.34 9 5v8c-1.21.91-2 2.37-2 4 0 2.76 2.24 5 5 5s5-2.24 5-5c0-1.63-.79-3.09-2-4zm-4-2V5c0-.55.45-1 1-1s1 .45 1 1v1h-1v1h1v2h-1v1h1v1h-2z',
    // AcUnit (snowflake thingy)
    'M22 11h-4.17l3.24-3.24-1.41-1.42L15 11h-2V9l4.66-4.66-1.42-1.41L13 6.17V2h-2v4.17L7.76 2.93 6.34 4.34 11 9v2H9L4.34 6.34 2.93 7.76 6.17 11H2v2h4.17l-3.24 3.24 1.41 1.42L9 13h2v2l-4.66 4.66 1.42 1.41L11 17.83V22h2v-4.17l3.24 3.24 1.42-1.41L13 15v-2h2l4.66 4.66 1.41-1.42L17.83 13H22z',
    // WbSunny
    'm6.76 4.84-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7 1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91 1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z',
    // NoteAdd
    'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z',
    // Lock Open
    'M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z',
    // Lock
    'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z',
    // Sailing
    'M11 13.5V2L3 13.5h8zm10 0C21 6.5 14.5 1 12.5 1c0 0 1 3 1 6.5s-1 6-1 6H21zm1 1.5H2c.31 1.53 1.16 2.84 2.33 3.73.65-.27 1.22-.72 1.67-1.23.73.84 1.8 1.5 3 1.5s2.27-.66 3-1.5c.73.84 1.8 1.5 3 1.5s2.26-.66 3-1.5c.45.51 1.02.96 1.67 1.23 1.17-.89 2.02-2.2 2.33-3.73zm0 8v-2h-1c-1.04 0-2.08-.35-3-1-1.83 1.3-4.17 1.3-6 0-1.83 1.3-4.17 1.3-6 0-.91.65-1.96 1-3 1H2v2h1c1.03 0 2.05-.25 3-.75 1.89 1 4.11 1 6 0 1.89 1 4.11 1 6 0 .95.5 1.97.75 3 .75h1z',
    // DirectionsBoat
    'M20 21c-1.39 0-2.78-.47-4-1.32-2.44 1.71-5.56 1.71-8 0C6.78 20.53 5.39 21 4 21H2v2h2c1.38 0 2.74-.35 4-.99 2.52 1.29 5.48 1.29 8 0 1.26.65 2.62.99 4 .99h2v-2h-2zM3.95 19H4c1.6 0 3.02-.88 4-2 .98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68c.08-.26.06-.54-.06-.78s-.34-.42-.6-.5L20 10.62V6c0-1.1-.9-2-2-2h-3V1H9v3H6c-1.1 0-2 .9-2 2v4.62l-1.29.42c-.26.08-.48.26-.6.5s-.15.52-.06.78L3.95 19zM6 6h12v3.97L12 8 6 9.97V6z',
    // LocalShipping
    'M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9 1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z',
    // ChatIcon
    'M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z',
    // Our custom import icon
    'm20 18v3.1h-8.2v-3.1h4.1s-6.6-5.4-7.8-6.3l5-0.43s-1.3-2.8-2.7-3.7c-2.7-1.8-5.1-1.9-8.2-1.9v-3.1c2.3 0 6.8 0.16 10 2.7 1.8 1.3 3.8 5.6 3.8 5.6l5.2-0.54c-1.1 1-5.6 7.7-5.6 7.7z',
    // Our custom export icon
    'm17 8.3c-3.6 2.1-7.5 5.5-8.3 10h3.2v2.7h-9.5v-2.7h3.2c0.87-4.7 1.6-8 9.8-13l-2-3.1 8 0.83-2.3 7.7z',
    // PhoneCallback (maybe for callout action)
    'M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2zm13.54-7.1-.71-.7L13 9.29V5h-1v6h6v-1h-4.15z',
    // MagnifyingGlass (maybe for PTI)
    'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
    // BuildIcon (spanner, maybe for repair)
    'm22.7 19-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z',
    // SettingsInputComponentIcon (looks like 3 plugs)
    'M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H1v6h6V6H5V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2H9v2zm-8 0c0 1.3.84 2.4 2 2.82V23h2v-4.18C6.16 18.4 7 17.3 7 16v-2H1v2zM21 6V2c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6V6h-2zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4H9v6h6V6h-2V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z',
    // SettingsIcon
    'M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z',
    // SettingsApplicationsIcon
    'M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm7-7H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-1.75 9c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.3.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.26 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.29l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42c-.09-.15-.05-.34.08-.45l1.48-1.16c-.03-.23-.05-.46-.05-.69 0-.23.02-.46.05-.68l-1.48-1.16c-.13-.11-.17-.3-.08-.45l1.4-2.42c.09-.15.27-.21.43-.15l1.74.7c.36-.28.76-.51 1.18-.69l.26-1.85c.03-.17.18-.3.35-.3h2.8c.17 0 .32.13.35.29l.26 1.85c.43.18.82.41 1.18.69l1.74-.7c.16-.06.34 0 .43.15l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.23.05.46.05.69z',
    // DownloadIcon
    'M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z',
    // MoveDownIcon
    'M3 11c0 2.45 1.76 4.47 4.08 4.91l-1.49-1.49L7 13l4 4.01L7 21l-1.41-1.41 1.58-1.58v-.06C3.7 17.54 1 14.58 1 11c0-3.87 3.13-7 7-7h3v2H8c-2.76 0-5 2.24-5 5zm19 0V4h-9v7h9zm-2-2h-5V6h5v3zm-7 4h9v7h-9z',
    // MoveUpIcon
    'M3 13c0-2.45 1.76-4.47 4.08-4.91l-1.49 1.5L7 11l4-4.01L7 3 5.59 4.41l1.58 1.58v.06C3.7 6.46 1 9.42 1 13c0 3.87 3.13 7 7 7h3v-2H8c-2.76 0-5-2.24-5-5zm10 0v7h9v-7h-9zm7 5h-5v-3h5v3zM13 4h9v7h-9z',
    // RemoveRoadIcon
    'M18 4h2v9h-2zM4 4h2v16H4zm7 0h2v4h-2zm0 6h2v4h-2zm0 6h2v4h-2zm11.5.41L21.09 15 19 17.09 16.91 15l-1.41 1.41 2.09 2.09-2.09 2.09L16.91 22 19 19.91 21.09 22l1.41-1.41-2.09-2.09z',
    //MonitorHeartIcon
    'M15.11 12.45 14 10.24l-3.11 6.21c-.16.34-.51.55-.89.55s-.73-.21-.89-.55L7.38 13H2v5c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-5h-6c-.38 0-.73-.21-.89-.55z' +
    'M20 4H4c-1.1 0-2 .9-2 2v5h6c.38 0 .73.21.89.55L10 13.76l3.11-6.21c.34-.68 1.45-.68 1.79 0L16.62 11H22V6c0-1.1-.9-2-2-2z',
    //PhoneForwardedIcon
    'm18 11 5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z',
    //EuroIcon
    'M15 18.5c-2.51 0-4.68-1.42-5.76-3.5H15l1-2H8.58c-.05-.33-.08-.66-.08-1s.03-.67.08-1H15l1-2H9.24C10.32 6.92 12.5 5.5 15 5.5c1.61 0 3.09.59 4.23 1.57L21 5.3C19.41 3.87 17.3 3 15 3c-3.92 0-7.24 2.51-8.48 6H3l-1 2h4.06c-.04.33-.06.66-.06 1s.02.67.06 1H3l-1 2h4.52c1.24 3.49 4.56 6 8.48 6 2.31 0 4.41-.87 6-2.3l-1.78-1.77c-1.13.98-2.6 1.57-4.22 1.57z',
    // Air
    'M14.5 17c0 1.65-1.35 3-3 3s-3-1.35-3-3h2c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1H2v-2h9.5c1.65 0 3 1.35 3 3zM19 6.5C19 4.57 17.43 3 15.5 3S12 4.57 12 6.5h2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S16.33 8 15.5 8H2v2h13.5c1.93 0 3.5-1.57 3.5-3.5zm-.5 4.5H2v2h16.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5v2c1.93 0 3.5-1.57 3.5-3.5S20.43 11 18.5 11z',
    // HeatPump (using this as the one for vents)
    'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-6.25 4.08c.82.12 1.57.44 2.2.91l-2.2 2.2V7.08zm-1.5 0v3.11l-2.2-2.2c.63-.47 1.38-.79 2.2-.91zM7.99 9.05l2.2 2.2H7.08c.12-.82.44-1.57.91-2.2zm-.91 3.7h3.11l-2.2 2.2c-.47-.63-.79-1.38-.91-2.2zm4.17 4.17c-.82-.12-1.57-.44-2.2-.91l2.2-2.2v3.11zM12 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm.75 3.92v-3.11l2.2 2.2c-.63.47-1.38.79-2.2.91zm3.26-1.97-2.2-2.2h3.11c-.12.82-.44 1.57-.91 2.2zm-2.2-3.7 2.2-2.2c.47.64.79 1.39.91 2.2h-3.11z',
    // Water
    'M21.98 14H22h-.02zM5.35 13c1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.4.98 3.31 1v-2c-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1v2c1.9 0 2.17-1 3.35-1zm13.32 2c-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.1 1-3.34 1-1.24 0-1.38-1-3.33-1-1.95 0-2.1 1-3.34 1v2c1.95 0 2.11-1 3.34-1 1.24 0 1.38 1 3.33 1 1.95 0 2.1-1 3.34-1 1.19 0 1.42 1 3.33 1 1.94 0 2.09-1 3.33-1 1.19 0 1.42 1 3.33 1v-2c-1.24 0-1.38-1-3.33-1zM5.35 9c1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.4.98 3.31 1V8c-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1C3.38 7 3.24 8 2 8v2c1.9 0 2.17-1 3.35-1z',
    // WaterDamageIcon
    'M12 3 2 12h3v8h14v-8h3L12 3zm0 13c-1.1 0-2-.9-2-2s2-4 2-4 2 2.9 2 4-.9 2-2 2z',
    // InvertColorsIcon
    'M12 4.81V19c-3.31 0-6-2.63-6-5.87 0-1.56.62-3.03 1.75-4.14L12 4.81M6.35 7.56C4.9 8.99 4 10.96 4 13.13 4 17.48 7.58 21 12 21s8-3.52 8-7.87c0-2.17-.9-4.14-2.35-5.57L12 2 6.35 7.56z',
    // OpacityIcon
    'M17.66 8 12 2.35 6.34 8C4.78 9.56 4 11.64 4 13.64s.78 4.11 2.34 5.67 3.61 2.35 5.66 2.35 4.1-.79 5.66-2.35S20 15.64 20 13.64 19.22 9.56 17.66 8zM6 14c.01-2 .62-3.27 1.76-4.4L12 5.27l4.24 4.38C17.38 10.77 17.99 12 18 14H6z',
    // ReportIcon
    'M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM12 17.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2V7h2v6z',
    // ReportOffIcon
    'M11 7h2v2.92l6.91 6.91 1.09-1.1V8.27L15.73 3H8.27L7.18 4.1 11 7.92zm11.27 14.73-20-20.01L1 2.99l3.64 3.64L3 8.27v7.46L8.27 21h7.46l1.64-1.63L21 23l1.27-1.27zM12 17.3c-.72 0-1.3-.58-1.3-1.3s.58-1.3 1.3-1.3 1.3.58 1.3 1.3-.58 1.3-1.3 1.3z',
    // Warning
    'M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z',
    // SystemSecurityUpdateGood
    'M17 1H7c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 17H7V6h10v12zm-1-7.95-1.41-1.41-3.54 3.54-1.41-1.41-1.41 1.41L11.05 15 16 10.05z',
    // Logout
    'm17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z',
    // SevereCold
    'm12 10.41 4-4L14.59 5 12 7.59V4h-2v3.59L7.41 5 6 6.41l4 4V12H8.41l-4-4L3 9.41 5.59 12H2v2h3.59L3 16.59 4.41 18l4-4H10v1.59l-4 4L7.41 21 10 18.41V22h2v-3.59L14.59 21 16 19.59l-4-4V14h1.59l4 4L19 16.59 16.41 14H20v-2h-8zM19 2h2v5h-2zm0 6h2v2h-2z',
    // ImportExport
    'M9 3 5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z',
    // ForwardToInbox
    'M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5zm7 4 4 4-4 4v-3h-4v-2h4v-3z',
    // Download
    'M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z',
    // ResetTv
    'M21 10h-8.01V7L9 11l3.99 4v-3H21v5H3V5h18v3h2V5c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2v-5H23c0-1.1-.9-2-2-2z',
    // Construction
    'm13.7826 15.1719 2.1213-2.1213 5.9963 5.9962-2.1213 2.1213zM17.5 10c1.93 0 3.5-1.57 3.5-3.5 0-.58-.16-1.12-.41-1.6l-2.7 2.7-1.49-1.49 2.7-2.7c-.48-.25-1.02-.41-1.6-.41C15.57 3 14 4.57 14 6.5c0 .41.08.8.21 1.16l-1.85 1.85-1.78-1.78.71-.71-1.41-1.41L12 3.49c-1.17-1.17-3.07-1.17-4.24 0L4.22 7.03l1.41 1.41H2.81l-.71.71 3.54 3.54.71-.71V9.15l1.41 1.41.71-.71 1.78 1.78-7.41 7.41 2.12 2.12L16.34 9.79c.36.13.75.21 1.16.21z',
];

// To add more to the above list
// - https://mui.com/material-ui/material-icons/
// - Find the icon you like
// - Inspect the source svg
// - copy the 'd' attribute from the svg->path element.
import React from "react";
import {SvgIcon} from "@mui/material";
import PropTypes from "prop-types";


const DEFAULT_ACTION_SVG_PATH = 'M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 ' +
    '1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75' +
    '.34-.75.75-.75zM9.1 17H7v-2.14l5.96-5.96 2.12 2.12L9.1 17zm7.75-7.73-1.06 1.06-2.12-2.12 1.06-1.06c.2-.2' +
    '.51-.2.71 0l1.41 1.41c.2.2.2.51 0 .71z';

export default function ActionIcon(props) {
    const {iconData, ...svgProps} = props;

    // For now this just handles the simplest case, which is an svg with a single path and nothing else.
    // (this is what the material-ui icons have).

    return (<SvgIcon {...svgProps}>{
        React.createElement('path', {d: iconData || DEFAULT_ACTION_SVG_PATH})
    }</SvgIcon>)
}
ActionIcon.propTypes = {
    iconData: PropTypes.string,
}
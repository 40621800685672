import React from "react";
import {recordUserAct, setTitle} from "../common/shared";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack
} from "@mui/material";
import {db, getUnsyncedChangeCount, recreateDb, SYNC_URL} from "../common/db";
import {AuthContext} from "../contexts/AuthProvider";
import axios from "axios";
import {useSnackbar} from "notistack";
import config from "../config";
import {DateTime} from "luxon";
import logo from '../logo.svg';


export default function About() {
    const auth = React.useContext(AuthContext);
    //const dexie = React.useContext(DexieContext);
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        setTitle('About');
    }, []);
    //console.log('Dexie status: ' + getStatusText());

    const [fixing, setFixing] = React.useState(false);
    const [confirmDlgOpen, setConfirmDlgOpen] = React.useState(false);

    const [recreateDbPending, setRecreateDbPending] = React.useState(false);

    // This is so we don't have to go digging in IndexedDb to find the clientIdentity
    const [myClientIdentity, setMyClientIdentity] = React.useState(null);

    React.useEffect(() => {
        db._syncNodes.where('url').equalsIgnoreCase(SYNC_URL).first((node) => {
            // console.log(node);
            if (node) {
                if (node.syncContext?.clientIdentity) {
                    setMyClientIdentity(node.syncContext.clientIdentity);
                }
            }
        });
    }, []);

    const runFixup = () => {
        setFixing(true);
        axios.get('/admin/super/fixupDexie')
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        enqueueSnackbar(result.data.message || 'Fixup failed', {
                            variant: 'error', autoHideDuration: 15000,
                        })
                    }
                    else {
                        enqueueSnackbar(result.data.message || 'Fixup finished', {
                            variant: 'success', autoHideDuration: 7000,
                        })
                    }
                },
                (error) => {
                    enqueueSnackbar(error.message || error.toString(), {
                        variant: 'error', autoHideDuration: 15000,
                    })
                }
            )
            .finally(() => {
                setFixing(false);
            });
    }

    const onRecreateDb = () => {
        recordUserAct( {
            action: 'recreateDb',
            user: auth.user,
        });
        getUnsyncedChangeCount().then((n) => {
            if (n > 0) {
                setConfirmDlgOpen(true);
            }
            else {
                setRecreateDbPending(true);
                recreateDb(true);
            }
        });
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                { confirmDlgOpen &&
                    <Dialog
                        open={confirmDlgOpen}
                        onClose={() => setConfirmDlgOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Data will be lost!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You currently have some changes that have not been communicated to the server.
                                If you re-create the local database, these changes will be lost.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="success" onClick={() => setConfirmDlgOpen(false)} autoFocus>Cancel</Button>
                            <Button variant="outlined" color="warning" onClick={() => {
                                setConfirmDlgOpen(false);
                                setRecreateDbPending(true);
                                recreateDb(true);
                            }}>
                                I understand, delete my changes
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <Paper variant="outlined" square sx={{ minHeight: '100%', p: 3}}>
                    <img src={logo} alt="Logo" style={{float: 'right'}} />
                    <h4>About {config.APP_NAME}</h4>
                    <div style={{fontSize: "smaller", color: '#888'}}>
                        <strong>© 2024 Lankel Refrigeration Ltd.</strong><br />
                        <strong>All Rights Reserved.</strong><br />
                        <strong>Version: </strong><span>{config.APP_VERSION} ({config.BUILD_TYPE}), built on {
                            `${config.BUILD_TIMESTAMP ? DateTime.fromMillis(config.BUILD_TIMESTAMP).toLocaleString(DateTime.DATETIME_SHORT) : '- Unknown -'}`
                        }</span><br />
                        {
                            myClientIdentity && <span
                                title="This is used to identify your device. Click to copy to clipboard."
                                onClick={async () => {
                                    try {
                                        // Getting an error here?  You must run the site on https for this to work.
                                        await navigator.clipboard.writeText(myClientIdentity);
                                        enqueueSnackbar('Client Identity copied!', {
                                            variant: 'success', autoHideDuration: 1000,
                                        })
                                    }
                                    catch (e) {
                                        console.error(e);
                                    }
                                }}
                            ><strong>Client Identity: </strong><span>{myClientIdentity}</span><br /></span>
                        }
                    </div>
                    <p>Developed and supported by <a href="mailto:support@pentagoncomputers.com">Pentagon Computers Ltd.</a></p>

                    <Stack spacing={2} direction="row" sx={{mt: 3}}>
                        {
                            auth.isLoggedIn() &&
                            <Button
                                variant="contained"
                                disabled={recreateDbPending}
                                onClick={onRecreateDb}
                                title="Drop Dexie DB and re-create it"
                            >Re-create DB</Button>
                        }
                        {
                            auth.hasRole('ROLE_SUPER') &&
                            <Button
                                variant="contained"
                                disabled={fixing}
                                onClick={runFixup}
                                title="Sync Dexie with the current mysql state (run this after making mysql changes)"
                            >Fixup Dexie</Button>
                        }
                    </Stack>
                </Paper>
            </Box>
        </Box>
    );
}
import {SvgIcon} from "@mui/material";
import React from "react";


export default function ContainerIcon(props) {
    return <SvgIcon {...props}>
        <path d="m19 5v14h-14v-14h14m1-2h-16c-0.5 0-0.9 0.4-0.9 0.9v16s0.4 1 0.9 1h16l1-1v-16c0-0.5-1-0.9-1-0.9zm-5 4h2v2h-2zm-4 4h6v2h-6zm0 4h6v2h-6zm-4-8h6v2h-6zm0 4h2v2h-2zm0 4h2v2h-2z"/>
    </SvgIcon>
}
ContainerIcon.propTypes = SvgIcon.propTypes;

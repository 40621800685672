/**
 * In your component/container/page
 *      import { AuthContext } from "../contexts/AuthProvider";
 * Then in your fn:
 *      const auth = React.useContext(AuthContext);
 *
 * And you can then:
 *      auth.user -> this is the current user {id, email, firstname, surname, and roles}
 *      auth.getFullName() -> 'Joe Bloggs' or 'Guest' (if not logged-in)
 *      auth.hasRole('ROLE_ADMIN') -> true/false
 * i.e. all the properties of the `value` object below.
 */


import axios from "axios";
import React from 'react';
import {decodeToken, isExpired} from "react-jwt";
import {reconnectIfNeeded} from "../common/db";

export const AuthContext = React.createContext(null);

const userFromToken = (token) => {
    if (!token) return null;

    const decoded = decodeToken(token);

    return decoded.user;
};

export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = React.useState(userFromToken(localStorage.getItem('token')));
    // const locn = useLocation();


    const setToken = React.useCallback((token) => {
        if (token) {
            localStorage.setItem("token", token);
            setCurrentUser(userFromToken(token));
        }
        else {
            localStorage.removeItem('token');
            setCurrentUser(null);
        }
    }, []);

    const getToken = React.useCallback(() => localStorage.getItem('token'), []);


    /**
     * Check the user's current login state.
     * Sends a request to /auth/login/check, which will respond with the user info if the user is logged-in, or
     * a failure if not. `currentUser` and the 'token' object in localStorage are updated based on this.
     *
     * Call this if the user might be logged-in but not know it, or may have been logged-out (due to session
     * expiry).  It should handle either case gracefully.
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    const checkLogin = React.useCallback(async () => {
        return axios
            .get("/auth/login/check")
            .then((response) => {
                console.log(response);
                if (response.data?.token) {
                    setToken(response.data.token);
                    try {
                        reconnectIfNeeded();
                    }
                    catch (e) {
                        console.error(e); // not fatal here, just log it.
                    }
                    return true;
                }
                if (response.data.error) {
                    throw response.data;
                }
                if (!response.data.success) {
                    //  Not logged-in.
                    setCurrentUser(false);
                    localStorage.removeItem("token");
                    return false;
                }

                //return response.data;
                throw Error('Missing token in response');
            });
    }, [setToken]);

    /**
     * True/False if they're logged-in/not logged-in, or null if we don't know yet.
     * The `null` only happens immediately after a full browser page load, either through opening the site from an
     * external link, or a page refresh (i.e. before the `useEffect` above has a chance to run).
     *
     * @returns {null|boolean}
     */
    const isLoggedIn = () => {
        //  If we don't know yet (happens before the useEffect has had a chance to run), return null.
        if (currentUser === undefined) return null;

        //  Otherwise return true/false
        return !!currentUser;
    };

    React.useEffect(() => {
        // Refresh our token every 4 hrs (or thereabouts).
        const tid = setInterval(() => currentUser && checkLogin(), 4*60*60*1000);

        return () => {
            clearInterval(tid);
        }
    }, [currentUser, checkLogin]);

    // At startup, check if we're logged-in (by pulling the token from localStorage).
    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setCurrentUser(null);   //  not logged-in
        }
        else {
            if (!isExpired(token)) {
                setCurrentUser(userFromToken(token));
            }
            else {
                console.log('Have a token, but it has expired, logging us out');
                setCurrentUser(null);
            }
        }
    }, []);

    React.useEffect(() => {
        //  Listen for changes to localstorage to see when login changes in another tab.
        //  Note: this ONLY fires when localstorage is modified outside the current tab.
        const checkUserOnStorageChange = (e) => {
            if (e.key === 'token') {
                console.log('User change in another tab detected, checking...', e);
                const token = localStorage.getItem('token');
                if (!token) {
                    setCurrentUser(null);   //  not logged-in
                } else {
                    if (!isExpired(token)) {
                        const newUser = userFromToken(token);
                        setCurrentUser((prev) => {
                            if (!newUser) return null;
                            if (prev == null) return newUser;
                            return {
                                ...prev,
                                ...newUser
                            };
                        });
                    } else {
                        console.log('Have a token, but it has expired, logging us out');
                        setCurrentUser(null);
                    }
                }
            }
        }

        window.addEventListener('storage', checkUserOnStorageChange);
        return () => {
            window.removeEventListener('storage', checkUserOnStorageChange);
        }
    }, []);

    // const isLoggedInAsync = async () => {
    //     // If currentUser is not still undefined, no need to wait.
    //     if (currentUser !== undefined) return !!currentUser;
    //
    //     const WAIT_INTERVAL = 10;   // milliseconds
    //     const MAX_WAIT_INTERVALS = 100; // 10ms x 100 = 1 second
    //     let checkCount = 0;
    //     const chkFn = async () => {
    //         if (currentUser !== undefined) return !!currentUser;
    //         if (checkCount > MAX_WAIT_INTERVALS) {
    //             console.error('Timeout waiting for currentUser in AuthProvider');
    //             //  Maybe we should throw here?
    //         }
    //         checkCount += 1;
    //         await new Promise(resolve => setTimeout(resolve, WAIT_INTERVAL));
    //         return chkFn();
    //     }
    //     return chkFn();
    // };

    // Add an interceptor for axios to catch 403 responses and check for login
    // Note: using layout effect to ensure this runs before any axios requests are made.
    React.useLayoutEffect(() => {
        const resIntcpt = axios.interceptors.response.use(undefined, (err) => {
            if (err?.response?.status === 403 && currentUser) {
                checkLogin();
            }
            return Promise.reject(err);
        });

        // Add our jwt to every request, if we have one.
        const reqIntcpt = axios.interceptors.request.use((config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        return () => {
            axios.interceptors.response.eject(resIntcpt);
            axios.interceptors.request.eject(reqIntcpt);
        }
    }, [currentUser, checkLogin]);

    const handleLogin = async (username, password) => {
        return axios
            .post("/auth/login", {
                username: username,
                password: password,
            })
            .then((response) => {
                //console.log(response);
                if (response.data?.token) {
                    setToken(response.data.token);
                    try {
                        reconnectIfNeeded();
                    }
                    catch (e) {
                        console.error(e); // not fatal here, just log it.
                    }
                    return true;
                }
                if (response.data?.error) {
                    throw response.data;
                }
                if (!response.data?.success) {
                    throw response.data || 'Missing data in server response.';
                }

                //return response.data;
                throw Error('Missing token in response');
            });
    };

    const handleLogout = async () => {
        setToken(null);

        // Safer to do this too, as we need to invalidate the session to be safe.
        axios.post("/auth/login/logout");
    }

    /**
     * Check if the current user has a role.
     * @param {string|string[]} role  A role (e.g. 'ROLE_ADMIN') or an array of roles (in which case any matching role
     *                                will satisfy).
     * @returns {boolean} `true` if the user has one or more of the roles, `false` otherwise.  If `role` is
     *                    null/undefined/empty will return `false`.
     */
    const hasRole = (role) => {
        if (!currentUser) return false;

        //  If they passed in an array, we need to check each one.
        if (Array.isArray(role)) {
            for (const r of role) {
                if (currentUser.roles.includes(r)) return true;
            }
            return false;
        }
        //  Otherwise we assume it's a string (just one role), and check for that.
        return currentUser.roles.includes(role);
    }

    /**
     * Full name of the current user (or 'Guest' if not logged-in).
     *
     * @returns {string}
     */
    const getFullName = () => {
        if (!currentUser) return 'Guest';

        return (currentUser.firstname + ' ' + currentUser.surname).trim();
    }

    /**
     * Initials for the current user (or an empty string if not logged-in).
     *
     * @returns {string}
     */
    const getInitials = () => {
        if (!currentUser) return '?';
        if (currentUser.initials && currentUser.initials !== '') return currentUser.initials;
        return (currentUser.firstname?.[0] ?? '') + (currentUser.surname?.[0] ?? '');
    }

    /**
     * Returns the default port_code for the current user (might be null).
     *
     * @returns {string|null}
     */
    const getDefaultPort = () => {
        if (!currentUser) return null;
        return currentUser.port_code;
    }

    const value = {
        user: currentUser,
        login: handleLogin,
        logout: handleLogout,
        setToken,
        getToken,
        checkLogin,
        hasRole,
        getFullName,
        getInitials,
        isLoggedIn,
        getDefaultPort,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

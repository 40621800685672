import config from "../config.js";
import {DateTime} from "luxon";
import eventBus from "./EventBus";
import {db} from "./db";
import axios from "axios";

/**
 * Set the page title, with `| APP_NAME` appended.
 *
 * @param {string} title
 */
const setTitle = (title) => {
    document.title = `${title} | ${config.APP_NAME}`;
    // Header.js listens for this and changes the text to match.
    eventBus.dispatch('pageTitleChange', {title});
}

/**
 * Check a container number for validity.
 * reefer_num should be passed in without spaces (this function will NOT remove them automatically).
 * Case is ignored.
 *
 * @param {string} reefer_num  11 characters long, with no spaces (this fn will NOT remove any spaces).
 * @returns {boolean}
 */
const isReeferValid = (reefer_num) => {
    // cf. https://en.wikipedia.org/wiki/ISO_6346
    if (!reefer_num) return false;
    if (reefer_num.length !== 11) return false;

    const alphabet = {
        'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19,
        'J': 20, 'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29,
        'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
    };
    reefer_num = reefer_num.toUpperCase();

    if (!(/^[A-Z]{4}\d{7}/.test(reefer_num))) return false;

    let sum = 0;
    const checkDigit = Number(reefer_num.substring(10));

    reefer_num.substring(0, 10).split('').map((ch, i) => {
        //  We can safely do this b/c of the regex test above
        let n = (i < 4) ? alphabet[ch] : Number(ch);
        n *= Math.pow(2, i);
        sum += n;
        return false;   //  not used, just to keep react happy
    });
    sum %= 11;
    sum %= 10;
    return sum === checkDigit;
}

/**
 * Format a container number to include two spaces, e.g.
 *  'CSQU3054383' becomes 'CSQU 305438 3'
 *
 * @param {string} reefer_num
 * @returns {string}
 */
const formatReefer = (reefer_num) => {
    if (!reefer_num) return '';
    reefer_num = reefer_num.toUpperCase();
    return (reefer_num.substring(0, 4) + ' ' + reefer_num.substring(4, 10) + ' ' + reefer_num.substring(10)).trim();
}

/**
 * Format a MYSQL datetime string (YYYY-MM-DD HH:mm:ss) as a simple date and time.
 *
 * @param {string} dt_str MYSQL date string (e.g. '2021-01-01 11:22:33') in `inTz` timezone.
 * @param {object} opts Currently just:<pre>
 *      isMagic - (default: false) Magic times are 09:30:01, 12:00:01, and 18:00:01 (all irish time).  If the time part of `dt_str`
 *          matches one of these exactly (in Irish TZ), then the time part will be shown as 'AM', 'MD', or 'PM'
 *          respectively (instead of showing HH:mm).
 *      useTomorrow - (default: false) If the date part of `dt_str` is tomorrow, then the returned string will be prefixed with
 *          'Tomorrow' instead of the date.
 *      useYesterday - (default: false) If the date part of `dt_str` is yesterday, then the returned string will be prefixed with
 *          'Yesterday' instead of the day-of-week.
 *      inTz - The timezone of `dt_str`.  Defaults to 'utc'.
 *      outTz - The display timezone, defaults to 'local'.</pre>
 * @returns {string} Returns a friendlier string, e.g.
 *              For any times from today (in outTz tz), it just displays the 24hr time, e.g. '11:22'.
 *              For any times in the last 6 days, it precedes the above with the dow, e.g. 'Mon 11:22'.
 *              Any anything else, it currently just shows the date, e.g. '01/01/21'.
 */
const prettyDateTime = (dt_str,
                        opts = {
                            isMagic: false,
                            useTomorrow: false,
                            useYesterday: false,
                            inTz: 'utc',
                            outTz: 'local',
                        }) => {
    const _opts = {
        isMagic: false,
        useTomorrow: false,
        useYesterday: false,
        inTz: 'utc',
        outTz: 'local',
        ...opts
    };

    if (dt_str == null || dt_str === '') return '';

    const dt = DateTime.fromSQL(dt_str, { zone: _opts.inTz || 'utc' }).setZone(_opts.outTz || 'local');
    let magicTime = false;
    if (_opts.isMagic) {
        // Magic times are always irish-tz, so ignore the display tz for this
        const timeIrl = dt.setZone('Europe/Dublin').toISOTime({suppressSeconds: false, suppressMilliseconds: true, includeOffset: false});
        magicTime = MAGIC_TIME[timeIrl] ?? false;
        // Handle the old magic times too (before lankel/lankel#54)
        if (!magicTime) {
            if (timeIrl === '14:30:01') magicTime = 'PM';
        }
    }
    const dtNow = DateTime.now().setZone(_opts.outTz || 'local');
    if (dtNow.toISODate() === dt.toISODate()) {
        //  it's today, just give the time
        return magicTime ? `Today ${magicTime}` : dt.toFormat('HH:mm');
    }
    if (_opts.useTomorrow && dtNow.plus({days: 1}).toISODate() === dt.toISODate()) {
        //  it's tomorrow
        return 'Tomorrow ' + (magicTime ? `${magicTime}` : dt.toFormat('HH:mm'));
    }
    if (_opts.useYesterday && dtNow.minus({days: 1}).toISODate() === dt.toISODate()) {
        //  it's yesterday
        return 'Yesterday ' + (magicTime ? `${magicTime}` : dt.toFormat('HH:mm'));
    }
    if (dtNow.minus({days: 6}) < dt) {
        //  less than 6 days ago, go with DOW HH:mm
        return magicTime ? `${dt.toFormat('EEE')} ${magicTime}` : dt.toFormat('EEE HH:mm');
    }
    return magicTime ? `${dt.toFormat('dd/MM/yy')} ${magicTime}` : dt.toFormat('dd/MM/yy HH:mm');
}

/**
 * Format a date or datetime string (YYYY-MM-DD or YYYY-MM-DD HH:mm:ss) as a simple date.
 *
 * @param {string} dt_str MYSQL date or datetime string (e.g. '2021-01-01 11:22:33') in `inTz` timezone.<br>
 *      NOTE: if `dt_str` is JUST a date (no time), then `opts.inTz` and `opts.outTz` will be ignored.
 * @param {object} opts Settings:<br>
 *    <ul><li>ucFirst - true to capitalise the first letter of the returned string.</li>
 *    <li>dateFmt - Luxon date format string to use instead of the default (which is to use the locale's short date format).</li>
 *    <li>inTz - The timezone of `dt_str`.  Defaults to 'utc'.  Ignored unless dt_str includes a time portion.</li>
 *    <li>outTz - The display timezone, defaults to 'local'.  Ignored unless dt_str includes a time potion.</li></ul>
 * @returns {string|string}
 */
const prettyDate = (dt_str, opts = {ucFirst: true, dateFmt: null, inTz: 'utc', outTz: 'local'}) => {
    const _opts = {
        ucFirst: true,
        dateFmt: null,
        inTz: 'utc',
        outTz: 'local',
        ...opts,
    }
    if (!dt_str || dt_str.trim() === '') return '';
    // Special case: if dt_str is JUST a date (no time), force both inTz and outTz to be 'utc' (so the date doesn't get changed)
    if (dt_str.length === 10) {
        _opts.inTz = 'utc';
        _opts.outTz = 'utc';
    }

    const dt = DateTime.fromSQL(dt_str, { zone: _opts.inTz || 'utc' }).setZone(_opts.outTz || 'local');
    const dtToday = DateTime.now().setZone(_opts.outTz || 'local');
    const dtTomw = dtToday.plus({days: 1});
    const dtYest = dtToday.minus({days: 1});
    if (dt.toISODate() === dtToday.toISODate()) return _opts.ucFirst ? 'Today' : 'today';
    if (dt.toISODate() === dtTomw.toISODate()) return _opts.ucFirst ? 'Tomorrow' : 'tomorrow';
    if (dt.toISODate() === dtYest.toISODate()) return _opts.ucFirst ? 'Yesterday': 'yesterday';
    if (!_opts.dateFmt) return dt.toLocaleString(DateTime.DATE_SHORT);
    return dt.toFormat(_opts.dateFmt);
}

/**
 * Possible values for ActionType.data_type (same as the enum in the database).
 */
const ACT_TYPE = {
    NONE: 'NONE',
    BOOL: 'BOOL',
    FLOAT: 'FLOAT',
    INT: 'INT',
    TEXT: 'TEXT',
    RECTEMP: 'RECTEMP',
    SETTEMP: 'SETTEMP',
    LEFT_DEPOT: 'LEFT_DEPOT',
    ARRIVED: 'ARRIVED',
    SHIPPED: 'SHIPPED',
    MONITOR: 'MONITOR',
    SETHUMID: 'SETHUMID',
    SETVENT: 'SETVENT',
    FLAG: 'FLAG',
    UNFLAG: 'UNFLAG',
    UNPLUG: 'UNPLUG',
};


/**
 * Labels for ACT_TYPE
 */
const ACT_TYPE_LABEL = {
    [ACT_TYPE.NONE]: 'No associated data',
    [ACT_TYPE.BOOL]: 'Yes/No',
    [ACT_TYPE.FLOAT]: 'Decimal number',
    [ACT_TYPE.INT]: 'Integer',
    [ACT_TYPE.TEXT]: 'Text',
    [ACT_TYPE.RECTEMP]: 'Record temperature',
    [ACT_TYPE.SETTEMP]: 'Update the Set Point',
    [ACT_TYPE.SETHUMID]: 'Update the Humidity Set Point',
    [ACT_TYPE.LEFT_DEPOT]: 'Left Depot',
    [ACT_TYPE.ARRIVED]: 'Record container as arrived',
    [ACT_TYPE.SHIPPED]: 'Record container as dispatched',
    [ACT_TYPE.MONITOR]: 'Daily Monitoring',
    [ACT_TYPE.SETVENT]: 'Change Vent Position',
    [ACT_TYPE.FLAG]: 'Flag for follow-up',
    [ACT_TYPE.UNFLAG]: 'Remove flag',
    [ACT_TYPE.UNPLUG]: 'Unplug-Only',
};

/**
 * ACT_TYPEs that can have associated data.
 *
 * @type {(string)[]}
 */
const ACT_TYPE_WITH_DATA = [ACT_TYPE.FLOAT, ACT_TYPE.INT, ACT_TYPE.TEXT, ACT_TYPE.RECTEMP, ACT_TYPE.SETTEMP,
    ACT_TYPE.SETHUMID, ACT_TYPE.SETVENT, ACT_TYPE.FLAG, ACT_TYPE.UNFLAG, ACT_TYPE.UNPLUG];

/**
 * ACT_TYPEs that can have an associated unit (e.g. degC).
 *
 * @type {(string)[]}
 */
const ACT_TYPE_WITH_UNIT = [ACT_TYPE.FLOAT, ACT_TYPE.INT, ACT_TYPE.SETTEMP, ACT_TYPE.RECTEMP, ACT_TYPE.SETHUMID];

/**
 * ACT_TYPEs that appear in the 'Other Actions' column of the spreadsheet (and hence have associated names).
 * Must match what's in \Models\ActionType::appearsInOtherActions().
 *
 * @type {(string)[]}
 */
const ACT_TYPE_WITH_SS_TERMS = [ACT_TYPE.ARRIVED, ACT_TYPE.SHIPPED, ACT_TYPE.NONE, ACT_TYPE.TEXT,
    ACT_TYPE.SETTEMP, ACT_TYPE.FLAG, ACT_TYPE.UNFLAG];

/**
 * This is the Port table (at least the default one) as key-value pairs.
 * Here as a const to use it as the default for dropdowns until the real one is loaded.
 *
 * @type {{IEDUB: string, IEBEL: string, IEORK: string}}
 */
const PORTS = {
    IEBEL: "Belfast",
    IEORK: "Cork",
    IEDUB: "Dublin",
};

/**
 * Times (in Irish tz) which signify 'AM', 'MD' and 'PM'.
 * Note: 1sec past their 'real' times, as this is impossible for users to enter directly.
 *
 * @type {object}
 */
const MAGIC_TIME = {
    '09:30:01': 'AM',
    '12:00:01': 'MD',
    // '14:30:01': 'PM',
    '18:00:01': 'PM',  // changed per lankel/lankel#54
};

/**
 * Convert a time (in Irish tz) to a slot (AM, MD, PM).
 *
 * @param {string} timeInIrl - Time in Irish tz, e.g. '11:45' or '13:01:23'
 * @returns {string|null}
 */
const actTimeToSlot = (timeInIrl) => {
    if (!timeInIrl) return null;
    if (timeInIrl < '11:45') return 'AM';
    if (timeInIrl < '15:00') return 'MD';
    return 'PM';
}

/**
 * Possible values for OrderContainer.vent.
 *
 * @type {{CLOSED: string, OPEN: string}}
 */
const VENT_POSNS = {
    OPEN: "Open",
    CLOSED: "Closed",
};

/**
 * Get the "blank" entry for a port.
 *
 * @param forPortCode
 * @returns {Promise<T | undefined>}
 */
async function getBlankDepot(forPortCode) {
    return db.Depot.where({port_code: forPortCode, entry_type: 'BLANK'}).first();
}

/**
 * Luxon date format for MySQL datetimes.
 *
 * e.g. DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT)
 * (instead of DateTime.utc().toSQL({includeOffset: false, includeZone: false}), as the latter includes milliseconds
 * by default, which MySQL will just trim off).
 *
 * @type {string}
 */
const LUXON_MYSQL_DATETIME_FMT = 'yyyy-MM-dd HH:mm:ss';


const recordUserAct = (info) => {
    // Just fire it over the wall.  We don't care about the response.
    axios.post('/home/sync/recordAction', info)
        .catch((err) => {
            console.error(err);
        });
}

export {setTitle, isReeferValid, formatReefer, prettyDateTime, prettyDate, getBlankDepot, ACT_TYPE, ACT_TYPE_LABEL,
    ACT_TYPE_WITH_DATA, ACT_TYPE_WITH_UNIT, ACT_TYPE_WITH_SS_TERMS, PORTS, MAGIC_TIME, VENT_POSNS,
    LUXON_MYSQL_DATETIME_FMT, recordUserAct, actTimeToSlot};
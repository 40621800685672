import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from 'notistack';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './components/Navigator';
import Header from './components/Header';
import theme from "./theme";
import {Route, Routes} from "react-router-dom";
import Login from "./containers/Login";
import Profile from "./containers/user/Profile";
import User from "./containers/adm/User";
import NoMatch from "./containers/NoMatch";
import AuthProvider from "./contexts/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import ResetPassword from "./containers/user/ResetPassword";
import EmailChange from "./containers/user/EmailChange";
import Home from "./containers/Home";
import Containers from "./containers/user/conts/Containers";
import Depot from "./containers/adm/Depot";
import CustomerType from "./containers/adm/CustomerType";
import BillingFreq from "./containers/adm/BillingFreq";
import Price from "./containers/adm/pr/Price";
import Customer from "./containers/adm/Customer";
import Invoice from "./containers/adm/in/Invoice";
import Invoiceable from "./containers/adm/in/Invoiceable";
import ActionType from "./containers/adm/ActionType";
import Order from "./containers/adm/Order";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import DexieProvider from "./contexts/DexieProvider";
import Worksheets from "./containers/user/sheets/Worksheets";
import About from "./containers/About";
import OrderContainer from "./containers/adm/oc/OrderContainer";
import Lane from "./containers/adm/Lane";
import ActionReport from "./containers/user/report/ActionReport";
import AutoAction from "./containers/adm/AutoAction";
import Device from "./containers/adm/Device";
import Unplugs from "./containers/user/unplugs/Unplugs";

const drawerWidth = 256;

export default function App() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [desktopOpen, setDesktopOpen] = React.useState(true);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        // console.log('handleDrawerToggle', isSmUp, desktopOpen, mobileOpen);
        isSmUp ? setDesktopOpen(!desktopOpen) : setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <AuthProvider>
                    <DexieProvider>
                        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                            <CssBaseline />
                            <Box
                                component="nav"
                                sx={{ width: { sm: desktopOpen ? drawerWidth : 'auto' }}}
                                // sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                            >
                                {isSmUp ? null : (
                                    <Navigator
                                        PaperProps={{ style: { width: drawerWidth } }}
                                        variant="temporary"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        onLinkClick={() => setMobileOpen(false)}
                                    />
                                )}

                                {desktopOpen ? <Navigator
                                    PaperProps={{style: {width: drawerWidth}}}
                                    sx={{display: {sm: 'block', xs: 'none'}}}
                                /> : null
                                }
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
                                <Header onDrawerToggle={handleDrawerToggle} />
                                <Box component="main" sx={{ flex: 1, bgcolor: '#eaeff1' }}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Routes>
                                            {/*These don't need login:*/}
                                            <Route path="/" element={<Home />} />
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/user/reset" element={<ResetPassword />} />

                                            {/*This needs login, but is not under /user*/}
                                            <Route path="/about" element={<ProtectedRoute role={"ROLE_USER"}><About /></ProtectedRoute>} />

                                            {/*These need ROLE_USER*/}
                                            <Route path="/user" element={<ProtectedRoute role={"ROLE_USER"} />}>
                                                <Route path="email-change" element={<EmailChange />} />
                                                <Route path="profile" element={<Profile />} />
                                                <Route path="containers" element={<Containers />} />
                                                <Route path="unplugs" element={<Unplugs />} />
                                                <Route path="worksheets" element={<Worksheets />} />
                                                <Route path="report/action" element={<ActionReport />} />
                                            </Route>

                                            {/*And these need ROLE_ADMIN*/}
                                            <Route path="/adm" element={<ProtectedRoute role={"ROLE_ADMIN"} />}>
                                                <Route path="terminal" element={<Depot />} />
                                                <Route path="lane" element={<Lane />} />
                                                <Route path="user" element={<User />} />
                                                <Route path="customer-type" element={<CustomerType />} />
                                                <Route path="billing-freq" element={<BillingFreq />} />
                                                <Route path="price" element={<Price />} />
                                                <Route path="customer" element={<Customer />} />
                                                <Route path="action-type" element={<ActionType />} />
                                                <Route path="order" element={<Order />} />
                                                <Route path="invoice" element={<Invoice />} />
                                                <Route path="invoiceable" element={<Invoiceable />} />
                                                <Route path="containers" element={<OrderContainer />} />
                                                <Route path="auto-action" element={<AutoAction />} />
                                            </Route>

                                            <Route path="/adm" element={<ProtectedRoute role={"ROLE_SUPER"} />}>
                                                <Route path="device" element={<Device />} />
                                            </Route>

                                            <Route path="*" element={<NoMatch />} />
                                        </Routes>
                                    </ErrorBoundary>
                                </Box>
                                {/*<Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>*/}
                                {/*    /!*<Copyright />*!/*/}
                                {/*</Box>*/}
                            </Box>
                        </Box>
                    </DexieProvider>
                </AuthProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

import * as React from 'react';
import {LUXON_MYSQL_DATETIME_FMT, setTitle} from "../../../common/shared";
import axios from "axios";
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Switch,
    TextField
} from "@mui/material";
import * as PropTypes from "prop-types";
import EuroIcon from '@mui/icons-material/Euro';
import {DateTime} from "luxon";
import {DataGrid, GridToolbarContainer} from "@mui/x-data-grid";
import ActionIcon from "../../../components/ActionIcon";
import EditDialog from "./EditDialog";

export default function Price() {
    const [error, setError] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [price, setPrice] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [actionTypes, setActionTypes] = React.useState([]);
    const [actionFilter, setActionFilter] = React.useState(0);
    const [actionFilters, setActionFilters] = React.useState([]) ;
    const [customerFilter, setCustomerFilter] = React.useState(0);
    const [customerFilters, setCustomerFilters] = React.useState([]) ;
    const [startDateFilter, setStartDateFilter] = React.useState();
    const [inactiveFilter, setInactiveFilter] = React.useState(false);
    const [priceQuantityFilter, setPriceQuantityFilter] = React.useState(1);


    React.useEffect(() => {
        setTitle('Prices');
        refreshCustomers();
        refreshActionTypes();
    }, []);

    const refreshPrice = React.useCallback(() => {
        let params = null;

        if (actionFilter || customerFilter || startDateFilter || priceQuantityFilter || inactiveFilter) {
            params = {actionType: actionFilter,customer: customerFilter, startDate : startDateFilter, priceQuantity:priceQuantityFilter, inactive:inactiveFilter};
        }

        axios.get('/admin/price', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setPrice(result.data.result);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }, [actionFilter, customerFilter, startDateFilter, priceQuantityFilter, inactiveFilter]);

    const refreshCustomers = () => {
        let params = {hide_inactive: true};
        axios.get('/admin/customer', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setCustomers(result.data.result);
                        let data = result.data.result;
                        data = [{'id':0, 'name':'-All-'}, ...data];
                        setCustomerFilters(data);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }

    const refreshActionTypes = () => {
        let params = null;
        params = {billable:true};
        axios.get('/admin/actionType', { params: params })
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setActionTypes(result.data.result);
                        let data = result.data.result;
                        data = [{'id':0, 'label':'-All-'}, ...data];
                        setActionFilters(data);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
    }


    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            action_type_id: null,
            customer_id: null,
            start_date: DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT), //.toSQL({includeZone: false, includeOffset: false}),
            comments:'',
            price_quantity: priceQuantityFilter,
            active:1
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshPrice(); //  easiest way to get the new data (but not really necessary, we could just modify users too)
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setPrice((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params, event, details) => {
        editRow(params.row);
    }

    const onActionFilterChange = (event) => {
        setActionFilter(event.target.value);
    }

    const onCustomerFilterChange = (event) => {
        setCustomerFilter(event.target.value);
    }

    const onStartDateFilterChange = (event) => {
        setStartDateFilter(event.target.value);
    }

    const onInactiveFilterChange = (event) => {
        setInactiveFilter(event.target.checked);
    }

    const onPriceQuantityFilterChange = (event) => {
        setPriceQuantityFilter(event.target.value);
    }


    React.useEffect(() => {
        refreshPrice();
    }, [ refreshPrice ]);

    const isLoaded = customers && actionTypes ;

    const columns = React.useMemo(() => [
            {field: 'id', headerName: 'ID', width: 50, type: 'number',
                valueGetter: ({value}) => parseInt(value)},
        {field: 'action_type_id', headerName: 'Action', flex: 1,
            renderCell: ({row}) => {
                return (
                    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                    <ActionIcon iconData={actionTypes.filter((at) => at.id === row.action_type_id)?.[0]?.icon_data}
                                sx={{color: actionTypes.filter((at) => at.id === row.action_type_id)?.[0]?.icon_color, alignSelf: 'center',mr: '8px'}}/>
                        <span>
                            {actionTypes.filter((at) => at.id === row.action_type_id)?.[0]?.label}
                        </span>
                    </Box>
                )
            }
        },

        {field: 'customer_id', headerName: 'Customer', flex: 1,
                valueGetter: ({row}) => customers.filter((c) => c.id === row.customer_id)?.[0]?.name},
            {field: 'start_date', headerName: 'Start Date', flex: 1},
            {field: 'price', headerName: 'Price €', type: 'number',
                valueFormatter: ({value}) => value == null ? '' : Number(value).toFixed(2) ,
                flex: 1},
            {field: 'comments', headerName: 'Comments', flex: 1},
            { field: 'active', headerName: 'Active', width: 60, type: 'boolean' },
        ], [customers,actionTypes],
    );

    const priceQuantityMarks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
    ];


    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={price}
                          loading={!isLoaded}
                          sx={{bgcolor: '#ffffff'}}
                          components={{
                              Toolbar: CustomToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  addPrice: addNewRow,
                                  actionFilter:actionFilter,
                                  actionFilters:actionFilters,
                                  onActionFilterChange:onActionFilterChange,
                                  customerFilter:customerFilter,
                                  customerFilters:customerFilters,
                                  onCustomerFilterChange:onCustomerFilterChange,
                                  startDateFilter:startDateFilter,
                                  onStartDateFilterChange:onStartDateFilterChange,
                                  inactiveFilter:inactiveFilter,
                                  onInactiveFilterChange:onInactiveFilterChange,
                                  priceQuantityMarks:priceQuantityMarks,
                                  priceQuantityFilter:priceQuantityFilter,
                                  onPriceQuantityFilterChange:onPriceQuantityFilterChange,

                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                            customers={customers}
                            actionTypes={actionTypes}
                            priceQuantityFilter={priceQuantityFilter}

                />
            }
        </Box>
    )
}

function CustomToolbar(props) {
    return (
        <GridToolbarContainer>

            <FormControl size="small" sx={{minWidth: "150px", mt: 3, mr: 1}}>
                <InputLabel id="action-filter-label">Action Type</InputLabel>
                <Select
                    labelId="action-filter-label"
                    id="action-filter"
                    value={props.actionFilter}
                    label="Action Type"
                    onChange={props.onActionFilterChange}
                >
                    {
                        props.actionFilters.map((vt) => <MenuItem key={vt.id} value={vt.id}>{vt.label}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl size="small" sx={{minWidth: "250px", mt: 3, mr: 1}}>
                <InputLabel id="customer-filter-label">Customer</InputLabel>
                <Select
                    labelId="customer-filter-label"
                    id="customer-filter"
                    value={props.customerFilter}
                    label="Customer"
                    onChange={props.onCustomerFilterChange}
                >
                    {
                        props.customerFilters.map((vt) => <MenuItem key={vt.id} value={vt.id}>{vt.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl size="small" sx={{minWidth: "150px", mt: 3, mr: 1}}>
            <TextField
                label="Start Date From"
                id="start-date-filter"
                type="date"
                size="small"
                //helperText="Date on which price becomes active"
                InputLabelProps={{
                    shrink: true,
                }}
                value={props.startDateFilter}
                onChange={props.onStartDateFilterChange}
            />
            </FormControl>

            <FormControlLabel
                control={
                    <Slider
                            onChange={props.onPriceQuantityFilterChange}
                           value={props.priceQuantityFilter}
                           step={1}
                           valueLabelDisplay="auto"
                           marks={props.priceQuantityMarks}
                           min={1}
                           max={5}
                           />
                        }
                label="Sliding Price Quantity "
                labelPlacement="top"
            />

            <FormControlLabel
                control={<Switch id="inactive-filter" checked={props.inactiveFilter} onChange={props.onInactiveFilterChange} />}
                label="Show Inactive Records"
                labelPlacement="top"
            />

            <Button aria-label="Add Price Record" size="small" startIcon={<EuroIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addPrice}> New Price Record</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    addPrice: PropTypes.func.isRequired,
    actionFilter: PropTypes.number,
    actionFilters: PropTypes.array.isRequired,
    onActionFilterChange: PropTypes.func.isRequired,
    customerFilter: PropTypes.number,
    customerFilters: PropTypes.array.isRequired,
    onCustomerFilterChange: PropTypes.func.isRequired,
    priceQuantityMarks: PropTypes.array,
    priceQuantityFilter:PropTypes.number,
    onPriceQuantityFilterChange: PropTypes.func.isRequired,

}


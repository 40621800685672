import React from "react";
import axios from "axios";
import {DataGrid} from "@mui/x-data-grid";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as PropTypes from "prop-types";

export default function InvoiceLinesDialog(props) {
    const record = props.record;
    const [invoiceLines, setInvoiceLines] = React.useState([]);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (record) {
            axios.get('/admin/invoice/lines', {params: {invoiceId: record.id}})
                .then(
                    (result) => {
                        if (result.data.error) {
                            setError(result.data);
                        }else{
                            setInvoiceLines(result.data.result);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
        }
    }, [record])

    const handleClose = () => {
        props.onCancel();
    }

    const columns = React.useMemo(() => [
        {field: 'id', headerName: 'Line #', width: 60, type: 'number', valueGetter: ({value}) =>value},
        {field: 'label', headerName: 'Action Type', width: 150, valueGetter: ({ row }) =>  row.actionType.label},
        {field: 'reefer_num', headerName: 'Reefer', width: 150,  valueGetter: ({ row }) =>  row.orderContainer.reefer_num},
        {field: 'order_id', headerName: 'Shipping List ID', width: 150,  valueGetter: ({ row }) =>  row.orderContainer.order_id},
        {field: 'booking_ref', headerName: 'Booking Ref', width: 150,  valueGetter: ({ row }) =>  row.orderContainer.booking_ref},
        {field: 'ship', headerName: 'Ship', width: 150,  valueGetter: ({ row }) =>  row.orderContainer.ship},
        {field: 'action_date', headerName: 'Action Date', width: 150,  valueGetter: ({ row }) =>  row.action.action_date},
        {field: 'sail_date', headerName: 'Vessel Date', width: 150,  valueGetter: ({ row }) =>  row.orderContainer.sail_date},
        {field: 'price_id', headerName: 'Price Code', width: 100, type: 'number', valueGetter: ({ row }) =>  row.price_id},
        {field: 'price', headerName: 'Price', width: 100, type: 'number', valueGetter: ({ row }) =>  row.price},
    ], []);

    return (
        <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth='xl'>
            <DialogTitle>Invoice Details for Invoice # {record.id} </DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <Box sx={{
                    height: 600, width: '100%',
                    '& .reefer-invalid': {
                        bgcolor: 'rgb(253, 237, 237)'
                    }
                }}>
                    <DataGrid
                        id='invoiceLinesDataGrid'
                        density="compact"
                        columns={columns}
                        rows={invoiceLines}
                        //loading={!isLoaded}
                        sx={{
                            border: 0,
                            //  Remove the 16px left & right padding on cells during edit
                            cell: { padding: 0 },
                            'cell--editing': { padding: 0 },
                            cellContent: { padding: 0 },    // @todo: fix these
                            'cell--textLeft': { padding: 0 },
                        }}
                        //loading={record.ocs == null}


                        getRowId={(r) => r.id}
                        //hideFooter={record.ocs == null ? true : record.ocs.length < 100}
                        pageSize={100}
                        //disableColumnFilter={true}
                        disableDensitySelector={true}
                        disableColumnMenu={true}
                        disableSelectionOnClick={true}
                        //editMode="row"
                        //experimentalFeatures={{newEditingApi: true}}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

InvoiceLinesDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
}
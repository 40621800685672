import React from "react";
import {useSearchParams} from "react-router-dom";
import {Box, Button, TextField, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import {AuthContext} from "../../contexts/AuthProvider";
import axios from "axios";
import {useSnackbar} from "notistack";
import {setTitle} from "../../common/shared";

export default function ResetPassword(props) {

    React.useEffect(() => {
        setTitle('Reset Password');
    }, []);

    const [searchParams] = useSearchParams();
    const [email, setEmail] = React.useState(searchParams.get('email') || "");
    const [passwords, setPasswords] = React.useState({password1: '', password2: ''})
    const [message, setMessage] = React.useState(props.message || "");
    const code = searchParams.get('code');
    const [loading, setLoading] = React.useState(false);
    // const [isSent, setIsSent] = React.useState(false);
    // const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    //  Steps are:
    //  email - entry of email address
    //  sent - email sent (no further action possible on the page at this point)
    //  code - got a code (from the email link), and we're verifying it
    //  pass - new password is being entered.
    //  done - new password has been set.
    const [step, setStep] = React.useState(code ? 'code': 'email');

    React.useEffect(() => {
        if (code) {
            setLoading(true);
            axios.post('/auth/reset/checkCode', {code})
                .then(
                    (response) => {
                        if (response.data?.isValid) {
                            setStep('pass');
                        }
                        else {
                            //  If the code is invalid, we send them back to the email stage with a message
                            setMessage(response.data?.message || response.data?.message ||
                                'Invalid or expired link, please try again.');
                            setStep('email');
                        }
                    },
                    (error) => {
                        setMessage(error?.response?.data.message || error.message || error.toString());
                        setStep('email');
                    }
                )
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [code]);


    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const onChangePasswords = (e) => {
        setPasswords((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
    }

    async function onResetClick(event) {
        event.preventDefault();
        setMessage("");
        setLoading(true);

        axios.post('/auth/reset/send', {email})
            .then(
                (response) => {
                    if (response.data?.success) {
                        setStep('sent');
                        setMessage(response.data.message || 'Email Sent.');
                    }
                    else {
                        setMessage(response.data?.message || response.data?.message ||
                            'Failed to send message.');
                    }
                },
                (error) => {
                    setMessage(error?.response?.data.message || error.message || error.toString());
                })
            .finally(() => {
                setLoading(false);
            });
    }

    async function onSetPasswordClick(event) {
        event.preventDefault();
        setMessage("");
        setLoading(true);

        axios.post('/auth/reset/setPassword', {
                code,
                password: passwords.password1
            })
            .then(
                (response) => {

                    if (response.data?.success) {
                        auth.setToken(response.data.token);
                        setStep('done');
                        setMessage(response.data.message || 'Password Updated.');
                        enqueueSnackbar(response.data.message || 'Password Updated.', {
                            variant: 'success'
                        });
                        // auth.checkLogin()
                        //     .then(() => {
                        //         setTimeout(() => {
                        //             navigate('/')
                        //         }, 10000);
                        //     });
                    }
                    else {
                        setMessage(response.data?.message || response.data?.message ||
                            'Failed to set password.');
                    }
                },
                (error) => {
                    setMessage(error?.response?.data.message || error.message || error.toString());
                })
            .finally(() => {
                setLoading(false);
            });
    }

    switch(step) {
        case 'done':
            //  Password has been set.
            return (
                <>
                    <Box sx={{maxWidth: 400, marginTop: 10, marginX: 'auto'}}>
                        <Typography variant="h6" component="div" align="center">
                            Password Saved
                        </Typography>
                        <Typography variant="p" component="div" color="text.disabled" align="center">
                            Your password has been changed.
                        </Typography>
                    </Box>
                    {/*<Snackbar open={true} autoHideDuration={6000}>*/}
                    {/*    <Alert severity="success">{message}</Alert>*/}
                    {/*</Snackbar>*/}
                </>
            );
        case 'pass':
            //  Code is verified (and valid), ask them for their new password (twice)
            return (
                <Box sx={{maxWidth: 400, marginTop: 10, marginX: 'auto'}}>
                    <form className="frmReset">
                        <Typography variant="h6" component="div" align="center">
                            Enter New Password
                        </Typography>
                        {
                            message ? <Typography variant="p" color="error">{message}</Typography> :
                                <Typography variant="p">Please enter your new password below.</Typography>
                        }
                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="password1"
                            name="password1"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            required={true}
                            value={passwords.password1}
                            onChange={onChangePasswords}
                            disabled={loading}
                        />
                        <TextField
                            margin="dense"
                            id="password2"
                            name="password2"
                            label="Verify Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            required={true}
                            value={passwords.password2}
                            onChange={onChangePasswords}
                            disabled={loading}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<SaveIcon/>}
                            color="primary"
                            fullWidth
                            onClick={onSetPasswordClick}
                            disabled={loading ||
                                (passwords.password1 !== passwords.password2) ||
                                (!passwords.password1)}
                            sx={{marginTop: '10px'}}
                        >Set new Password</Button>
                    </form>
                </Box>
            );
        case 'code':
            //  Got a code from the email, and we're currently verifying it.
            return (
                <Box sx={{maxWidth: 400, marginTop: 10, marginX: 'auto'}}>
                    <Typography variant="h6" component="div" align="center">
                        Verifying
                    </Typography>
                    <Typography variant="p" component="div" color="text.disabled" align="center">{
                        message || 'We\'re currently checking the link you have followed, please wait a moment...'
                    }</Typography>
                </Box>
            );
        case 'sent':
            //  If the email is sent, there's nothing more to do on this screen.
            return (
                <Box sx={{maxWidth: 400, marginTop: 10, marginX: 'auto'}}>
                    <Typography variant="h6" component="div" align="center">
                        Password Reset Email Sent
                    </Typography>
                    <Typography variant="p" component="div" color="text.disabled" align="center">{message}</Typography>
                </Box>
            );
        default:
        case 'email':
            return (
                <Box sx={{maxWidth: 400, marginTop: 10, marginX: 'auto'}}>
                    <form className="frmReset">
                        <Typography variant="h6" component="div" align="center">
                            Reset Password
                        </Typography>
                        {
                            message ? <Typography variant="p" color="error">{message}</Typography> :
                                <Typography variant="p">We can send you an email to reset your password.<br/>
                                    Please enter your email address below.</Typography>
                        }
                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="standard"
                            required={true}
                            value={email}
                            onChange={onChangeEmail}
                            disabled={loading}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<SendIcon/>}
                            color="primary"
                            fullWidth
                            onClick={onResetClick}
                            disabled={loading || !email}
                            sx={{marginTop: '10px'}}
                        >Send Password Reset</Button>
                    </form>
                </Box>
            );
    }
}
import {useSnackbar} from "notistack";
import * as React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";
import * as PropTypes from "prop-types";

export default function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const isNew = !record.id;

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        console.log(record);
        if (record) {
            axios.post('/admin/price/save', record)
                .then(
                    (result) => {
                        //console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Price Record Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.price);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/price/delete', record)
            .then(
                (result) => {
                    //console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Price Record Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }))
    }

    let isValid = (!!record.action_type_id && !!record.price && !!record.start_date);

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>{isNew ? 'New': 'Edit'} Price Record</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }

                <FormControl size="small" sx={{minWidth: "150px", mt: 3, mr: 1}}>
                    <InputLabel id="action-type-id-label">Action</InputLabel>
                    <Select
                        labelId="action-type-id-label"
                        id="action-type-id"
                        name="action_type_id"
                        value={record.action_type_id}
                        label="Action"
                        onChange={onFormChange}
                    >
                        {
                            props.actionTypes.filter(at => at.billable === 1).map((at) => <MenuItem key={at.id} value={at.id}>{at.label}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{minWidth: "150px", mt: 3, mr: 1}}>
                    <InputLabel id="customer-id-label">Customer</InputLabel>
                    <Select
                        labelId="customer-id-label"
                        id="customer-id"
                        name="customer_id"
                        value={record.customer_id}
                        label="Customer"
                        onChange={onFormChange}
                    >
                        {
                            props.customers.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Start Date"
                    id="start_date"
                    name="start_date"
                    type="date"
                    fullWidth
                    helperText="Date on which price becomes active"
                    value={record.start_date}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={onFormChange}
                />

                <TextField
                    margin="dense"
                    id="price_quantity"
                    name="price_quantity"
                    label="Sliding Price Quantity"
                    helperText="Quantity for which this Price applies"
                    type="number"
                    fullWidth
                    variant="standard"
                    required={true}
                    defaultValue={props.priceQuantityFilter}
                    //value=record.price_quantity
                    //onChange={onFormChange}
                />

                <TextField
                    margin="dense"
                    id="price"
                    name="price"
                    label="Price €"
                    helperText="Price to apply for this Action"
                    type="number"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={record.price}
                    onChange={onFormChange}
                />
                <TextField
                    margin="dense"
                    id="comments"
                    name="comments"
                    label="Comments"
                    helperText="Multi-line text allowed here."
                    type="text"
                    multiline
                    fullWidth
                    variant="standard"
                    required={true}
                    value={record.comments}
                    onChange={onFormChange}
                />
                <FormControlLabel
                    control={<Switch name="active" checked={record.active} onChange={onFormChange} />}
                    label="Active"
                />
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New Price Record' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    actionTypes: PropTypes.array.isRequired,
    priceQuantityFilter:PropTypes.number.isRequired

}
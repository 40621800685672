import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../common/db";
import {PORTS, setTitle} from "../../common/shared";
import {AuthContext} from "../../contexts/AuthProvider";
import {DataGrid, GridToolbarContainer} from "@mui/x-data-grid";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select,
    TextField, useTheme
} from "@mui/material";
import axios from "axios";
import {useSnackbar} from "notistack";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as PropTypes from "prop-types";
import LaneIcon from "../../icons/LaneIcon";

export default function Lane() {
    const auth = React.useContext(AuthContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [portCode, setPortCode] = React.useState(auth.getDefaultPort() || 'IEDUB');
    const [depotId, setDepotId] = React.useState(null);
    const [laneGroupId, setLaneGroupId] = React.useState(null);
    const [lanes, setLanes] = React.useState([]);
    const [editGroupRecord, setEditGroupRecord] = React.useState(null);
    const [groupDialogOpen, setGroupDialogOpen] = React.useState(false);
    const [editLaneRecord, setEditLaneRecord] = React.useState(null);
    const [laneDialogOpen, setLaneDialogOpen] = React.useState(false);

    // We get away with using dexie for these two as they never change on this screen.
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {})),
        [], PORTS);
    const depots = useLiveQuery(() => db.Depot.toArray(), [], []);

    // This one however does change, so we need to use the api.
    const [laneGroups, setLaneGroups] = React.useState([]);
    const refreshLaneGroups = React.useCallback(async () => {
        try {
            const res = await axios.get(`/admin/lane/groups`);
            if (res.data?.error) setError(res.data?.message || 'Failed to retrieve lane groups');
            else setLaneGroups(res.data.result);
        } catch (e) {
            setError(e);
        }
    }, []);

    const refreshLanes = React.useCallback(async () => {
        setIsLoaded(false);
        const params = {
            portCode,
            depotId,
            laneGroupId
        };
        axios.get(`/admin/lane`, {params})
            .then(
                (result) => {
                    console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setLanes(result.data.result);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => setIsLoaded(true));
    }, [portCode, depotId, laneGroupId]);


    React.useEffect(() => {
        setTitle("Lanes");
    }, []);

    React.useEffect(() => {
        refreshLaneGroups();
    }, [refreshLaneGroups]);

    React.useEffect(() => {
        refreshLanes()
    }, [refreshLanes]);

    const handleAddLane = () => {
        console.log("handleAddLane");

        if (laneGroupId) {
            setEditLaneRecord({
                depot_id: depotId,
                lane_group_id: laneGroupId,
                name: '',
                deleted: 0,
            });
            setLaneDialogOpen(true);
        }
    }

    const handleAddGroup = () => {
        console.log("handleAddGroup");

        if (depotId) {
            setEditGroupRecord({
                depot_id: depotId,
                name: '',
                sort_order: 100,
            });
            setGroupDialogOpen(true);
        }
    }

    const handleEditGroup = (record) => {
        if (record) {
            setEditGroupRecord(record);
            setGroupDialogOpen(true);
        }
    }

    const handleEditLane = (record) => {
        if (record) {
            handleFilterChange(null, record.depot_id, record.lane_group_id, 'laneGroup');
            setEditLaneRecord(record);
            setLaneDialogOpen(true);
        }
    }

    const handleFilterChange = (_portCode, _depotId, _laneGroupId, whatChanged='port') => {
        console.log('handleFilterChange', _portCode, _depotId, _laneGroupId, whatChanged);

        // Start with the defaults from the parent scope.
        let newPortCode = portCode;
        let newDepotId = depotId;
        let newLaneGroupId = laneGroupId;

        if (whatChanged === 'port') {
            // if the port changed, everything else gets set to null
            newPortCode = _portCode;
            newDepotId = null;
            newLaneGroupId = null;
        }
        else if (whatChanged === 'depot') {
            newDepotId = _depotId;
            // When the depot changes, clear the lane group, and correct the port if necessary
            newLaneGroupId = null
            const correctPortCode = depots.find(d => d.id === newDepotId)?.port_code;
            if (correctPortCode) newPortCode = correctPortCode;
        }
        else if (whatChanged === 'laneGroup') {
            // when the lane group changes, we just need to make sure that the correct depot and port are selected
            newLaneGroupId = _laneGroupId;
            const correctDepotId = laneGroups.find(lg => lg.id === newLaneGroupId)?.depot_id;
            if (correctDepotId) newDepotId = correctDepotId;
            const correctPortCode = depots.find(d => d.id === correctDepotId)?.port_code;
            if (correctPortCode) newPortCode = correctPortCode;
        }

        setPortCode(newPortCode);
        setDepotId(newDepotId);
        setLaneGroupId(newLaneGroupId);
    }

    const columns = React.useMemo(() => [
        {field: 'id', headerName: 'ID', type: 'number', width: 80},
        {field: 'name', headerName: 'Name/Number', minWidth: 100, flex: 1},
        {field: 'port', headerName: 'Port', minWidth: 100, flex: 1, valueGetter: ({value}) => value?.name},
        {field: 'depot', headerName: 'Terminal', minWidth: 100, flex: 1, valueGetter: ({value}) => value?.name},
        {field: 'laneGroup', headerName: 'Group', minWidth: 100, flex: 1, valueGetter: ({value}) => value?.name},
    ], []);

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={lanes}
                          density="compact"
                          loading={!isLoaded}
                          error={error}
                          sx={{
                              bgcolor: '#ffffff',
                              borderRadius: 0,
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                          }}
                          components={{
                              Toolbar: Toolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  portCode,
                                  depotId,
                                  laneGroupId,
                                  handleFilterChange,
                                  handleAddLane,
                                  handleAddGroup,
                                  handleEditGroup,
                                  ports, depots, laneGroups
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={(params) => handleEditLane(params.row)}
                          initialState={{
                              columns: {
                                  columnVisibilityModel: {
                                      id: false,
                                  },
                              },
                          }}
                          // columnVisibilityModel={colVis}
                          // onColumnVisibilityModelChange={onColVisChange}
                          // initialState={{
                          //     sorting: {
                          //         sortModel: [{field: 'id', sort: 'desc'}]
                          //     }
                          // }}
                />
                {
                    groupDialogOpen && editGroupRecord &&
                    <EditGroupDialog
                        record={editGroupRecord}
                        onCancel={() => setGroupDialogOpen(false)}
                        onSave={(record) => {
                            setGroupDialogOpen(false);
                            refreshLaneGroups().then(() => {
                                setLaneGroupId(record.id);
                                refreshLanes();
                            });
                        }}
                        onDelete={(record) => {
                            setGroupDialogOpen(false);
                            setLaneGroupId(null);
                            refreshLaneGroups();
                        }}
                        />
                }
                {
                    laneDialogOpen && editLaneRecord &&
                    <EditLaneDialog
                        record={editLaneRecord}
                        onCancel={() => setLaneDialogOpen(false)}
                        onSave={(record) => {
                            setLaneDialogOpen(false);
                            refreshLanes();
                        }}
                        onDelete={(record) => {
                            setLaneDialogOpen(false);
                            refreshLanes();
                        }}
                        laneGroups={laneGroups}
                        />
                }
            </Box>
        </Box>
    );
}

function Toolbar(props) {
    const {portCode, depotId, laneGroupId, handleFilterChange, handleAddLane, handleAddGroup, handleEditGroup,
        ports, depots, laneGroups} = props;


    const onPortChange = (newPort) => {
        handleFilterChange(newPort, null, null, 'port');
    }

    const onDepotChange = (newDepot) => {
        handleFilterChange(null, newDepot, null, 'depot');
    }

    const onLaneGroupChange = (newLaneGroup) => {
        handleFilterChange(null, null, newLaneGroup, 'laneGroup');
    }

    const useVariant = 'standard';

    return (
        <GridToolbarContainer>
            <FormControl size={"small"} variant={useVariant} sx={{minWidth: "100px", ml: 1}}>
                <InputLabel id="port-id-label">Port</InputLabel>
                <Select
                    labelId="port-id-label"
                    id="port-id"
                    value={portCode}
                    label="Port"
                    onChange={(e) => onPortChange(e.target.value)}
                >
                    {
                        Object.entries(ports).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl size={"small"} variant={useVariant} sx={{minWidth: "150px", ml: 1}}>
                <InputLabel id="depot-id-label">Terminal/Depot</InputLabel>
                <Select
                    labelId="depot-id-label"
                    id="depot-id"
                    value={depotId || ''}
                    label="Terminal/Depot"
                    onChange={(e) => onDepotChange(e.target.value)}
                >
                    {
                        depots.filter(d => d.port_code === portCode)
                            //  remove 'blank' entries
                            .filter(d => d.entry_type !== 'BLANK')
                            // This puts the TERMINALs first, then DEPOTs, then sorts within each by name
                            .sort((a, b) => -1*a.entry_type.localeCompare(b.entry_type) || a.name.localeCompare(b.name))
                            .map((d, i, arr) => <MenuItem
                                divider={i !== arr.length - 1 && arr[i+1].entry_type !== d.entry_type}
                                key={d.id} value={d.id}>{d.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <FormControl size={"small"} variant={useVariant} sx={{minWidth: "150px", ml: 1}}>
                <InputLabel id="lane-group-id-label">Lane Group</InputLabel>
                <Select
                    labelId="lane-group-id-label"
                    id="lane-group-id"
                    value={laneGroupId || ''}
                    label="Lane Group"
                    onChange={(e) => onLaneGroupChange(e.target.value)}
                >
                    {
                        laneGroups.filter(l => l.depot_id === depotId && !l.deleted)
                            .sort((a, b) => (a.sort_order - b.sort_order) || a.name.localeCompare(b.name))
                            .map((d, i, arr) => <MenuItem
                                divider={i === arr.length - 1}
                                key={d.id} value={d.id}>{d.name}</MenuItem>)
                    }
                    {depotId && <MenuItem
                        key="add-grp" value=""
                        onClick={() => handleAddGroup(depotId)}
                    >- Add a new Group -</MenuItem>}
                </Select>
            </FormControl>
            { laneGroupId &&
                <Button
                    aria-label="Edit Group" size="small"
                    onClick={() => handleEditGroup(laneGroups.find(l => l.id === laneGroupId))}
                >Edit Group</Button>
            }

            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            {/*<GridToolbarColumnsButton sx={{ml:'auto'}} />*/}
            {laneGroupId && <Button aria-label="Add Lane" size="small"
                                    sx={{ml: 'auto'}}
                                    startIcon={<LaneIcon />}
                                    onClick={handleAddLane}> New Lane</Button>
            }
        </GridToolbarContainer>
    );
}

function EditLaneDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isNew = !record.id;

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {

            // Make a copy of record so we can sanitize some data
            let rec = {
                ...record
            };


            axios.post('/admin/lane/save', rec)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Lane Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.laneGroup);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/lane/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Lane Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value
        }))
    }

    let isValid = (!!record.name);
    //console.log(depots);

    return (
        <Dialog open={true} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="sm">
            <DialogTitle>{isNew ? 'New': 'Edit'} Lane</DialogTitle>
            <DialogContent dividers={true}>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Lane Name"
                    sx={{mr: 1}}
                    helperText="Name/Number of the lane"
                    type="text"
                    // fullWidth
                    inputProps={{ maxLength: 250 }}
                    variant="standard"
                    value={record.name || ''}
                    onChange={onFormChange}
                />
                {props.laneGroups && <TextField
                    id="lane_group_id"
                    name="lane_group_id"
                    sx={{mr: 1}}
                    value={record.lane_group_id || ''}
                    select
                    label="Group"
                    helperText="Group lane is in"
                    variant="standard"
                    margin="dense"
                    onChange={onFormChange}
                >
                    {
                        props.laneGroups
                            .filter(d => !d.deleted)
                            .filter(d => d.depot_id === record.depot_id)
                            .sort((a, b) => (a.sort_order - b.sort_order) || a.name.localeCompare(b.name))
                            .map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
                    }
                </TextField>}
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New Lane' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}
EditLaneDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    laneGroups: PropTypes.array.isRequired,
};

function EditGroupDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState(props.record);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isNew = !record.id;
    // const depots = useLiveQuery(() => db.Depot.toArray(), []);

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {

            // Make a copy of record so we can sanitize some data
            let rec = {
                ...record
            };


            axios.post('/admin/lane/saveGroup', rec)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Group Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.laneGroup);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/lane/deleteGroup', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Group Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value
        }))
    }

    let isValid = (!!record.name);

    return (
        <Dialog open={true} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="sm">
            <DialogTitle>{isNew ? 'New': 'Edit'} Lane Group</DialogTitle>
            <DialogContent dividers={true}>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Group Name"
                    helperText="Name of the group of lanes"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 250 }}
                    variant="standard"
                    value={record.name || ''}
                    onChange={onFormChange}
                />
                {/*{depots && <TextField*/}
                {/*    id="depot_id"*/}
                {/*    name="depot_id"*/}
                {/*    sx={{mr: 1}}*/}
                {/*    value={record.depot_id}*/}
                {/*    select*/}
                {/*    label="Terminal/Depot"*/}
                {/*    helperText="Group belongs to this terminal/depot"*/}
                {/*    variant="standard"*/}
                {/*    margin="dense"*/}
                {/*    onChange={onFormChange}*/}
                {/*>*/}
                {/*    {*/}
                {/*        depots*/}
                {/*            .filter(d => d.entry_type !== 'BLANK')*/}
                {/*            .filter(d => d.port_code === depots.find(p => p.id === record.depot_id)?.port_code)*/}
                {/*            .sort((a, b) => -1*a.entry_type.localeCompare(b.entry_type) || a.name.localeCompare(b.name))*/}
                {/*            .map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)*/}
                {/*    }*/}
                {/*</TextField>}*/}
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? 'New Group' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}
EditGroupDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};
import {useSnackbar} from "notistack";
import * as React from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";


export default function ProcessContainersDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);

    const invStatuses = [
        {'status':'NO','label':'NO'},
        {'status':'YES','label':'YES'},
        {'status':'IGNORE','label':'IGNORE'}
    ]

    const [containerInvoiceStatus, setContainerInvoiceStatus] = React.useState('');

    const onContainerInvoiceStatusChange = (event) => {
        setContainerInvoiceStatus(event.target.value);
        console.log(containerInvoiceStatus);
    }

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if(props.containerSelection && containerInvoiceStatus) {
            let data = {
                targetStatus:containerInvoiceStatus,
                containers: props.containerSelection
            };
            setSaving(true);
            axios.post('/admin/invoice/updateContainerInvoiceStatus', data)
                .then(
                    (result) => {
                        //console.log(result);
                        if (result.data.error) {
                            setError(result.data);
                        } else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Price Record Saved', {
                                variant: result.data.warning ? 'warning' : 'success'
                            });
                            props.onSave(result.data.price);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }else{
            console.log('failed - nothing selected');
        }
    }

    let numberSelected = props.containerSelection.length;
    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle> Update Status for Selected Containers</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <p>You are about to change the Invoice Status of the <strong>{numberSelected} selected records</strong><br/>
                    Choose the target status from the dropdown list below</p>
                <FormControl size="small" sx={{minWidth: "150px", mt: 3, mr: 1}}>
                    <InputLabel id="container-invoice-status-label">Target Status</InputLabel>
                    <Select
                        labelId="container-invoice-status-label"
                        id="container-invoice-status"
                        label="Target Status"
                        value={containerInvoiceStatus}
                        onChange={onContainerInvoiceStatusChange}
                    >
                        {
                            invStatuses.map((sf) => <MenuItem key={sf.status} value={sf.status}>{sf.label}</MenuItem>)
                        }
                    </Select>
                </FormControl>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={saving}>Update Status</Button>
            </DialogActions>
        </Dialog>
    );
}

ProcessContainersDialog.propTypes = {
    containerSelection:PropTypes.array,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
}

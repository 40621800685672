import React from "react";
import {Dialog, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PropTypes from "prop-types";
import ActionIcon from "../../../components/ActionIcon";


export default function SelectActionDialog(props) {
    const { actions, onAction, onCancel, onShowDetails } = props

    return (
        <Dialog onClose={() => onCancel()} open={true}>
            <List sx={{ py: 0 }}>
                {actions?.map((action, i) =>
                    <ListItemButton
                        onClick={() => onAction(action)}
                        key={action.id}
                        title={action.action_message}
                        divider={i === actions.length - 1}
                    >
                        <ListItemIcon><ActionIcon
                            iconData={action.icon_data}
                            // fontSize="inherit"
                            sx={{
                                color: action.icon_color || '#000000',
                            }}
                        /></ListItemIcon>
                        <ListItemText primary={action.label} />
                    </ListItemButton>
                )}
                {
                    onShowDetails &&
                    <ListItemButton onClick={() => onShowDetails()} key="actDetails" divider={true}>
                        <ListItemText primary="Show Details" primaryTypographyProps={{ color: 'blue'}} />
                    </ListItemButton>
                }
                <ListItemButton onClick={() => onCancel()} key="actCancel">
                    <ListItemText primary="Cancel" primaryTypographyProps={{ color: 'orange'}} />
                </ListItemButton>
            </List>
        </Dialog>
    );
}

SelectActionDialog.propTypes = {
    actions: PropTypes.array.isRequired,
    onAction: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onShowDetails: PropTypes.func
}
import React from "react";
import {createSearchParams, useLocation, useNavigate} from "react-router-dom";
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import {AuthContext} from "../contexts/AuthProvider";
import {setTitle} from "../common/shared";

export default function Login(props) {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const locn = useLocation();
    const auth = React.useContext(AuthContext);
    const [message, setMessage] = React.useState(props.message ||
        locn.state?.loginMessage ||
        (auth.isLoggedIn() ? 'You are already logged-in, but you can login as a different user below.': ""));
    const navigate = useNavigate();

    React.useEffect(() => {
        setTitle('Login');
    }, []);


    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    async function onLoginClick(event) {
        //alert('do login');
        event.preventDefault();
        setMessage("");
        setLoading(true);

        auth.login(username, password).then(
            () => {
                //  If there's a locn.state.from, we send them there.
                let goTo = "/";
                const from = locn.state?.from;
                if (from) {
                    goTo = from.pathname;
                    if (from.search) {
                        goTo += from.search;
                    }
                    if (from.hash) {
                        goTo += locn.hash;
                    }
                    //  Also clear the `from` from the location, so we don't end up in a loop
                    window.history.replaceState({
                        ...locn.state,
                        loginMessage: undefined,
                        from: undefined
                    }, document.title);
                }
                navigate(goTo);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        )
    }

    const onResetPassword = () => {
        if (username) {
            navigate({
                pathname: '/user/reset',
                search: createSearchParams({
                    email: username
                }).toString()
            })
        }
        else {
            navigate("/user/reset");
        }
    }

    return (
        <Box sx={{maxWidth: 400, marginTop: 10, marginX: 'auto'}}>
            <form className="frmLogin">
                <Typography variant="h6" component="div" align="center">
                    Login
                </Typography>
                {
                    message && <Typography variant="p" color="error">{message}</Typography>
                }
                <TextField
                    autoFocus={true}
                    margin="dense"
                    id="username"
                    name="username"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={username}
                    onChange={onChangeUsername}
                    disabled={loading}
                />
                <TextField
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={password}
                    onChange={onChangePassword}
                    disabled={loading}
                />
                <Button
                    type="submit"
                    variant="contained"
                    endIcon={<LoginIcon />}
                    color="primary"
                    fullWidth
                    onClick={onLoginClick}
                    disabled={loading}
                    sx={{marginTop: '10px'}}
                >Login</Button>
            </form>
            <Divider variant="middle" sx={{my: 2}}/>
            <Button onClick={onResetPassword}>Reset Password</Button>
        </Box>
    );
}
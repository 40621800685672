import {useSnackbar} from "notistack";
import * as React from "react";
import axios from "axios";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as PropTypes from "prop-types";

export default function GenerateInvoicesDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(null);
    const [running, setRunning] = React.useState(false);

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        setRunning(true);
        axios.post('/admin/invoice/generateInvoices')
            .then(
                (result) => {
                    //console.log(result);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Invoices Generated successfully', {
                            variant: result.data.warning ? 'warning' : 'success'
                        });
                        props.onSave(result.data.price);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                //setSaving(false);
                setRunning(false);
            });
    }

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle> Generate Invoices</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                {   !error && (
                <Alert severity="warning">
                    Generate invoices for all invoiceable containers with <br/>
                    <strong>Invoice Status = YES</strong>, this is not reversible.
                </Alert> )
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {   !error && (
                    <Button onClick={handleSave} disabled={running} >Generate Invoices</Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

GenerateInvoicesDialog.propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
}

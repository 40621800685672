import React from "react";
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import {prettyDateTime, setTitle} from "../../common/shared";
import axios from "axios";
import {Alert, Box, LinearProgress} from "@mui/material";
import {db} from "../../common/db";
import {useLiveQuery} from "dexie-react-hooks";
import PropTypes from "prop-types";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {useSnackbar} from "notistack";

// This is the key in localstorage where we save the column settings
const COLS_KEY = 'admin/Device/cols';
const NUM_ROWS_KEY = 'admin/Device/numRows';
const SORT_MODEL_KEY = 'admin/Device/sortModel';


export default function Device() {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [devices, setDevices] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(() => {
        const sz = parseInt(localStorage.getItem(NUM_ROWS_KEY));
        if ([25, 50, 100].includes(sz)) return sz;
        return 100;
    });
    const [sortModel, setSortModel] = React.useState(() => {
        const sm = JSON.parse(localStorage.getItem(SORT_MODEL_KEY));
        if (sm) return sm;
        return [{field: 'last_sync_at', sort: 'desc'}];
    });
    const usersById = useLiveQuery(
        () => db.User.toArray(a => a.reduce(
        (o, c) => ({...o, [c.id]: c}), {}
    )), [], {});

    React.useEffect(() => {
        setTitle('Devices');
    }, [], {});

    const onPageSizeChange = (newSize) => {
        localStorage.setItem(NUM_ROWS_KEY, newSize)
        setPageSize(newSize);
    };

    const refreshDevices = () => {
        let params = null;
        setLoading(true);
        axios.get('/admin/device', { params: params })
            .then(
                (result) => {
                    // console.log(result);
                    setLoading(false);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setDevices(result.data.result);
                    }
                },
                (error) => {
                    setLoading(false);
                    setError(error.response.data || error);
                }
            )
    }

    React.useEffect(() => {
        refreshDevices();
        const intervalId = setInterval(refreshDevices, 30000);
        return () => clearInterval(intervalId);
    }, []);

    const [colVis, setColVis] = React.useState(() => {
        const c = localStorage.getItem(COLS_KEY);
        if (c) return JSON.parse(c);
        return {
            first_seen_at: false,
            user_id: false,
            user_name: false,
        }
    })
    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const sendResyncNeeded = React.useCallback((row) => {
        if (window.confirm(`Send Resync Needed to ${row.client_identity} next time it syncs?`)) {
            const params = {
                client_identity: row.client_identity,
            }
            axios.post('/admin/device/sendResyncNeeded', params)
                .then(
                    (result) => {
                        // console.log(result);
                        if (result.data.error) {
                            enqueueSnackbar(result.data.error || result.data.toString(), {
                                variant: 'error', autoHideDuration: 15000,
                            })
                        } else {
                            refreshDevices();
                        }
                    },
                    (error) => {
                        enqueueSnackbar(error.message || error.toString(), {
                            variant: 'error', autoHideDuration: 15000,
                        })
                    }
                )
        }
    }, [enqueueSnackbar]);

    const columns = React.useMemo(() => [
        {
            field: 'client_identity',
            headerName: 'Client Identity',
            width: 300,
        },
        {
            field: 'first_seen_at', headerName: 'First Seen',
            width: 110,
            valueFormatter: (params) => {
                if (!params.value) return null;
                return prettyDateTime(params.value);
            },
        },
        {
            field: 'last_sync_at', headerName: 'Last Sync',
            width: 110,
            valueFormatter: (params) => {
                if (!params.value) return null;
                return prettyDateTime(params.value);
            }
        },
        {
            field: 'versions',
            headerName: 'Version',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.app_version}/${params.row.db_version}`;
            }
        },
        {
            field: 'is_app',
            headerName: 'App?',
            type: 'boolean',
            width: 50,
        },
        {
            field: 'user_agent',
            headerName: 'User Agent',
            flex: 2,
            renderCell: renderCellTooltip,
        },
        {
            field: 'client_ip_address',
            headerName: 'IP Address',
            width: 120,
            renderCell: renderCellTooltip,
        },
        {
            field: 'user_id',
            headerName: 'User ID',
            width: 60,
            type: 'number',
        },
        {
            field: 'user_name',
            headerName: 'User',
            minWidth: 100,
            flex: 1,
            valueGetter: (params) => {
                if (!params.row.user_id || !usersById) return null;
                const u = usersById[params.row.user_id];
                if (!u) return null;
                return `${u.firstname} ${u.surname}`;
            },
            renderCell: renderCellTooltip,
        },
        {
            field: 'user_initials',
            headerName: 'Initials',
            width: 60,
            valueGetter: (params) => {
                if (!params.row.user_id || !usersById) return null;
                return usersById[params.row.user_id]?.initials;
            }
        },
        {
            field: 'revisions',
            headerName: 'Base/Synced/Sent Revision',
            width: 200,
            valueGetter: (params) => {
                const r = params.row;
                return `${r.base_revision}/${r.synced_revision}/${r.last_sent_revision}`;
            }
        },
        {
            field: 'anomaly_count',
            headerName: 'Anomalies',
            width: 60,
            type: 'number',
        },
        {
            field: 'sent_resync_needed_at',
            headerName: 'RS Sent at',
            width: 110,
            valueFormatter: (params) => {
                if (!params.value) return null;
                return prettyDateTime(params.value);
            },
        },
        {
            field: 'send_resync_needed',
            headerName: 'Force Resync',
            width: 50,
            type: 'boolean',
            valueGetter: (params) => {
                return !!params.row.send_resync_needed;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem icon={<CloudSyncIcon />}
                                     label="Send Resync Needed"
                                     title="Send Resync Needed"
                                     onClick={() => sendResyncNeeded(params.row)}
                />
            ]
        }
        ], [usersById, sendResyncNeeded],
    );

    // console.log(usersById);

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={devices}
                          density="compact"
                          sx={{
                              borderRadius: 0,
                              bgcolor: '#ffffff',
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                          }}
                          loading={!devices || loading}
                          pageSize={pageSize}
                          onPageSizeChange={onPageSizeChange}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          hideFooterSelectedRowCount={true}
                          getRowId={(row) => row.client_identity}
                          // onRowClick={onRowClick}
                          sortModel={sortModel}
                          onSortModelChange={(sm) => setSortModel(sm)}
                          columnVisibilityModel={colVis}
                          onColumnVisibilityModelChange={onColVisChange}
                          components={{
                              LoadingOverlay: LinearProgress,
                          }}
                />
            </Box>
        </Box>
    )
}
//
// function CustomToolbar(props) {
//
//     return (
//         <GridToolbarContainer>
//             {/*<GridToolbarColumnsButton />*/}
//             {/*<GridToolbarFilterButton />*/}
//             {/*<GridToolbarDensitySelector />*/}
//             {/*<GridToolbarExport />*/}
//             <Button aria-label="Add User" size="small" startIcon={<MoreTimeIcon />}
//                     sx={{marginLeft: 'auto'}}
//                     onClick={props.addBillingFreq}> New Billing Frequency</Button>
//         </GridToolbarContainer>
//     );
// }
//
// CustomToolbar.propTypes = {
//     addBillingFreq: PropTypes.func.isRequired,
// }



const GridCellTooltip = React.memo(function GridCellTooltip(props) {
    /**
     * This just renders the cell with the value in a tooltip.
     */
    const { value } = props;

    return (
        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
             title={value}>{value}</Box>
    );
});
GridCellTooltip.propTypes = {
    value: PropTypes.string.isRequired,
};

function renderCellTooltip(params) {
    // console.log(params);
    return (
        <GridCellTooltip value={params.formattedValue || ''} />
    );
}
renderCellTooltip.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.string,
};
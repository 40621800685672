import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../common/db";
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {PORTS, setTitle} from "../../common/shared";
import axios from "axios";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select,
    TextField
} from "@mui/material";
import * as PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../contexts/AuthProvider";
import TerminalIcon from "../../icons/TerminalIcon";


const ENTRY_TYPE_LABELS = {
    BLANK: '"Blank" entry',
    DEPOT: 'Depot',
    TERMINAL: 'Terminal',
};

export default function Depot() {
    const auth = React.useContext(AuthContext);
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [depots, setDepots] = React.useState([]);
    const [port, setPort] = React.useState(auth.getDefaultPort() || '');
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {'': '- All Ports -'}))
    , [], {'': '- All Ports-', ...PORTS});

    React.useEffect(() => {
        setTitle('Terminals/Depots');
    }, []);

    const refreshDepots = React.useCallback( () => {
        let params = null;
        if (port) {
            params = {portCode: port}
        }
        axios.get('/admin/depot', { params: params })
            .then(
                (result) => {
                    console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setDepots(result.data.result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }, [port]);

    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            name: '',
            port_code: port,
            entry_type: 'TERMINAL',
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshDepots(); //  easiest way to get the new data (but not really necessary, we could just modify users too)
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setDepots((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params, event, details) => {
        console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    const onPortChange = (event) => {
        // console.log(event);
        setPort(event.target.value);
    }

    React.useEffect(() => {
        refreshDepots();
    }, [refreshDepots]);

    const columns = React.useMemo(() => [
            { field: 'id', headerName: 'ID', width: 50, type: 'number',
                valueGetter: ({ value }) => parseInt(value) },
            { field: 'name', headerName: 'Terminal Name', flex: 1 },
            { field: 'port', headerName: 'Port', flex: 1,
                valueGetter: ({ row }) => ports[row.port_code]
            },
            { field: 'entry_type', headerName: 'Type', flex: 1,
                valueGetter: ({ value }) => ENTRY_TYPE_LABELS[value]
            },
        ], [ports],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={depots}
                          loading={!isLoaded}
                          sx={{
                              bgcolor: '#ffffff',
                              borderRadius: 0,
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                          }}
                          components={{
                              Toolbar: CustomToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  port: port,
                                  ports: ports,
                                  onPortChange: onPortChange,
                                  addDepot: addNewRow
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                          initialState={{
                              sorting: {
                                  sortModel: [{field: 'name', sort: 'asc'}]
                              },
                          }}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                            ports={ports}
                />
            }
        </Box>
    )
}

function CustomToolbar(props) {

    return (
        <GridToolbarContainer>
            <FormControl size="small" sx={{minWidth: "150px", ml: 1, mt: 1}}>
                <InputLabel id="port-id-label">Port</InputLabel>
                <Select
                    labelId="port-id-label"
                    id="port-id"
                    value={props.port}
                    label="Port"
                    onChange={props.onPortChange}
                >
                    {
                        Object.entries(props.ports).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                    }
                </Select>
            </FormControl>
            {/*<GridToolbarColumnsButton />*/}
            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            <Button aria-label="Add Terminal/Depot" size="small" startIcon={<TerminalIcon />}
                    sx={{marginLeft: 'auto'}}
                    onClick={props.addDepot}> New Terminal</Button>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    port: PropTypes.string.isRequired,
    ports: PropTypes.object.isRequired,
    onPortChange: PropTypes.func.isRequired,
    addDepot: PropTypes.func.isRequired,
}

function EditDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [record, setRecord] = React.useState({
        ...props.record,
        port_code: props.record.port_code || 'IEDUB'
    });
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const isNew = !record.id;

    const handleClose = () => {
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {
            axios.post('/admin/depot/save', record)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Terminal Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.depot);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {
        axios.post('/admin/depot/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Terminal Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    let isValid = (!!record.name && !!record.port_code);

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>{isNew ? 'New': 'Edit'} Terminal/Depot</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Terminal/Depot Name"
                    helperText="Name of the Terminal or Depot"
                    type="text"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={record.name}
                    onChange={onFormChange}
                />
                <FormControl size="small" sx={{minWidth: "150px", mt: 1, mr: 1}}>
                    <InputLabel id="depot-port-id-label">Port</InputLabel>
                    <Select
                        labelId="depot-port-id-label"
                        id="depot-port-id"
                        name="port_code"
                        value={record.port_code}
                        label="Port"
                        onChange={onFormChange}
                    >
                        {
                            Object.entries(props.ports).filter(([k,v]) => k !== '')
                                .map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{minWidth: "150px", mt: 1, mr: 1}}>
                    <InputLabel id="entry_type_label">Type</InputLabel>
                    <Select
                        labelId="entry_type_label"
                        id="entry_type"
                        name="entry_type"
                        value={record.entry_type}
                        label="Type"
                        onChange={onFormChange}
                    >
                        {Object.entries(ENTRY_TYPE_LABELS).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving}>{isNew ? `New ${record.entry_type === 'DEPOT' ? 'Depot':'Terminal'}` : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    ports: PropTypes.object.isRequired,
}
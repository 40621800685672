import React from "react";
import {Alert, Box, IconButton, Toolbar, Typography} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import {
    ACT_TYPE, ACT_TYPE_WITH_DATA, formatReefer, LUXON_MYSQL_DATETIME_FMT, prettyDate, prettyDateTime, VENT_POSNS
} from "../../../common/shared";
import {DataGrid} from "@mui/x-data-grid";
import {DateTime} from "luxon";
import ActionDialog from "./ActionDialog";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import SelectActionDialog from "../../user/conts/SelectActionDialog";
import {AuthContext} from "../../../contexts/AuthProvider";
import theme from "../../../theme";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddTaskIcon from '@mui/icons-material/AddTask';

export default function Actions(props) {
    const {uuid, onClose} = props;
    const auth = React.useContext(AuthContext);
    const [error, setError] = React.useState(undefined);
    const [oc, setOc] = React.useState(undefined);
    const [actions, setActions] = React.useState(undefined);
    const [editRow, setEditRow] = React.useState(undefined);
    const [allActsDlgVisible, setAllActsDlgVisible] = React.useState(false);

    const refreshActions = React.useCallback(() => {
        axios.get('/admin/orderContainer/actions', {params: {uuid}})
            .then(
                (response) => {
                    const data = response.data;
                    if (!data) {
                        setError('Missing data in response');
                    } else {
                        if (data.error) setError(data.message);
                        else {
                            setActions(data.actions);
                        }
                    }
                },
                (error) => {
                    setError(error.response?.data?.message || error.toString());
                });
    }, [uuid]);

    React.useEffect(() => {
        axios.get('/admin/orderContainer/get', {params: {uuid}})
            .then(
                (response) => {
                    const data = response.data;
                    if (!data) {
                        setError('Missing data in response');
                    }
                    else {
                        if (data.error) setError(data.message);
                        else {
                            setOc(data.oc);
                            // There's other stuff here like 'port', 'depot', 'customer', 'order' etc too if we need it.
                            // setOrder(data.order);
                            // setCustomer(data.customer);
                        }
                    }
                },
                (error) => {
                    setError(error.response?.data?.message || error.toString());
                });
        refreshActions();
    }, [uuid, refreshActions]);

    const actionTypes = useLiveQuery(
        () => {

            // 'oc' not loaded yet - no actions available.
            if (oc?.status === undefined) return [];
            return db.ContainerStatusActionType.where('status').equals(oc.status).toArray(csats => {
                const actionTypeIds = csats.map(csat => csat.action_type_id);

                return db.ActionType.where('id').anyOf(actionTypeIds)
                    .filter(at => !at.deleted)
                    .sortBy('sort_order')
                    .then(arr => {
                        // Need to filter arr for actionTypes that apply to this container.
                        return arr.filter(at => (
                            // oc doesn't have a location type, or
                            !oc.depot || oc.depot.entry_type === 'BLANK' ||
                            // the action type doesn't care what location type is, or
                            (at.available_in === 'BOTH') ||
                            // the action type location is for a depot, and the oc is in one, or
                            (at.available_in === 'DEPOT' && oc.depot.entry_type === 'DEPOT') ||
                            // the action type location is for a terminal, and the oc is in one.
                            (at.available_in === 'TERMINAL' && oc.depot.entry_type === 'TERMINAL'))
                        );
                    })
                    .then(arr => {
                        // Any UNFLAG action is only available on a flagged container.
                        if (!oc.is_flagged) return arr.filter(at => at.data_type !== ACT_TYPE.UNFLAG);
                        return arr;
                    });
            });
        },
        [oc?.status, oc?.depot_id]
    );

    const handleAddAction = (act = null) => {
        console.log('handleAddAction', act);
        if (!act) setAllActsDlgVisible(true);
        else {
            let rec = {
                action_type_id: act.id,
                actionType: act,
                order_container_uuid: uuid,
                depot_id: oc.depot_id,
                recorded_at: DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT), //.toSQL({includeOffset: false, includeZone: false}),
                recorded_by: parseInt(auth.user.id),
                data_value: act.data_type === ACT_TYPE.SETTEMP ? oc.set_temp?.toString() : null,
                action_date: DateTime.now().setZone('Europe/Dublin').toSQLDate(),
                action_time: DateTime.now().setZone('Europe/Dublin').set({second: 0, millisecond: 0}).toISOTime(
                    {suppressSeconds: true, suppressMilliseconds: true, includeOffset: false}),
                time_slot: null
            };

            //  If the action has a slot, set a sensible default value for it
            if (act.has_slot) {
                const irlTime = DateTime.local({ zone: "Europe/Dublin" }).set({second: 0, millisecond: 0}).toFormat('HH:mm');
                if (irlTime < '11:45') rec.time_slot = 'AM';
                else if (irlTime <= '15:00') rec.time_slot = 'MD';
                else rec.time_slot = 'PM';
            }

            setEditRow(rec);
        }
    };

    const handleEditRow = (row) => {
        console.log('handleEditRow', row);
        setEditRow(row);
    };

    const afterRowSave = (row) => {
        console.log('afterRowSave', row);
        refreshActions();
        setEditRow(undefined);
    };

    const afterRowDelete = (row) => {
        console.log('afterRowDelete', row);
        refreshActions();
        setEditRow(undefined);
    }


    const columns = React.useMemo(() => {
        return [
            {
                field: 'recorded_at',
                headerName: 'Recorded At',
                type: 'dateTime',
                valueGetter: ({value}) => {
                    return value && DateTime.fromSQL(value, { zone: 'utc' }).toLocal().toJSDate();
                },
                minWidth: 150,
                maxWidth: 200,
                flex: 1,
            },
            {
                field: 'action_date',
                headerName: 'Date',
                valueGetter: (params) => {
                    return `${params.row.action_date} ${params.row.action_time ?? ''}`.trim();
                },
                valueFormatter: (params) => {
                    //console.log(params.value);
                    if (!params.value) return '';
                    if (params.value.length === 10) {
                        // just a date.  Give it a dateFmt (to match what prettyDateTime does) so it doesn't look wrong
                        return prettyDate(params.value, {ucFirst: true, dateFmt: 'dd/MM/yy'});
                    }
                    return params.value && prettyDateTime(params.value, {isMagic: true, inTz: 'Europe/Dublin'});
                },
                minWidth: 120,
                maxWidth: 200,
                flex: 1,
            },
            {
                field: 'action_type_id',
                headerName: 'Action',
                type: 'string',
                valueGetter: ({row}) => row?.actionType?.label,
                minWidth: 100,
                maxWidth: 200,
                flex: 1.5,
            },
            {
                field: 'data_value',
                headerName: 'Value',
                type: 'string',
                sortable: false,
                valueGetter: ({row}) => {
                    if (row.actionType.data_type === ACT_TYPE.SETVENT) {
                        return VENT_POSNS[row.data_value] ?? 'Unknown';
                    }
                    if (!ACT_TYPE_WITH_DATA.includes(row.actionType?.data_type)) {
                        return 'N/A'
                    }
                    if (row.actionType?.data_unit) {
                        return `${row.data_value} ${row.actionType?.data_unit}`;
                    }
                    return row.data_value;
                },
                minWidth: 80,
                flex: 2,
            },
            {
                field: 'recorded_by',
                headerName: 'Recorded By',
                type: 'string',
                valueGetter: ({row}) => `${row.recordedBy?.firstname} ${row.recordedBy?.surname}`,
                minWidth: 100,
                flex: 2,
            },
        ];
    }, []);

    const toolbarColour = theme.palette.secondary.main;

    return (
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', bgcolor: 'white'}}>
            <Toolbar
                sx={{ backgroundColor: toolbarColour, color: theme.palette.getContrastText(toolbarColour) }}
                variant={'dense'}
            >
                <IconButton
                    sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5, borderRadius: 0}}
                    aria-label="Back"
                    edge={'start'}
                    onClick={onClose}
                >
                    <ArrowBackIcon fontSize="small" />
                    <Typography variant="caption" sx={{lineHeight: 1}}>Back</Typography>
                </IconButton>
                <Typography variant="h6" component="div" sx={{ml: 2}}>{formatReefer(oc?.reefer_num)}</Typography>
                <IconButton
                    sx={{display: 'flex', flexDirection: 'column', color: 'inherit', py: 0.5, borderRadius: 0, ml: 'auto'}}
                    aria-label="Add Action"
                    edge={'end'}
                    onClick={() => handleAddAction()}
                >
                    <AddTaskIcon fontSize="small" />
                    <Typography variant="caption" sx={{lineHeight: 1}}>Add Action</Typography>
                </IconButton>
            </Toolbar>
            {error && <Alert severity="error">{error}</Alert>}
            <DataGrid
                columns={columns}
                rows={actions || []}
                loading={actions === undefined}
                getRowId={({uuid}) => uuid}
                density="compact"
                hideFooterSelectedRowCount
                sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-cell:focus': {
                        outline: "none",
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'action_date', sort: 'desc'}],
                    },
                    columns: {
                        columnVisibilityModel: {
                            recorded_at: false,
                        }
                    }
                }}
                onRowClick={(params) => handleEditRow(params.row) }
            />
            {
                editRow &&
                <ActionDialog
                    record={editRow}
                    actionType={editRow.actionType}
                    oc={oc}
                    onSave={afterRowSave}
                    onCancel={() => setEditRow(undefined)}
                    onDelete={afterRowDelete}
                />
            }
            {
                allActsDlgVisible &&
                <SelectActionDialog
                    actions={actionTypes}
                    onAction={(act) => {
                        // Ensure that the SelectActionDialog gets hidden
                        setTimeout(() => setAllActsDlgVisible(false), 300);
                        handleAddAction(act);
                    }}
                    onCancel={() => setAllActsDlgVisible(false)} />
            }
        </Box>
    );
}

Actions.propTypes = {
    uuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}
import React from "react";
import {SvgIcon} from "@mui/material";


export default function AddOrderIcon(props) {
    return <SvgIcon {...props}>
        <path d="m2 17h2v1h-1v1h1-2v1h3v-4h-3zm1-9h1v-4h-2v1h1zm-1 3h1.8l-1.8 2v1h3v-1h-1.8l1.8-2v-1h-3zm5-6v2h8v-2zm0 14h14v-2h-14zm0-6h14v-2h-14z"/>
        <path d="m19 4v-3h2v3h3v2h-3v2.5h-2v-2.5h-3v-2z"/>
    </SvgIcon>
}
AddOrderIcon.propTypes = SvgIcon.propTypes;